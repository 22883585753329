import { api } from "@/lib/api-client";

export const EracuniIndex = () => {
  const { data, isFetching, isError, isSuccess } = api.admin.useGetPartners();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (!isSuccess) {
    return <div>Error</div>;
  }

  return <div>st partnerjev {data.partners.length}</div>;
};
