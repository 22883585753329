import { TextVariant } from "@/types/text-variants";
import { Box, Flex, Text, Tooltip } from "@mantine/core";
import dayjs from "dayjs";

export const NotificationTriggerSection = ({
  triggers,
  notificationLog,
  label,
}: {
  triggers: string[];
  notificationLog: {
    trigger: string;
    message_content: string;
    notification_type: string;
    timeSent: string;
    status: "success" | "error" | "PENDING_ACCEPTED";
  }[];
  label: string;
}) => {
  if (
    !notificationLog ||
    !notificationLog.find((nl) => triggers.includes(nl.trigger))
  ) {
    return null;
  }

  const colors = {
    success: "#22c55e",
    PENDING_ACCEPTED: "orange",
    error: "red",
  };

  return (
    <>
      <Text variant={TextVariant.BodyEmphasized}>{label}</Text>
      {notificationLog
        .filter((nl) => triggers.includes(nl.trigger))
        .map((notification, index) => {
          return (
            <Tooltip
              color={"#f8f8f8"}
              label={<Text c={"black"}>{notification.message_content}</Text>}
              multiline
              w={300}
              transitionProps={{
                transition: "skew-up",
                duration: 300,
              }}
              style={{ overflow: "hidden" }}
              key={index}
            >
              <Flex
                justify={"space-between"}
                align={"center"}
                style={{ cursor: "pointer" }}
              >
                <Text variant={TextVariant.Body}>
                  {notification.notification_type}
                </Text>

                <Flex align={"center"} gap={15}>
                  <Text variant={TextVariant.Body}>
                    {dayjs(notification.timeSent).format("DD. MM. HH:mm")}
                  </Text>
                  <Box
                    bg={colors[notification.status]}
                    style={{ borderRadius: "50%" }}
                    h={15}
                    w={15}
                  ></Box>
                </Flex>
              </Flex>
            </Tooltip>
          );
        })}
    </>
  );
};
