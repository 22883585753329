import { api } from "@/lib/api-client";
import {
  ActionIcon,
  Button,
  Chip,
  NumberInput,
  Table,
  Text,
} from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { max } from "lodash";
import { LucideSave, LucideTrash } from "lucide-react";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";

type BillingTierPeriod = {
  periodFrom: Date;
  periodTo: Date;
  priceBeforeDiscount: number;
  discountAmount: number;
  discountType: "percentage" | "amount";
  externalInvoiceId: number | null;
  tierPeriodId: number;
}[];

export const BillingTierPeriods = ({ clientId }: { clientId: number }) => {
  const [numberOfPeriods, setNumberOfPeriods] = useState<string | number>(1);

  const {
    data,
    refetch: refetchBillingData,
    isFetching,
  } = api.admin.useGetClientBilling(clientId);

  const { mutateAsync: createTierPeriods } = api.admin.useCreateTierPeriods(
    Number(numberOfPeriods),
  );

  const handleCreateTierPeriods = async () => {
    await createTierPeriods({ params: { clientId } });
    refetchBillingData();
  };

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>Error</div>;

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex items-end gap-2">
        <NumberInput
          value={numberOfPeriods}
          onChange={setNumberOfPeriods}
          min={1}
          max={5}
          label="st naslednjih obdobij "
        ></NumberInput>
        <Button onClick={handleCreateTierPeriods}>
          Ustvari naslednja obdobja
        </Button>
      </div>

      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Status </Table.Th>
            <Table.Th>Zacetek obdobja </Table.Th>
            <Table.Th>Konec obdobja </Table.Th>
            <Table.Th>
              <div className="flex flex-col">
                <span>Cena pred popustom</span>
                <span className="text-xs text-gray-500">
                  (osnovna cena<br></br> + dodatne funkcije <br></br> + cena za
                  ID pošiljatelja)
                </span>
              </div>
            </Table.Th>
            <Table.Th>Popust</Table.Th>

            <Table.Th>
              <div className="flex flex-col">
                <span>Cena dodatkov</span>
                <span className="text-xs text-gray-500">
                  (lokacije <br></br> + uporabniki <br></br> + resourci)
                </span>
              </div>
            </Table.Th>
            <Table.Th>Skupaj cena s popustom</Table.Th>
            <Table.Th>Shrani</Table.Th>
            <Table.Th>Izbriši</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.billingInfo.tierPeriod.map((period, index) => (
            <TierPeriodRow
              refetchBillingData={refetchBillingData}
              clientId={clientId}
              period={period}
              key={index}
              canBeDeleted={index === data.billingInfo.tierPeriod.length - 1}
            ></TierPeriodRow>
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
};

type TierPeriodRowFormProps = {
  periodFrom: Date;
  periodTo: Date;
  priceBeforeDiscount: number;
  discountAmount: number;
  discountType: "percentage" | "amount";
  externalInvoiceId: string | null;
  tierPeriodId: number;
};

const TierPeriodRow = ({
  period,
  canBeDeleted,
  clientId,
  refetchBillingData,
}: {
  period: {
    periodFrom: Date;
    periodTo: Date;
    priceCentsBeforeDiscount: number;
    discountAmount: number;
    discountType: "percentage" | "amount";
    id: number;
    priceCents: number;
    invoice: {
      externalInvoiceId: string | null;
      InvoiceAdditionalItem: {
        priceCentsTotal: number;
      }[];
    } | null;
  };
  canBeDeleted: boolean;
  clientId: number;
  refetchBillingData: () => void;
}) => {
  const { mutateAsync: deleteTierPeriod } = api.admin.useDeleteTierPeriod();

  const { mutateAsync: updateTierPeriod } = api.admin.useUpdateTierPeriod();

  const form = useForm<TierPeriodRowFormProps>({
    initialValues: {
      periodFrom: period.periodFrom,
      periodTo: period.periodTo,
      priceBeforeDiscount: period.priceCentsBeforeDiscount / 100,
      discountAmount: period.discountAmount,
      discountType: period.discountType,
      externalInvoiceId: period.invoice?.externalInvoiceId ?? null,
      tierPeriodId: period.id,
    },
  });

  const additionalsPrice =
    (period.invoice?.InvoiceAdditionalItem.reduce((acc, curr) => {
      return acc + curr.priceCentsTotal;
    }, 0) ?? 0) / 100;

  console.log("additionals ", period.invoice?.InvoiceAdditionalItem);

  const canBeEdited = !period.invoice?.externalInvoiceId;

  return (
    <Table.Tr>
      <Table.Td>
        {period.invoice?.externalInvoiceId ? (
          <div className="w-fit rounded-full bg-green-600 px-4 py-1 text-white">
            Izdan račun
          </div>
        ) : (
          <></>
        )}
      </Table.Td>
      <Table.Td>{dayjs(period.periodFrom).format("DD. MM. YYYY")}</Table.Td>
      <Table.Td>{dayjs(period.periodTo).format("DD. MM. YYYY")}</Table.Td>
      <Table.Td>
        <span>{period.priceCentsBeforeDiscount / 100}</span>
      </Table.Td>
      <Table.Td>
        <div className="flex items-center gap-2">
          {canBeEdited ? (
            <NumberInput
              min={0}
              className="w-20"
              value={form.values.discountAmount}
              onChange={(value) => {
                if (!value) {
                  form.setFieldValue("discountAmount", 0);
                  return;
                }
                form.setFieldValue("discountAmount", Number(value));
              }}
            ></NumberInput>
          ) : (
            <span>{period.discountAmount}</span>
          )}
          <span>{period.discountType === "percentage" ? "%" : "€"}</span>
        </div>
      </Table.Td>
      <Table.Td>{additionalsPrice}</Table.Td>
      <Table.Td>
        {Math.round(
          (form.values.priceBeforeDiscount + additionalsPrice) *
            (1 - form.values.discountAmount / 100) *
            100,
        ) / 100}
      </Table.Td>
      <Table.Td>
        {form.isDirty() ? (
          <ActionIcon>
            <LucideSave
              onClick={async () => {
                try {
                  await updateTierPeriod({
                    params: { clientId, tierPeriodId: period.id },
                    body: {
                      discountAmount: form.values.discountAmount,
                      discountType: form.values.discountType,
                    },
                  });

                  notifications.show({
                    title: "Obdobje je uspešno posodobljeno",
                    message: "Obdobje je uspešno posodobljeno",
                  });

                  await refetchBillingData();
                } catch (error) {
                  notifications.show({
                    title: "Napaka pri posodabljanju obdobja",
                    message: "Prišlo je do napake pri posodabljanju obdobja",
                    color: "red",
                  });
                }
              }}
            />
          </ActionIcon>
        ) : null}
      </Table.Td>
      <Table.Td>
        {canBeDeleted ? (
          <ActionIcon
            color="red"
            onClick={() => {
              modals.openConfirmModal({
                title: "Izbriši obdobje?",
                children: <Text>Želite izbrisati izbrano obdobje?</Text>,
                labels: { confirm: "Izbriši", cancel: "Prekliči" },
                onConfirm: async () => {
                  try {
                    await deleteTierPeriod({
                      params: { clientId, tierPeriodId: period.id },
                    });
                    notifications.show({
                      title: "Obdobje je uspešno izbrisano",
                      message: "Obdobje je uspešno izbrisano",
                    });
                    await refetchBillingData();
                  } catch (error) {
                    notifications.show({
                      title: "Napaka pri izbrisu obdobja",
                      message: "Prišlo je do napake pri izbrisu obdobja",
                      color: "red",
                    });
                  }
                },
              });
            }}
          >
            <LucideTrash />
          </ActionIcon>
        ) : null}
      </Table.Td>
    </Table.Tr>
  );
};
