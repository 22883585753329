import { Flex, Pagination, Select } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

// all per page options
const perPageOptions = ["10", "25", "50", "100", "1000", "1500"] as const;

export const LimePagination = ({
  page,
  setPage,
  perPage,
  setPerPage,
  pageCount,
  disabled,
  label,
  maxPerPage,
}: {
  page: number;
  setPage: (page: number) => void;
  perPage: string;
  setPerPage: (perPage: string) => void;
  pageCount: number;
  disabled?: boolean;
  label: string;
  maxPerPage?: (typeof perPageOptions)[number];
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Flex
      w={"100%"}
      justify={"space-between"}
      align={isMobile ? "flex-start" : "center"}
      direction={isMobile ? "column-reverse" : "row"}
      gap={"xs"}
    >
      <Pagination
        total={pageCount}
        color={"dark"}
        withEdges
        value={page}
        onChange={setPage}
        disabled={disabled}
        w={isMobile ? "100%" : "auto"}
      />
      <Select
        value={perPage.toString()}
        onChange={(value) => {
          setPerPage(value ?? "10");
        }}
        data={perPageOptions
          .filter((i) => !maxPerPage || parseInt(i) <= parseInt(maxPerPage))
          .map((i) => ({
            label: `${i} ${label}`,
            value: i,
          }))}
        allowDeselect={false}
        w={isMobile ? "100%" : "auto"}
      />
    </Flex>
  );
};
