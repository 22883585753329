import { Flex, Switch } from "@mantine/core";

export const Toggle = ({
  toggle,
  isToggledOn,
  togglePermission,
  isMobile,
  disabled,
}) => {
  let label = "__Missing label";
  if (toggle.label) {
    if (isMobile && toggle.label.length > 30 && typeof toggle.id === "number") {
      label = toggle.label.substring(0, 30) + "...";
    } else {
      label = toggle.label;
    }
  }

  return (
    <Flex
      justify={"space-between"}
      w={"100%"}
      flex={1}
      p={
        isMobile
          ? toggle.isBlock
            ? "20px 0"
            : "0"
          : toggle.isBlock
            ? "10px 20px"
            : "0 20px"
      }
      mb={isMobile || toggle.isBlock ? 0 : "10px"}
      mih={!isMobile && toggle.isBlock ? "80px" : "auto"}
      style={{
        borderBottom: toggle.isBlock ? "1px solid #EAEBED" : "none",
      }}
    >
      <Switch
        disabled={disabled}
        w={"100%"}
        labelPosition="left"
        label={
          <Flex direction={"column"} gap={isMobile ? "15px" : "10px"}>
            <span
              style={{
                fontWeight: toggle.description != null ? "600" : "400",
                lineBreak: isNaN(toggle.id) ? "initial" : "anywhere", // line break anywhere on toggles for location/service/user
                lineHeight: "1.3rem",
              }}
            >
              {label}
            </span>
            {toggle.description && (
              <span style={{ lineHeight: "1.3rem" }}>{toggle.description}</span>
            )}
          </Flex>
        }
        checked={isToggledOn}
        onChange={() => togglePermission(toggle.id)}
        styles={{
          labelWrapper: {
            width: "100%",
          },
          track: {
            alignSelf: "center",
          },
        }}
      />
    </Flex>
  );
};
