import { t } from "@lingui/macro";

export const TIME_UNITS = () => {
  const timeUnits: {
    unit: "hours" | "days" | "minutes";
    label: string;
    rating: number;
  }[] = [
    { unit: "days", label: t`Dni`, rating: 1440 },
    {
      label: t`Ur`,
      unit: "hours",
      rating: 60,
    },
    {
      label: t`Minut`,
      unit: "minutes",
      rating: 1,
    },
  ];

  return timeUnits;
};
