import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Text } from "@mantine/core";
import dayjs from "dayjs";
import styled from "styled-components";
import DayScheduleSettings from "./DayScheduleSettings";
const SpecificShift = ({ data, updateData }) => {
  const shift = data.shifts?.at(0);
  const { i18n } = useLingui();
  const locale = i18n.locale;

  if (!shift) return null;

  const { date } = shift;

  const dateName = dayjs(date).locale(locale).format("dddd");

  return (
    <Wrapper>
      <Text size={"sm"} fw={500}>
        <Trans>Urejanje urnika za</Trans> {dateName}
      </Text>
      <DayScheduleSettings updateData={updateData} data={shift} date={date} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default SpecificShift;
