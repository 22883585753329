import { useEffect, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import styled from "styled-components";

const LoadingSpinner = ({ noTimer = false }) => {
  const [isHidden, setIsHidden] = useState(!noTimer);

  useEffect(() => {
    if (noTimer) return;

    const timer = setTimeout(() => {
      setIsHidden(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <CustomSpinner
      height="100"
      width="100"
      color="#74B587"
      secondaryColor="#649C74"
      radius="12.5"
      ariaLabel="mutating-dots-loading"
      wrapperStyle={{
        justifyContent: "center",
        alignItems: "center",
      }}
      wrapperClass=""
      visible={true}
    />
  );
};

const CustomSpinner = styled(MutatingDots)`
  color: #2f2f2f;
`;

export default LoadingSpinner;
