import { Trans } from "@lingui/macro";
import { Button, Divider, Drawer, Group, Space, Text } from "@mantine/core";
import { areIntervalsOverlapping } from "date-fns/fp";
import _ from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import classes from "../../../../Components/LimeDrawer/LimeDrawer.module.css";
import { useScheduleStore } from "../Stores/useScheduleStore";
import GeneralSettings from "./LocationScheduleModalComponents/GeneralSettings";

const LocationScheduleModal = ({ handleClose, editingSchedule }) => {
  const { createSchedule, updateSchedule, schedules } = useScheduleStore(
    (state) => state,
  );
  const [data, setData] = useState({});

  const [isMutating, setIsMutating] = useState(false);

  const isNewSchedule = data.scheduleId == null;

  useEffect(() => {
    const isNewSchedule = editingSchedule == null || _.isEmpty(editingSchedule);

    setData({
      scheduleId: !isNewSchedule ? editingSchedule.scheduleId : null,
      name: !isNewSchedule ? editingSchedule.name : "",
      locationId: !isNewSchedule ? editingSchedule.locationId : null,
      dateRange: !isNewSchedule ? editingSchedule.dateRange : [null, null],
      color: !isNewSchedule ? editingSchedule.color : null,
      firstShift: !isNewSchedule
        ? dayjs(editingSchedule.firstShift).toDate()
        : null,
      lastShift: !isNewSchedule
        ? dayjs(editingSchedule.lastShift).toDate()
        : null,
    });
  }, [editingSchedule]);

  const submitSchedule = () => {
    if (!canSubmit) return;

    setIsMutating(true);
    if (!isNewSchedule) {
      // UPDATE
      updateSchedule(data).then((r) => {
        if (r.status === 200) {
          setIsMutating(false);
          handleClose();
        }
      });
    } else {
      // CREATE
      createSchedule(data).then((r) => {
        if (r.status === 200) {
          setIsMutating(false);
          handleClose();
        }
      });
    }
  };

  let canSubmit = true;
  if (data.name == null || data.name === "") canSubmit = false;
  if (data.locationId == null) canSubmit = false;

  // get overlapping schedules
  let overlappingSchedules = [];
  if (
    schedules != null &&
    data.dateRange != null &&
    data.dateRange[0] != null &&
    data.dateRange[1] != null
  ) {
    const selectedStartDate = data.dateRange[0];
    const selectedEndDate = data.dateRange[1];

    overlappingSchedules = schedules.filter((schedule) => {
      if (schedule.locationId !== data.locationId) return false;

      // if is same schedule
      if (schedule.scheduleId === data.scheduleId) return false;

      const startDate = schedule.dateRange[0];
      const endDate = schedule.dateRange[1];

      return areIntervalsOverlapping(
        { start: startDate, end: endDate },
        { start: selectedStartDate, end: selectedEndDate },
      );
    });
  }

  if (overlappingSchedules.length > 0) {
    canSubmit = false;
  }
  if (!data.dateRange || !data.dateRange[0] || !data.dateRange[1]) {
    canSubmit = false;
  }

  const shiftsWillBeDeleted =
    (data.firstShift &&
      data.dateRange[0] &&
      data.dateRange[0] > data.firstShift) ||
    (data.lastShift && data.dateRange[1] && data.dateRange[1] < data.lastShift);

  return (
    <Drawer.Root
      opened={editingSchedule != null}
      onClose={handleClose}
      position={"right"}
      padding={"xl"}
      classNames={classes}
      className={classes.root}
      transitionProps={{
        transition: "slide-left",
        duration: 250,
        timingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      }}
    >
      <Drawer.Overlay opacity={0.5} blur={2} />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title>
            <Text fw={500} size={"xl"}>
              <Trans>Dodajanje urnika</Trans>
            </Text>
          </Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body>
          <GeneralSettings
            overlappingSchedules={overlappingSchedules}
            data={data}
            setData={setData}
          />

          {shiftsWillBeDeleted && (
            <Text c="red" size={"sm"} mt={"md"}>
              <Trans>
                Opozorilo: Ta sprememba urnika bo izbrisala izmene izven
                izbranega časovnega obdboja veljavnosti urnika.
              </Trans>
            </Text>
          )}

          <Space h="xl" />

          <Divider py={"10px"} />
          <Group position="right" spacing={"xs"}>
            <Button variant="outline" onClick={handleClose}>
              <Trans>Prekliči</Trans>
            </Button>
            <Button
              disabled={!canSubmit}
              variant="filled"
              onClick={submitSchedule}
              loading={isMutating}
            >
              {!isNewSchedule ? <Trans>Shrani</Trans> : <Trans>Dodaj</Trans>}
            </Button>
          </Group>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

const Wrapper = styled.div``;

export default LocationScheduleModal;
