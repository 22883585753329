import { useEffect, useState } from "react";
import styled from "styled-components";
import LocationBlock from "./LocationBlock/LocationBlock";

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(1, 1fr);
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const LocationTable = (props) => {
  const [selected, setselected] = useState(-1);
  const { locations, modalOpened } = props;

  useEffect(() => {
    if (!modalOpened) setselected(-1);
  }, [modalOpened]);

  return (
    <Wrapper>
      {locations.map((location, id) => {
        return (
          <LocationBlock
            selected={selected}
            setSelected={setselected}
            id={id}
            key={id}
            updateStates={(e) => {
              props.updateStates(e);
            }}
            location={location}
          ></LocationBlock>
        );
      })}
    </Wrapper>
  );
};

export default LocationTable;
