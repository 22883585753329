import { LimeSingleSelect } from "@/Components/NextBase/LimeSelect";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/Components/ui/input-otp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { api } from "@/lib/api-client";
import { useForm } from "@mantine/form";
import { POSPinSetupDialog } from "./Invoices/POS/PinSetupDialog";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { useEffect, useRef } from "react";

export type TaxRegisterAuthorizationForm = {
  userId: number | null;
  PIN: string;
};

export const TaxRegisterAuthorizationDialog = ({
  handleSuccessfulAuthorization,
  authenticatedUserId,
  requiresSetupCompleted = true,
}: {
  handleSuccessfulAuthorization: () => void;
  authenticatedUserId: number | undefined;
  requiresSetupCompleted?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const preselectedUserId = searchParams.get("auid");

  const navigate = useNavigate();

  const {
    onOpen: openCreatePinDialog,
    isOpen: isCreatePinDialogOpen,
    onClose: closeCreatePinDialog,
  } = useDisclosure();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    data: taxRegisterSettings,
    isFetching: isFetchingTaxRegisterSettings,
    processedErrorMessage: taxRegisterSettingsErrorMessage,
  } = api.taxRegister.useGetOrganizationTaxRegisterSettings({});

  const { data: users, isFetching: isUsersFetching } = api.user.useUserList();

  const form = useForm<TaxRegisterAuthorizationForm>({
    initialValues: {
      PIN: "",
      userId: null,
    },

    onValuesChange: (values, previous) => {
      const didUserChange = values.userId !== previous.userId;
      if (didUserChange) {
        form.setFieldValue("locationId", null);
      }
    },

    validate: {
      PIN: (value) =>
        value || taxRegisterSettings?.skipAuthorizationGlobally
          ? null
          : t`Obvezno polje`,
      userId: (value) => (value ? null : t`Obvezno polje`),
    },
  });

  useEffect(() => {
    if (form.getValues().userId != null) {
      inputRef.current?.focus();
      return;
    }

    if (preselectedUserId) {
      form.setFieldValue("userId", Number(preselectedUserId));
      return;
    }

    if (!users) return;

    form.setFieldValue("userId", users.users[0].userId);
  }, [users, form]);

  useEffect(() => {
    if (!taxRegisterSettings || !users) return;

    if (
      taxRegisterSettings.skipAuthorizationGlobally &&
      users?.users.length === 1
    ) {
      handleSubmit({
        userId: users.users[0].userId,
        PIN: "",
      });
    }
  }, [taxRegisterSettings, users]);

  const {
    data: userHasPin,
    isFetching: isUserHasPinFetching,
    refetch: refetchUserHasPin,
  } = api.user.useGetUserHasPin({
    pinType: "POS",
    userId: form.getValues().userId || undefined,
  });

  const {
    mutateAsync: verifyUserPIN,
    isPending: isVerifyingUserPIN,
    processedErrorMessage: verifyUserPINErrorMessage,
  } = api.user.useVerifyUserPIN();

  const {
    mutateAsync: organizationSetupProgress,
    isPending: isCheckingSetupProgress,
  } = api.taxRegister.useGetOrganizationSetupProgressMutation();

  const handleAuthorizeModalClose = (open: boolean) => {
    if (!open) {
      navigate("/dashboard/");
    }
  };
  const handleSubmit = async (data: TaxRegisterAuthorizationForm) => {
    await verifyUserPIN({
      PIN: data.PIN,
      type: "POS",
      userId: data.userId!,
    });

    if (requiresSetupCompleted) {
      const setupProgress = await organizationSetupProgress();
      if (!setupProgress.isSetupCompleted) {
        navigate(`/dashboard/tax-register/settings/setup?auid=${data.userId}`);
        return;
      }
    }

    handleSuccessfulAuthorization();

    form.reset();
  };

  if (isCreatePinDialogOpen && form.getValues().userId != null) {
    return (
      <POSPinSetupDialog
        userId={form.getValues().userId!}
        handleCreatedPin={() => {
          refetchUserHasPin();
          closeCreatePinDialog();
        }}
        handleAuthorizeModalClose={() => {
          closeCreatePinDialog();
        }}
      />
    );
  }

  return (
    <Modal
      isOpen={authenticatedUserId == null}
      onOpenChange={handleAuthorizeModalClose}
    >
      <ModalContent>
        {(onClose) => (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <ModalHeader>
              <Trans>Avtorizacija</Trans>
            </ModalHeader>

            <ModalBody className="flex flex-col items-center justify-center gap-4">
              <LimeSingleSelect
                label={t`Zaposleni`}
                items={
                  users?.users.map((user) => ({
                    key: user.userId.toString(),
                    label: `${user.name} ${user.lastName}`,
                  })) || []
                }
                isLoading={
                  isUsersFetching ||
                  isFetchingTaxRegisterSettings ||
                  isVerifyingUserPIN
                }
                {...form.getInputProps("userId")}
              />

              {form.getValues().userId != null &&
                !isUserHasPinFetching &&
                !userHasPin?.hasPin && (
                  <div className="flex flex-col items-center gap-2">
                    <p>
                      <Trans>Nimate PIN kode</Trans>
                    </p>
                    <Button onClick={openCreatePinDialog} color="primary">
                      <Trans>Ustvari PIN kodo</Trans>
                    </Button>
                  </div>
                )}
              {(form.getValues().userId == null || userHasPin?.hasPin) &&
                !taxRegisterSettings?.skipAuthorizationGlobally && (
                  <div className="flex flex-col items-center gap-2">
                    <p className="font-semibold">
                      <Trans>PIN Koda</Trans>
                    </p>
                    <InputOTP
                      maxLength={4}
                      {...form.getInputProps("PIN")}
                      type="password"
                      ref={inputRef}
                      onComplete={() => {
                        if (form.isValid()) {
                          handleSubmit(form.values);
                        }
                      }}
                    >
                      <InputOTPGroup className="w-full">
                        <InputOTPSlot
                          index={0}
                          inputMode="numeric"
                          isPassword
                        />
                        <InputOTPSlot
                          index={1}
                          inputMode="numeric"
                          isPassword
                        />
                        <InputOTPSlot
                          index={2}
                          inputMode="numeric"
                          isPassword
                        />
                        <InputOTPSlot
                          index={3}
                          inputMode="numeric"
                          isPassword
                        />
                      </InputOTPGroup>
                    </InputOTP>

                    {form.errors.PIN && (
                      <p className="text-xs text-red-500">{form.errors.PIN}</p>
                    )}
                  </div>
                )}

              <ErrorCard
                message={verifyUserPINErrorMessage}
                className="w-full"
              />
              <ErrorCard
                message={taxRegisterSettingsErrorMessage}
                className="w-full"
              />
            </ModalBody>

            <ModalFooter>
              <Button
                type="submit"
                isDisabled={isUsersFetching || isUserHasPinFetching}
                isLoading={isVerifyingUserPIN || isCheckingSetupProgress}
                color="primary"
              >
                <Trans>Potrdi</Trans>
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};
