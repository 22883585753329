import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Box, Flex, SwitchProps, Text } from "@mantine/core";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabelProps } from "./LimeInputLabel";

export const LimeTextDisplay = ({
  text,
  ...props
}: {
  text: string;
} & LimeInputLabelProps &
  SwitchProps) => {
  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);

  return (
    <Box {...spacingProps} h={50}>
      <Flex
        bg={"#E6F5E8"}
        h={"100%"}
        align={"center"}
        justify={"center"}
        style={{
          border: `1px solid ${Color.Brand}`,
          borderRadius: "8px",
        }}
      >
        <Text
          c={Color.Brand}
          variant={TextVariant.BodyEmphasized}
          {...propsWithoutSpacing}
        >
          {text}
        </Text>
      </Flex>
    </Box>
  );
};
