import { MantineSpacing } from "@mantine/core";
import { omit, pick } from "lodash";

const MantineSpacingKeys = [
  "m",
  "my",
  "mx",
  "mt",
  "mb",
  "ml",
  "mr",
  "p",
  "py",
  "px",
  "pt",
  "pb",
  "pl",
  "pr",
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractSpacingProps = (props: any) => {
  const spacingProps = pick(props, ...MantineSpacingKeys) as MantineSpacing[];
  const propsWithoutSpacing = omit(props, ...MantineSpacingKeys);

  return {
    propsWithoutSpacing,
    spacingProps,
  };
};
