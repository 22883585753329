import { useLingui } from "@lingui/react";
import { useEffect, useState } from "react";
import { apiGetServices } from "../../../apiCalls";

/**
 * @deprecated Use `useGetServices` hook instead
 */
const useServices = (showNotifications = true) => {
  useLingui();
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [tags, setTags] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    updateServices().then(() => {
      setIsLoading(false);
    });
  }, []);

  const updateServices = async () => {
    setIsLoading(true);
    setError(null);
    const token = localStorage.getItem("usr_token") || "";
    const ser = await apiGetServices(token);
    if (ser.status === 200) {
      setServices(ser.data.services);
      setTags(ser.data.tags);
      setUsers(ser.data.users);
    } else {
      setError({ status: ser.status, message: ser.statusText });
    }
    setIsLoading(false);
  };

  return {
    services,
    updateServices,
    isLoading,
    error,
    tags,
    setTags,
    users,
  };
};

export default useServices;
