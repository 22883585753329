import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { api } from "@/lib/api-client";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Loader,
  NumberInput,
  Text,
} from "@mantine/core";
import { type UseFormReturnType, useForm } from "@mantine/form";
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { LuCopy, LuExternalLink } from "react-icons/lu";
import type {
  GetClientSettings,
  PutClientSettings,
} from "../../../../../server/src/types";

type ClientSettingsForm = {
  restrictLunchTime: boolean;
  maxLunchTimeMinutes: number | null;

  rescheduleMinRescheduleHours: number;
  rescheduleMinCancelHours: number;

  allowDuplicateCustomerOnAppointment: boolean;
};
export const ClientSettings = () => {
  const {
    data: settings,
    isPending: isSettingsPending,
    isSuccess: isSettingsSuccess,
  } = api.client.useClientSettings();

  const { mutateAsync: updateSettings, isPending: isUpdateSettingsPending } =
    api.client.usePutClientSettings();

  const form = useForm<ClientSettingsForm>({
    mode: "controlled",
    initialValues: {
      restrictLunchTime: false,
      maxLunchTimeMinutes: null,
      rescheduleMinCancelHours: 0,
      rescheduleMinRescheduleHours: 0,
      allowDuplicateCustomerOnAppointment: false,
    },

    validate: {
      maxLunchTimeMinutes: (value) => {
        if (form.getValues().restrictLunchTime && (!value || value === 0)) {
          return t`Polje je obvezno`;
        }
      },
    },
  });

  useEffect(() => {
    if (settings) {
      form.setValues({
        restrictLunchTime: Boolean(settings.maxLunchTimeMinutes),
        maxLunchTimeMinutes: settings.maxLunchTimeMinutes,
        rescheduleMinCancelHours: settings.rescheduleMinCancelHours,
        rescheduleMinRescheduleHours: settings.rescheduleMinRescheduleHours,
        allowDuplicateCustomerOnAppointment:
          settings.allowDuplicateCustomerOnAppointment,
      });
    }
  }, [settings]);

  const handleSubmitForm = async (values: ClientSettingsForm) => {
    const data: PutClientSettings["body"] = {
      ...values,
      maxLunchTimeMinutes: values.restrictLunchTime
        ? values.maxLunchTimeMinutes
        : null,
    };

    await updateSettings(data);

    notifications.show({
      message: t`Nastavitve so bile uspešno shranjene`,
      autoClose: 5000,
    });
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <LimePageHeader title={t`Nastavitve`} />

      {isSettingsPending && <Loader />}

      {isSettingsSuccess && (
        <Box>
          <form onSubmit={form.onSubmit(handleSubmitForm)}>
            <Box mt={"md"} px={isMobile ? "sm" : "xl"} maw={"550"}>
              <GeneralSettings form={form} />
            </Box>

            <Divider my={"md"} />

            <Box mt={"md"} px={isMobile ? "sm" : "xl"} maw={"550px"}>
              <FormLinks hashes={settings.formHashes} />
            </Box>

            <Divider my={"md"} />

            <Box px={isMobile ? "sm" : "xl"} maw={"550px"}>
              <RescheduleSettings form={form} />
            </Box>

            <Divider my={"md"} />

            <Box px={isMobile ? "sm" : "xl"} maw={"550px"}>
              <BreakSettings form={form} />
            </Box>

            <Divider my={"md"} />

            <Box px={isMobile ? "sm" : "xl"} maw={"550px"}>
              <Button type={"submit"} loading={isUpdateSettingsPending}>
                <Trans>Shrani</Trans>
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};

const FormLinks = ({
  hashes,
}: {
  hashes: GetClientSettings["response"]["formHashes"];
}) => {
  const clipboard = useClipboard();

  return (
    <>
      <Text variant={TextVariant.Subheading} mb={"md"}>
        <Trans>Povezave do form</Trans>
      </Text>

      <Flex direction={"column"} gap={"sm"}>
        {hashes.map((hash) => {
          const formLink = `${import.meta.env.VITE_FORM_URL}/${hash}`;

          const copyLink = () => {
            clipboard.copy(formLink);

            notifications.show({
              title: t`Povezava kopirana`,
              message: formLink,
              autoClose: 5000,
            });
          };

          return (
            <Flex gap={"md"} key={hash}>
              <ActionIcon onClick={copyLink}>
                <LuCopy />
              </ActionIcon>

              <Anchor
                key={hash}
                href={formLink}
                target={"_blank"}
                rel={"noreferrer"}
              >
                <Flex align={"center"} gap={"xs"}>
                  <LuExternalLink />
                  {hash}
                </Flex>
              </Anchor>
            </Flex>
          );
        })}
      </Flex>
    </>
  );
};

const RescheduleSettings = ({
  form,
}: {
  form: UseFormReturnType<ClientSettingsForm>;
}) => {
  return (
    <>
      <Text variant={TextVariant.Subheading} mb={"md"}>
        <Trans>Nastavitve prenaročanja</Trans>
      </Text>

      <NumberInput
        label={t`Minimalno število ur pred prenaročanjem`}
        {...form.getInputProps("rescheduleMinRescheduleHours")}
        mb={"sm"}
      />

      <NumberInput
        label={t`Minimalno število ur pred odpovedjo`}
        {...form.getInputProps("rescheduleMinCancelHours")}
      />
    </>
  );
};

const BreakSettings = ({
  form,
}: {
  form: UseFormReturnType<ClientSettingsForm>;
}) => {
  return (
    <>
      <Text variant={TextVariant.Subheading} mb={"md"}>
        <Trans>Nastavitve pavz</Trans>
      </Text>

      <LimeSwitch
        label={t`Omeji dolžino malice`}
        {...form.getInputProps("restrictLunchTime", {
          type: "checkbox",
        })}
        mb={"md"}
      />

      <Collapse in={form.getValues().restrictLunchTime}>
        <NumberInput
          label={t`Največja dolžina malice v minutah`}
          min={0}
          step={5}
          {...form.getInputProps("maxLunchTimeMinutes")}
        />
      </Collapse>
    </>
  );
};

const GeneralSettings = ({
  form,
}: {
  form: UseFormReturnType<ClientSettingsForm>;
}) => {
  return (
    <>
      <Text variant={TextVariant.Subheading} mb={"md"}>
        <Trans>Splošno</Trans>
      </Text>

      <LimeSwitch
        label={t`Privzeto dovoli podvojene stranke na terminih`}
        {...form.getInputProps("allowDuplicateCustomerOnAppointment", {
          type: "checkbox",
        })}
        mb={"md"}
      />
    </>
  );
};
