import {
  SelectProps,
  Select,
  SelectItem,
  SelectSection,
} from "@nextui-org/react";
import { MantineFormValues } from "./shared.types";

export type SingleSelect = Omit<MantineFormValues & SelectProps, "children"> & {
  items: {
    label: string;
    key: string;
    sectionName?: string;
    startContent?: React.ReactNode;
  }[];
};

export const LimeSingleSelect = ({ error, value, ...props }: SingleSelect) => {
  const itemsBySection = props.items.reduce(
    (acc, item) => {
      const section = item.sectionName || "default";
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(item);
      return acc;
    },
    {} as Record<string, typeof props.items>,
  );

  const renderItems = () => {
    return Object.keys(itemsBySection)
      .map((sectionName) => {
        const items = itemsBySection[sectionName];

        if (sectionName === "default") {
          return items.map((item) => (
            <SelectItem key={item.key} startContent={item.startContent}>
              {item.label}
            </SelectItem>
          ));
        }

        return (
          <SelectSection key={sectionName} title={sectionName} showDivider>
            {items.map((item) => (
              <SelectItem key={item.key} startContent={item.startContent}>
                {item.label}
              </SelectItem>
            ))}
          </SelectSection>
        );
      })
      .flat(); // Flatten the resulting array
  };

  return (
    <Select
      errorMessage={error}
      isInvalid={!!error}
      selectedKeys={value ? [value.toString()] : []}
      {...props}
      onChange={(e) => props.onChange(e.target.value)}
      selectionMode="single"
      disallowEmptySelection
    >
      {renderItems()}
    </Select>
  );
};
