import {
  GetAnalyitcsUserChangeLog,
  GetAnalyticsUserWorkTime,
  GetAnalyticsUsersGeneral,
  GetCustomerDataForDateSpan,
  GetRevenue,
  GetUserActivityPerDate,
} from "@/server-types";
import { useQuery } from "@tanstack/react-query";
import { get } from "../request-helpers";

/**
 * GET /analytics/revenue
 */
export const useRevenue = (
  query: GetRevenue["query"],
  enableQuery: boolean,
) => {
  return useQuery({
    queryKey: ["analyticsRevenue", query],
    queryFn: () => get<GetRevenue>("/analytics/revenue", query),
    enabled: enableQuery,
  });
};

/**
 * GET /analytics/customers
 */
export const useCustomerDataForDateSpan = (
  query: GetCustomerDataForDateSpan["query"],
  enableQuery: boolean,
) => {
  return useQuery({
    queryKey: ["analyticsCustomers", query],
    queryFn: () =>
      get<GetCustomerDataForDateSpan>("/analytics/customers", query),
    enabled: enableQuery,
  });
};

/**
 * GET /analytics/users/activity_per_date
 */
export const useUserActivityPerDate = (
  query: GetUserActivityPerDate["query"],
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ["analyticsUserActivity", query],
    queryFn: ({ signal }) =>
      get<GetUserActivityPerDate>("/analytics/users/activity_per_date", query, {
        signal,
      }),
    enabled,
    placeholderData: (prevData) => {
      return prevData;
    },
  });
};

/**
 * GET /analytics/users/general
 */
export const useAnalyticsUserGeneral = (
  query: GetAnalyticsUsersGeneral["query"],
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ["analyticsUserGeneral", query],
    queryFn: ({ signal }) =>
      get<GetAnalyticsUsersGeneral>("/analytics/users/general", query, {
        signal,
      }),
    enabled,
    placeholderData: (prevData) => {
      return prevData;
    },
  });
};

/**
 * GET /analytics/users/work_time
 */
export const useAnalyticsUserWorkTime = (
  query: GetAnalyticsUserWorkTime["query"],
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ["analyticsUserWorkTime", query],
    queryFn: ({ signal }) =>
      get<GetAnalyticsUserWorkTime>("/analytics/users/work_time", query, {
        signal,
      }),
    enabled,
    placeholderData: (prevData) => {
      return prevData;
    },
  });
};

/**
 * GET /analytics/users/change_log
 */
export const useAnalyticsChangeLog = (
  query: GetAnalyitcsUserChangeLog["query"],
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ["analyticsChangeLog", query],
    queryFn: ({ signal }) =>
      get<GetAnalyitcsUserChangeLog>("/analytics/users/change_log", query, {
        signal,
      }),
    enabled,
    placeholderData: (prevData) => {
      return prevData;
    },
  });
};
