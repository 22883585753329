import type {
  DeleteTierPeriod,
  DeleteTimeOffs,
  GetClientBilling,
  GetClientDetails,
  GetClientFeatures,
  GetClientForm,
  GetClientForms,
  GetClientList,
  GetClientNotifications,
  GetInvoices,
  GetPartners,
  GetPricingTier,
  GetPricingTierById,
  GetTimeOffs,
  GetTotalPrice,
  PostActivateBilling,
  PostClientForm,
  PostClientLogo,
  PostClientTierPeriod,
  PostDeactivateBilling,
  PostFormBackgroundImage,
  PostImportServices,
  PostLocationImage,
  PostLoginAsAdmin,
  PostServiceImage,
  PostUserImage,
  PostValidateServiceData,
  PutClientBilling,
  PutClientDetails,
  PutClientFeatures,
  PutClientForm,
  PutPricingTier,
  PutTierPeriod,
} from "@/server-types";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { del, get, post, put } from "../request-helpers";

/**
 * GET /lime-admin/clients
 */
export const useClientList = (query: GetClientList["query"]) => {
  return useQuery({
    queryKey: ["clientList", query],
    queryFn: () => get<GetClientList>("/lime-admin/clients", query),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
};

/**
 * GET /lime-admin/client/[clientId]
 */
export const useClientDetails = (
  // query: GetClientDetails["query"],
  clientId: number,
) => {
  return useQuery({
    queryKey: ["clientDetails", clientId],
    queryFn: () => get<GetClientDetails>(`/lime-admin/client/${clientId}`),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
  });
};

/**
 * PUT /lime-admin/client/[clientId]
 */
export const useUpdateClientDetails = () => {
  return useMutation({
    mutationFn: ({
      clientId,
      body,
    }: {
      clientId: number;
      body: PutClientDetails["body"];
    }) => put<PutClientDetails>(`/lime-admin/client/${clientId}`, body),
  });
};

/**
 * GET /lime-admin/client/[clientId]/features
 */
export const useClientFeatures = ({
  clientId,
}: GetClientFeatures["params"]) => {
  return useQuery({
    queryKey: ["clientList", clientId],
    queryFn: () =>
      get<GetClientFeatures>(`/lime-admin/client/${clientId}/features`),
  });
};

/**
 * PUT /lime-admin/client/[clientId]/features
 */
export const useSaveClientFeatures = (clientId: number) => {
  return useMutation({
    mutationFn: (body: PutClientFeatures["body"]) =>
      put<PutClientFeatures>(`/lime-admin/client/${clientId}/features`, body),
  });
};

/**
 * POST /lime-admin/client/[clientId]/features
 */
export const useLoginAsAdmin = () => {
  return useMutation({
    mutationFn: (clientId: PostLoginAsAdmin["params"]["clientId"]) =>
      post<PostLoginAsAdmin>(`/lime-admin/client/${clientId}/login`),
  });
};

/**
 * POST /
 */
export const useUploadUserImage = () => {
  return useMutation({
    mutationFn: (props: { body: FormData; params: PostUserImage["params"] }) =>
      post(
        `/lime-admin/client/${props.params.clientId}/user/${props.params.userId}/image`,
        props.body as unknown as { image: string }, // no bueno ampak bueno
      ),
  });
};

/**
 * POST /
 */
export const useUploadServiceImage = () => {
  return useMutation({
    mutationFn: (props: {
      body: FormData;
      params: PostServiceImage["params"];
    }) =>
      post(
        `/lime-admin/client/${props.params.clientId}/service/${props.params.serviceId}/image`,
        props.body as unknown as { image: string }, // no bueno ampak bueno
      ),
  });
};

export const useUploadLocationImage = () => {
  return useMutation({
    mutationFn: (props: {
      body: FormData;
      params: PostLocationImage["params"];
    }) =>
      post(
        `/lime-admin/client/${props.params.clientId}/location/${props.params.locationId}/image`,
        props.body as unknown as { image: string }, // no bueno ampak bueno
      ),
  });
};

export const useUploadClientLogo = () => {
  return useMutation({
    mutationFn: (props: { body: FormData; params: PostClientLogo["params"] }) =>
      post(
        `/lime-admin/client/${props.params.clientId}/logo`,
        props.body as unknown as { image: string }, // no bueno ampak bueno
      ),
  });
};

export const useUploadFormBackgroundImage = () => {
  return useMutation({
    mutationFn: (props: {
      body: FormData;
      params: PostFormBackgroundImage["params"];
    }) =>
      post(
        `/lime-admin/client/${props.params.clientId}/form/${props.params.formId}/image`,
        props.body as unknown as { image: string }, // no bueno ampak bueno
      ),
  });
};

/**
 * GET /lime-admin/clients
 */
export const useClientNotifications = (
  clientId: GetClientNotifications["params"]["clientId"],
  query: GetClientNotifications["query"],
) => {
  return useQuery({
    queryKey: ["clientNotifications", clientId, query],
    queryFn: () =>
      get<GetClientNotifications>(
        `/lime-admin/client/${clientId}/notifications`,
        query,
      ),
    placeholderData: keepPreviousData,
    gcTime: 0,
  });
};

/**
 * GET /lime-admin/client/[clientId]/form
 */
export const useClientForms = (
  clientId: GetClientForms["params"]["clientId"],
) => {
  return useQuery({
    queryKey: ["clientNotifications", clientId],
    queryFn: () => get<GetClientForms>(`/lime-admin/client/${clientId}/form`),
    placeholderData: keepPreviousData,
  });
};

/**
 * GET /lime-admin/client/[clientId]/form/[formId]
 */
export const useClientForm = ({
  clientId,
  formId,
}: {
  clientId: GetClientForm["params"]["clientId"];
  formId: GetClientForm["params"]["formId"];
}) => {
  return useQuery({
    queryKey: ["clientNotifications", clientId, formId],
    queryFn: () =>
      get<GetClientForm>(`/lime-admin/client/${clientId}/form/${formId}`),
    gcTime: 0,
  });
};

/**
 * PUT /lime-admin/client/[clientId]/form/[formId]
 */
export const useUpdateClientForm = () => {
  return useMutation({
    mutationFn: ({
      body,
      params,
    }: {
      body: PutClientForm["body"];
      params: PutClientForm["params"];
    }) =>
      put<PutClientForm>(
        `/lime-admin/client/${params.clientId}/form/${params.formId}`,
        body,
      ),
  });
};

/**
 * DELETE /lime-admin/client/[clientId]/form/[formId]
 */
export const useDeleteClientForm = () => {
  return useMutation({
    mutationFn: ({ clientId, formId }: PutClientForm["params"]) =>
      del<PutClientForm>(`/lime-admin/client/${clientId}/form/${formId}`),
  });
};

/**
 * POST /lime-admin/client/[clientId]/form/
 */
export const useCreateClientForm = () => {
  return useMutation({
    mutationFn: ({
      clientId,
      formHash,
    }: {
      clientId: PostClientForm["params"]["clientId"];
      formHash: PostClientForm["body"]["formHash"];
    }) =>
      post<PostClientForm>(`/lime-admin/client/${clientId}/form`, { formHash }),
  });
};

export const useGetTimeOffs = (query: GetTimeOffs["query"]) => {
  return useQuery({
    queryKey: ["TimeOffs", query],
    queryFn: () => get<GetTimeOffs>("/lime-admin/timeoff", query),
    placeholderData: keepPreviousData,
  });
};

export const useDeleteTimeOffs = () => {
  return useMutation({
    mutationFn: (body: DeleteTimeOffs["query"]) =>
      del<DeleteTimeOffs>("/lime-admin/timeoff", body),
  });
};

export const useValidateServiceData = () => {
  return useMutation({
    mutationFn: (body: PostValidateServiceData["body"]) =>
      post<PostValidateServiceData>(
        "/lime-admin/import/validateServiceData",
        body,
      ),
  });
};

export const useImportServices = () => {
  return useMutation({
    mutationFn: (body: PostImportServices["body"]) =>
      post<PostImportServices>("/lime-admin/import/importServices", body),
  });
};

export const useGetTotalPrice = (clientId: number) => {
  return useQuery({
    queryKey: ["total-price", clientId],
    queryFn: () =>
      get<GetTotalPrice>(`/lime-admin/client/${clientId}/price-total`),
  });
};

export const useGetPricingTiers = () => {
  return useQuery({
    queryKey: ["pricing-tiers"],
    queryFn: () => get<GetPricingTier>("/lime-admin/pricing-tier"),
  });
};

export const useGetPricingTier = (pricingTierId: string | undefined) => {
  return useQuery({
    queryKey: ["pricing-tier", pricingTierId],
    queryFn: () =>
      get<GetPricingTierById>(`/lime-admin/pricing-tier/${pricingTierId}`),
    enabled: !!pricingTierId,
  });
};

export const usePutPricingTier = () => {
  return useMutation({
    mutationFn: ({
      body,
      pricingTierId,
    }: {
      body: PutPricingTier["body"];
      pricingTierId: string;
    }) =>
      put<PutPricingTier>(`/lime-admin/pricing-tier/${pricingTierId}`, body),
  });
};

/**
 * ERACUNI
 */
export const useGetPartners = () => {
  return useQuery({
    queryKey: ["eracuni-partners"],
    queryFn: () => get<GetPartners>("/lime-admin/eracuni/partner"),
    // placeholderData: keepPreviousData,
  });
};

export const useGetClientBilling = (clientId: number) => {
  return useQuery({
    queryKey: ["client-billing", clientId],
    queryFn: () =>
      get<GetClientBilling>(`/lime-admin/client/${clientId}/billing`),
    // placeholderData: keepPreviousData,
  });
};

export const usePutClientBilling = () => {
  return useMutation({
    mutationFn: ({
      body,
      params,
    }: {
      body: PutClientBilling["body"];
      params: PutClientBilling["params"];
    }) =>
      put<PutClientBilling>(
        `/lime-admin/client/${params.clientId}/billing`,
        body,
      ),
  });
};

export const useActivateBilling = () => {
  return useMutation({
    mutationFn: ({ params }: { params: PostActivateBilling["params"] }) =>
      post<PostActivateBilling>(
        `/lime-admin/client/${params.clientId}/billing/activate`,
      ),
  });
};

export const useDeactivateBilling = () => {
  return useMutation({
    mutationFn: ({ params }: { params: PostDeactivateBilling["params"] }) =>
      post<PostDeactivateBilling>(
        `/lime-admin/client/${params.clientId}/billing/deactivate`,
      ),
  });
};

export const useCreateTierPeriods = (numberOfPeriods: number) => {
  return useMutation({
    mutationFn: ({ params }: { params: PostClientTierPeriod["params"] }) =>
      post<PostClientTierPeriod>(
        `/lime-admin/client/${params.clientId}/billing/tier-period`,
        { numberOfPeriods },
      ),
  });
};

export const useDeleteTierPeriod = () => {
  return useMutation({
    mutationFn: ({ params }: { params: DeleteTierPeriod["params"] }) =>
      del<DeleteTierPeriod>(
        `/lime-admin/client/${params.clientId}/tier-period/${params.tierPeriodId}`,
      ),
  });
};

export const useUpdateTierPeriod = () => {
  return useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: PutTierPeriod["params"];
      body: PutTierPeriod["body"];
    }) =>
      put<PutTierPeriod>(
        `/lime-admin/client/${params.clientId}/tier-period/${params.tierPeriodId}`,
        body,
      ),
  });
};

export const useGetInvoices = (clientId: number) => {
  return useQuery({
    queryKey: ["invoices", clientId],
    queryFn: () => get<GetInvoices>(`/lime-admin/client/${clientId}/invoice`),
  });
};
