import { useMutation } from "@tanstack/react-query";
import { DeleteHttpCookie, GetPosToken } from "@/server-types";
import { del, get } from "../request-helpers";
import { useCustomQuery } from "../custom-query";
import { queryClient } from "..";

export const useDeleteHttpCookie = () => {
  return useMutation({
    mutationFn: ({ cookieName }: { cookieName: string }) =>
      del<DeleteHttpCookie>(`/cookie/${cookieName}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getPosToken"] });
    },
  });
};

export const useGetPosToken = () => {
  return useCustomQuery({
    queryKey: ["getPosToken"],
    queryFn: () => get<GetPosToken>(`/cookie/pos`),
    placeholderData: null,
    // gcTime: 0,
    // refetchInterval: 5000,
    // refetchOnMount: true,
    // refetchOnReconnect: true,
    // refetchOnWindowFocus: true,
  });
};
