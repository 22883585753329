import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useCalendarStore } from "../../store/useCalendarStore";
import useDrag from "../hooks/useDrag";
import DayWeekCalendar from "./DayWeekCalendar";
import MonthCalendar from "./MonthCalendar";
import { RescheduleModal } from "./RescheduleModal/RescheduleModal";

const Calendar = ({ setIsMonthCalendar, isFetchingTimeoffs }) => {
  const {
    calendarData,
    calendarView,
    fetchingCalendarData,
    isRefetchingCalendarDate,
    rescheduleData,
  } = useCalendarStore((state) => state);

  const rescheduleForm = useForm({
    initialValues: {
      rescheduleAllLinked: true,
      allLinkedSameUserOrResource: false,
      sendNotifications: true,
      overrideUserService: false,
      overrideResourceService: false,
    },
  });

  const { handleDragEnd, handleDragMove, handleDragStart } = useDrag();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  useEffect(() => {
    setIsMonthCalendar(calendarView === "month");
  }, [calendarView]);

  useEffect(() => {
    if (rescheduleData) {
      rescheduleForm.reset();
    }
  }, [rescheduleData]);

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      sensors={sensors}
      onDragMove={handleDragMove}
      onDragStart={handleDragStart}
    >
      <>
        <LoadingOverlay
          transitionDuration={500}
          visible={
            (fetchingCalendarData || isFetchingTimeoffs) &&
            !isRefetchingCalendarDate
          }
          overlayBlur={2}
        />

        {/* calendar for day and week view */}
        {calendarView !== "month" && calendarData?.days?.length < 10 && (
          <DayWeekCalendar />
        )}

        {/* calendar for month view */}
        {calendarView === "month" && calendarData?.days?.length > 10 && (
          <MonthCalendar />
        )}
      </>

      {rescheduleData ? <RescheduleModal></RescheduleModal> : null}
    </DndContext>
  );
};

export default Calendar;
