import { Trans, t } from "@lingui/macro";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { apiDeleteAppointment } from "../../../../../Components/AppointmentModal/apiAppointment";
import {
  deleteUserAppointment,
  updateUserAppointmentAttendance,
  updateUserAppointmentComment,
} from "../../../../../apiSchedule";
import CustomerAppointment from "./CustomerAppointment";

import { notifications } from "@mantine/notifications";

const CustomerAppointments = ({
  customerData,
  isMobile,
  setCustomerData,
  refreshCustomerData,
}) => {
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(0);
  const [arrayToDisplay, setArrayToDisplay] = useState([]);

  const deleteUserAppointmentHandler = async (
    userAppointmentId,
    appointmentId,
  ) => {
    try {
      await deleteUserAppointment(userAppointmentId, appointmentId);
      notifications.show({ title: t`Termin izbrisan` });
      refreshCustomerData();
    } catch (e) {
      notifications.show({ title: t`Prišlo je do napake`, color: "red" });
    }
  };

  const deleteAppointmentHandler = async (appointmentId) => {
    try {
      await apiDeleteAppointment(appointmentId);
      notifications.show({ title: t`Termin izbrisan` });
      refreshCustomerData();
    } catch (e) {
      notifications.show({ title: t`Prišlo je do napake`, color: "red" });
    }
  };

  const updateUserAppointmentCommentHandler = async (
    userAppointmentId,
    comment,
  ) => {
    const response = await updateUserAppointmentComment(
      userAppointmentId,
      comment,
      localStorage.getItem("usr_token"),
    );
    if (response.status === 200) {
      setArrayToDisplay((prevArray) => {
        return prevArray.map((appointment) => {
          if (appointment.userAppointmentId === userAppointmentId) {
            appointment.comment = comment;
          }
          return appointment;
        });
      });
      refreshCustomerData();
    }

    return response;
  };

  const updateUserAppointmentAttendanceHandler = async (id, attendance) => {
    const response = await updateUserAppointmentAttendance(id, attendance);
    if (response.status === 200) {
      setArrayToDisplay((prevArray) => {
        return prevArray.map((appointment) => {
          if (appointment.userAppointmentId === id) {
            appointment.customerDidAttend = attendance;
          }
          return appointment;
        });
      });
      refreshCustomerData();
    }
  };

  useEffect(() => {
    setSelectedAppointmentType(0);
  }, []);

  useEffect(() => {
    setArrayToDisplay(
      selectedAppointmentType === 0
        ? customerData.upcommingAppointments
        : customerData.completedAppointments,
    );
  }, [customerData, selectedAppointmentType]);

  return (
    <CustomerAppointmentsWrapper>
      <AppointmentTypeToggler>
        <AppointmentTypeTogglerTitle
          onClick={() => {
            setSelectedAppointmentType(0);
            setArrayToDisplay(customerData.upcommingAppointments || []);
          }}
          selected={selectedAppointmentType === 0}
        >
          <Trans>Prihajajoči termini</Trans>
        </AppointmentTypeTogglerTitle>
        <AppointmentTypeTogglerTitle
          onClick={() => {
            setSelectedAppointmentType(1);
            setArrayToDisplay(customerData.completedAppointments || []);
          }}
          selected={selectedAppointmentType === 1}
        >
          <Trans>Pretekli termini</Trans>
        </AppointmentTypeTogglerTitle>
      </AppointmentTypeToggler>
      <AppointmentsList>
        {arrayToDisplay.length === 0 && (
          <p>
            <Trans>Ni terminov</Trans>
          </p>
        )}
        {arrayToDisplay.map((appointment, index) => {
          return (
            <CustomerAppointment
              refreshCustomerData={refreshCustomerData}
              key={appointment.appointmentId}
              isMobile={isMobile}
              appointment={appointment}
              deleteUserAppointment={deleteUserAppointmentHandler}
              selectedAppointmentType={selectedAppointmentType}
              updateUserAppointmentAttendance={
                updateUserAppointmentAttendanceHandler
              }
              updateUserAppointmentComment={updateUserAppointmentCommentHandler}
              deleteAppointmentHandler={deleteAppointmentHandler}
            />
          );
        })}
      </AppointmentsList>
    </CustomerAppointmentsWrapper>
  );
};

const AppointmentsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
  max-height: 400px;
  overflow-y: scroll;
`;

const AppointmentTypeToggler = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`;

const AppointmentTypeTogglerTitle = styled.div`
  color: ${(props) => (props.selected ? "#74B587" : "#424b64")};
  padding: 0.5rem;
  font-size: 1rem;
  border-bottom: ${(props) =>
    props.selected ? "3px solid #74B587" : "3px solid #E2E4E5"};
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  transition: all 0.4s ease-in-out;
`;

const CustomerAppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export default CustomerAppointments;
