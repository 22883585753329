import { t, Trans } from "@lingui/macro";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useForm } from "@mantine/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/Components/ui/input-otp";
import { api } from "@/lib/api-client";

export type POSPinSetupForm = {
  PIN: string;
};

export const POSPinSetupDialog = ({
  userId,
  handleCreatedPin,
  handleAuthorizeModalClose,
}: {
  userId: number;
  handleCreatedPin: () => void;
  handleAuthorizeModalClose: (open: boolean) => void;
}) => {
  const form = useForm<POSPinSetupForm>({
    initialValues: {
      PIN: "",
    },
    validate: {
      PIN: (value) => (value ? null : t`Obvezno polje`),
    },
  });

  const { mutateAsync: postUserPIN, isPending: isPostingUserPIN } =
    api.user.usePostUserPIN();

  const handleSubmit = async (data: POSPinSetupForm) => {
    console.log(data);

    const response = await postUserPIN({
      PIN: data.PIN,
      userId,
    });

    console.log(response);

    handleCreatedPin();
  };

  return (
    <Modal isOpen={true} onOpenChange={handleAuthorizeModalClose}>
      <ModalContent>
        {(onClose) => (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <ModalHeader>
              <Trans>Ustvarjanje PIN kode</Trans>
            </ModalHeader>

            <ModalBody className="flex flex-col items-center justify-center gap-8">
              <div className="flex flex-col items-center gap-2">
                <p className="font-semibold">
                  <Trans>PIN Koda</Trans>
                </p>
                <InputOTP maxLength={4} {...form.getInputProps("PIN")}>
                  <InputOTPGroup className="w-full">
                    <InputOTPSlot index={0} inputMode="numeric" isPassword />
                    <InputOTPSlot index={1} inputMode="numeric" isPassword />
                    <InputOTPSlot index={2} inputMode="numeric" isPassword />
                    <InputOTPSlot index={3} inputMode="numeric" isPassword />
                  </InputOTPGroup>
                </InputOTP>

                {form.errors.PIN && (
                  <p className="text-xs text-red-500">{form.errors.PIN}</p>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button isLoading={isPostingUserPIN} type="submit">
                <Trans>Ustvari PIN kodo</Trans>
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};
