import { notifications as notifs } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { apiRemoveLocation } from "../../../api/apiLocation";
import {
  apiAddLocation,
  apiGetLocations,
  apiUpdateLocation,
} from "../../../apiCalls";

const useLocations = (notifications = true) => {
  const [locations, setLocations] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    updateLocations().then(() => {
      setIsLoading(false);
    });
  }, []);

  const updateLocations = async () => {
    setError(null);
    const token = localStorage.getItem("usr_token") || "";
    try {
      const loc = await apiGetLocations(token);
      if (loc.status === 200) {
        setLocations(loc.data.locations);
        setCurrencies(loc.data.currencies);
      } else {
        setError({ status: loc.status, message: loc.statusText });
      }
    } catch (error) {
      setError({ status: 500, message: error.message });
    }
  };

  const updateExistingLocation = async (data) => {
    setError(null);
    setIsLoading(true);
    data.label = data.name;

    const token = localStorage.getItem("usr_token") || "";
    const response = await apiUpdateLocation(data, token);
    if (response.status === 200) {
      await updateLocations();
      setIsLoading(false);
      notifications &&
        notifs.show({ title: "Lokacija je uspešno posodobljena" });
    } else {
      setIsLoading(false);
      setError({ status: response.status, message: response.statusText });
    }
  };

  const addLocation = async (data, assignLocationToUsers) => {
    setError(null);
    setIsLoading(true);
    data.label = data.name;
    const token = localStorage.getItem("usr_token") || "";
    let response;
    try {
      response = await apiAddLocation(data, token, assignLocationToUsers);
    } catch (error) {
      // setError({ status: error.response.status, message: error.response.data });
      setIsLoading(false);
      notifs.show({
        title: "Error",
        message: error.response.data,
        color: "red",
      });
    }
    if (response && response.status === 200) {
      await updateLocations();
      setIsLoading(false);
      notifications && notifs.show({ title: "Lokacija je uspešno dodana" });
    }
  };

  const removeLocation = async (locationId) => {
    setError(null);
    setIsLoading(true);
    const token = localStorage.getItem("usr_token") || "";
    const response = await apiRemoveLocation(locationId, token);
    if (response.status === 200) {
      updateLocations();
      setIsLoading(false);
      notifications &&
        notifs.show({ title: "Lokacija je uspešno odstranjena" });
    } else {
      setIsLoading(false);
      setError({ status: response.status, message: response.statusText });
    }
  };

  return {
    locations,
    updateLocations,
    addLocation,
    updateExistingLocation,
    removeLocation,
    isLoading,
    error,
    currencies,
  };
};

export default useLocations;
