import dayjs from "dayjs";
import { create } from "zustand";

export const useAnalyticsStore = create((set) => ({
  dateRange: [
    dayjs().startOf("day").subtract(1, "week").toDate(),
    dayjs().endOf("day").toDate(),
  ],
  setDateRange: (dateRange) => set({ dateRange }),
  selectedLocations: [],
  setSelectedLocations: (selectedLocations) => set({ selectedLocations }),
}));
