import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div<{
  isOpen: boolean;
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: ${(props) =>
    !props.isOpen
      ? props.isMobile
        ? "0px"
        : "60px"
      : props.isMobile
        ? "100%"
        : "270px"};
  padding: 30px 0;
  overflow: scroll;
  max-height: 100vh;
  overflow-x: hidden;
  position: ${(props) => (props.isMobile ? "fixed" : "relative")};
  ${(props) => props.isMobile && "z-index: 54;"}
  background: ${(props) => (props.isMobile ? "#eff4f2" : "transparent")};
  ${(props) =>
    props.isMobile &&
    `
    height: 100vh;
    `};

  padding-bottom: ${(props) => (props.isMobile ? "100px" : "0px")};

  transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1);
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const LogoutButton = styled.button<{
  isOpen: boolean;
}>`
  cursor: pointer;
  min-width: 150px;
  display: flex;
  height: 36px;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  color: #6c7b86;
  font-size: 0.875rem;
  font-family: "Inter", Arial, Helvetica, sans-serif;

  margin-bottom: 15px;
  margin-left: 10px;

  padding: 10px;

  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

  border-radius: 8px;

  .text {
    opacity: ${(props) => (props.isOpen ? "1" : "0")};
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    padding-left: 10px;
    font-weight: 500;
    transform: ${(props) => (!props.isOpen ? "translateX(5px)" : "none")};
  }

  &:hover {
    background: #dfece6;
  }
`;

export const TopRow = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 30px 20px;
  cursor: pointer;

  transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateX(${(props) => (!props.isOpen ? "-122px" : "0")});

  .logo {
    opacity: ${(props) => (!props.isOpen ? "0" : "1")};
    transform: scale(${(props) => (!props.isOpen ? "0.95" : "1")});
    transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1);
    //width: ${(props) => (!props.isOpen ? "0" : "100%")};
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const PagesGroupLabel = styled.div<{
  isOpen: boolean;
}>`
  margin-top: 24px;
  font-size: 12px;
  font-weight: 500;
  color: #969ba9;
  height: 28px;
  padding-left: 20px;

  transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1);

  opacity: ${(props) => (!props.isOpen ? "0" : "1")};
`;

export const Item = styled(Link)<{
  selected: boolean;
  $isOpen: boolean;
  $isDisabled?: boolean;
}>`
  cursor: "pointer";
  pointer-events: ${(props) => (props.$isDisabled ? "none" : "all")};

  min-width: 150px;
  display: flex;
  height: 36px;
  align-items: center;
  text-decoration: none;

  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

  .leftKnob {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

    display: block;
    background-color: #74b587;
    width: ${(props) => (props.selected ? "5px" : "0px")};
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .content {
    white-space: pre;
    margin-left: 10px;
    display: flex;

    align-items: center;
    font-size: 15px;
    height: 100%;
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 10px;
    font-weight: 500;

    transition: background 0.15s cubic-bezier(0.4, 0, 0.2, 1);

    background: ${(props) =>
      props.selected ? "#74b587 !important" : "transparent"};
    color: ${(props) =>
      props.selected ? "white !important" : "var(--secondary-text)"};

    overflow: ${(props) => props.selected && "hidden"};

    .icon {
      font-size: 20px;
      display: flex;
      align-items: center;

      width: 20px;
      height: 20px;

      svg {
        color: ${(props) =>
          props.$isDisabled
            ? "var(--disable)"
            : props.selected
              ? "white !important"
              : "var(--secondary-text)"};
      }
    }

    .name {
      z-index: 1;
      opacity: ${(props) => (!props.$isOpen ? "0" : "1")};
      padding-left: 10px;
      transform: ${(props) => (!props.$isOpen ? "translateX(5px)" : "none")};

      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  &:hover {
    color: white;

    .content {
      background: #dfece6;
    }
  }
`;
