import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Flex, Tabs, TextInput } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useChangeLogStore from "./store/useChangeLogStore";

import { DateRangeActions } from "@/Components/DateRangeActions";
import { LimeLoader } from "@/Components/LimeLoader";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import useFeature from "@/hooks/useFeature";
import { useSearchParams } from "react-router-dom";
import type { GetAppointmentsLog } from "../../../../../server/src/types";
import { api } from "../../../lib/api-client";
import { AppointmentsLogTable } from "./components/AppointmentsLogTable";

const ChangeLog = () => {
  useLingui();

  const { isFeatureEnabled } = useFeature();
  const { paginationPage, logsPerPage, setPaginationPageCount } =
    useChangeLogStore((state) => state);

  const [dateRange, setDateRange] = useState([
    dayjs().subtract(1, "week"),
    dayjs().endOf("day").add(1, "day"),
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams.get("q") || "");

  const [selectedTab, setSelectedTab] = useState<
    "appointments" | "prescheduled"
  >("appointments");

  const { data, isLoading } = api.log.useAppointmentsLog({
    page: paginationPage || 1,
    limit: logsPerPage || 10,
    type: selectedTab,
    dateRange: dateRange.map((date) => date.format("YYYY-MM-DD")),
    searchQuery: searchParams.get("q") || undefined,
  });

  const rightTab = (
    <DateRangeActions
      handleChangeDateRange={(start, end) => {
        if (!start || !end) return;

        setDateRange([dayjs(start), dayjs(end)]);
      }}
      disabledDateRangeOptions={["all", "1d"]}
      defaultSelectedOption="1w"
    ></DateRangeActions>
  );

  const tabs = [
    { label: t`Termini`, value: "appointments", rightSection: rightTab },
  ];

  if (isFeatureEnabled("PRESCHEDULED")) {
    tabs.push({
      label: t`Razpisani`,
      value: "prescheduled",
      rightSection: rightTab,
    });
  }

  useEffect(() => {
    if (!data) return;

    const { totalPages } = data;

    setPaginationPageCount(totalPages);
  }, [data]);

  const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchParams({ q: search });
  };

  return (
    <>
      <PageHeader
        title={t`Zgodovina sprememb`}
        subHeader={{
          tabs,
          onSelectTab: (tab) => {
            setSelectedTab(tab as GetAppointmentsLog["query"]["type"]);
          },
          selectedTab,
        }}
      ></PageHeader>
      <form onSubmit={onSearchSubmit}>
        <Flex gap={40} mt={20}>
          <TextInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Iskalni niz"
          ></TextInput>
          <Button type="submit">
            <Trans>Išči</Trans>
          </Button>
        </Flex>
      </form>
      <Tabs
        value={selectedTab}
        onChange={(tab) => {
          setSelectedTab(tab as GetAppointmentsLog["query"]["type"]);
        }}
      >
        {isLoading || !data ? (
          <LimeLoader></LimeLoader>
        ) : (
          <>
            <Tabs.Panel value="appointments">
              <AppointmentsLogTable log={data.log}></AppointmentsLogTable>
            </Tabs.Panel>
            <Tabs.Panel value="prescheduled">
              <AppointmentsLogTable log={data.log}></AppointmentsLogTable>
            </Tabs.Panel>
          </>
        )}
      </Tabs>
    </>
  );
};

export default ChangeLog;
