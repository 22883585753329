import { Input, InputProps } from "@nextui-org/react";
import { MantineFormValues } from "./shared.types";
import React from "react";
import { cn } from "@/utils";
import { isMobileSafari } from "react-device-detect";

type Input = MantineFormValues & InputProps;

export const LimeInput = React.forwardRef(
  ({ error, ...props }: Input, ref: React.Ref<HTMLInputElement>) => (
    <Input
      ref={ref}
      errorMessage={error}
      isInvalid={!!error}
      {...props}
      classNames={{
        ...props.classNames,
        input: cn(props.classNames?.input, {
          "text-[16px]": isMobileSafari,
        }),
      }}
      inputMode={props.type === "number" ? "numeric" : props.inputMode}
    />
  ),
);
LimeInput.displayName = "LimeInput";
