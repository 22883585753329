import { Loader, Popover, Text } from "@mantine/core";
import dayjs from "dayjs";
import { BsThreeDots } from "react-icons/bs";
import styled from "styled-components";
import MonthDayBlock from "../../../../Components/MonthDayBlock";
import { useScheduleStore } from "../Stores/useScheduleStore";
import { GetDatesInRange, GetShiftsForDate } from "../Utils";

const MonthShiftBlock = ({ shift, scheduleType, user, onClick }) => {
  if (shift == null) return null;

  const firstName = user.name.split(" ")[0];
  const lastName = user.name.split(" ")[1];
  const name = `${firstName}`;
  if (lastName) name.concat(` ${lastName?.charAt(0)}.`);

  let startTime = dayjs(shift.start).format("HH:mm");
  let endTime = dayjs(shift.end).format("HH:mm");
  if (scheduleType === "online") {
    startTime = shift.formStart ? dayjs(shift.formStart).format("HH:mm") : "";
    endTime = shift.formEnd ? dayjs(shift.formEnd).format("HH:mm") : "";
  }

  return (
    <button
      onClick={() => onClick(dayjs(shift.date), shift.userId)}
      className={"shift"}
    >
      <Text truncate={"end"} size={"sm"} fw={600}>
        {name}
      </Text>
      <Text style={{ whiteSpace: "pre" }} size={"xs"}>
        {startTime} - {endTime}
      </Text>
    </button>
  );
};

const MonthSchedule = ({ scheduleType, filteredUsers }) => {
  const {
    selectedScheduleData,
    setScheduleViewType,
    setScheduleDate,
    toggleScheduleModal,
  } = useScheduleStore((state) => state);

  if (selectedScheduleData == null) return <Loader />;

  const dates = GetDatesInRange(
    selectedScheduleData.date_from,
    selectedScheduleData.date_to,
  );

  const onShiftClick = (date, userId) => {
    toggleScheduleModal({
      userId,
      date: date,
      opened: true,
    });
  };

  return (
    <Wrapper>
      {dates.map((date, index) => {
        const shiftsForDateObject = GetShiftsForDate(
          date,
          selectedScheduleData.shifts,
        );
        const shiftsDate = shiftsForDateObject?.at(0)?.date;
        const shifts = shiftsForDateObject?.at(0)?.shifts;

        const hasTooManyShifts = shifts?.length > 3;

        return (
          <MonthDayBlock
            gap={"5px"}
            key={date}
            date={date}
            onDateClick={() => {
              setScheduleViewType("day");
              setScheduleDate(date);
            }}
          >
            {shifts
              ?.slice(0, hasTooManyShifts ? 3 : shifts.length)
              .map((shift) => {
                const user = selectedScheduleData.users.find(
                  (user) => user.userId === shift.userId,
                );

                if (!filteredUsers.find((s) => s.userId === user.userId)?.shown)
                  return null;

                return (
                  <MonthShiftBlock
                    key={shift.scheduleShiftId}
                    shift={shift}
                    scheduleType={scheduleType}
                    user={user}
                    onClick={onShiftClick}
                  />
                );
              })}
            {hasTooManyShifts && (
              <Popover
                position={"right-start"}
                width={"200px"}
                withArrow
                shadow="md"
              >
                <Popover.Target>
                  <button className={"show-all-shifts-button"}>
                    <BsThreeDots />
                  </button>
                </Popover.Target>
                <Popover.Dropdown>
                  <div className={"all-appointments"}>
                    {shifts?.slice(3).map((shift) => {
                      const user = selectedScheduleData.users.find(
                        (user) => user.userId === shift.userId,
                      );

                      return (
                        <MonthShiftBlock
                          key={shift.scheduleShiftId}
                          shift={shift}
                          scheduleType={scheduleType}
                          user={user}
                          onClick={onShiftClick}
                        />
                      );
                    })}
                  </div>
                </Popover.Dropdown>
              </Popover>
            )}
          </MonthDayBlock>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  padding-left: 0;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;

  .shift {
    background: none;
    border: 1px solid #d5dad6;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .show-all-shifts-button {
    cursor: pointer;
    background: none;
    border: none;
    height: 20px;
    border-radius: 5px;
    margin: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(7, 1fr);
  }
`;

export default MonthSchedule;
