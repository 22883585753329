import { Text } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import styled from "styled-components";
import { calculateYOffsetForTime } from "../../../../../Utilities";
import { useCalendarStore } from "../../store/useCalendarStore";
import { getCalendarTimes } from "../../utils";
import DayColumn from "./DayColumn";
import DayName from "./DayName";

const DayWeekCalendar = () => {
  const {
    calendarData,
    filterItems,
    selectedLocation,
    timeoffs,
    selectedLocationId,
  } = useCalendarStore((state) => state);

  const timesRef = React.useRef(null);

  const usersRef = React.useRef(null);
  const calendarRef = React.useRef(null);

  const updateCurrentTimeLineData = () => {
    const currentTime = dayjs().format("HH:mm");

    const newYoffset = calculateYOffsetForTime({
      time: currentTime,
      slotHeight: calendarData.slotHeight,
      startTime: calendarData.startTime,
      endTime: calendarData.endTime,
      timeStep: calendarData.timeStep,
    });

    setRedLineData({
      currentTime,
      yOffset: newYoffset,
    });
  };

  useEffect(() => {
    updateCurrentTimeLineData();
  }, [filterItems]);

  const [redLineData, setRedLineData] = useState({
    currentTime: dayjs().format("HH:mm"),
    yOffset: 0,
  });
  const redLineInterval = useInterval(() => {
    updateCurrentTimeLineData();
  }, 60000);

  const { startTime, endTime, timeStep, slotHeight } = calendarData;

  const times = useMemo(
    () => getCalendarTimes(startTime, endTime, timeStep),
    [startTime, endTime, timeStep],
  );

  useEffect(() => {
    updateCurrentTimeLineData();

    redLineInterval.start();

    return redLineInterval.stop;
  }, [calendarData]);

  return (
    <>
      <ScrollSync>
        <>
          <CurrentTimeLine redLineYOffset={redLineData.yOffset}>
            <div className={"current-time-label"}>
              {redLineData.currentTime}
            </div>
          </CurrentTimeLine>
          <div className={"top_row empty"}></div>
          <ScrollSyncPane>
            <div
              className={"top_row scroll_row"}
              ref={usersRef}
              id={"users_row"}
            >
              <DaysUsers isOnlyDay={calendarData.days?.length === 1}>
                {calendarData?.days?.map((day, index) => {
                  return <DayName day={day} key={index}></DayName>;
                })}
              </DaysUsers>
            </div>
          </ScrollSyncPane>
          <div className={"times_row"} ref={timesRef}>
            <TimeColumn slotHeight={slotHeight}>
              {times.map((time, index) => {
                return (
                  <Text
                    h={"20px"}
                    key={index}
                    size={"xs"}
                    c={"#6c7386"}
                    fw={500}
                    pr={"4px"}
                  >
                    {time}
                  </Text>
                );
              })}
            </TimeColumn>
          </div>
          <ScrollSyncPane>
            <div
              style={{ paddingTop: "7px" }}
              className={"calendar_row scroll_row"}
              ref={calendarRef}
              id={"calendar_row"}
            >
              <Days>
                {calendarData?.days?.map((day, index) => {
                  return (
                    <DayColumn
                      day={day}
                      key={index}
                      timeoffs={
                        timeoffs?.timeoffs?.filter(
                          (t) =>
                            dayjs(day.date).isBetween(
                              t.timeFrom,
                              t.timeTo,
                              "day",
                              "[]",
                            ) && Number(selectedLocationId) === t.locationId,
                        ) || []
                      }
                      isOnlyDay={calendarData.days.length === 1}
                    ></DayColumn>
                  );
                })}
              </Days>
            </div>
          </ScrollSyncPane>
        </>
      </ScrollSync>
    </>
  );
};

const DaysUsers = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => {
    if (!props.isOnlyDay) {
      return `
      @media (max-width: 768px) {
    width: fit-content;
  }
      `;
    }
  }};
`;

const CurrentTimeLine = styled.div`
  position: absolute;
  top: ${(props) => props.redLineYOffset + 182}px;

  margin-left: 34px;

  left: 11px;
  right: 0;

  display: ${(props) => (props.hidden ? "none" : "block")};

  border-bottom: 2px dashed red;
  transition: all 0.25s cubic-bezier(0.01, 1.06, 0.18, 1.03);
  z-index: 4;

  pointer-events: none;

  .current-time-label {
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    position: absolute;
    left: ${(props) => (props.isMobile ? "5px" : "10px")};
    transform: translate(-100%, -50%);
    width: ${(props) => (props.isMobile ? "41px" : "45px")};
    height: 20px;
    background: red;
    border-radius: 5px;
  }
`;

const Days = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  /* min-width: 100%; */
  /* width: fit-content; */
`;

const TimeColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(${(props) => props.slotHeight}px - 20px);
  position: sticky;
  left: 0;
  background: white;
  z-index: 4;

  width: fit-content;
  padding-left: 5px;
`;

export default DayWeekCalendar;
