import useAccountInfo from "./useAccountInfo";

const useFeature = () => {
  const { accountInfo } = useAccountInfo((state) => state);

  const isFeatureEnabled = (feature) => {
    return accountInfo?.features?.includes(feature);
  };

  return { isFeatureEnabled };
};

export default useFeature;
