import { cn } from "@/utils";
import { Trans } from "@lingui/macro";
import { LucideTriangleAlert } from "lucide-react";

export const ErrorCard = ({
  message,
  isCompact,
  className,
  bottomSection,
}: {
  message?: string | null;
  isCompact?: boolean;
  className?: string;
  bottomSection?: React.ReactNode;
}) => {
  if (!message) return undefined;

  return (
    <div
      className={cn(
        "rounded-xl bg-red-200 p-4 text-sm text-red-700",
        {
          "p-2": isCompact,
        },
        className,
      )}
    >
      <p
        className={cn("mb-1 flex items-center gap-2", {
          "mb-0 gap-1": isCompact,
        })}
      >
        <LucideTriangleAlert size={isCompact ? "1.2rem" : undefined} />
        {!isCompact && (
          <p className="font-semibold">
            <Trans>Napaka</Trans>
          </p>
        )}
        {isCompact && <p className="break-words">{message}</p>}
      </p>
      {!isCompact && <p className="break-words">{message}</p>}

      {bottomSection ? (
        <div className="text-sm text-red-700">{bottomSection}</div>
      ) : undefined}
    </div>
  );
};
