import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { notifications } from "@mantine/notifications";

type CustomMutationOptions<TData, TError, TVariables, TContext> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  "onError"
> & {
  onError?: (
    error: TError,
    variables: TVariables,
    context: TContext | undefined,
    processedError: string | null,
    processedErrorCode: string | null,
  ) => void;
  displayNotification?: boolean; // Add the displayNotification option
};

type CustomMutationResult<TData, TError, TVariables, TContext> =
  UseMutationResult<TData, TError, TVariables, TContext> & {
    processedErrorMessage: string | null;
    processedErrorCode: string | null;
  };

export function useCustomMutation<
  TData,
  TError = AxiosError,
  TVariables = void,
  TContext = unknown,
>(
  options: CustomMutationOptions<TData, TError, TVariables, TContext>,
): CustomMutationResult<TData, TError, TVariables, TContext> {
  const { displayNotification = false, ...mutationOptions } = options; // Default to false for displayNotification

  const mutation = useMutation<TData, TError, TVariables, TContext>({
    ...mutationOptions,
    onError: (error, variables, context) => {
      const processedErrorMessage =
        error instanceof AxiosError && error?.response?.data?.error;

      const processedErrorCode =
        error instanceof AxiosError && error?.response?.data?.errorCode;

      // Show the notification only if displayNotification is true
      if (displayNotification) {
        notifications.show({
          title: "An error occurred",
          message: processedErrorMessage || "An unexpected error occurred",
          color: "red",
        });
      }

      // If the user provided an onError callback, call it with the processed error message
      if (mutationOptions.onError) {
        mutationOptions.onError(
          error,
          variables,
          context,
          processedErrorMessage,
          processedErrorCode,
        );
      }
    },
  });

  // Process the error message
  const processedErrorMessage =
    mutation.error instanceof AxiosError ?
    mutation.error?.response?.data?.error : null;

  const processedErrorCode =
    mutation.error instanceof AxiosError ?
    mutation.error?.response?.data?.errorCode : null;

  return {
    ...mutation,
    processedErrorMessage,
    processedErrorCode,
  };
}
