import { AddButton, LimePageHeader } from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { api } from "@/lib/api-client";
import { t, Trans } from "@lingui/macro";
import { Flex, Tabs, TextInput } from "@mantine/core";
import { useDebouncedState, useMediaQuery } from "@mantine/hooks";
import { Button, Link } from "@nextui-org/react";
import { RiSearch2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

type Tabs = "products" | "categories";

export const Products = ({ tab }: { tab: Tabs }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const [search, setSearch] = useDebouncedState("", 200);

  const navigateToId = (
    tab: "products" | "products/categories",
    productId: number,
  ) => {
    navigate(`/dashboard/${tab}/${productId}`);
  };

  const rightSections: Record<Tabs, AddButton> = {
    products: {
      label: t`Dodaj produkt`,
      onClick: () => navigateToId("products", -1),
      identifier: "add-product",
    },
    categories: {
      label: t`Dodaj kategorijo`,
      onClick: () => navigateToId("products/categories", -1),
      identifier: "add-product",
    },
  };

  return (
    <>
      <LimePageHeader
        title={t`Produkti`}
        rightSection={rightSections[tab]}
      ></LimePageHeader>

      <LimePageTabs
        activeTab={tab}
        onChangeTab={(tab) => {
          if (tab === "products") {
            navigate("/dashboard/products");
            return;
          }
          navigate("/dashboard/products/categories");
        }}
        tabs={[
          {
            label: t`Vsi produkti`,
            value: "products",
          },
          {
            label: t`Kategorije`,
            value: "categories",
          },
        ]}
        rightSection={
          <Flex
            justify={"space-between"}
            gap={"md"}
            align={"center"}
            p={isMobile ? "xs" : 0}
          >
            <LimeInput
              placeholder={t`Išči produkte`}
              defaultValue={search}
              // onChange={(event) => setSearch(event.currentTarget.value)}
              onValueChange={setSearch}
              endContent={<RiSearch2Line />}
            />
          </Flex>
        }
      ></LimePageTabs>

      <div className="flex flex-col gap-4 px-4 py-4 md:px-8">
        {tab === "products" && <ProductsList search={search} />}

        {tab === "categories" && <ProductCategoriesList search={search} />}
      </div>
    </>
  );
};

const ProductCategoriesList = ({ search }: { search: string }) => {
  const { data: categoriesData } = api.product.useGetProductCategories({
    search,
  });

  return (
    <div className="flex flex-col gap-4">
      {categoriesData?.map((category, index) => (
        <Button
          as={Link}
          href={`/dashboard/products/categories/${category.id}`}
          className={`box-content flex h-fit min-h-10 justify-between rounded-md border-1 border-l-4 px-2 py-1`}
          style={{ borderColor: category.color }}
          variant="bordered"
          disableAnimation
          key={index}
        >
          <div className="flex flex-col items-start">
            <span className="text-[14px] text-[#202124]">{category.name}</span>
          </div>
        </Button>
      ))}
    </div>
  );
};

const ProductsList = ({ search }: { search: string }) => {
  const { data: productsData } = api.product.useGetProducts({ search });

  return (
    <div className="flex flex-col gap-4">
      {productsData?.products.map((product, index) => {
        return (
          <Button
            className={`box-content flex h-fit min-h-10 justify-between rounded-md border-1 border-l-4 px-2 py-1`}
            style={{ borderColor: product.color }}
            variant="bordered"
            disableAnimation
            key={index}
            as={Link}
            href={`/dashboard/products/${product.id}`}
          >
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-[#202124]">{product.name}</span>
              {product.purchaseProductData && (
                <span className="text-[12px] font-light text-[#202124]">
                  <Trans>Nabavna cena</Trans>:{" "}
                  {product.purchaseProductData.grossPriceFormatted}
                </span>
              )}
              {product.salesProductData && (
                <span className="text-[12px] font-light text-[#202124]">
                  <Trans>Prodajna cena</Trans>:{" "}
                  {product.salesProductData.grossPriceFormatted}
                </span>
              )}
            </div>
          </Button>
        );
      })}
    </div>
  );
};
