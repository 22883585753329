import React, { ElementType, useMemo } from "react";
import { LimeInput } from "./LimeInput";
import { MantineFormValues } from "./shared.types";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import PhoneInput, {
  DefaultInputComponentProps,
} from "react-phone-number-input";
import { Select, SelectItem } from "@nextui-org/react";
import { cn } from "@/utils";

type PhoneInput = MantineFormValues &
  Pick<DefaultInputComponentProps, "defaultCountry"> & {
    label?: string;
    onChange: (value: string) => void;
  };

export const LimePhoneInput = ({ ...props }: PhoneInput) => {
  return (
    <div>
      <PhoneInput
        {...props}
        className="group h-14"
        inputComponent={CustomInput}
        countrySelectComponent={CustomCountrySelectWithIcon}
      />
    </div>
  );
};

const CustomInput = React.forwardRef(
  (props: any, ref: React.Ref<HTMLInputElement>) => (
    <LimeInput
      ref={ref}
      {...props}
      autoComplete="tel"
      variant="bordered"
      classNames={{
        mainWrapper: "h-14",
        inputWrapper: "h-14",
        base: "h-14",
      }}
      error={props.error}
      errorMessage={undefined}
    />
  ),
);
CustomInput.displayName = "CustomInput";

const CustomCountrySelectWithIcon = ({
  value,
  options,
  iconComponent: Icon,
  arrowComponent,
  unicodeFlags,
  ...rest
}: CustomCountrySelectWithIconProps) => {
  const selectedOption = useMemo(() => {
    return getSelectedOption(options, value);
  }, [options, value]);

  return (
    <div className="PhoneInputCountry">
      <CustomCountrySelect
        {...rest}
        value={value}
        options={options}
        className={"PhoneInputCountrySelect"}
      />

      {/* Either a Unicode flag icon. */}
      {unicodeFlags && value && (
        <div className="PhoneInputCountryIconUnicode">
          {getUnicodeFlagIcon(value)}
        </div>
      )}

      {/* Or an SVG flag icon. */}
      {!(unicodeFlags && value) && Icon && (
        <div className="flex h-14 w-14 items-center justify-center rounded-medium border-2 border-default-200 group-[.PhoneInput--focus]:border-default-foreground">
          <Icon
            aria-hidden
            country={value}
            label={selectedOption && selectedOption.label}
            aspectRatio={unicodeFlags ? 1 : undefined}
          />
        </div>
      )}
    </div>
  );
};

type CustomCountrySelectWithIconProps = CustomCountrySelectProps & {
  iconComponent?: ElementType;
  arrowComponent?: ElementType;
  unicodeFlags?: boolean;
};

const CustomCountrySelect = ({
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  options,
  disabled,
  readOnly,
  tabIndex,
  className,
}: CustomCountrySelectProps) => {
  return (
    <Select
      label={name}
      selectedKeys={value ? [value] : ["ZZ"]}
      variant="bordered"
      onChange={(event) => onChange(event.target.value || undefined)}
      onFocus={onFocus}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      aria-readonly={readOnly}
      tabIndex={tabIndex ? Number(tabIndex) : undefined}
      className={cn(className, "data-[focus=true]:border-default-foreground")}
      isDisabled={disabled}
      classNames={{
        popoverContent: "w-fit",
      }}
      selectionMode="single"
      disallowEmptySelection
    >
      {options.map(({ value, label, divider }) => (
        <SelectItem
          key={divider ? "|" : value || "ZZ"}
          value={divider ? "|" : value || "ZZ"}
          startContent={getUnicodeFlagIcon(value || "ZZ")}
        >
          {label}
        </SelectItem>
      ))}
    </Select>
  );
};

type CustomCountrySelectProps = {
  name?: string;
  value?: string; // Two letter country code (e.g. "US", "RU")
  onChange: (value?: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  options: {
    value?: string;
    label: string;
    divider?: boolean;
  }[];
  disabled?: boolean;
  readOnly?: boolean;
  tabIndex?: number | string;
  className?: string;
};

function getSelectedOption(options: any, value?: string) {
  for (const option of options) {
    if (!option.divider && option.value === value) {
      return option;
    }
  }
}
