import { Combobox, type ComboboxProps, Flex } from "@mantine/core";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabel, type LimeInputLabelProps } from "./LimeInputLabel";

export const LimeCombobox = ({
  ...props
}: LimeInputLabelProps & ComboboxProps & { w?: string | number }) => {
  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);

  return (
    <Flex direction={"column"} {...spacingProps} w={props.w}>
      <LimeInputLabel {...props} />

      <Combobox radius={8} {...propsWithoutSpacing}>
        {props.children}
      </Combobox>
    </Flex>
  );
};
