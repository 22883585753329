import { PostCompany, GetCompanies } from "@/server-types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { post, get } from "../request-helpers";

export const postCompany = () => {
  return useMutation({
    mutationFn: (body: PostCompany["body"]) =>
      post<PostCompany>("/company", body),
  });
};

export const getCompanies = (query: GetCompanies["query"]) => {
  return useQuery({
    queryKey: ["companies", query],
    queryFn: () => get<GetCompanies>("/company", query),
    enabled: query.companyId != null || !!query.searchQuery,
  });
};
