import axios from "axios";

const prefix = import.meta.env.VITE_BE_HOST;

const getConfig = () => {
  const token = localStorage.getItem("usr_token");

  return {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
};

const deleteUserAppointment = (userAppointmentId, appointmentId) => {
  return axios.delete(
    `${prefix}/calendar/appointment/prescheduled/${appointmentId}`,
    {
      data: { userAppointmentIds: [userAppointmentId], appointmentId },
      ...getConfig(),
    },
  );
};

const updateUserAppointmentAttendance = (userAppointmentId, attendance) => {
  return axios.post(
    `${prefix}/appointment/updateUserAppointmentAttendance`,
    { userAppointmentId, attendance },
    getConfig(),
  );
};

const updateUserAppointmentComment = (userAppointmentId, comment) => {
  return axios.post(
    `${prefix}/appointment/updateUserAppointmentComment`,
    { userAppointmentId, comment },
    getConfig(),
  );
};

const getCustomerData = (customerId) => {
  return axios.get(
    `${prefix}/appointment/getCustomer?customerId=${customerId}`,
    getConfig(),
  );
};

const postMergeCustomers = (customerIds, data) => {
  return axios.post(
    `${prefix}/customer/merge`,
    { customerIds, ...data },
    getConfig(),
  );
};

const getCustomerList = (paginationPage, searchQuery, perPage, sortBy) => {
  return axios.get(
    `${prefix}/appointment/getCustomerList?page=${paginationPage}&query=${searchQuery}&perPage=${perPage}&sortBy=${sortBy}`,
    getConfig(),
  );
};

const deleteCustomerWithId = (customerId) => {
  return axios.post(
    `${prefix}/user/deleteCustomer`,
    { customerId },
    getConfig(),
  );
};

export {
  updateUserAppointmentAttendance,
  getCustomerData,
  getCustomerList,
  deleteUserAppointment,
  deleteCustomerWithId,
  postMergeCustomers,
  updateUserAppointmentComment,
};
