import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import {
  Box,
  Button,
  Flex,
  NumberInput,
  NumberInputHandlers,
  NumberInputProps,
  Text,
} from "@mantine/core";
import { useRef } from "react";
import { RiAddFill, RiSubtractFill } from "react-icons/ri";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabel, LimeInputLabelProps } from "./LimeInputLabel";

export const EasyNumberInput = ({
  ...props
}: LimeInputLabelProps & NumberInputProps) => {
  const handlersRef = useRef<NumberInputHandlers>(null);

  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);
  const {
    error,
    ...rest
  }: {
    error?: string;
  } = propsWithoutSpacing;

  return (
    <Box {...spacingProps} style={{ width: "100%" }}>
      <LimeInputLabel {...props} />
      <Flex gap={"sm"} h={50}>
        <Button
          h={"100%"}
          miw={80}
          variant={"light"}
          onClick={() => handlersRef.current?.decrement()}
          radius={8}
          type="button"
        >
          <RiSubtractFill size={"1rem"} />
        </Button>
        <NumberInput
          handlersRef={handlersRef}
          {...rest}
          style={{ width: "100%" }}
          hideControls
          trimLeadingZeroesOnBlur={true}
          styles={{
            wrapper: {
              height: "100%",
            },
            input: {
              textAlign: "center",
              height: "100%",
              color: Color.SecondaryText,
            },
          }}
          variant="filled"
          radius={8}
          label={undefined}
        />
        <Button
          h={"100%"}
          miw={80}
          variant={"light"}
          onClick={() => handlersRef.current?.increment()}
          radius={8}
          type="button"
        >
          <RiAddFill size={"1.2rem"} />
        </Button>
      </Flex>
      {error ? (
        <Text mt={"xs"} c={Color.Error} variant={TextVariant.Caption}>
          {error}
        </Text>
      ) : undefined}
    </Box>
  );
};
