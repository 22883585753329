import { LimeMultiSelect } from "@/Components/LimeMultiSelect";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Accordion,
  Button,
  Divider,
  Flex,
  NumberInput,
  Text,
} from "@mantine/core";
import { useState } from "react";
import {
  RiMoneyEuroCircleLine,
  RiSearch2Line,
  RiTimeLine,
} from "react-icons/ri";
import classes from "./RolesModal.module.css";
import {
  formatCurrency,
  returnCurrencySymbol,
} from "../../../../../../../shared/utils/utils";
import { i18n } from "@lingui/core";

const RolesModal = ({ fields, setFields, users, services }) => {
  useLingui();

  const [search, setSearch] = useState("");
  const changeServicePrice = (value, serviceId) => {
    setFields((prev) => {
      const _services = prev.services;
      const serviceIndex = _services.findIndex(
        (s) => s.serviceId === serviceId,
      );
      if (serviceIndex === -1) {
        _services.push({
          serviceId: serviceId,
          price: value,
        });
        return { ...prev, services: _services };
      } else {
        console.log("value", value);
        _services[serviceIndex].price = value;

        console.log("services", _services);
        return { ...prev, services: _services };
      }
    });
  };

  const resetPrice = (serviceId) => {
    setFields((prev) => {
      const _services = prev.services;
      const serviceIndex = _services.findIndex(
        (s) => s.serviceId === serviceId,
      );

      if (serviceIndex !== -1) {
        _services[serviceIndex].price = null;

        return { ...prev, services: _services };
      }
      return prev;
    });
  };

  const resetDuration = (serviceId) => {
    setFields((prev) => {
      const _services = prev.services;
      const serviceIndex = _services.findIndex(
        (s) => s.serviceId === serviceId,
      );
      if (serviceIndex !== -1) {
        _services[serviceIndex].duration = null;
        return { ...prev, services: _services };
      }
      return prev;
    });
  };

  const changeServiceDuration = (value, serviceId) => {
    setFields((prev) => {
      const services = prev.services;
      const serviceIndex = services.findIndex((s) => s.serviceId === serviceId);
      if (serviceIndex === -1) {
        services.push({
          serviceId: serviceId,
          duration: value,
        });
        return { ...prev, services };
      } else {
        services[serviceIndex].duration = value;
        return { ...prev, services };
      }
    });
  };

  const { locale } = i18n;

  return (
    <Flex direction={"column"} gap={20}>
      <Divider></Divider>
      <div>
        <LimeTextInput
          label={t`Ime vloge`}
          value={fields.roleName}
          onChange={(e) => {
            setFields({ ...fields, roleName: e.currentTarget.value });
          }}
          placeholder={t`Vnesite ime vloge`}
        ></LimeTextInput>
      </div>
      <div>
        <LimeMultiSelect
          label={t`Izberite osebje`}
          data={users.map((u) => ({
            value: u.userId.toString(),
            label: u.name,
          }))}
          value={fields.userIds.map((u) => u.toString())}
          onChange={(value) => {
            setFields({ ...fields, userIds: value });
          }}
          placeholder={t`Ni osebja`}
        ></LimeMultiSelect>
      </div>
      <Divider></Divider>
      <div>
        <LimeTextInput
          label={t`Določite ceno in trajanje po meri`}
          placeholder={t`Išči storitve`}
          icon={<RiSearch2Line />}
          onChange={(e) => setSearch(e.currentTarget.value)}
        ></LimeTextInput>
      </div>
      <Divider></Divider>
      <Accordion classNames={classes} className={classes.root}>
        {services
          ?.filter((s) => s.name?.toLowerCase().includes(search?.toLowerCase()))
          .map((service, index) => {
            let price = fields.services?.find(
              (s) => s.serviceId === service.serviceId,
            )?.price;
            if (price === null || price === undefined) {
              price = service.price;
            }
            if (price === null || price === undefined) {
              price = 0;
            }

            let duration = fields.services.find(
              (s) => s.serviceId === service.serviceId,
            )?.duration;
            if (duration === null || duration === undefined) {
              duration = service.duration * 1;
            }

            if (duration === null || duration === undefined) {
              duration = 0;
            }

            return (
              <Accordion.Item
                key={index}
                value={service.name}
                className={"accordion_item"}
              >
                <Accordion.Control className={"accordion_control"}>
                  <Flex justify={"space-between"}>
                    <Text className={"accordion_title_name"}>
                      {" "}
                      {service.name}
                    </Text>
                    <Flex justify={"end"} gap={15}>
                      <Flex align={"center"} gap={5}>
                        <RiTimeLine />
                        <Text>{duration} min</Text>
                      </Flex>
                      <Flex align={"center"} gap={5}>
                        <RiMoneyEuroCircleLine />
                        <Text>
                          {formatCurrency({
                            amount: price,
                            currency: service.currency,
                            locale,
                          })}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  <div
                    className={"accordion_panel_content"}
                    style={{
                      color: "gray",
                    }}
                  >
                    <Flex
                      direction={"column"}
                      gap={10}
                      justify={"space-between"}
                    >
                      <Flex justify={"space-between"} align={"center"}>
                        <Text>
                          <Trans>Urejanje cene</Trans>
                        </Text>
                        <Button
                          variant="white"
                          onClick={() => {
                            resetPrice(service.serviceId);
                          }}
                        >
                          <Trans> Ponastavi ceno</Trans>
                        </Button>
                      </Flex>
                      <Divider></Divider>
                      <Flex
                        direction={"row"}
                        gap={70}
                        align={"center"}
                        justify={"space-between"}
                      >
                        <Flex gap={10} justify={"space-between"}>
                          <Text>
                            <Trans>Redna cena:</Trans>{" "}
                            {formatCurrency({
                              amount: service.price,
                              currency: service.currency,
                              locale,
                            })}
                          </Text>
                        </Flex>
                        <Flex
                          align={"center"}
                          gap={10}
                          justify={"space-between"}
                        >
                          <Text>
                            <Trans>Cena po meri:</Trans>{" "}
                          </Text>
                          <NumberInput
                            precision={1}
                            min={0}
                            noClampOnBlur
                            hideControls={true}
                            value={price}
                            onChange={(value) => {
                              changeServicePrice(value, service.serviceId);
                            }}
                            styles={{ input: { width: "8ch" } }}
                          ></NumberInput>{" "}
                          {returnCurrencySymbol({
                            currency: service.currency,
                          })}
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex direction={"column"} gap={10} mt={"md"}>
                      <Flex justify={"space-between"} align={"center"}>
                        <Text>
                          <Trans>Urejanje trajanja</Trans>
                        </Text>
                        <Button
                          variant="white"
                          onClick={() => {
                            resetDuration(service.serviceId);
                          }}
                        >
                          <Trans>Ponastavi trajanje</Trans>
                        </Button>
                      </Flex>
                      <Divider></Divider>
                      <Flex
                        direction={"row"}
                        gap={70}
                        align={"center"}
                        justify={"space-between"}
                      >
                        <Flex gap={10}>
                          <Text>
                            <Trans>
                              Redno trajanje: {service.duration} min
                            </Trans>
                          </Text>
                        </Flex>
                        <Flex align={"center"} gap={10}>
                          <Text>
                            <Trans>Trajanje po meri</Trans>:{" "}
                          </Text>
                          <NumberInput
                            precision={1}
                            noClampOnBlur
                            hideControls={true}
                            value={duration}
                            onChange={(value) => {
                              changeServiceDuration(value, service.serviceId);
                            }}
                            styles={{ input: { width: "8ch" } }}
                          ></NumberInput>
                          min
                        </Flex>
                      </Flex>
                    </Flex>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
      </Accordion>
    </Flex>
  );
};

export default RolesModal;
