import { LimeLoader } from "@/Components/LimeLoader";
import { api } from "@/lib/api-client";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Divider, Flex, Space, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { StripeOutletContextProps } from "./Stripe";

export const GeneralTab = () => {
  const { general } = useOutletContext<StripeOutletContextProps>();
  const { dateRange } = general;

  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
  useLingui();
  const { data: analyticsData, isLoading } = api.stripe.useGetStripeAnalytics({
    startDate: dateRange[0],
    endDate: dateRange[1],
  });

  const isMobile = useMediaQuery("(max-width: 768px)");

  let yWidth = 45;
  const max =
    _.max(
      analyticsData?.chart.map((item) => item.value.toString().length ?? 3) ?? [
        3,
      ],
    ) ?? 3;
  if (max > 3) yWidth = 75;
  else if (max > 2) yWidth = 60;
  else if (max > 1) yWidth = 45;

  return (
    <div>
      {isLoading || !analyticsData ? (
        <LimeLoader />
      ) : (
        <>
          {isMobile && (
            <>
              <InfoBlock
                data={[
                  {
                    label: t`Skupaj prihodki`,
                    values: analyticsData.general.totalIncomeByCurrency.map(
                      (item) => item.formattedAmount,
                    ),
                  },
                  {
                    label: t`Št. plačil`,
                    values: [`${analyticsData.general.numPayments}`],
                  },
                  {
                    label: t`Št. strank`,
                    values: [`${analyticsData.general.numUniqueCustomers}`],
                  },
                ]}
              />
              <Space h={"lg"} />
            </>
          )}

          {isMobile && (
            <Text variant={TextVariant.BodyEmphasized} mb={"5px"}>
              <Trans>Grafični prikaz prihodkov</Trans>
            </Text>
          )}

          <Flex direction={"row"} w={"100%"} gap={"lg"}>
            <Box
              style={{
                border: isMobile ? `1px solid ${Color.Border}` : "none",
                borderRadius: "10px",
                padding: "20px 5px",
                // width: "100%",
                flex: 1,
              }}
            >
              <ResponsiveContainer
                width={"100%"}
                height={300}
                className={"chart-container"}
              >
                <LineChart
                  data={analyticsData.chart.map((item) => ({
                    ...item,
                    date: dayjs(item.date).format("DD. MM."),
                    fullDate: item.date,
                  }))}
                  onClick={(e) => {
                    setSelectedDay(e?.activePayload?.[0].payload.fullDate);
                  }}
                  margin={{
                    left: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    padding={{ left: 25, right: 25 }}
                    tickMargin={7}
                    axisLine={{
                      stroke: Color.Border,
                      strokeWidth: 2,
                    }}
                  />
                  <YAxis
                    dataKey={"value"}
                    width={yWidth}
                    axisLine={{
                      stroke: Color.Border,
                      strokeWidth: 2,
                    }}
                    tickLine={false}
                    tickMargin={7}
                  />
                  {isMobile ? (
                    <Tooltip content={() => <></>} />
                  ) : (
                    <Tooltip
                      cursor={{
                        stroke: Color.PrimaryText,
                        strokeWidth: 2,
                        strokeDasharray: "3 3",
                      }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          return (
                            <Box
                              bg={"white"}
                              py={"2px"}
                              style={{
                                boxShadow:
                                  "0px 5px 20px 0px rgba(166, 166, 166, 0.30)",
                                borderRadius: "10px",
                              }}
                            >
                              <Text
                                p={"5px 10px"}
                                ta="center"
                                variant={TextVariant.CaptionEmphasized}
                                c={Color.SecondaryText}
                              >
                                {_.capitalize(
                                  dayjs(payload[0].payload.fullDate)
                                    .locale("sl")
                                    .format("dddd, DD. MMMM"),
                                ) ?? ""}
                              </Text>
                              <Divider />
                              <Text
                                p={"5px 18px"}
                                ta="center"
                                variant={TextVariant.Body}
                              >
                                {payload[0].payload.formattedValue}
                              </Text>
                            </Box>
                          );
                        }

                        return null;
                      }}
                    />
                  )}
                  <Line
                    type="linear"
                    dataKey="value"
                    stroke="#548F68"
                    strokeWidth={3}
                    gradientUnits="userSpaceOnUse"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>

            {!isMobile && (
              <Flex direction={"column"} w={"260px"} gap={"25px"}>
                <InfoBlock
                  data={[
                    {
                      label: t`Št. plačil`,
                      values: analyticsData.general.totalIncomeByCurrency.map(
                        (item) => item.formattedAmount,
                      ),
                    },
                  ]}
                />

                <InfoBlock
                  data={[
                    {
                      label: t`Št. plačil`,
                      values: [`${analyticsData.general.numPayments}`],
                    },
                  ]}
                />
                <InfoBlock
                  data={[
                    {
                      label: t`Št. strank`,
                      values: [`${analyticsData.general.numUniqueCustomers}`],
                    },
                  ]}
                />
              </Flex>
            )}
          </Flex>

          {isMobile && (
            <>
              <Space h={"lg"} />
              <InfoBlock
                data={[
                  {
                    label:
                      _.capitalize(
                        dayjs(selectedDay)
                          .locale("sl")
                          .format("dddd, DD. MMMM YYYY"),
                      ) ?? "",
                    values:
                      selectedDay == undefined
                        ? [t`Dan ni izbran`]
                        : [
                            analyticsData.chart.find(
                              (item) => item.date == selectedDay,
                            )?.formattedValue ?? t`Ni podatkov`,
                          ],
                  },
                ]}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

const InfoBlock = ({
  data,
}: {
  data: {
    label: string;
    values: string[];
  }[];
}) => (
  <Flex
    direction={"row"}
    justify={data.length <= 1 ? "center" : "space-between"}
    style={{
      border: "1px solid #D8D8D8",
      borderRadius: "10px",
    }}
    py={20}
    px={20}
    // my={"lg"}
  >
    {data.map((item, i) => (
      <Flex
        direction={"column"}
        justify={"center"}
        align={"center"}
        gap={5}
        key={i}
      >
        <Text variant="body" c={"var(--secondary-text)"}>
          {item.label}
        </Text>
        {item.values.map((value, id) => (
          <Text variant="heading" key={id}>
            {value}
          </Text>
        ))}
      </Flex>
    ))}
  </Flex>
);
