import axios from "axios";

const prefix = import.meta.env.VITE_BE_HOST;

const apiRemoveLocation = (locationId, token) => {
  const config = {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };

  return axios.post(prefix + "/params/deleteLocation", { locationId }, config);
};

export { apiRemoveLocation };
