import TimeSpanRow from "@/Components/TimeSpanRow";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import dayjs from "dayjs";
import { RiAddFill } from "react-icons/ri";

const BreakType = ({
  data,
  type,
  breaks,
  updateBreak,
  addBreak,
  deleteBreak,
  date,
  maxLunchTimeMinutes,
}) => {
  const shift = data?.shifts?.at(0);
  if (shift == null) {
    return null;
  }

  const lunchDurationTooMuch = (() => {
    if (maxLunchTimeMinutes == null) {
      return false;
    }

    const totalBreakDuration = breaks
      .filter((break_) => break_.type === "LUNCH")
      .reduce((acc, curr) => {
        const from = dayjs(curr.from);
        const to = dayjs(curr.to);
        return acc + to.diff(from, "minutes");
      }, 0);

    return totalBreakDuration > maxLunchTimeMinutes;
  })();

  return (
    <div>
      <div
        style={{
          padding: "5px 0",
          borderBottom: "1px solid #EAEBED",
        }}
      >
        <Flex align={"center"} justify={"space-between"}>
          <Text c={"#8c8c8c"} size="14px">
            {type.label}
          </Text>
          <Button
            leftSection={<RiAddFill />}
            variant={"white"}
            onClick={addBreak}
            style={{ fontSize: "14px" }}
          >
            <Trans>Dodaj</Trans>
          </Button>
        </Flex>
      </div>
      <Flex direction={"column"} gap={"xs"} my={"xs"}>
        {breaks?.map((break_) => {
          return (
            <Flex
              key={break_.id}
              direction={"column"}
              gap={"5px"}
              style={{
                border: "1px solid #EAEBED",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <TimeSpanRow
                fromTimeValue={dayjs(break_.from).format("HH:mm")}
                toTimeValue={dayjs(break_.to).format("HH:mm")}
                onFromTimeChange={(value) => {
                  updateBreak(
                    break_.id,
                    "from",
                    dayjs(`${date} ${value}`).format("YYYY-MM-DD HH:mm:ss"),
                  );
                }}
                onToTimeChange={(value) => {
                  updateBreak(
                    break_.id,
                    "to",
                    dayjs(`${date} ${value}`).format("YYYY-MM-DD HH:mm:ss"),
                  );
                }}
                error={
                  lunchDurationTooMuch
                    ? t`Vsota vseh malic ne sme presegati ${maxLunchTimeMinutes} minut`
                    : null
                }
              />
              <Flex direction={"column"} gap={5}>
                <Text variant={TextVariant.Caption} c={Color.SecondaryText}>
                  <Trans>Opomba</Trans>
                </Text>
                <TextInput
                  value={break_.note}
                  onChange={(e) =>
                    updateBreak(break_.id, "note", e.target.value)
                  }
                ></TextInput>
              </Flex>
              <Button
                variant={"transparent"}
                w={"fit-content"}
                fw={500}
                color={"red"}
                size={"xs"}
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => {
                  deleteBreak(break_.id);
                }}
                pl={0}
              >
                <Trans>Izbriši</Trans>
              </Button>
            </Flex>
          );
        })}
      </Flex>
    </div>
  );
};

export default BreakType;
