import { FinishOnboarding, Register, VerifyTempToken } from "@/server-types";
import { useMutation } from "@tanstack/react-query";
import { post } from "../request-helpers";

/**
 * POST /user/register
 */
export const useRegister = () => {
  return useMutation({
    mutationFn: (body: Register["query"]) =>
      post<Register>("/user/register", body),
  });
};

/**
 * POST /user/onboarding/finish
 */
export const useFinishOnboarding = () => {
  return useMutation({
    mutationFn: (body: FinishOnboarding["query"]) =>
      post<FinishOnboarding>("/user/onboarding/finish", body),
  });
};

/**
 * POST /verify/token/temp
 */
export const useVerifyTempToken = () => {
  return useMutation({
    mutationFn: (body: VerifyTempToken["query"]) =>
      post<VerifyTempToken>("/verify/token/temp", body),
  });
};
