import { api } from "@/lib/api-client";
import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { Color } from "@/types/colors";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Accordion, Flex, Tooltip, UnstyledButton } from "@mantine/core";
import React, { useEffect } from "react";
import { FaUserAltSlash } from "react-icons/fa";
import { GoCheck } from "react-icons/go";
import { LuCopySlash, LuTimerOff } from "react-icons/lu";
import styled from "styled-components";
import { useAppointmentModalStore } from "../useAppointmentModalStore_";
import { isEqual } from "lodash";

const ServiceSelectList = ({
  serviceTags,
  services,
  onSelectService,
  selectedServices,
}) => {
  const {
    calendarMode,
    lastSelectedUserId,
    lastSelectedResourceId,
    selectedLocationId,
    doesHaveCombinations,
  } = useCalendarStore((state) => state);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const {
    appointmentData,
    setRequiredTags,
    setIsTagCombinationValid,
    setSelectedServices,
  } = useAppointmentModalStore((s) => s);

  const { data: validOptions, isFetching: areValidOptionsFetching } =
    api.appointments.usePostReturnValidOptions(
      {
        locationId: selectedLocationId,
        selectedServices: [
          ...JSON.parse(JSON.stringify(selectedServices)).map((ss) => ({
            ...ss,
            duration:
              (ss.startDuration || 0) +
              (ss.endDuration || 0) +
              (ss.timeOffDuration || 0),
            timeOffDuration: ss.timeOffDuration || undefined,
            timeOffStart: ss.timeOffDuration ? ss.startDuration : undefined,
            appointmentId: ss.appointmentId || undefined,
          })),
          {
            serviceId: services[0]?.serviceId,
            users: lastSelectedUserId ? [lastSelectedUserId] : [],
            resources: lastSelectedResourceId ? [lastSelectedResourceId] : [],
            duration: 60,
          },
        ],
        ignoreLastSelected: true,
        selectedServiceIndex: selectedServices.length,
        startTime: appointmentData.startTime,
      },
      (!!doesHaveCombinations ||
        !!permissions.show_appointment_warnings ||
        !!permissions.restrict_appointment_overlaps ||
        !!permissions.restrict_appointment_services ||
        !!permissions.restrict_appointment_to_shifts) &&
        services.length > 0,
    );

  useEffect(() => {
    setRequiredTags(validOptions?.requiredTags || []);
    setIsTagCombinationValid(validOptions?.isValid || false);

    if (validOptions?.order) {
      if (
        !isEqual(
          selectedServices.map((ss) => ss.serviceId),
          validOptions?.order,
        )
      ) {
        const orderedSelectedServices = selectedServices.sort((a, b) => {
          return (
            validOptions?.order?.indexOf(a.serviceId) -
            validOptions?.order?.indexOf(b.serviceId)
          );
        });

        setSelectedServices(orderedSelectedServices, false, false);
      }
    }
  }, [validOptions]);

  useLingui();
  // if (serviceTags?.length === 0) {
  //   serviceTags.push({ name: t`Ostale storitve`, tagId: -1 });
  // }

  const servicesWithWarnings = services
    .filter(
      (s) =>
        !validOptions ||
        validOptions.availableServices.find(
          (ser) => s.serviceId === ser.serviceId && ser.isValid,
        ) ||
        selectedServices.find((ss) => ss.serviceId === s.serviceId),
    )
    .map((service) => {
      const availableServiceDetails = validOptions?.availableServices?.find(
        (s) => s.serviceId === service.serviceId,
      );

      return {
        ...service,
        id: service.serviceId,
        value: service.serviceId.toString(),
        label: service.name,
        relationWarning: availableServiceDetails?.relationWarning,
        shiftWarning: availableServiceDetails?.shiftWarning,
        overlapWarning: availableServiceDetails?.overlapWarning,
        isValid: availableServiceDetails
          ? availableServiceDetails.isValid
          : true,
      };
    });

  return (
    <Wrapper>
      <Accordion
        defaultValue={
          serviceTags.length === 1 ? serviceTags[0].tagId.toString() : null
        }
      >
        {serviceTags?.map((tag) => {
          const tagServices = servicesWithWarnings.filter(
            (service) =>
              (service.tagId === tag.tagId && service.permission) ||
              (tag.tagId === -1 && !service.tagId),
          );

          if (tagServices.length === 0) return null;

          const isServiceFromTagSelected = selectedServices.some(
            (selectedService) =>
              tagServices
                .map((ts) => ts.serviceId)
                .includes(selectedService.serviceId),
          );

          const tagName = tag.name;

          return (
            <Accordion.Item
              key={tag.tagId}
              label={tag.tagId}
              value={tag.tagId.toString()}
            >
              <Accordion.Control
                disabled={
                  !isServiceFromTagSelected &&
                  (areValidOptionsFetching || validOptions?.isCombination
                    ? !validOptions?.requiredTags.includes(tag.tagId)
                    : false)
                }
              >
                {tagName}
              </Accordion.Control>
              <Accordion.Panel>
                <Flex direction={"column"} gap={"10px"}>
                  {tagServices.map((service) => {
                    const isSelected = selectedServices.some(
                      (selectedService) =>
                        selectedService.serviceId === service.serviceId,
                    );

                    const selectedItem = selectedServices.find(
                      (selectedService) =>
                        selectedService.serviceId === service.serviceId,
                    );

                    return (
                      <ServiceItem
                        key={service.serviceId}
                        color={service.color}
                        isSelected={isSelected}
                        onClick={() => onSelectService(service, isSelected)}
                        // disabled={true}
                      >
                        <div className={"color"}></div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                            alignItems: "center",
                          }}
                        >
                          {service.name}

                          <Flex gap={5} align={"center"} h={"fit-content"}>
                            {(
                              selectedItem
                                ? selectedItem.relationWarning
                                : service.relationWarning
                            ) ? (
                              <Tooltip
                                label={
                                  calendarMode === "users"
                                    ? t`Izbran zaposleni ne izvaja te storitve`
                                    : t`Izbrano sredstvo ne izvaja te storitve`
                                }
                              >
                                <Flex align={"center"}>
                                  <FaUserAltSlash color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}

                            {(
                              selectedItem
                                ? selectedItem.overlapWarning
                                : service.overlapWarning
                            ) ? (
                              <Tooltip label={t`Prekrivanje`}>
                                <Flex align={"center"}>
                                  <LuCopySlash color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}
                            {(
                              selectedItem
                                ? selectedItem.shiftWarning
                                : service.shiftWarning
                            ) ? (
                              <Tooltip label={t`Ni urnika`}>
                                <Flex align={"center"}>
                                  <LuTimerOff color={Color.Error} />
                                </Flex>
                              </Tooltip>
                            ) : undefined}

                            {
                              <GoCheck
                                style={{
                                  color: "black",
                                  fontSize: "1.1rem",
                                  visibility: isSelected ? undefined : "hidden",
                                  marginLeft: "20px",
                                }}
                              />
                            }
                          </Flex>
                        </div>
                      </ServiceItem>
                    );
                  })}
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .mantine-Accordion-label {
    padding: 0.65rem 0;
    font-weight: 500;
  }

  .mantine-Accordion-control {
    padding: 0 0.5rem;
  }

  .mantine-Accordion-content {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ServiceItem = styled(UnstyledButton)`
  display: flex;
  align-items: center;

  border-radius: 7px;
  padding: 0.5rem 1rem;

  border: 1px solid ${(props) => props.color};
  font-size: 0.92rem;

  &:hover {
    background-color: ${(props) => props.color}10;
  }

  .color {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
`;

export default React.memo(ServiceSelectList);
