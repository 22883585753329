import { t } from "@lingui/macro";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect } from "react";
import { apiRemoveCustomerFromPreScheduledAppointment } from "../../pages/Dashboard/Calendar/api/apiCalendar";
import { useCalendarStore } from "../../pages/Dashboard/Calendar/store/useCalendarStore";
import { useAppointmentModalStore } from "./useAppointmentModalStore_";

const useAppointmentModal = (appointmentId) => {
  const { services, selectedAppointmentId } = useCalendarStore(
    (state) => state,
  );

  const {
    setAppointmentId,
    isFetchingData,
    appointmentData,
    selectedServices,
    setSelectedServices,
    confirmedServiceSelection,
    selectedUserAppointments,
    setSelectedUserAppointments,
    modifyAppointmentData,
    modifySelectedUserAppointments,
    handleConfirmServiceSelection,
    handleSubmitAppointmentForm,
    fetchAppointmentData,
  } = useAppointmentModalStore((state) => state);

  useEffect(() => {
    setAppointmentId(appointmentId);
  }, [appointmentId, setAppointmentId]);
  // TODO: check za optimizatione za to pa endTime calculation v utils.js
  useEffect(() => {
    // update appointmentData duration as sum of selected services
    if (
      appointmentData &&
      appointmentData.appointmentId == null &&
      selectedServices.length > 0
    ) {
      const newDuration = selectedServices.reduce(
        (acc, ser) =>
          acc +
          services.find((service) => service.serviceId === ser.serviceId)
            .duration *
            1,
        0,
      );

      modifyAppointmentData({
        duration: newDuration,
      });
    }
  }, [selectedServices]);

  const handleStartTimeChange = (startTime) => {
    if (!appointmentData || !startTime) return;

    const splitTime = startTime.split(":");

    const dayObject = dayjs(appointmentData.startTime);
    const newStartTime = dayObject
      .hour(splitTime[0])
      .minute(splitTime[1])
      .second(0)
      .millisecond(0)
      .format("YYYY-MM-DDTHH:mm");

    modifyAppointmentData({
      startTime: newStartTime,
    });

    setSelectedServices(
      selectedServices,
      selectedAppointmentId === -1,
      selectedAppointmentId === -1,
    );
  };

  const handleEndTimeChange = (endTime) => {
    if (!appointmentData || !endTime) return;

    // calculate new duration based on appointmentData.startTime and the new endTime
    const startTimeObject = dayjs(appointmentData.startTime);
    const endTimeObject = dayjs(appointmentData.startTime)
      .hour(endTime.split(":")[0])
      .minute(endTime.split(":")[1]);

    const newDuration = endTimeObject.diff(startTimeObject, "minute");

    modifyAppointmentData({
      duration: newDuration,
    });

    setSelectedServices(
      selectedServices,
      selectedAppointmentId === -1,
      selectedAppointmentId === -1,
    );
  };

  const handleUserAppointmentSelect = (userAppointmentId) => {
    if (selectedUserAppointments.includes(userAppointmentId)) {
      setSelectedUserAppointments(
        selectedUserAppointments.filter((id) => id !== userAppointmentId),
      );
    } else {
      const isOnlyUserSelected = selectedUserAppointments.length === 0;

      modifySelectedUserAppointments([userAppointmentId]);

      if (isOnlyUserSelected) {
        // fill form fields
        const userAppointment = appointmentData.customers.find(
          (userAppt) => userAppt.userAppointmentId === userAppointmentId,
        );
        if (userAppointment) {
          modifyAppointmentData({
            gsm: userAppointment.gsm,
            email: userAppointment.email,
            name: userAppointment.name,
          });
        }
      }
    }
  };

  const handleDateChange = (date) => {
    if (!appointmentData || !date) return;

    const dayObject = dayjs(appointmentData.startTime);
    const newStartTime = dayObject
      .year(date.getFullYear())
      .month(date.getMonth())
      .date(date.getDate())
      .hour(dayObject.hour())
      .minute(dayObject.minute())
      .second(0)
      .millisecond(0)
      .format("YYYY-MM-DD HH:mm");

    modifyAppointmentData({
      startTime: newStartTime,
    });

    setSelectedServices(
      selectedServices,
      selectedAppointmentId === -1,
      selectedAppointmentId === -1,
    );
  };

  const handleRemoveCustomerFromPreScheduledAppointment = async (
    userAppointmentIds,
    sendCancelNotifications,
  ) => {
    console.log(appointmentData);
    const response = await apiRemoveCustomerFromPreScheduledAppointment(
      appointmentData.appointmentParts[0].appointmentId,
      { userAppointmentIds, sendCancelNotifications },
    ).catch((err) => {
      console.error(err);
      notifications.show({
        message: err.message,
        color: "red",
      });
    });

    if (response.status !== 200) {
      console.error("Error removing customer from pre-scheduled appointment");

      notifications.show({
        message: "Error removing customer from pre-scheduled appointment",
        color: "red",
      });

      return;
    }

    notifications.show({
      message: t`Uspešno ste odstranili osebo s termina`,
      variant: "success",
      color: "green",
    });

    setSelectedUserAppointments([]);

    // refresh the appointment
    fetchAppointmentData(appointmentData.appointmentId);
  };

  return {
    appointmentData,
    isFetchingData,
    handleDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    services,
    selectedServices,
    setSelectedServices,
    confirmedServiceSelection,
    handleConfirmServiceSelection,
    selectedUserAppointments,
    setSelectedUserAppointments,
    handleUserAppointmentSelect,
    handleSubmitAppointmentForm,
    handleRemoveCustomerFromPreScheduledAppointment,
  };
};

export default useAppointmentModal;
