import axios from "axios";

const prefix = import.meta.env.VITE_BE_HOST || "http://localhost:8080";

const getConfig = () => {
  const token = localStorage.getItem("usr_token");

  return {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
};

export const apiPostBulkSms = async (message, selectedCustomers) => {
  return axios.post(
    prefix + `/customer/bulk_sms`,
    { message, selectedCustomers },
    getConfig(),
  );
};
