import { useNavigate } from "react-router-dom";
import { api } from "../../../../lib/api-client/index";
import { useForm } from "@mantine/form";
import { Button, Divider } from "@nextui-org/react";
import { t, Trans } from "@lingui/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { useEffect } from "react";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { LimeSingleSelect } from "@/Components/NextBase/LimeSelect";
import { notifications } from "@mantine/notifications";
import { countries } from "../../../../../../shared/constants/countries";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

export const TaxRegisterSetupBasicInfo = () => {
  const navigate = useNavigate();

  const {
    mutateAsync: createOrganization,
    isPending: isCreatingOrganization,
    processedErrorMessage: createOrganizationErrorMessage,
  } = api.taxRegister.usePostCreateOrganization();

  const {
    mutateAsync: updateOrganization,
    isPending: isUpdatingOrganization,
    processedErrorMessage: updateOrganizationErrorMessage,
  } = api.taxRegister.useUpdateOrganization();

  const { data: organization, isPending: isFetchingOrganization } =
    api.taxRegister.useGetOrganization({});

  const isExistingOrganization = !isFetchingOrganization && !!organization;

  const form = useForm<Form>({
    initialValues: {
      organization: {
        name: "",
        address: "",
        zip: "",
        city: "",
        country: "",
        organizationTaxNumber: "",
        isTaxSubject: false,
      },
      fursStrategy: "C",
    },

    validate: {
      organization: {
        name: (value) => (value === "" ? t`Ime je obvezno` : null),
        address: (value) => (value === "" ? t`Naslov je obvezen` : null),
        zip: (value) => (value === "" ? t`Pošta je obvezna` : null),
        city: (value) => (value === "" ? t`Mesto je obvezno` : null),
        country: (value) => (value === "" ? t`Država je obvezna` : null),
        organizationTaxNumber: (value) =>
          value === "" ? t`Davčna številka je obvezna` : null,
      },
    },
  });

  useEffect(() => {
    if (!organization) return;

    form.setValues({
      organization: {
        name: organization.name,
        address: organization.address,
        zip: organization.zip,
        city: organization.city,
        country: organization.country,
        organizationTaxNumber: organization.taxNumber,
        isTaxSubject: organization.taxSubject,
      },
    });
  }, [organization]);

  const handleSubmit = async (values: Form) => {
    const response = await (async () => {
      if (isExistingOrganization) {
        const res = await updateOrganization({
          body: values,
        });
        return res;
      }

      const res = await createOrganization(values);
      return res;
    })();

    if (response) {
      notifications.show({
        message: t`Uspešno`,
      });
      navigate("../", {
        relative: "path",
      });
    }
  };

  return (
    <>
      <LimePageHeader
        title={t`Vzpostavitev davčne blagajne`}
        subPage={{
          title: t`Osnovne informacije`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      <div className="mt-4 flex flex-col px-4 md:px-8">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 lg:flex-row">
              <div className="flex flex-1 flex-col gap-2">
                <p className="text-sm font-semibold text-gray-400">
                  <Trans>Podatki organizacije</Trans>
                </p>

                <LimeInput
                  {...form.getInputProps("organization.name")}
                  label={t`Ime`}
                  type="text"
                />

                <LimeInput
                  {...form.getInputProps("organization.address")}
                  label={t`Naslov`}
                  type="text"
                />

                <LimeInput
                  {...form.getInputProps("organization.zip")}
                  label={t`Poštna številka`}
                  type="text"
                />

                <LimeInput
                  {...form.getInputProps("organization.city")}
                  label={t`Mesto`}
                  type="text"
                />

                <LimeSingleSelect
                  label={t`Država`}
                  items={countries.map((country) => ({
                    key: country.code,
                    label: country.name,
                    startContent: getUnicodeFlagIcon(country.code),
                  }))}
                  {...form.getInputProps("organization.country")}
                />

                <LimeInput
                  {...form.getInputProps("organization.organizationTaxNumber")}
                  label={t`Davčna številka`}
                  type="text"
                />

                <LimeSwitch
                  {...form.getInputProps("organization.isTaxSubject", {
                    type: "checkbox",
                  })}
                  title={t`Davčni zavezanec`}
                />
              </div>
            </div>

            {!isExistingOrganization && (
              <>
                <Divider />
                <div>
                  <p className="mb-2">
                    <Trans>Številčenje (FURS)</Trans>
                  </p>

                  <LimeSingleSelect
                    label={t`FURS strategija`}
                    items={[
                      {
                        key: "B",
                        label: t`B - Po napravah`,
                      },
                      {
                        key: "C",
                        label: t`C - Po prostorih`,
                      },
                    ]}
                    {...form.getInputProps("fursStrategy")}
                  />
                </div>
              </>
            )}

            <Divider />

            <ErrorCard message={createOrganizationErrorMessage} />
            <ErrorCard message={updateOrganizationErrorMessage} />

            <Button
              type="submit"
              isLoading={isCreatingOrganization || isUpdatingOrganization}
              isDisabled={isFetchingOrganization}
              color="primary"
              className="w-full self-end lg:w-fit"
            >
              {isExistingOrganization ? (
                <Trans>Posodobi organizacijo</Trans>
              ) : (
                <Trans>Ustvari organizacijo</Trans>
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

type Form = {
  organization: {
    name: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    organizationTaxNumber: string;
    isTaxSubject: boolean;
  };
  fursStrategy: "C" | "B";
};
