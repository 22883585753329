import { LimeLoader } from "@/Components/LimeLoader";
import {
  LimePageHeader,
  type LimePageHeaderProps,
} from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import useFeature from "@/hooks/useFeature";
import { useQueryParamsState } from "@/hooks/useQueryParamState";
import { api, queryClient } from "@/lib/api-client";
import type { DayOfWeek, GetService, PostService } from "@/server-types";
import { SUPPORTED_LANGUAGES } from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { t } from "@lingui/macro";
import { Box, Flex, Space } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDidUpdate } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { VscTrash } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import { ActionButton } from "../ResourceGroups/Components/ActionButton";
import { ManageBracket } from "./ManageBracket";
import { ManageSchedule } from "./ManageSchedule";
import { ManageTier } from "./ManageTier";
import { DurationTab } from "./ServiceTabs/DurationTab";
import { GeneralTab } from "./ServiceTabs/GeneralTab";
import { PriceTab } from "./ServiceTabs/PriceTab";
import { ResourcesTab } from "./ServiceTabs/ResourcesTab";
import { i18n } from "@lingui/core";
import { AdditionalTab } from "./ServiceTabs/AdditionalTab";

const groupSchedules = (
  schedules: GetService["response"]["schedules"][number][],
): FormValues["schedules"] => {
  const grouped: {
    [key: string]: {
      price: number;
      timeFrom: Date;
      timeTo: Date;
      daysOfWeek: DayOfWeek[];
    };
  } = {};

  schedules.forEach((schedule) => {
    // Create a key that includes the time range and the price
    const key = `${schedule.timeFrom}-${schedule.timeTo}-${schedule.price}`;

    if (!grouped[key]) {
      grouped[key] = {
        price: schedule.price,
        timeFrom: dayjs(schedule.timeFrom).toDate(),
        timeTo: dayjs(schedule.timeTo).toDate(),
        daysOfWeek: [],
      };
    }

    grouped[key].daysOfWeek.push(schedule.daysOfWeek);
  });

  return Object.values(grouped).map((group) => ({
    ...group,
    daysOfWeek: [...new Set(group.daysOfWeek.flat())],
  }));
};

export type ClientSchedule = {
  price: number;
  timeFrom: Date;
  timeTo: Date;
  daysOfWeek: DayOfWeek[];
};

export type FormValues = {
  // general tab
  nameLocalized: PostService["body"]["nameLocalized"];
  descriptionLocalized?: PostService["body"]["descriptionLocalized"];

  tagIds: PostService["body"]["tagIds"];

  // isFavourite: boolean;
  color: string;
  userIds: PostService["body"]["userIds"];
  resourceGroupIds: PostService["body"]["resourceGroupIds"];

  shownOnForm: PostService["body"]["shownOnForm"];
  doesRequireUser: PostService["body"]["doesRequireUser"];

  // duration tab
  isExtraTimeAllowed: boolean;

  baseDuration: number;

  extraTimeMax: PostService["body"]["extraTimeMax"];
  extraTimeStep: PostService["body"]["extraTimeStep"];

  blockTimeEnabled: boolean;
  blockBefore: number;
  blockAfter: number;

  handsOffEnabled: boolean;
  handsOffStart: number;
  handsOffDuration: number;

  // resources tab
  isAttendanceExplicit: PostService["body"]["isAttendanceExplicit"];
  minAttendance?: PostService["body"]["minAttendance"];
  maxAttendance?: PostService["body"]["maxAttendance"];

  areExtraResourcesExplicit: PostService["body"]["areExtraResourcesExplicit"];
  totalResourcesMax?: PostService["body"]["totalResourcesMax"];

  allowsMultipleAppointments: boolean;
  isTimeOffBlocking: boolean;

  // price tab
  pricingStrategy: PostService["body"]["pricingStrategy"];

  // -- flat
  pricingMultiplier: PostService["body"]["pricingMultiplier"];
  priceBase: number;

  // -- bracketed
  brackets: PostService["body"]["brackets"];

  // -- scheduled
  schedules: ClientSchedule[];

  // -- tiered
  tiers: PostService["body"]["tiers"];

  // additional tab
  shouldCalculateTax: boolean;
  isPriceWithTax: boolean;
  taxPercentage: number;

  appointmentType: PostService["body"]["appointmentType"];
};

const defaultManagingPricingObject: {
  pricingStrategy: GetService["response"]["pricingStrategy"];
  objectIndex: string | null;
} = {
  pricingStrategy: "flat",
  objectIndex: null,
};
export const managingPriceObjectAtom = atom<{
  pricingStrategy: GetService["response"]["pricingStrategy"];
  objectIndex: string | null;
}>(defaultManagingPricingObject);

export const ManageService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isFeatureEnabled } = useFeature();

  const serviceId = id ?? undefined;
  const isEditing = serviceId != "-1";

  const [managingPricingObject, setManagingPricingObject] = useAtom(
    managingPriceObjectAtom,
  );

  const { alertModal } = useLimeAlertModal();

  const { mutateAsync: deleteService } = api.service.useDeleteService();

  const [tab, setTab] = useQueryParamsState<string>("tab", "general");
  const [furthestTab, setFurthestTab] = useState<string | null>(null);

  const areResourcesEnabled: boolean = isFeatureEnabled("RESOURCES");

  const {
    data: service,
    isLoading: serviceIsLoading,
    isError: serviceIsError,
    isSuccess: isServiceSuccess,
  } = api.service.useGetService(parseInt(serviceId ?? "-1"));

  const { mutateAsync: postService, isPending: isPostingService } =
    api.service.usePostService();

  const form = useForm<FormValues>({
    initialValues: {
      // general tab

      nameLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        name: "",
      })),

      descriptionLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        description: "",
      })),

      tagIds: [],
      // categoryId: undefined,
      // isFavourite: false,
      color: "#E9B2BF",
      userIds: [],
      resourceGroupIds: [],

      shownOnForm: true,
      doesRequireUser: true,

      // duration tab
      isExtraTimeAllowed: false,

      baseDuration: 0,

      extraTimeMax: undefined,
      extraTimeStep: undefined,

      blockTimeEnabled: false,
      blockBefore: 0,
      blockAfter: 0,

      handsOffEnabled: false,
      handsOffStart: 0,
      handsOffDuration: 0,

      // resources tab
      isAttendanceExplicit: false,
      minAttendance: undefined,
      maxAttendance: undefined,

      areExtraResourcesExplicit: false,
      totalResourcesMax: undefined,

      allowsMultipleAppointments: false,
      isTimeOffBlocking: true,

      // price tab
      pricingStrategy: "flat",

      // -- flat
      pricingMultiplier: "perPerson",
      priceBase: 0,

      // -- bracketed
      brackets: [],

      // -- scheduled
      schedules: [],

      // -- tiered
      tiers: [],

      // additional tab
      shouldCalculateTax: false,
      isPriceWithTax: false,
      taxPercentage: 0,

      appointmentType: "adhoc",
    },

    validate: (values) => {
      if (tab === "general") {
        return {
          color: !values.color ? t`Barva je obvezno polje` : null,
          userIds: (() => {
            if (values.resourceGroupIds.length < 1) {
              if (values.userIds.length < 1 && values.doesRequireUser) {
                if (areResourcesEnabled) {
                  return t`Storitev potrebuje izvajalca ali sredstvo`;
                }

                return t`Določite izvajalca storitve`;
              }
            }

            if (values.doesRequireUser && values.userIds.length < 1) {
              return t`Določite izvajalca storitve`;
            }
          })(),
          resourceGroupIds:
            values.resourceGroupIds.length < 1 &&
            (values.userIds.length < 1 || !values.doesRequireUser)
              ? t`Storitev potrebuje izvajalca ali sredstvo`
              : null,
        };
      } else if (tab === "duration") {
        const handsOffTimeLeft =
          values.baseDuration - values.handsOffStart - values.handsOffDuration;

        const handsOffDurationStartMinError =
          values.handsOffEnabled && values.handsOffStart < 0;

        const handsOffDurationDurationMinError =
          values.handsOffEnabled && values.handsOffDuration <= 0;

        let handsOffDurationStartError = null;
        if (handsOffDurationStartMinError) {
          handsOffDurationStartError = t`Čas mora biti večji ali enak 0`;
        } else if (handsOffTimeLeft < 0) {
          handsOffDurationStartError = t`Trajanje brez izvajalca ne sme biti daljše od trajanja storitve`;
        }

        let handsOffDurationDurationError = null;
        if (handsOffDurationDurationMinError) {
          handsOffDurationDurationError = t`Trajanje mora biti večje od 0`;
        } else if (handsOffTimeLeft < 0) {
          handsOffDurationDurationError = t`Trajanje brez izvajalca ne sme biti daljše od trajanja storitve`;
        }

        return {
          baseDuration:
            values.baseDuration <= 0 ? t`Trajanje mora biti večje od 0` : null,
          blockBefore:
            values.blockTimeEnabled && values.blockBefore < 0
              ? t`Čas mora biti večji ali enak 0`
              : null,
          blockAfter:
            values.blockTimeEnabled && values.blockAfter < 0
              ? t`Čas mora biti večji ali enak 0`
              : null,
          handsOffStart: values.handsOffEnabled && handsOffDurationStartError,
          handsOffDuration:
            values.handsOffEnabled && handsOffDurationDurationError,
          extraTimeMax:
            values.isExtraTimeAllowed &&
            (!values.extraTimeMax || values.extraTimeMax <= values.baseDuration)
              ? t`Maksimalen čas trajanja mora biti večji od trajanja storitve`
              : null,
          extraTimeStep:
            values.isExtraTimeAllowed &&
            (!values.extraTimeStep || values.extraTimeStep <= 0)
              ? t`Časovni korak mora biti večji od trajanja storitve`
              : null,
        };
      } else if (tab === "resources") {
        return {
          minAttendance:
            values.isAttendanceExplicit && !values.minAttendance
              ? t`Minimalno število strank mora biti manjše od maksimalnega št.`
              : null,
          maxAttendance:
            values.isAttendanceExplicit && !values.maxAttendance
              ? t`Maksimalno število strank mora biti večje od minimalnega št.`
              : null,

          totalResourcesMax:
            values.areExtraResourcesExplicit && !values.totalResourcesMax
              ? t`Vnesite število sredstev`
              : null,
        };
      } else if (tab === "price") {
        const pricingStrategy = values.pricingStrategy;

        if (pricingStrategy === "flat") {
          return {
            priceBase:
              values.priceBase < 0 ? t`Cena mora biti pozitivna` : null,
          };
        }

        if (pricingStrategy === "bracketed") {
          return {
            brackets:
              values.brackets.length < 1
                ? t`Dodajte vsaj en cenovni razred`
                : null,
          };
        }

        if (pricingStrategy === "scheduled") {
          return {
            schedules:
              values.schedules.length < 1 ? t`Dodajte vsaj eno tarifo` : null,
            overlappingSchedules: (() => {
              const schedules = values.schedules.map((schedule) => ({
                timeFrom: schedule.timeFrom,
                timeTo: schedule.timeTo,
                daysOfWeek: schedule.daysOfWeek,
              }));

              const overlappingSchedules: {
                timeFrom: Date;
                timeTo: Date;
                daysOfWeek: DayOfWeek[];
              }[] = [];

              schedules.forEach((schedule, idx) => {
                schedules.forEach((otherSchedule, otherIdx) => {
                  if (idx === otherIdx) return;

                  const fromTime = dayjs(schedule.timeFrom).format("HH:mm");
                  const toTime = dayjs(schedule.timeTo).format("HH:mm");
                  const from = dayjs(`1970-01-01 ${fromTime}`);
                  const to = dayjs(`1970-01-01 ${toTime}`);

                  const otherFromTime = dayjs(otherSchedule.timeFrom).format(
                    "HH:mm",
                  );
                  const otherToTime = dayjs(otherSchedule.timeTo).format(
                    "HH:mm",
                  );
                  const otherFrom = dayjs(`1970-01-01 ${otherFromTime}`);
                  const otherTo = dayjs(`1970-01-01 ${otherToTime}`);

                  if (
                    dayjs(from).isBefore(otherTo) &&
                    dayjs(to).isAfter(otherFrom) &&
                    schedule.daysOfWeek.some((day) =>
                      otherSchedule.daysOfWeek.includes(day),
                    )
                  ) {
                    overlappingSchedules.push(schedule);
                  }
                });
              });

              return overlappingSchedules.length > 0
                ? t`Tarife se prekrivajo`
                : null;
            })(),
          };
        }

        if (pricingStrategy === "tiered") {
          return {
            tiers:
              values.tiers.length < 1 ? t`Dodajte vsaj eno kategorijo` : null,
          };
        }
      } else if (tab === "additional") {
        return {
          taxRate:
            values.shouldCalculateTax && !values.taxPercentage
              ? t`Prosimo izberite davčno stopnjo`
              : null,
        };
      }

      return {};
    },
  });

  const close = ({ invalidate }: { invalidate: boolean }) => {
    if (invalidate) {
      queryClient.invalidateQueries({ queryKey: ["services"] });

      if (serviceId) {
        queryClient.invalidateQueries({
          queryKey: ["service", serviceId],
        });
      }
    }

    navigate(`/dashboard/services?opened=${service?.tagIds[0] || -1}`);
  };

  useEffect(() => {
    if (!service) return;

    const existingNameLocalized = service.nameLocalized.map((name) => ({
      language: name.language,
      name: name.name,
    }));
    const missingNamesLocalized = SUPPORTED_LANGUAGES.filter(
      (language) =>
        !existingNameLocalized.some((name) => name.language === language),
    );

    const existingDescriptionLocalized = service.descriptionLocalized?.map(
      (description) => ({
        language: description.language,
        description: description.description,
      }),
    );
    const missingDescriptionsLocalized = SUPPORTED_LANGUAGES.filter(
      (language) =>
        !existingDescriptionLocalized?.some(
          (description) => description.language === language,
        ),
    );

    form.setValues({
      ...service,

      nameLocalized: existingNameLocalized.concat(
        missingNamesLocalized.map((language) => ({
          language,
          name: "",
        })),
      ),

      descriptionLocalized: existingDescriptionLocalized?.concat(
        missingDescriptionsLocalized.map((language) => ({
          language,
          description: "",
        })),
      ),

      tagIds: service.tagIds ?? [],
      // categoryId: service.categoryId ?? undefined,
      minAttendance: service.minAttendance ?? undefined,
      maxAttendance: service.maxAttendance ?? undefined,
      totalResourcesMax: service.totalResourcesMax ?? undefined,

      color: service.color ?? "",

      blockTimeEnabled: service.blockBefore > 0 || service.blockAfter > 0,
      handsOffEnabled:
        service.handsOffStart > 0 || service.handsOffDuration > 0,
      priceBase: service.priceBaseCents ? service.priceBaseCents / 100 : 0,

      schedules: groupSchedules(service.schedules),
      brackets: service.brackets ?? [],
    });
  }, [service]);

  //local storage for form values
  useEffect(() => {
    const storedValue = window.localStorage.getItem(`user-form-service`);

    if (isEditing || !storedValue) {
      return;
    }

    alertModal({
      title: t`Ali želite obnoviti vrednosti obrazca?`,
      onConfirm: () => {
        if (storedValue) {
          try {
            form.setValues(
              JSON.parse(window.localStorage.getItem(`user-form-service`)!)!,
            );
          } catch (e) {
            console.log("Failed to parse stored value");
          }
        }
      },
      onCancel: () => {
        window.localStorage.removeItem(`user-form-service`);
      },
    });
  }, []);

  useDidUpdate(() => {
    if (isEditing) return;

    window.localStorage.setItem(
      "user-form-service",
      JSON.stringify(form.values),
    );
  }, [form.values]);

  const handleSubmit = async (values: typeof form.values) => {
    const isFinalTab = tab === tabs[tabs.length - 1].value;
    const shouldSubmit = isFinalTab || isEditing;
    if (shouldSubmit) {
      const priceBaseCents = values.priceBase * 100;

      // If blockTimeEnabled is false, set blockBefore and blockAfter to 0
      const blockBefore = values.blockTimeEnabled ? values.blockBefore : 0;
      const blockAfter = values.blockTimeEnabled ? values.blockAfter : 0;

      // If handsOffEnabled is false, set handsOffStart and handsOffDuration to 0
      const handsOffStart = values.handsOffEnabled ? values.handsOffStart : 0;
      const handsOffDuration = values.handsOffEnabled
        ? values.handsOffDuration
        : 0;

      const resourceGroupIds = values.resourceGroupIds.map((id) =>
        parseInt(id as unknown as string),
      );

      // Only send values for selected pricing strategy
      let brackets: PostService["body"]["brackets"] = [];
      let schedules: PostService["body"]["schedules"] = [];
      let tiers: PostService["body"]["tiers"] = [];

      if (values.pricingStrategy === "bracketed") {
        brackets = values.brackets.map((bracket) => ({
          count: bracket.count,
          price: bracket.price * 100,
        }));
      } else if (values.pricingStrategy === "scheduled") {
        // Break apart each schedules dayOfWeek[] into its own object
        schedules = values.schedules.flatMap((schedule) => {
          return schedule.daysOfWeek.map((day) => {
            return {
              ...schedule,
              daysOfWeek: day,
              price: schedule.price * 100,
              timeFrom: dayjs(schedule.timeFrom).format("YYYY-MM-DDTHH:mm"),
              timeTo: dayjs(schedule.timeTo).format("YYYY-MM-DDTHH:mm"),
            };
          });
        });
      } else if (values.pricingStrategy === "tiered") {
        tiers = values.tiers.map((tier) => ({
          ...tier,
          price: tier.price * 100,
        }));
      }

      try {
        await postService({
          ...values,
          id: serviceId === "-1" ? undefined : service?.serviceId,
          priceBaseCents,
          handsOffStart,
          handsOffDuration,
          blockBefore,
          blockAfter,
          resourceGroupIds,

          brackets,
          tiers,
          schedules,
        });
        notifications.show({
          message: t`Storitev uspešno posodobljena`,
          color: "green",
        });
      } catch (_) {
        notifications.show({
          message: t`Napaka pri posodabljanju storitve`,
          color: "red",
        });
      }

      window.localStorage.removeItem("user-form-service");
      close({ invalidate: true });
    } else {
      nextTab();
    }
  };

  const nextTab = () => {
    setTab((current) => {
      if (form.validate().hasErrors) {
        return current;
      }

      const currentIndex = tabs.findIndex((t) => t.value === current);
      const nextTab = tabs[currentIndex + 1];

      if (
        !furthestTab ||
        tabs.findIndex((t) => t.value === furthestTab) <= currentIndex
      ) {
        setFurthestTab(nextTab.value);
      }

      if (nextTab) {
        return nextTab.value;
      }

      return current;
    });
  };

  const previousTab = () => {
    setTab((current) => {
      const currentIndex = tabs.findIndex((t) => t.value === current);
      const previousTab = tabs[currentIndex - 1];

      if (previousTab) {
        return previousTab.value;
      }

      return current;
    });
  };

  if (serviceIsLoading) {
    return <LimeLoader />;
  }

  if (serviceIsError && !isServiceSuccess) {
    return <div>Error</div>;
  }

  const { locale } = i18n;
  const tabs = [
    {
      label: t`Splošno`,
      value: "general",
      component: <GeneralTab form={form} />,
    },
    {
      label: t`Trajanje`,
      value: "duration",
      component: <DurationTab form={form} />,
    },
    ...(areResourcesEnabled
      ? [
          {
            label: t`Sredstva`,
            value: "resources",
            component: <ResourcesTab form={form} />,
          },
        ]
      : []),
    {
      label: t`Cena`,
      value: "price",
      component: (
        <PriceTab
          form={form}
          currency={service?.currency || "EUR"}
          locale={locale || "en"}
        />
      ),
    },
    {
      label: t`Dodatno`,
      value: "additional",
      component: <AdditionalTab form={form} />,
    },
  ];

  const tabsAfterFurthestTab = furthestTab
    ? tabs.slice(tabs.findIndex((t) => t.value === furthestTab) + 1)
    : tabs.filter((t) => t.value !== "general");

  let managingObjectIndexComponent = null;

  let subPage: LimePageHeaderProps["subPage"] = {
    title: isEditing ? t`Uredi storitev` : t`Dodaj storitev`,
    onBackButtonClick: () => close({ invalidate: false }),

    rightSection: isEditing
      ? {
          options: [
            {
              label: t`Izbriši storitev`,
              color: Color.Error,
              icon: <VscTrash />,
              onClick: async () => {
                if (serviceId != null) {
                  alertModal({
                    title: t`Ali ste prepričani, da želite izbrisati storitev?`,
                    onConfirm: async () => {
                      await deleteService(parseInt(serviceId));
                      close({ invalidate: true });
                    },
                    isDanger: true,
                  });
                }
              },
            },
          ],
        }
      : undefined,
  };

  if (
    managingPricingObject.pricingStrategy === "bracketed" &&
    managingPricingObject.objectIndex != null
  ) {
    subPage = {
      title:
        parseInt(managingPricingObject.objectIndex) >=
        form.values.brackets.length
          ? t`Ustvari cenovni razred`
          : t`Uredi cenovni razred`,
      onBackButtonClick: () => {
        setManagingPricingObject(defaultManagingPricingObject);
      },
      rightSection: {
        options: [
          {
            label: t`Izbriši razred`,
            color: Color.Error,
            icon: <VscTrash />,
            onClick: () => {
              alertModal({
                title: t`Želite res izbrisati cenovni razred?`,
                onConfirm: () => {
                  form.setValues({
                    brackets: form.values.brackets.filter(
                      (_, idx) =>
                        idx !== parseInt(managingPricingObject.objectIndex!),
                    ),
                  });

                  setManagingPricingObject(defaultManagingPricingObject);
                },
                isDanger: true,
              });
            },
          },
        ],
      },
    };

    managingObjectIndexComponent = (
      <ManageBracket
        index={parseInt(managingPricingObject.objectIndex)}
        brackets={form.values.brackets}
        basePrice={form.values.priceBase}
        handleSaveBracket={(values) => {
          const isExistingBracket = form.values.brackets.some(
            (_, idx: number) =>
              idx === parseInt(managingPricingObject.objectIndex!),
          );

          if (isExistingBracket) {
            form.setValues({
              brackets: form.values.brackets.map((bracket, idx) => {
                if (idx === parseInt(managingPricingObject.objectIndex!)) {
                  return values;
                }

                return bracket;
              }),
            });
          } else {
            form.setValues({
              brackets: form.values.brackets.concat(values),
            });
          }

          setManagingPricingObject(defaultManagingPricingObject);
        }}
      />
    );
  } else if (
    managingPricingObject.pricingStrategy === "scheduled" &&
    managingPricingObject.objectIndex != null
  ) {
    subPage = {
      title:
        parseInt(managingPricingObject.objectIndex) >=
        form.values.schedules.length
          ? t`Ustvari tarifo`
          : t`Uredi tarifo`,
      onBackButtonClick: () => {
        setManagingPricingObject(defaultManagingPricingObject);
      },
      rightSection: {
        options: [
          {
            label: t`Izbriši tarifo`,
            color: Color.Error,
            icon: <VscTrash />,
            onClick: () => {
              alertModal({
                title: t`Želite res izbrisati tarifo?`,
                onConfirm: () => {
                  form.setValues({
                    schedules: form.values.schedules.filter(
                      (_, idx) =>
                        idx !== parseInt(managingPricingObject.objectIndex!),
                    ),
                  });

                  setManagingPricingObject(defaultManagingPricingObject);
                },
                isDanger: true,
              });
            },
          },
        ],
      },
    };

    managingObjectIndexComponent = (
      <ManageSchedule
        basePrice={form.values.priceBase}
        index={parseInt(managingPricingObject.objectIndex)}
        schedules={form.values.schedules}
        handleSaveSchedule={(values) => {
          const isExistingSchedule = form.values.schedules.some(
            (_, idx) => idx === parseInt(managingPricingObject.objectIndex!),
          );

          if (isExistingSchedule) {
            form.setValues({
              schedules: form.values.schedules.map((schedule, idx) => {
                if (idx === parseInt(managingPricingObject.objectIndex!)) {
                  return values;
                }

                return schedule;
              }),
            });
          } else {
            form.setValues({
              schedules: form.values.schedules.concat(values),
            });
          }

          setManagingPricingObject(defaultManagingPricingObject);
        }}
      />
    );
  } else if (
    managingPricingObject.pricingStrategy === "tiered" &&
    managingPricingObject.objectIndex != null
  ) {
    subPage = {
      title:
        parseInt(managingPricingObject.objectIndex) >= form.values.tiers.length
          ? t`Ustvari kategorijo`
          : t`Uredi kategorijo`,
      onBackButtonClick: () => {
        setManagingPricingObject(defaultManagingPricingObject);
      },
      rightSection: {
        options: [
          {
            label: t`Izbriši kategorijo`,
            color: Color.Error,
            icon: <VscTrash />,
            onClick: () => {
              alertModal({
                title: t`Želite res izbrisati kategorijo?`,
                onConfirm: () => {
                  form.setValues({
                    tiers: form.values.tiers.filter(
                      (_, idx) =>
                        idx !== parseInt(managingPricingObject.objectIndex!),
                    ),
                  });

                  setManagingPricingObject(defaultManagingPricingObject);
                },
                isDanger: true,
              });
            },
          },
        ],
      },
    };

    managingObjectIndexComponent = (
      <ManageTier
        tier={form.values.tiers[parseInt(managingPricingObject.objectIndex)]}
        handleSaveTier={(values) => {
          console.log("save tier", values);

          const isExistingTier = form.values.tiers.some(
            (_, idx) => idx === parseInt(managingPricingObject.objectIndex!),
          );

          if (isExistingTier) {
            form.setValues({
              tiers: form.values.tiers.map((tier, idx) => {
                if (idx === parseInt(managingPricingObject.objectIndex!)) {
                  return values;
                }

                return tier;
              }),
            });
          } else {
            form.setValues({
              tiers: form.values.tiers.concat(values),
            });
          }

          setManagingPricingObject(defaultManagingPricingObject);
        }}
      />
    );
  }

  if (managingObjectIndexComponent) {
    return (
      <>
        <LimePageHeader title="" subPage={subPage} />
        <Box maw={"450px"} mx={"auto"} mt={"md"}>
          {managingObjectIndexComponent}
        </Box>
      </>
    );
  }

  const activeTabComponent = tabs.find((t) => t.value === tab)?.component;

  return (
    <Flex direction={"column"}>
      <Box h={"54px"}>
        <form
          style={{ display: "contents" }}
          onSubmit={form.onSubmit(handleSubmit)}
        >
          <LimePageHeader title="" subPage={subPage} />

          <LimePageTabs
            activeTab={tab}
            onChangeTab={(value) => {
              if (form.validate() && form.isValid()) {
                setTab(value ?? "general");
              } else {
                setTimeout(() => {
                  document
                    .getElementsByClassName("mantine-InputWrapper-error")[0]
                    .scrollIntoView({ behavior: "smooth" });
                }, 0);
              }
            }}
            tabs={tabs.map((tab) => ({
              value: tab.value,
              label: tab.label,
              disabled: !isEditing
                ? tabsAfterFurthestTab.findIndex(
                    (t) => t.value === tab.value,
                  ) !== -1
                : false,
            }))}
          />

          <Box mt={"md"} maw={"450px"} mx={"auto"} pos={"relative"}>
            {activeTabComponent}

            <ActionButton
              isLoading={isPostingService}
              text={isEditing || tab === "additional" ? t`Shrani` : t`Nadaljuj`}
              onBackButtonClick={
                tab === "general" ? undefined : () => previousTab()
              }
              data-identifier={`${tab}-continue-button`}
            />
          </Box>

          {/* Space behind action button */}
          <Space h={"100px"} />
        </form>
      </Box>
    </Flex>
  );
};
