/*
 * useAccountInfo stores all information of currently logged in user
 * including pricing tier
 * */

import { create } from "zustand";

const useAccountInfo = create((set) => ({
  accountInfo: {},
  setAccountInfo: (accountInfo) => {
    set({ accountInfo });
  },
}));

export default useAccountInfo;
