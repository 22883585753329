import { Box, Flex } from "@mantine/core";
import { RiArrowRightSLine, RiCheckboxCircleFill } from "react-icons/ri";
import { isPageVisited } from "../Users";
import { Toggles } from "./Toggles";

export const CalendarSubPillar = ({
  user,
  permissions,
  togglePermission,
  setServicePermissions,
  setUserPermissions,
  setLocationPermissions,
  setResourcePermissions,
  isMobile,
  choices,

  calendarSubPillarChoice,
  setCalendarSubPillarChoice,
  visitedPermissionPages,
  setVisitedPermissionPages,
  disabled,
}) => {
  const selectChoice = (choice) => {
    setCalendarSubPillarChoice(choice);
  };

  const toggleServicePermission = (id) => {
    const service = permissions.services.find((s) => s.serviceId == id);

    service.view = !service.view;
    service.edit = !service.edit;

    setServicePermissions(service, id);
  };

  const toggleLocationPermission = (id) => {
    const location = permissions.locations.find((s) => s.locationId == id);

    location.view = !location.view;
    location.edit = !location.edit;

    setLocationPermissions(location, id);
  };

  const toggleUserPermission = (id) => {
    const user = permissions.users.find((s) => s.userId == id) || {
      userId: id,
      view: false,
    };

    user.view = !user.view;

    setUserPermissions(user, id);
  };

  const toggleResourcePermission = (id) => {
    const res = permissions.resources.find((s) => s.resourceId == id) || {
      resourceId: id,
      view: false,
    };

    res.view = !res.view;

    setResourcePermissions(res, id);
  };

  const getContent = () => {
    if (!calendarSubPillarChoice) return <></>;

    return (
      <Toggles
        disabled={disabled}
        isMobile={isMobile}
        permissions={permissions}
        togglePermission={(val) => {
          if (!isNaN(val)) {
            if (calendarSubPillarChoice.id === "storitve") {
              return toggleServicePermission(val);
            }

            if (calendarSubPillarChoice.id === "lokacija") {
              return toggleLocationPermission(val);
            }

            if (calendarSubPillarChoice.id === "users") {
              return toggleUserPermission(val);
            }

            if (calendarSubPillarChoice.id === "resources") {
              return toggleResourcePermission(val);
            }
          }

          togglePermission(val);
        }}
        toggles={calendarSubPillarChoice.toggles}
        serviceToggles={calendarSubPillarChoice.serviceToggles}
        locationToggles={calendarSubPillarChoice.locationToggles}
        userToggles={calendarSubPillarChoice.userToggles}
        resourceToggles={calendarSubPillarChoice.resourceToggles}
      />
    );
  };

  return (
    <>
      {(!isMobile || (isMobile && !calendarSubPillarChoice)) && (
        <Box
          miw={"200px"}
          w={isMobile ? "100%" : "33%"}
          style={{
            userSelect: "none",
            borderRight: isMobile ? "none" : "1px solid #eaebed",
          }}
        >
          {choices.map((choice, index) => {
            const isSelected = calendarSubPillarChoice?.id === choice.id;

            return (
              <Flex
                key={index}
                h={isMobile ? "60px" : "80px"}
                align={"center"}
                justify={"space-between"}
                p={isMobile ? 0 : "0 20px"}
                bg={isSelected ? "#F6F8F9" : "transparent"}
                style={{
                  borderBottom: "1px solid #eaebed",
                  cursor: "pointer",
                  flexShrink: 0,
                }}
                fw={"bold"}
                onClick={() => {
                  selectChoice(choice);
                  if (!visitedPermissionPages.includes(choice.id)) {
                    setVisitedPermissionPages((prev) => [...prev, choice.id]);
                  }
                }}
              >
                {choice.label}
                {user == null &&
                isPageVisited(choice.id, visitedPermissionPages) &&
                !isSelected ? (
                  <RiCheckboxCircleFill
                    style={{
                      color: "#74B587",
                      fontSize: "2.1rem",
                      flexShrink: 0,
                      border: "2px solid #transparent",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <RiArrowRightSLine
                    style={{
                      background: isSelected ? "#74B587" : "transparent",
                      color: isSelected ? "white" : "#DBDBDB",
                      border: isSelected
                        ? "2px solid #74B587"
                        : "2px solid #DBDBDB",
                      borderRadius: "50%",
                      fontSize: "1.6rem",
                      marginRight: "4px",
                    }}
                  />
                )}
              </Flex>
            );
          })}
        </Box>
      )}

      {getContent()}
    </>
  );
};
