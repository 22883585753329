import {
  GetBillingCycle,
  GetClientSettings,
  PutClientSettings,
} from "@/server-types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, put } from "../request-helpers";

/**
 * GET /analytics/revenue
 */
export const useBillingCycle = (query: GetBillingCycle["query"]) => {
  return useQuery({
    queryKey: ["analyticsRevenue", query],
    queryFn: () => get<GetBillingCycle>("/user/billing_cycle", query),
  });
};

/**
 * GET /settings
 */
export const useClientSettings = () => {
  return useQuery({
    queryKey: ["clientSettings"],
    queryFn: () => get<GetClientSettings>("/client/settings"),
  });
};

/**
 * PUT /settings
 */
export const usePutClientSettings = () => {
  return useMutation({
    mutationFn: (body: PutClientSettings["body"]) =>
      put<PutClientSettings>("/client/settings", body),
  });
};
