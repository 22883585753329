import { TextVariant } from "@/types/text-variants";
import { cn } from "@/utils";
import { useLingui } from "@lingui/react";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { capitalize } from "lodash";

const CalendarDate = ({ date, onClick, isDisabled }) => {
  const dayObject = dayjs(date);
  const { i18n } = useLingui();
  const locale = i18n.locale;
  const dayOfWeek = capitalize(dayObject.locale(locale).format("dddd"))
    .substring(0, 3)
    .toUpperCase();
  const dayNumber = dayObject.date();

  const isToday = dayObject.isSame(dayjs(), "day");

  const isMobile = useMediaQuery("(max-width: 768px)");

  let day_name = dayOfWeek;

  if (isMobile) {
    day_name = dayOfWeek.substring(0, 3);
  }

  return (
    <button
      type="button"
      onClick={() => !isDisabled && onClick(date)}
      className={cn(
        "group flex items-center justify-center text-base md:text-xl",
        {
          "gap-2 font-medium": isToday,
        },
      )}
    >
      <p
        className={cn("text-sm text-[#262626]", {
          "text-[#548F68]": isToday,
          "text-[#ccc]": isDisabled,
        })}
      >
        {day_name}
      </p>

      <p
        className={cn(
          "text-md flex h-9 w-9 shrink-0 items-center justify-center rounded-full font-semibold text-[#548f68] transition-colors md:text-xl",
          {
            "bg-[#548f68] text-white": isToday,
            "text-[#ccc]": isDisabled,
            "group-hover:bg-[#00000013]": !isToday,
          },
        )}
      >
        {dayNumber}
      </p>
    </button>
  );
};

export default CalendarDate;
