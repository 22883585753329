export interface RawData {
  [key: string]: string;
}

export interface TransformedData {
  name: string;
  priceBaseCents: number;
  baseDurationMins: number;
  workers: string[];
  color: string;
  tags: string[];
  description?: string;
  timeOffStart?: number;
  timeOffDuration?: number;
  blockBeforeMins: number | undefined;
  blockAfterMins: number | undefined;
  nameSL?: string;
  nameHR?: string;
  nameEN?: string;
  nameDE?: string;
  nameIT?: string;
  nameSR?: string;
  descriptionSL?: string;
  descriptionHR?: string;
  descriptionEN?: string;
  descriptionDE?: string;
  descriptionIT?: string;
  descriptionSR?: string;
}

export const transformData = (data: RawData[]): TransformedData[] => {
  return data.map((item) => {
    return {
      name: item["name"].trim(),
      priceBaseCents: parseFloat(item["price"].replace("€", "").trim()) * 100,
      baseDurationMins: parseInt(item["baseDurationMins"], 10),
      description: item["description"]?.trim() || undefined,
      timeOffStart: item["timeOffStart"]
        ? parseInt(item["timeOffStart"], 10)
        : undefined,
      timeOffDuration: item["timeOffDuration"]
        ? parseInt(item["timeOffDuration"], 10)
        : undefined,
      blockBeforeMins: item["blockBeforeMins"]
        ? parseInt(item["blockBeforeMins"], 10)
        : undefined,
      blockAfterMins: item["blockAfterMins"]
        ? parseInt(item["blockAfterMins"], 10)
        : undefined,
      workers: item["workers"]
        .split(",")
        .map((w) => w.trim())
        .filter((w) => w),
      color: item["color"]?.trim() || "",
      tags: item["tags"]
        .split(",")
        .map((t) => t.trim())
        .filter((t) => t),
      nameSL: item["nameSL"]?.trim(),
      nameHR: item["nameHR"]?.trim(),
      nameEN: item["nameEN"]?.trim(),
      nameDE: item["nameDE"]?.trim(),
      nameIT: item["nameIT"]?.trim(),
      nameSR: item["nameSR"]?.trim(),
      descriptionSL: item["descriptionSL"]?.trim(),
      descriptionHR: item["descriptionHR"]?.trim(),
      descriptionEN: item["descriptionEN"]?.trim(),
      descriptionDE: item["descriptionDE"]?.trim(),
      descriptionIT: item["descriptionIT"]?.trim(),
      descriptionSR: item["descriptionSR"]?.trim(),
    };
  });
};
