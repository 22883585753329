import { Transition } from "@mantine/core";
import styled from "styled-components";
import { useSidebarStore } from "@/stores/useSidebarStore";
import { useMediaQuery } from "@mantine/hooks";

type BottomStickyModalProps = {
  isOpen: boolean;

  children: React.ReactNode;
};

export const BottomStickyModal = ({
  isOpen,

  children,
}: BottomStickyModalProps) => {
  const { isOpen: isSidebarOpen } = useSidebarStore();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Transition
      mounted={isOpen}
      transition={"slide-up"}
      duration={300}
      timingFunction="cubic-bezier(.22,1.41,.34,1)"
    >
      {(styles) => (
        <Wrapper
          style={{
            ...styles,
            left: isMobile ? 0 : isSidebarOpen ? "270px" : "60px",
            width: isMobile
              ? "100%"
              : isSidebarOpen
                ? "calc(100% - 270px)"
                : "calc(100% - 60px)",
          }}
        >
          {children}
        </Wrapper>
      )}
    </Transition>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  z-index: 99999999;

  padding: 12.5px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 7px;

  background: white;

  .mantine-Switch-body {
    justify-content: space-between;
  }

  @media (min-width: 768px) {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    position: absolute;
  }
`;
