import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { api } from "@/lib/api-client";
import { cn } from "@/utils";
import { getPaymentTypeLabel, PaymentType } from "@/utils/pos-printer";
import { t, Trans } from "@lingui/macro";
import {
  DateRangePicker,
  DateValue,
  Divider,
  Spinner,
} from "@nextui-org/react";
import { getLocalTimeZone, parseDate, today } from "@internationalized/date";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const CashRegisterStatus = () => {
  const { businessPremiseId } = useParams();

  const { data: posAuthData } = api.cookie.useGetPosToken();

  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const startDateParam = searchParams.get("start");
  const endDateParam = searchParams.get("end");

  const startDate = startDateParam
    ? parseDate(startDateParam)
    : today(getLocalTimeZone());
  const endDate = endDateParam
    ? parseDate(endDateParam)
    : today(getLocalTimeZone());

  const {
    data: status,
    isFetching: isFetchingStatus,
    isSuccess,
    processedErrorMessage,
  } = api.taxRegister.useGetCashRegisterStatus({
    businessPremiseId: businessPremiseId!,
    query: {
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    },
  });

  const handleDateChange = (range: { start: DateValue; end: DateValue }) => {
    const newStartDate = range.start.toString();
    const newEndDate = range.end.toString();

    // Update the URL with new start and end dates
    navigate(
      `/dashboard/tax-register/cash-register/${businessPremiseId}/status?start=${newStartDate}&end=${newEndDate}`,
    );
  };

  if (isFetchingStatus) {
    return <Spinner />;
  }

  if (!isSuccess) {
    return <ErrorCard message={processedErrorMessage} />;
  }

  const date =
    status.dateFrom === status.dateTo
      ? status.dateFrom
      : `${status.dateFrom} - ${status.dateTo}`;

  const hasData =
    Object.values(status.documentsByUserAndPaymentMethodFormatted).length > 0;

  if (!posAuthData?.userId) {
    return <div></div>;
  }

  return (
    <div>
      <DateRangePicker
        label={t`Razpon časa`}
        maxValue={today(getLocalTimeZone())}
        defaultValue={{
          start: today(getLocalTimeZone()).subtract({ days: 1 }),
          end: today(getLocalTimeZone()),
        }}
        value={{
          start: startDate,
          end: endDate,
        }}
        onChange={handleDateChange}
        className="mb-4"
      />

      <p className="text-lg font-semibold">
        <Trans>Stanje blagajne</Trans>
      </p>

      <p>
        <Trans>Datum</Trans>: {date}
      </p>
      {/* <p>
        <Trans>Blagajna</Trans>: Mojca
      </p> */}

      <Divider className="my-4" />

      {!hasData && (
        <p className="font-medium">
          <Trans>Ni podatkov</Trans>
        </p>
      )}

      {hasData && (
        <div className="flex flex-col gap-2">
          {Object.values(status.documentsByUserAndPaymentMethodFormatted).map(
            (doc) => (
              <div
                className="mb-4 rounded-lg bg-divider px-4 pb-4 pt-3"
                key={`${doc.name}-${doc.lastName}`}
              >
                <p className="mb-2 font-semibold">
                  {doc.name} {doc.lastName}
                </p>
                <div>
                  {Object.entries(doc.payments)
                    .filter(([paymentMethod, _]) => paymentMethod !== "total")
                    .map(([paymentMethod, value]) => (
                      <PaymentRow
                        key={`${doc.name}-${doc.lastName}-${paymentMethod}`}
                        label={getPaymentTypeLabel(
                          paymentMethod as PaymentType,
                        )}
                        value={value.formatted}
                      />
                    ))}

                  <PaymentRow
                    label={t`Skupaj`}
                    value={doc.payments["total"].formatted}
                    isTotal
                  />
                </div>
              </div>
            ),
          )}

          <Divider className="mb-3" />

          <div className="px-2">
            {Object.entries(status.totalsByPaymentMethodFormatted)
              .filter(([paymentMethod, _]) => paymentMethod !== "total")
              .map(([paymentMethod, value]) => (
                <PaymentRow
                  key={`${paymentMethod}`}
                  label={getPaymentTypeLabel(paymentMethod as PaymentType)}
                  value={value.formatted}
                />
              ))}

            <PaymentRow
              label={t`Skupaj`}
              value={status.totalsByPaymentMethodFormatted["total"]?.formatted}
              isTotal
            />
          </div>
        </div>
      )}
    </div>
  );
};

const PaymentRow = ({
  label,
  value,
  isTotal,
}: {
  label: string;
  value: string;
  isTotal?: boolean;
}) => {
  return (
    <div
      className={cn("flex justify-between gap-2 text-sm", {
        "mt-2": isTotal,
      })}
    >
      <p
        className={cn({
          "font-semibold": isTotal,
        })}
      >
        {label}
      </p>
      <p>{value}</p>
    </div>
  );
};
