import { EasyNumberInput } from "@/Components/EasyNumberInput";
import { LimeSelect } from "@/Components/LimeSelect";
import { daysOfWeek } from "@/constants/daysOfWeek";
import useFeature from "@/hooks/useFeature";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Collapse,
  Divider,
  Flex,
  SegmentedControl,
  Text,
  UnstyledButton,
} from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { BiChevronRight } from "react-icons/bi";
import {
  type ClientSchedule,
  type FormValues,
  managingPriceObjectAtom,
} from "../ManageService";
import { getBracketCounts } from "../Utils";
import { formatCurrency } from "../../../../../../shared/utils/utils";

export const PriceTab = ({
  form,
  currency,
  locale,
}: {
  form: UseFormReturnType<FormValues>;
  currency: string;
  locale: string;
}) => {
  const { isFeatureEnabled } = useFeature();

  const isAdvancedPricingEnabled = isFeatureEnabled("SERVICE_ADVANCED_PRICING");

  const [_, setManagingPricingObject] = useAtom(managingPriceObjectAtom);

  const onBracketClick = (bracketId: string) => {
    setManagingPricingObject({
      pricingStrategy: "bracketed",
      objectIndex: bracketId,
    });
  };

  const onScheduleClick = (scheduleId: string) => {
    setManagingPricingObject({
      pricingStrategy: "scheduled",
      objectIndex: scheduleId,
    });
  };

  const onTierClick = (tierId: string) => {
    setManagingPricingObject({
      pricingStrategy: "tiered",
      objectIndex: tierId,
    });
  };

  return (
    <Flex direction={"column"}>
      {isAdvancedPricingEnabled ? (
        <>
          <SegmentedControl
            {...form.getInputProps("pricingStrategy")}
            data={[
              { label: t`Osnovna`, value: "flat" },
              { label: t`Razred`, value: "bracketed" },
              { label: t`Tarifa`, value: "scheduled" },
              // { label: t`Kategorija`, value: "tiered" },
            ]}
            h={"40px"}
            mx={"md"}
          />
          <Divider my={"md"} />
        </>
      ) : (
        ""
      )}

      <Collapse in={isFeatureEnabled("RESOURCES")}>
        <Explanation
          title={t`Izberite enoto obračunavanja`}
          description={t`Izberite ali se cena spreminja glede na izbrano število oseb ali glede na izbrano število sredstev.`}
        />
        <LimeSelect
          {...form.getInputProps("pricingMultiplier")}
          mx={"md"}
          data={[
            { label: t`Na osebo`, value: "perPerson" },
            { label: t`Na sredstvo`, value: "perResource" },
          ]}
          allowDeselect={false}
        />

        <Divider my={"md"} />
      </Collapse>

      <Collapse
        in={
          form.values.pricingStrategy === "flat" ||
          form.values.pricingStrategy === "bracketed"
        }
      >
        <FlatPriceTab
          form={form}
          isAdvancedPricingEnabled={isAdvancedPricingEnabled}
        />
      </Collapse>

      {isAdvancedPricingEnabled ? (
        <>
          <Collapse in={form.values.pricingStrategy === "bracketed"}>
            <BracketedPriceTab
              form={form}
              onBracketClick={onBracketClick}
              currency={currency}
              locale={locale}
            />
          </Collapse>

          <Collapse in={form.values.pricingStrategy === "scheduled"}>
            <ScheduledPriceTab form={form} onScheduleClick={onScheduleClick} />
          </Collapse>

          <Collapse in={form.values.pricingStrategy === "tiered"}>
            <TieredPriceTab form={form} onCategoryClick={onTierClick} />
          </Collapse>

          {form.errors.overlappingSchedules && (
            <Text
              mt={"xs"}
              px={"md"}
              c={Color.Error}
              variant={TextVariant.Body}
            >
              {form.errors.overlappingSchedules}
            </Text>
          )}
        </>
      ) : (
        ""
      )}
    </Flex>
  );
};

const FlatPriceTab = ({
  form,
  isAdvancedPricingEnabled,
}: {
  form: UseFormReturnType<FormValues>;
  isAdvancedPricingEnabled: boolean;
}) => {
  return (
    <>
      <Explanation
        title={t`Osnovna cena`}
        description={
          isAdvancedPricingEnabled
            ? t`Cena se sešteva glede na število izbranih oseb ali trajanje storitve.`
            : ""
        }
      />
      <EasyNumberInput
        {...form.getInputProps("priceBase")}
        px={"md"}
        min={0}
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        data-identifier={"base-price"}
      />
    </>
  );
};

const BracketedPriceTab = ({
  form,
  onBracketClick,
  currency,
  locale,
}: {
  form: UseFormReturnType<FormValues>;
  onBracketClick: (bracketId: string) => void;
  currency: string;
  locale: string;
}) => {
  return (
    <>
      {/* <EasyNumberInput
        {...form.getInputProps("priceBase")}
        label={t`Osnovna cena`}
        px={"md"}
        mt={"md"}
      /> */}

      <Divider mt={"md"} />

      <Button
        variant="white"
        c={Color.Brand}
        justify={"space-between"}
        h={"50px"}
        px={"15px"}
        style={{
          borderBottom: `1px solid ${Color.Divider}`,
        }}
        fullWidth
        styles={{
          label: {
            justifyContent: "space-between",
            width: "100%",
          },
        }}
        onClick={() => onBracketClick(form.values.brackets.length.toString())}
      >
        <Text variant={TextVariant.BodyEmphasized}>
          <Trans>Dodaj razred</Trans>
        </Text>
        <BiChevronRight size={"1.5rem"} />
      </Button>

      {form.values.brackets?.map((_, index) => (
        <Bracket
          key={index}
          index={index}
          brackets={form.values.brackets}
          onClick={() => onBracketClick(index.toString())}
          currency={currency}
          locale={locale}
        />
      ))}
    </>
  );
};

const ScheduledPriceTab = ({
  form,
  onScheduleClick,
}: {
  form: UseFormReturnType<FormValues>;
  onScheduleClick: (scheduleId: string) => void;
}) => {
  return (
    <>
      <Explanation
        title={t`Tarifna cena`}
        description={t`Cena se sešteva glede na število izbranih oseb ali trajanje storitve. Na ceno vpliva tudi ura in dan v tednu.`}
      />
      <EasyNumberInput
        {...form.getInputProps("priceBase")}
        label={t`Osnovna cena`}
        px={"md"}
      />

      <Divider mt={"md"} />

      <Button
        variant="white"
        c={Color.Brand}
        justify={"space-between"}
        h={"50px"}
        px={"15px"}
        style={{
          borderBottom: `1px solid ${Color.Divider}`,
        }}
        fullWidth
        styles={{
          label: {
            justifyContent: "space-between",
            width: "100%",
          },
        }}
        onClick={() => onScheduleClick(form.values.schedules.length.toString())}
      >
        <Text variant={TextVariant.BodyEmphasized}>
          <Trans>Dodaj tarifo</Trans>
        </Text>
        <BiChevronRight size={"1.5rem"} />
      </Button>

      {form.values.schedules.map((schedule, index) => {
        return (
          <Schedule
            key={index}
            schedule={schedule}
            onClick={() => onScheduleClick(index.toString())}
          />
        );
      })}
    </>
  );
};

const TieredPriceTab = ({
  form,
  onCategoryClick,
}: {
  form: UseFormReturnType<FormValues>;
  onCategoryClick: (categoryId: string) => void;
}) => {
  return (
    <>
      <Explanation
        title={t`Kategorična cena`}
        description={t`Cena je odvisna od tega, v kateri kategoriji se nahaja izbrana oseba ali sredstvo.`}
      />

      <Divider mt={"md"} />

      <Button
        variant="white"
        c={Color.Brand}
        justify={"space-between"}
        h={"50px"}
        px={"15px"}
        style={{
          borderBottom: `1px solid ${Color.Divider}`,
        }}
        fullWidth
        styles={{
          label: {
            justifyContent: "space-between",
            width: "100%",
          },
        }}
        onClick={() => onCategoryClick(form.values.tiers.length.toString())}
      >
        <Text variant={TextVariant.BodyEmphasized}>
          <Trans>Dodaj kategorijo</Trans>
        </Text>
        <BiChevronRight size={"1.5rem"} />
      </Button>

      {form.values.tiers?.map((tier, index) => (
        <Tier
          key={tier.label}
          tier={form.values.tiers[index]}
          onClick={() => onCategoryClick(index.toString())}
        />
      ))}
    </>
  );
};

const Schedule = ({
  schedule,
  onClick,
}: {
  schedule: ClientSchedule;
  onClick: () => void;
}) => {
  const timeFrom = dayjs(schedule.timeFrom).format("HH:mm");
  const timeTo = dayjs(schedule.timeTo).format("HH:mm");

  return (
    <UnstyledButton w={"100%"} onClick={onClick}>
      <Flex
        direction={"row"}
        px={"md"}
        py={"5px"}
        h={"54px"}
        align={"center"}
        justify={"space-between"}
        style={{
          borderBottom: `1px solid ${Color.Divider}`,
        }}
      >
        <Text variant={TextVariant.BodyEmphasized}>
          {timeFrom} - {timeTo}
        </Text>

        <Flex
          h={"100%"}
          flex={1}
          justify={"flex-end"}
          gap={"1px"}
          align={"center"}
          wrap={"wrap"}
          maw={"100px"}
        >
          {daysOfWeek().map((day, i) => {
            const isSelected = schedule.daysOfWeek.includes(day.value);

            return (
              <Flex
                key={i}
                align={"center"}
                justify={"center"}
                w={"20px"}
                h={"20px"}
                style={{
                  borderRadius: "3px",
                  backgroundColor: Color.TableHeader,

                  ...(isSelected && {
                    backgroundColor: "#E6F5E8",
                    border: `1px solid ${Color.Brand}`,
                  }),
                }}
              >
                <Text c={isSelected ? Color.Brand : Color.Disabled} size="6px">
                  {day.label.charAt(0).toUpperCase()}
                </Text>
              </Flex>
            );
          })}
        </Flex>

        <Text
          c={Color.SecondaryText}
          ta={"center"}
          variant={TextVariant.BodyEmphasized}
        >
          <Trans>Cena</Trans>
          <br />
          {schedule.price.toString().startsWith("-") ? "" : "+"}
          {schedule.price}
        </Text>

        <BiChevronRight size={"1.5rem"} />
      </Flex>
    </UnstyledButton>
  );
};

const Tier = ({
  tier,
  onClick,
}: {
  tier: FormValues["tiers"][number];
  onClick: () => void;
}) => {
  return (
    <UnstyledButton w={"100%"} onClick={onClick}>
      <Flex
        direction={"row"}
        px={"md"}
        py={"5px"}
        h={"54px"}
        align={"center"}
        justify={"space-between"}
        style={{
          borderBottom: `1px solid ${Color.Divider}`,
        }}
      >
        <Text variant={TextVariant.BodyEmphasized}>{tier.label}</Text>

        <Flex
          h={"100%"}
          flex={1}
          justify={"flex-end"}
          gap={"15px"}
          align={"center"}
        >
          <Flex
            bg={Color.TableHeader}
            h={"100%"}
            align={"center"}
            justify={"center"}
            maw={"100px"}
            w={"100%"}
            style={{
              borderRadius: "8px",
            }}
          >
            <Text variant={TextVariant.BodyEmphasized} c={Color.Disabled}>
              {tier.price} €
            </Text>
          </Flex>

          <BiChevronRight size={"1.5rem"} />
        </Flex>
      </Flex>
    </UnstyledButton>
  );
};

const Bracket = ({
  index,
  brackets,
  currency,
  locale,
  onClick,
}: {
  index: number;
  brackets: FormValues["brackets"];
  currency: string;
  locale: string;
  onClick: () => void;
}) => {
  const bracketCounts = getBracketCounts(brackets);
  const { fromCount, toCount } = bracketCounts[index];

  return (
    <UnstyledButton w={"100%"} onClick={onClick}>
      <Flex
        direction={"row"}
        px={"md"}
        py={"5px"}
        h={"54px"}
        align={"center"}
        justify={"space-between"}
        style={{
          borderBottom: `1px solid ${Color.Divider}`,
        }}
      >
        <Text variant={TextVariant.BodyEmphasized}>
          <Trans>Razred</Trans> {index + 1}
        </Text>

        <Flex h={"100%"} flex={1} justify={"center"} gap={"2px"}>
          <Flex
            bg={Color.TableHeader}
            h={"100%"}
            align={"center"}
            justify={"center"}
            maw={"100px"}
            w={"100%"}
            style={{
              borderRadius: "8px",
            }}
          >
            <Text variant={TextVariant.BodyEmphasized} c={Color.Disabled}>
              {fromCount} - {toCount}
            </Text>
          </Flex>
          <Flex
            bg={Color.TableHeader}
            h={"100%"}
            align={"center"}
            justify={"center"}
            maw={"100px"}
            w={"100%"}
            style={{
              borderRadius: "8px",
            }}
          >
            <Text variant={TextVariant.BodyEmphasized} c={Color.Disabled}>
              {formatCurrency({
                amount: brackets[index].price,
                currency,
                locale,
              })}
            </Text>
          </Flex>
        </Flex>

        <BiChevronRight size={"1.5rem"} />
      </Flex>
    </UnstyledButton>
  );
};

export const Explanation = ({
  title,
  description,
  titleVariant = TextVariant.BodyEmphasized,
}: {
  title: string;
  description: string;
  titleVariant?: TextVariant;
}) => {
  return (
    <>
      <Text variant={titleVariant} px={"md"}>
        {title}
      </Text>
      <Text
        my={6}
        variant={TextVariant.Caption}
        c={Color.SecondaryText}
        px={"md"}
      >
        {description}
      </Text>
    </>
  );
};
