import {
  DeleteCategory,
  GetCategories,
  GetCategory,
  PostCategory,
  PostCategorySortOrder,
} from "@/server-types";
import { del, get, post } from "../request-helpers";
import { useCustomQuery } from "../custom-query";
import { useCustomMutation } from "../custom-mutation";

/**
 * GET /category
 */
export const useGetCategories = (
  query: GetCategories["query"] & {
    enabled?: boolean;
  },
) => {
  return useCustomQuery({
    queryKey: ["categories", query],
    queryFn: () => get<GetCategories>("/category", query),
    enabled: query.enabled,
  });
};

/**
 * POST /category
 */
export const usePostCategory = () => {
  return useCustomMutation({
    mutationKey: ["categories"],
    mutationFn: (body: PostCategory["body"]) =>
      post<PostCategory>("/category", body),
  });
};

/**
 * GET /category/:id
 */
export const useGetCategory = (id: number) => {
  return useCustomQuery({
    queryKey: ["getCategory", id],
    queryFn: () => get<GetCategory>(`/category/${id}`),
    enabled: id !== -1,
    gcTime: 0,
  });
};

/**
 * DELETE /category/:id
 */
export const useDeleteCategory = () => {
  return useCustomMutation({
    mutationKey: ["categories"],
    mutationFn: (id: number) => del<DeleteCategory>(`/category/${id}`),
  });
};

/**
 * POST /category/sort-order
 * @param categoryIds
 * @returns
 */
export const usePostCategorySortOrder = (categoryIds: number[]) => {
  return useCustomMutation({
    mutationKey: ["postCategoriesOrder", categoryIds],
    mutationFn: () =>
      post<PostCategorySortOrder>(`/category/sort-order`, { categoryIds }),
  });
};
