import { useMutation, useQuery } from "@tanstack/react-query";

import {
  DeleteNotificationSetting,
  GetNotificationSetting,
  GetNotificationSettings,
  PostNotificationSettings,
  PutNotificationSetting,
  PutToggleNotificationSetting,
} from "@/server-types";
import { del, get, post, put } from "../request-helpers";

/**
 * GET /notification/setting
 */
export const useGetNotificationSettings = () => {
  return useQuery({
    queryKey: ["clientList"],
    queryFn: () => get<GetNotificationSettings>("/notification/setting"),
    gcTime: 0,
  });
};

/**
 * POST /notification/setting
 */
export const useCreateNotificationSetting = () => {
  return useMutation({
    mutationFn: (body: PostNotificationSettings["body"]) =>
      post<PostNotificationSettings>(`/notification/setting`, body),
  });
};

/**
 * DELETE /notification/setting/[notificationSettingId]
 */
export const useDeleteNotificationSetting = () => {
  return useMutation({
    mutationFn: (
      notificationSettingId: DeleteNotificationSetting["params"]["notificationSettingId"],
    ) =>
      del<DeleteNotificationSetting>(
        `/notification/setting/${notificationSettingId}`,
      ),
  });
};

/**
 * GET /notification/setting/[notificationSettingId]
 */
export const useGetNotificationSetting = (
  notificationSettingId:
    | GetNotificationSetting["params"]["notificationSettingId"]
    | undefined,
) => {
  return useQuery({
    queryKey: ["clientList", notificationSettingId],
    queryFn: () =>
      get<GetNotificationSetting>(
        `/notification/setting/${notificationSettingId || 0}`,
      ),
    gcTime: 0,
    enabled: !!notificationSettingId,
  });
};

/**
 * PUT /notification/setting/[notificationSettingId]
 */
export const useUpdateNotificationSetting = () => {
  return useMutation({
    mutationFn: ({
      notificationSettingId,
      body,
    }: {
      notificationSettingId: PutNotificationSetting["params"]["notificationSettingId"];
      body: PutNotificationSetting["body"];
    }) =>
      put<DeleteNotificationSetting>(
        `/notification/setting/${notificationSettingId}`,
        body,
      ),
  });
};

/**
 * PUT /notification/setting/[notificationSettingId]/toggle
 */
export const useToggleNotificationSetting = () => {
  return useMutation({
    mutationFn: (
      notificationSettingId: PutToggleNotificationSetting["params"]["notificationSettingId"],
    ) =>
      put<PutToggleNotificationSetting>(
        `/notification/setting/${notificationSettingId}/toggle`,
      ),
  });
};
