import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Avatar, Flex, Popover, Text, UnstyledButton } from "@mantine/core";
import React from "react";

const LimeAvatar = ({ user, size = 34, onClick }) => {
  const [popoverOpened, setPopoverOpened] = React.useState(false);

  return (
    <Popover opened={popoverOpened}>
      <Popover.Target>
        <UnstyledButton
          onMouseEnter={() => setPopoverOpened(true)}
          onMouseLeave={() => setPopoverOpened(false)}
          className={"userName"}
          onClick={onClick}
          style={{ cursor: "pointer", flexShrink: 0 }}
        >
          {user?.image != null ? (
            <Avatar
              src={user.image}
              radius={50}
              style={{ margin: "auto" }}
              size={size}
            ></Avatar>
          ) : (
            <UserAvatar size={size} name={user?.name} />
          )}
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown
        style={{
          pointerEvents: "none",
        }}
      >
        <Flex direction={"column"} align={"center"} maw={"300px"}>
          <Text variant={TextVariant.BodyEmphasized}>{user?.name}</Text>
          {user.description ? (
            <Text variant={TextVariant.Caption} c={Color.SecondaryText}>
              {user.description}
            </Text>
          ) : null}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default LimeAvatar;

export const UserAvatar = ({ name, image, size }) => {
  const initials = name
    ?.split(" ")
    .map((word) => word[0])
    .join("")
    .slice(0, 2);
  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          marginBottom: "10px",
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: "50%",
          backgroundColor: "#EBF8EF",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#74B587",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        {initials}
      </div>
    </div>
  );
};
