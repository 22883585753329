import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import {
  DeleteResourceGroup,
  GetResourceGroup,
  GetResourceGroupSchedule,
  GetResourceGroupServices,
  GetResourceGroups,
  PostResourceGroup,
  PostResourceGroupSchedule,
  PostResourceGroupServices,
  PutResourceGroup,
} from "../../../../../server/src/types";
import { del, get, post, put } from "../request-helpers";

export const useGetResourceGroups = (query: GetResourceGroups["query"]) => {
  return useQuery({
    queryKey: ["resourceGroups", query],
    queryFn: () => get<GetResourceGroups>("/resource-group", query),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });
};

export const usePostResourceGroup = () => {
  return useMutation({
    mutationKey: ["resourceGroups"],
    mutationFn: (resourceGroup: PostResourceGroup["body"]) =>
      post<PostResourceGroup>("/resource-group", resourceGroup),
  });
};

export const useGetResourceGroup = (resourceGroupId: number | undefined) => {
  return useQuery({
    queryKey: ["resourceGroups", resourceGroupId],
    queryFn: () =>
      get<GetResourceGroup>(`/resource-group/${resourceGroupId ?? -1}`),
    placeholderData: keepPreviousData,
    enabled: resourceGroupId != null && resourceGroupId !== -1,
    gcTime: 0,
  });
};

export const usePutResourceGroup = () => {
  return useMutation({
    mutationKey: ["resourceGroups"],
    mutationFn: ({
      resourceGroupId,
      resourceGroup,
    }: {
      resourceGroupId: number;
      resourceGroup: PutResourceGroup["body"];
    }) =>
      put<PutResourceGroup>(
        `/resource-group/${resourceGroupId}`,
        resourceGroup,
      ),
  });
};

export const useDeleteResourceGroup = () => {
  return useMutation({
    mutationKey: ["resourceGroups"],
    mutationFn: (resourceGroupId: number) =>
      del<DeleteResourceGroup>(`/resource-group/${resourceGroupId}`),
  });
};

export const useGetResourceGroupServices = (
  resourceGroupId: number | undefined,
) => {
  return useQuery({
    queryKey: ["resourceGroups", resourceGroupId, "services"],
    queryFn: () =>
      get<GetResourceGroupServices>(
        `/resource-group/${resourceGroupId ?? -1}/services`,
      ),
    placeholderData: keepPreviousData,
    enabled: resourceGroupId != null && resourceGroupId !== -1,
  });
};

export const usePostResourceGroupServices = () => {
  return useMutation({
    mutationKey: ["resourceGroups"],
    mutationFn: ({
      resourceGroupId,
      serviceIds,
    }: {
      resourceGroupId: number;
      serviceIds: PostResourceGroupServices["body"];
    }) =>
      post<PostResourceGroupServices>(
        `/resource-group/${resourceGroupId}/services`,
        serviceIds,
      ),
  });
};

export const useGetResourceGroupSchedule = (
  resourceGroupId: number | undefined,
) => {
  return useQuery({
    queryKey: ["resourceGroups", resourceGroupId, "schedule"],
    queryFn: () =>
      get<GetResourceGroupSchedule>(
        `/resource-group/${resourceGroupId ?? -1}/schedule`,
      ),
    placeholderData: keepPreviousData,
    enabled: resourceGroupId != null && resourceGroupId !== -1,
  });
};

export const usePostResourceGroupSchedule = () => {
  return useMutation({
    mutationKey: ["resourceGroups"],
    mutationFn: ({
      resourceGroupId,
      body,
    }: {
      resourceGroupId: number;
      body: PostResourceGroupSchedule["body"];
    }) =>
      post<PostResourceGroupSchedule>(
        `/resource-group/${resourceGroupId}/schedule`,
        body,
      ),
  });
};
