import { api } from "@/lib/api-client";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function RouteChangeListener() {
  const location = useLocation();
  const prevLocationRef = useRef(location);

  const { mutateAsync: deleteCookie } = api.cookie.useDeleteHttpCookie();

  useEffect(() => {
    const prevLocation = prevLocationRef.current;
    const currentLocation = location;
    prevLocationRef.current = currentLocation;

    const wasOnTaxReg = prevLocation.pathname.includes("tax-register");

    const wasOnCashRegister = prevLocation.pathname.includes("cash-register");
    const isCashRegisterSubPage =
      wasOnCashRegister && currentLocation.pathname.includes("cash-register");

    const wasOnInvoicesPage = prevLocation.pathname.includes("invoice");
    const isInvoicesSubPage =
      wasOnInvoicesPage && currentLocation.pathname.includes("invoice");

    const wasOnTaxRegSetup = prevLocation.pathname.includes("setup");
    const isTaxRegSetupSubPage =
      wasOnTaxRegSetup && currentLocation.pathname.includes("setup");

    const isLeavingTaxReg =
      wasOnTaxReg &&
      !isCashRegisterSubPage &&
      !isInvoicesSubPage &&
      !isTaxRegSetupSubPage;

    if (
      prevLocation &&
      isLeavingTaxReg &&
      prevLocation.pathname !== currentLocation.pathname
    ) {
      deleteCookie({ cookieName: "pos-token" });
    }
  }, [location]);

  return null; // This component doesn't render anything
}

export default RouteChangeListener;
