import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  GetUsers,
  GetUserHasPin,
  PostUserPin,
  PostVerifyUserPin,
} from "../../../../../server/src/types";

import { get, post } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";

/**
 * GET /user
 */
export const useUserList = (query?: GetUsers["query"]) => {
  return useQuery({
    queryKey: ["clientList", query],
    queryFn: () => get<GetUsers>("/user", query),
    placeholderData: keepPreviousData,
  });
};

export const useGetUserHasPin = (query: GetUserHasPin["query"]) => {
  return useCustomQuery({
    queryKey: ["user-has-pin", query],
    queryFn: () => get<GetUserHasPin>(`/user/has-pin`, query),
    enabled: !!query.userId,
  });
};

export const usePostUserPIN = () => {
  return useCustomMutation({
    mutationKey: ["post-user-pin"],
    mutationFn: (body: PostUserPin["body"]) =>
      post<PostUserPin>("/user/pin", body),
  });
};

export const useVerifyUserPIN = () => {
  return useCustomMutation({
    mutationKey: ["verify-user-pin"],
    mutationFn: (body: PostVerifyUserPin["body"]) =>
      post<PostVerifyUserPin>("/user/pin/verify", body),
  });
};
