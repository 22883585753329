import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  QueryKey,
  QueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { notifications } from "@mantine/notifications";

type CustomQueryOptions<
  TQueryFnData,
  TError,
  TData,
  TQueryKey extends QueryKey,
> = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
  displayNotification?: boolean; // Add the displayNotification option
};

type CustomQueryResult<TData, TError> = UseQueryResult<TData, TError> & {
  processedErrorMessage: string | null;
  processedErrorCode: string | null;
};

export function useCustomQuery<
  TQueryFnData = unknown,
  TError = AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: CustomQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  queryClient?: QueryClient,
): CustomQueryResult<TData, TError> {
  const { displayNotification = false, ...queryOptions } = options;

  const queryResult = useQuery<TQueryFnData, TError, TData, TQueryKey>(
    {
      ...queryOptions,
    },
    queryClient,
  );

  // Process the error message
  const processedErrorMessage =
    queryResult.error instanceof AxiosError
      ? queryResult.error?.response?.data?.error
      : null;

  const processedErrorCode =
    queryResult.error instanceof AxiosError
      ? queryResult.error?.response?.data?.code
      : null;

  // Show the notification only if displayNotification is true and there's an error
  if (displayNotification && processedErrorMessage) {
    notifications.show({
      title: "Failed to fetch data",
      message: processedErrorMessage || "An unexpected error occurred",
      color: "red",
    });
  }

  return {
    ...queryResult,
    processedErrorMessage,
    processedErrorCode,
  };
}
