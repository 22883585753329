import dayjs from "dayjs";
import { useScheduleStore } from "../../Stores/useScheduleStore";
import BreakType from "./BreakType";

const BreaksTab = ({ data, updateData, date, maxLunchTimeMinutes }) => {
  const { selectedScheduleData } = useScheduleStore((state) => state);

  if (selectedScheduleData == null) {
    return null;
  }

  const { break_types } = selectedScheduleData;

  const shift = data?.shifts?.at(0);
  if (shift == null) {
    return null;
  }

  return (
    <div>
      {break_types.map((type) => {
        const breaks = shift?.breaks?.filter(
          (break_) => break_.type === type.type,
        );

        return (
          <BreakType
            data={data}
            key={type.scheduleShiftBreakTypeId}
            type={type}
            breaks={breaks}
            date={date}
            maxLunchTimeMinutes={maxLunchTimeMinutes}
            addBreak={() => {
              // generate random scheduleShiftBreakId that is not in the list
              const ids = shift.breaks.map((break_) => break_.id);
              let id = Math.floor(Math.random() * 1000000);
              while (ids.includes(id)) {
                id = Math.floor(Math.random() * 1000000);
              }

              const shiftStartObject = dayjs(shift.start, "YYYY-MM-DD HH:mm");
              const shiftEndObject = dayjs(shift.end, "YYYY-MM-DD HH:mm");

              const isVacationOrSickLeave =
                type.type === "VACATION" || type.type === "SICK_LEAVE";

              const from = (
                isVacationOrSickLeave
                  ? dayjs(`${date} ${shiftStartObject.format("HH:mm")}`)
                  : dayjs(date)
              ).format("YYYY-MM-DD HH:mm:ss");

              const to = (
                isVacationOrSickLeave
                  ? dayjs(`${date} ${shiftEndObject.format("HH:mm")}`)
                  : dayjs(date)
              ).format("YYYY-MM-DD HH:mm:ss");

              // add break with type and leave the rest as it is
              const updatedBreaks = [
                ...shift.breaks,
                {
                  id,
                  from,
                  to,
                  userId: shift.userId,
                  locationId: shift.locationId,
                  type: type.type,
                },
              ];

              updateData(shift.date, "breaks", updatedBreaks);
            }}
            deleteBreak={(breakId) => {
              // delete break with id and leave the rest as it is
              console.log("delete break with id", breakId);
              const updatedBreaks = shift.breaks.filter(
                (break_) => break_.id !== breakId,
              );

              updateData(shift.date, "breaks", updatedBreaks);
            }}
            updateBreak={(breakId, key, value) => {
              // update break with id and leave the rest as it is
              const updatedBreaks = shift.breaks.map((break_) => {
                if (break_.id === breakId) {
                  return {
                    ...break_,
                    [key]: value,
                  };
                } else {
                  return break_;
                }
              });

              console.log("updated ", updatedBreaks);

              updateData(shift.date, "breaks", updatedBreaks);
            }}
          />
        );
      })}
    </div>
  );
};

export default BreaksTab;
