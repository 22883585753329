import { Trans } from "@lingui/macro";
import { Button, Divider, Flex, List, Popover, Text } from "@mantine/core";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { RiDeleteBin5Fill, RiEditBoxLine } from "react-icons/ri";

const RolesList = ({ list, handleClick, handleDelete }) => {
  return (
    <Flex direction={"column"}>
      <div
        style={{
          padding: "10px 20px",
          borderBottom: "1px solid rgb(234, 235, 237)",
          background: "rgb(249, 249, 249)",
        }}
      >
        <Text fw={"bold"}>
          <Trans>Vloga</Trans>
        </Text>
      </div>
      <List
        styles={{
          itemWrapper: {
            width: "100%",
          },
          itemLabel: {
            width: "100%",
          },
        }}
      >
        {list.map((item, index) => {
          return (
            <List.Item key={index}>
              <Flex
                justify={"space-between"}
                align={"center"}
                p={"10px 20px"}
                style={{
                  borderBottom: "1px solid rgb(234, 235, 237)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(item);
                }}
              >
                <Text>{item.label}</Text>
                <div
                  role="presentation"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{ height: "24px" }}
                >
                  <Popover>
                    <Popover.Target>
                      <Button variant="subtle" p={"2px 10px"} h={"fit-content"}>
                        <BiDotsHorizontalRounded
                          size={18}
                        ></BiDotsHorizontalRounded>
                      </Button>
                    </Popover.Target>

                    <Popover.Dropdown className={"popover_dropdown"}>
                      <Flex direction={"column"}>
                        <Button
                          fw={400}
                          fs={"1rem"}
                          lh={24}
                          h={"50px"}
                          justify="space-between"
                          c={"#6c7b86"}
                          p={"11px 18px"}
                          variant={"subtle"}
                          onClick={() => {
                            handleClick(item);
                          }}
                          styles={{
                            inner: {
                              justifyContent: "left",
                            },
                            label: {
                              display: "flex",
                              gap: "10px",
                              paddingRight: "30px",
                            },
                          }}
                        >
                          <RiEditBoxLine size={24}></RiEditBoxLine>
                          <Divider orientation={"vertical"}></Divider>
                          <Text span>
                            <Trans>Uredi</Trans>
                          </Text>
                        </Button>
                        <Button
                          variant={"subtle"}
                          onClick={() => {
                            handleDelete(item);
                          }}
                          fw={400}
                          fs={"1rem"}
                          lh={24}
                          h={"50px"}
                          justify="space-between"
                          p={"11px 18px"}
                          styles={{
                            inner: {
                              justifyContent: "left",
                            },
                            label: {
                              display: "flex",
                              gap: "10px",
                              paddingRight: "30px",
                            },
                          }}
                        >
                          <RiDeleteBin5Fill size={24}></RiDeleteBin5Fill>
                          <Divider orientation={"vertical"}></Divider>
                          <span>
                            <Trans>Izbriši</Trans>
                          </span>
                        </Button>
                      </Flex>
                    </Popover.Dropdown>
                  </Popover>
                </div>
              </Flex>
            </List.Item>
          );
        })}
      </List>
    </Flex>
  );
};

export default RolesList;
