import { i18n } from "@lingui/core";
import dayjs from "dayjs";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { apiGetPreferredLanguage } from "../apiCalls";

export const SUPPORTED_LANGUAGES = [
  "en",
  "sl",
  "hr",
  "sr",
  "it",
  "de",
  "es",
  "bs",
] as const;
// const YUGOSLAVIJA = ["hr", "sr", "bs", "mk", "me"];

const getClosestSupportedLanguage = (language: string) => {
  return SUPPORTED_LANGUAGES.includes(language) ? language : "en";
};

// const getPrimaryBrowserLanguage = () => navigator.language.split("-")[0];

const getPreferredLanguage = async (token: string) => {
  if (token) {
    const response = await apiGetPreferredLanguage(token);
    return response.data.preferredLanguage;
  }

  //const primaryLanguage = getPrimaryBrowserLanguage();
  const primaryLanguage = "sl";
  return {
    userPreferredLanguage: primaryLanguage,
    clientPreferredLanguage: primaryLanguage,
  };
};

type PreferredLanguage = {
  userPreferredLanguage: string;
  clientPreferredLanguage: string;
};

type PreferredLanguageStore = {
  preferredLanguage: PreferredLanguage;
  activateLocale: (language: string) => void;
  setPreferredLanguage: (value: PreferredLanguage) => void;
  loadPreferredLanguage: () => Promise<void>;
};

const usePreferredLanguageStore = create<PreferredLanguageStore>()(
  persist(
    (set, get) => ({
      preferredLanguage: {
        userPreferredLanguage: "sl",
        clientPreferredLanguage: "sl",
      },

      activateLocale: (language: string) => {
        dayjs.locale(language);
        i18n.activate(language);
      },

      setPreferredLanguage: (value) => {
        set({ preferredLanguage: value });
        get().activateLocale(value.userPreferredLanguage);
      },

      loadPreferredLanguage: async () => {
        const token = localStorage.getItem("usr_token") || "";
        const language = await getPreferredLanguage(token);
        set({ preferredLanguage: language });

        get().activateLocale(
          getClosestSupportedLanguage(language.userPreferredLanguage),
        );
      },
    }),
    {
      name: "lang",
    },
  ),
);

export default usePreferredLanguageStore;
