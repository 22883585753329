import { api } from "@/lib/api-client";
import { t } from "@lingui/macro";
import {
  Divider,
  getKeyValue,
  Input,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { InvoiceDetailsModal } from "./InvoiceDetailsModal";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { useDebouncedValue } from "@mantine/hooks";
import { cn } from "@/utils";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { useNavigate, useParams } from "react-router-dom";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";

type RowInvoice = {
  key: string;
  number: string;
  amount: string;
  time: string;
  canceled: boolean;
};

const perPage = 15;
export const TaxRegisterInvoices = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams();

  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 200);

  const { data: posAuthData, refetch: refetchPosAuth } =
    api.cookie.useGetPosToken();

  const { mutate: deleteHttpCookie } = api.cookie.useDeleteHttpCookie();

  const { data, refetch, isPending, processedErrorMessage } =
    api.taxRegister.useGetOrganizationInvoices({
      query: debouncedSearchQuery,
      page,
      perPage,
      disabled: !posAuthData?.userId,
    });
  const invoices = data?.invoices;

  /**
   * This is required for pagination to work correctly,
   * since it breaks if pageCount goes undefined between
   */
  const [pageCount, setPageCount] = useState(1);
  useEffect(() => {
    if (data && data.pageCount != pageCount) {
      setPageCount(data.pageCount > 0 ? data.pageCount : 1);
      setPage(1);
    }
  }, [data]);

  const columns = [
    {
      key: "number",
      label: t`Številka računa`,
    },
    {
      key: "amount",
      label: t`Znesek`,
    },
    {
      key: "time",
      label: t`Ura`,
    },
  ];

  const rows: RowInvoice[] = isPending
    ? Array(perPage)
        .fill(null)
        .map((_, index) => {
          return {
            key: `loading-${index}`,
            number: "XXXX-XXXXX",
            amount: "XX,XX X",
            time: "XX. XX. XXXX XX:XX",
            canceled: false,
          };
        })
    : invoices?.map((invoice) => {
        return {
          key: invoice.number,
          number: invoice.number,
          amount: invoice.totalFormatted,
          time: invoice.dateFormatted,
          canceled: invoice.canceled,
        };
      }) || [];

  console.log("pos auth", posAuthData);

  return (
    <>
      <TaxRegisterAuthorizationDialog
        handleSuccessfulAuthorization={async () => {
          refetchPosAuth();
        }}
        authenticatedUserId={posAuthData?.userId}
      />

      <LimePageHeader title={t`Arhiv računov`} />

      <div className="md:px-8">
        <Input
          className="my-4 w-full px-4"
          startContent={<LuSearch />}
          placeholder={t`Išči`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <Divider />

        {processedErrorMessage && (
          <ErrorCard className="mx-4 mt-4" message={processedErrorMessage} />
        )}

        <Table
          selectionMode="single"
          onRowAction={(key) => {
            if (isPending || !invoices) return;

            const invoice = invoices.find((invoice) => invoice.number === key);
            if (!invoice) return;

            navigate(`./${invoice.id}`, {
              relative: "path",
            });
          }}
          isStriped
          aria-label={t`Tabela arhiviranih računov`}
          className="p-4"
          bottomContent={
            <div className="flex w-full justify-center">
              <Pagination
                isCompact
                showControls
                showShadow
                color="primary"
                page={page}
                total={pageCount}
                onChange={(page) => setPage(page)}
              />
            </div>
          }
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            items={rows}
            emptyContent={isPending ? undefined : t`Niste še izdali računov`}
          >
            {(item) => (
              <TableRow key={item.key}>
                {(columnKey) => (
                  <TableCell
                    className={cn("", {
                      "text-red-600 line-through": item.canceled,
                    })}
                  >
                    <Skeleton isLoaded={!isPending} className="rounded-xl">
                      {getKeyValue(item, columnKey)}
                    </Skeleton>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <InvoiceDetailsModal
        invoiceId={invoiceId || undefined}
        onClose={() => {
          if (invoiceId) {
            navigate("../", { relative: "path" });
          }

          refetch();
        }}
      />
    </>
  );
};
