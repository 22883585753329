import { AddCustomer, PostCustomer2FA, GetCustomers } from "@/server-types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { post, get } from "../request-helpers";

/**
 * POST /customers
 * /
 */

export const useAddCustomer = () => {
  return useMutation({
    mutationFn: (body: AddCustomer["query"]) =>
      post<AddCustomer>("/customer", body),
  });
};

export const useConfirmCustomer2FA = () => {
  return useMutation({
    mutationFn: ({
      customerId,
      body,
    }: {
      customerId: number;
      body: PostCustomer2FA["body"];
    }) => post<PostCustomer2FA>(`/customer/${customerId}/2fa`, body),
  });
};

export const getCustomers = (query: GetCustomers["query"]) => {
  return useQuery({
    queryKey: ["customers", query],
    queryFn: () => get<GetCustomers>("/customer", query),
    enabled: query.customerId != null || !!query.searchQuery,
  });
};
