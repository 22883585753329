import axios from "axios";

const prefix = import.meta.env.VITE_BE_HOST;

let appointmentAbortController = new AbortController();
export const apiGetAppointment = (appointmentId) => {
  appointmentAbortController.abort();
  appointmentAbortController = new AbortController();

  const token = localStorage.getItem("usr_token");

  const config = {
    headers: {
      "x-access-token": token?.replaceAll('"', ""),
    },
    params: {
      appointmentId,
    },
    signal: appointmentAbortController.signal,
  };

  return axios.get(prefix + `/calendar/appointment/${appointmentId}`, config);
};

export const apiPostAppointment = (appointmentData) => {
  const token = localStorage.getItem("usr_token");

  const config = {
    headers: {
      "x-access-token": token?.replaceAll('"', ""),
    },
  };

  return axios.post(prefix + `/calendar/appointment`, appointmentData, config);
};

export const apiUpdateAppointment = (appointmentId, appointmentData) => {
  const token = localStorage.getItem("usr_token");

  const config = {
    headers: {
      "x-access-token": token?.replaceAll('"', ""),
    },
  };

  return axios.put(
    prefix + `/calendar/appointment/${appointmentId}`,
    appointmentData,
    config,
  );
};

export const apiDeleteAppointment = (
  appointmentId,
  delete_repeating = false,
  delete_linked = false,
  sendCancelNotifications,
) => {
  const token = localStorage.getItem("usr_token");

  const config = {
    headers: {
      "x-access-token": token?.replaceAll('"', ""),
    },
    data: {
      delete_repeating,
      delete_linked,
      sendCancelNotifications,
    },
  };

  return axios.delete(
    prefix + `/calendar/appointment/${appointmentId}`,
    config,
  );
};

let customerSearchAbortController = new AbortController();
export const apiGetCustomerSearch = (name, lastName, email, gsm) => {
  const token = localStorage.getItem("usr_token");

  customerSearchAbortController.abort();
  customerSearchAbortController = new AbortController();

  const config = {
    headers: {
      "x-access-token": token?.replaceAll('"', ""),
    },
    params: {
      name,
      lastName,
      email,
      gsm,
    },
    signal: customerSearchAbortController.signal,
  };
  return axios.get(prefix + `/user/searchCustomers`, config);
};
