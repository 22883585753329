//create store for change log

import { create } from "zustand";

const useChangeLogStore = create((set) => ({
  logsPerPage: "10",
  paginationPageCount: 99,
  paginationPage: 1,

  setLogsPerPage: (logsPerPage) => {
    set({ logsPerPage });
  },
  setPaginationPage: (page) => {
    set({ paginationPage: page });
  },

  setPaginationPageCount: (pageCount) => {
    set({ paginationPageCount: pageCount });
  },
}));

export default useChangeLogStore;
