import { EasyNumberInput } from "@/Components/EasyNumberInput";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { Button, Divider, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useAtom } from "jotai";
import { ValueDisplay } from "./Components/ValueDisplay";
import { type FormValues, managingPriceObjectAtom } from "./ManageService";
import { Explanation } from "./ServiceTabs/PriceTab";
import { getBracketCounts } from "./Utils";

type Form = {
  count: number;
  price: number;
};

export const ManageBracket = ({
  index,
  brackets,
  basePrice,
  handleSaveBracket,
}: {
  index: number;
  brackets: FormValues["brackets"];
  basePrice: number;
  handleSaveBracket: (values: Form) => void;
}) => {
  const [_, setManagingPricingObject] = useAtom(managingPriceObjectAtom);

  const bracketIndex =
    index === -1
      ? brackets.length
      : brackets.findIndex((_, idx) => idx === index) + 1;

  const existingBracket = brackets[index];

  const form = useForm<Form>({
    initialValues: {
      count: existingBracket?.count || 1,
      price: existingBracket?.price || 0,
    },

    validate: {
      price: (value) => {
        if (basePrice + value < 0) {
          return t`Končna cena ne sme biti negativna`;
        }
      },

      count: (value) => {
        if (value < 1) {
          return t`Število enot mora biti večje od 0.`;
        }
      },
    },
  });

  const bracketCounts =
    brackets.length > 0
      ? getBracketCounts(brackets)
      : [{ fromCount: 1, toCount: 0 }];
  const { fromCount, toCount } =
    bracketCounts[
      index > bracketCounts.length - 1 ? bracketCounts.length - 1 : index
    ];

  return (
    <form
      style={{ display: "contents" }}
      onSubmit={form.onSubmit(handleSaveBracket)}
    >
      <Flex direction={"column"}>
        <Explanation
          title={t`Razred ${bracketIndex}`}
          description={t`Določite maksimalno število izbranih enot v razredu in pribitek/odbitek razreda k osnovni ceni.`}
          titleVariant={TextVariant.Subheading}
        />

        <EasyNumberInput
          label={t`Določite število enot v razredu`}
          min={1}
          {...form.getInputProps("count")}
          px={"md"}
          // toolTip={t`PLACEHOLDER`}
        />

        <ValueDisplay
          label={t`Interval števila enot`}
          text={`${existingBracket ? fromCount : toCount + 1} - ${
            existingBracket
              ? fromCount + form.values.count - 1
              : toCount + form.values.count
          }`}
        />

        <EasyNumberInput
          label={t`Določite dodatek k ceni`}
          {...form.getInputProps("price")}
          px={"md"}
          mt={"10px"}
          // toolTip={t`PLACEHOLDER`}
        />

        <Divider my={"md"} />
      </Flex>

      <Flex
        align={"center"}
        justify={"center"}
        gap={"sm"}
        pos={"fixed"}
        bottom={"20px"}
        left={0}
        w={"100%"}
      >
        <Button
          variant="outline"
          onClick={() =>
            setManagingPricingObject({
              pricingStrategy: "flat",
              objectIndex: null,
            })
          }
        >
          <Trans>Prekliči</Trans>
        </Button>
        <Button type={"submit"}>
          <Trans>Shrani</Trans>
        </Button>
      </Flex>
    </form>
  );
};
