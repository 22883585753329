import { BottomStickyModal } from "@/Components/BottomStickyModal";
import { CheckboxListItem } from "@/Components/CheckboxListItem/CheckboxListItem";
import { api } from "@/lib/api-client";
import { UpdateServicesPaymentSettings } from "@/server-types";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import {
  Button,
  Divider,
  Flex,
  NumberInput,
  SegmentedControl,
  Space,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { RiGlobalLine, RiSearch2Line } from "react-icons/ri";
import { StripePageHeader } from "./Components/StripePageHeader";
import { returnCurrencySymbol } from "../../../../../shared/utils/utils";

type PaymentTypes =
  UpdateServicesPaymentSettings["body"]["updateData"]["paymentType"];

export const ServicePaymentSettings = () => {
  const { mutateAsync: updateServicesPaymentSettings, isPending } =
    api.stripe.useUpdateStripePaymentSettingsServices();

  const { data: paymentSettings } = api.stripe.useGetStripePaymentSettings();

  const [selectedServiceIds, setSelectedServiceIds] = React.useState<number[]>(
    [],
  );

  const [searchQuery, setSearchQuery] = React.useState("");

  const servicesPaymentForm = useForm({
    initialValues: {
      paymentAmount: 0,
      paymentType: "percentage" as PaymentTypes,
      formPaymentRequired: false,
    },
    validate: {
      paymentAmount: (value) => {
        if (!servicesPaymentForm.values.formPaymentRequired) return null;

        if (
          servicesPaymentForm.values.paymentType === "percentage" &&
          value > 100
        ) {
          return t`Cena ne sme presegati 100%`;
        }
      },

      paymentType: (value) => {
        if (!servicesPaymentForm.values.formPaymentRequired) return null;

        if (value !== "percentage" && value !== "amount") {
          return t`Vrednost mora biti odstotek ali znesek`;
        }
      },
    },
  });

  const closeEditModal = () => {
    setSelectedServiceIds([]);

    const data = {
      paymentAmount: 0,
      paymentType: "percentage" as PaymentTypes,
      formPaymentRequired: false,
    };
    servicesPaymentForm.setValues(data);
    servicesPaymentForm.resetDirty(data);
  };

  const filteredServices = paymentSettings?.services.filter((service) => {
    return service.name?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <StripePageHeader
        title={t`Nastavitve po meri - storitve`}
        subtitle={t`Določite znesek za vsako storitev po meri.`}
        rightSection={
          isMobile ? null : (
            <TextInput
              leftSection={<RiSearch2Line />}
              placeholder={t`Išči storitve`}
              value={searchQuery}
              maw={"320px"}
              w={"50%"}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
            />
          )
        }
      />

      {isMobile && (
        <>
          <TextInput
            leftSection={<RiSearch2Line />}
            placeholder={t`Išči storitve`}
            w="100%"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
          />

          <Divider my="md" w="100%" />
        </>
      )}

      <Flex direction={"column"} gap={10} w={"100%"}>
        {filteredServices?.map((service, _) => {
          return (
            <CheckboxListItem
              key={service.serviceId.toString()}
              isSelected={selectedServiceIds.includes(service.serviceId)}
              label={service.name}
              onClick={() => {
                setSelectedServiceIds((ids) => {
                  if (ids.includes(service.serviceId)) {
                    const newIds = [...ids];
                    newIds.splice(ids.indexOf(service.serviceId), 1);

                    // reset form when closing edit modal
                    if (newIds.length === 0) {
                      const data = {
                        paymentAmount: 0,
                        paymentType: "percentage" as PaymentTypes,
                        formPaymentRequired: false,
                      };
                      servicesPaymentForm.setValues(data);
                      servicesPaymentForm.resetDirty(data);
                    }

                    return newIds;
                  }

                  if (ids.length === 0) {
                    const takeGlobalSettings =
                      service.formPaymentRequired &&
                      service.paymentAmount === 0 &&
                      paymentSettings?.global.paymentPriceAmount !== 0;

                    const amount = takeGlobalSettings
                      ? (paymentSettings?.global.paymentPriceAmount ?? 0)
                      : (service.paymentAmount ?? 0);

                    const type = takeGlobalSettings
                      ? (paymentSettings?.global.paymentPriceType ?? "amount")
                      : (service.paymentType ?? ("percentage" as PaymentTypes));

                    const data = {
                      paymentAmount: amount,
                      paymentType: type,
                      formPaymentRequired: service.formPaymentRequired ?? false,
                    };
                    servicesPaymentForm.setValues(data);
                    servicesPaymentForm.resetDirty(data);
                  }

                  return [...ids, service.serviceId];
                });
              }}
              rightSection={
                <Flex
                  bg="#F5F5F5"
                  h="85%"
                  align={"center"}
                  justify={"center"}
                  px="md"
                  w="75px"
                  style={{
                    borderRadius: "5px",
                    position: "relative",
                    border: selectedServiceIds.includes(service.serviceId)
                      ? "1px solid #D8D8D8"
                      : "1px solid transparent",
                  }}
                  c={"#8C8C8C"}
                >
                  {service.formPaymentRequired &&
                  service.paymentAmount != null &&
                  service.paymentAmount > 0 ? (
                    <Text
                      variant={TextVariant.BodyEmphasized}
                      c={Color.SecondaryText}
                    >
                      {service.paymentAmount}{" "}
                      {service.paymentType === "percentage"
                        ? "%"
                        : returnCurrencySymbol({ currency: service.currency })}
                    </Text>
                  ) : (
                    <Text
                      display={"flex"}
                      ta="center"
                      variant={TextVariant.BodyEmphasized}
                      c={Color.SecondaryText}
                    >
                      {service.formPaymentRequired &&
                      paymentSettings?.global.paymentPriceAmount !== 0 ? (
                        <>
                          {paymentSettings?.global.paymentPriceAmount}{" "}
                          {paymentSettings?.global.paymentPriceType ===
                          "percentage"
                            ? "%"
                            : returnCurrencySymbol({
                                currency: service.currency,
                              })}
                          <div
                            style={{
                              position: "absolute",
                              top: "-2px",
                              right: 0,
                            }}
                          >
                            <RiGlobalLine size={".8rem"} />
                          </div>
                        </>
                      ) : (
                        <>/</>
                      )}
                    </Text>
                  )}
                </Flex>
              }
            />
          );
        })}
      </Flex>

      <BottomStickyModal isOpen={selectedServiceIds.length > 0}>
        <form
          onSubmit={servicesPaymentForm.onSubmit((values) => {
            updateServicesPaymentSettings({
              serviceIds: selectedServiceIds,
              updateData: values,
            }).then(() => closeEditModal());
          })}
        >
          <Flex
            justify={"space-between"}
            gap="md"
            className="input-section"
            align={"center"}
            direction={isMobile ? "column-reverse" : "row"}
            w={"100%"}
          >
            <div style={{ width: isMobile ? "100%" : "auto" }}>
              <Switch
                label={t`Omogoči spletno plačevanje`}
                {...servicesPaymentForm.getInputProps("formPaymentRequired", {
                  type: "checkbox",
                })}
                labelPosition={isMobile ? "left" : "right"}
                size={isMobile ? "lg" : "sm"}
                w={isMobile ? "100%" : "auto"}
                styles={{
                  label: {
                    color: Color.SecondaryText,
                    fontSize: "0.8rem",
                  },
                }}
              />
            </div>
            <Flex
              align={isMobile ? "flex-end" : "center"}
              gap={"md"}
              w={isMobile ? "100%" : "auto"}
            >
              <Flex
                direction={isMobile ? "column" : "row"}
                align={"center"}
                gap={"10px"}
              >
                <Text variant={TextVariant.Caption} c={Color.SecondaryText}>
                  <Trans>Izberite odstotke ali znesek</Trans>:{" "}
                </Text>
                <NumberInput
                  {...servicesPaymentForm.getInputProps("paymentAmount")}
                  hideControls={true}
                  w={"150px"}
                  disabled={!servicesPaymentForm.values.formPaymentRequired}
                />
              </Flex>
              <SegmentedControl
                data={[
                  { value: "percentage", label: "%" },
                  {
                    value: "amount",
                    label: returnCurrencySymbol({
                      currency: paymentSettings?.mainCurrency ?? "EUR",
                    }),
                  },
                ]}
                {...servicesPaymentForm.getInputProps("paymentType")}
                w={"120px"}
                styles={{
                  root: {
                    height: "36px",
                  },
                }}
                disabled={!servicesPaymentForm.values.formPaymentRequired}
              />
            </Flex>
          </Flex>
          <Space h="xs" />
          <Flex justify={"flex-end"} gap={"sm"}>
            <Button
              w={isMobile ? "100%" : "100px"}
              onClick={() => closeEditModal()}
              loading={isPending}
              variant="outline"
            >
              <Trans>Prekliči</Trans>
            </Button>
            <Button
              w={isMobile ? "100%" : "100px"}
              type="submit"
              loading={isPending}
            >
              <Trans>Shrani</Trans>
            </Button>
          </Flex>
        </form>
      </BottomStickyModal>
    </>
  );
};
