import { Color } from "@/types/colors";
import styled from "styled-components";

export const Content = styled.table`
  /*  display: flex;
  flex-direction: column;*/
  width: 100%;

  overflow: hidden;
  text-align: left;

  border-collapse: collapse;

  .checkbox {
    cursor: pointer;
    text-align: start;

    input {
      cursor: pointer;
    }
  }
`;

export const Row = styled.tr`
  opacity: 0;
  animation-delay: ${(props) => props.id * 0.02}s;
  animation-name: block-initial-animation;
  animation-fill-mode: forwards;
  background: #ffffff;
  justify-content: left;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  border-top: 1px solid #eaebed;

  display: table-row;

  label {
    width: 100%;
    text-align: left;
  }

  td {
    padding: 16px 0;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  &:hover {
    background: #f6f8f9;
  }

  @media (min-width: 768px) {
    text-align: left;
  }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    border-radius: 12px;
    width: unset;
  }
`;

export const TitleRowFlex = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 30px;
`;

export const Search = styled.div`
  border-radius: 8px;

  border: 1px solid #eaebed;

  color: #eaebed;

  height: fit-content;

  display: flex;
  align-items: center;
  padding-left: 5px;

  svg {
    flex-shrink: 0;
  }

  input {
    padding: 8px 12px;

    outline: none;
    border: none;

    background: transparent;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    width: 200px;
  }
`;

export const RowInfoWrapper = styled.div`
  margin-bottom: ${(props) => (props.isMobile ? "0.5rem" : "0")};
  text-align: left;
  width: 100%;
`;

export const RowInfoTitle = styled.span`
  display: ${(props) => (props.isMobile ? "inline" : "none")};
  font-size: 13px;
  //background: #a7d7b4;
  border-radius: 12px;
  padding: 2px 5px;
  margin-right: 1rem;
  color: #969ba9;
  font-weight: ${(props) => props.fontWeight};
`;

export const TopRow = styled.tr`
  color: #969ba9;
  /* border-top: 1px solid #eaebed; */

  background: ${Color.Background};

  font-weight: 600;

  th {
    padding: 10px 0;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 0rem 1.5rem 2rem;

  label {
    width: 100%;
    text-align: left;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #969ba930;
`;

export const ActionsIcons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: 10px 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .action-button {
    height: 30px;
    /*width: 30px;*/

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.select-all {
      justify-content: start;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  overflow: hidden;
  gap: 10px;
  /*  max-width: ${(props) => (props.active ? "500px" : "0")};*/

  .active-buttons-transition-wrapper {
    display: flex;
    overflow: hidden;
    gap: 10px;
    transform: translateX(-100%);
    transform: translateX(${(props) => props.active && "-50%"});
    transform: translateX(${(props) => props.activeFull && "0"});

    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .action-button {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 10px;
    flex: 1;
    width: 18ch;

    display: flex;
    justify-content: start;
    gap: 1ch;

    @media (max-width: 768px) {
      width: 3ch;
      padding: 5px;
      justify-content: center;

      span {
        display: none;
      }
    }
  }
`;

export const AlertPopup = styled.div`
  display: flex;
  flex-direction: column;

  text-align: left;

  gap: 10px;

  margin-bottom: 20px;
`;

export const BottomActions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const SkeletonLoaderRow = styled.tr`
  width: 100%;
  border-top: 1px solid #eaebed;
  flex: 1;
  display: table-row;
  justify-content: left;

  opacity: 0;
  //animation-duration: 0.25s;
  animation-delay: 0.2s;
  animation-name: block-initial-animation;
  animation-fill-mode: forwards;
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 2px 0px;
  background: #f9f9f9;
  border-radius: 12px 12px 12px 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
