import { LimePageHeader } from "@/Components/LimePageHeader";
import { ErrorCard } from "@/Components/NextBase/ErrorCard";
import { LimeSingleSelect } from "@/Components/NextBase/LimeSelect";
import { api } from "@/lib/api-client";
import { t, Trans } from "@lingui/macro";
import { useForm } from "@mantine/form";
import {
  Button,
  Card,
  CardBody,
  Divider,
  Link,
  Radio,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import qz from "qz-tray";
import { isArray, union } from "lodash";
import { LimeRadioGroup } from "@/Components/NextBase/LimeRadioGroup";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { Download } from "lucide-react";
import { GetLocations, GetPosPrinterTypes } from "@/server-types";
import { toast } from "sonner";

export const TaxRegisterSetupPrinters = () => {
  const navigate = useNavigate();

  const [discoveredPrinters, setDiscoveredPrinters] = useState<string[]>([]);
  const [isDiscoveringPrinters, setIsDiscoveringPrinters] = useState(false);

  const [isQzTrayRunning, setIsQzTrayRunning] = useState(true);

  const {
    data: locationsData,
    isFetching: isLocationsFetching,
    processedErrorMessage: getLocationsErrorMessage,
    refetch: refetchLocations,
  } = api.location.useGetLocations({
    page: 1,
    perPage: 100,
    sortBy: "labelDesc",
  });

  const {
    mutateAsync: downloadQzTrayClientCertificate,
    isPending: isDownloadingClientCertificate,
    processedErrorMessage: downloadQzTrayClientCertificateErrorMessage,
  } = api.storage.useDownloadQzTrayClientCertificate();

  const { setupQzTray, isQzTraySigningSetup } = usePosPrinterStore(
    (state) => state,
  );

  useEffect(() => {
    if (!isQzTraySigningSetup) return;

    discoverPrinters();
  }, [isQzTraySigningSetup]);

  // useEffect(() => {
  //   return () => {
  //     if (qz.websocket.isActive()) {
  //       qz.websocket.disconnect();
  //     }
  //   };
  // }, []);

  const discoverPrinters = async () => {
    setIsQzTrayRunning(true);
    setIsDiscoveringPrinters(true);

    try {
      if (!isQzTraySigningSetup) {
        setupQzTray({});
      }

      if (!qz.websocket.isActive()) {
        await qz.websocket.connect();
      }
    } catch (e) {
      console.error("connect error ", e);

      setIsQzTrayRunning(false);
      setIsDiscoveringPrinters(false);
      // setDiscoveredPrinters([]);
      return;
    }

    try {
      const printers = await qz.printers.find();
      console.log("printers", printers);
      if (printers.length === 0) {
        setDiscoveredPrinters([]);
        setIsDiscoveringPrinters(false);
        return;
      }

      if (!isArray(printers)) {
        setDiscoveredPrinters([printers]);
      } else {
        setDiscoveredPrinters(printers);
      }

      setIsDiscoveringPrinters(false);
    } catch (error) {
      console.error(error);
      setIsDiscoveringPrinters(false);
      // setDiscoveredPrinters([]);
    }

    try {
      await qz.websocket.disconnect();
    } catch (error) {
      console.error("error disconnecting qztray", error);
    }
    setIsDiscoveringPrinters(false);
  };

  const onLocationUpdated = async () => {
    refetchLocations();
  };

  const locationPrinters =
    locationsData?.locations
      .filter((l) => l.PosPrinter)
      .map((l) => l.PosPrinter!.printerName) || [];
  const discoveredAndLocationPrintersUnion = union(
    discoveredPrinters,
    locationPrinters,
  );

  console.log(
    "discoveredAndLocationPrintersUnion",
    discoveredAndLocationPrintersUnion,
  );
  console.log("discovered printers", discoveredPrinters);

  return (
    <>
      <LimePageHeader
        title={t`Vzpostavitev tiskalnikov`}
        subPage={{
          title: t`Tiskalniki`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      {!isQzTrayRunning && (
        <div className="px-8 pt-4">
          <ErrorCard
            message={t`Preverite, da imate na napravi nameščen QZTray in je prižgan`}
            bottomSection={
              <Link
                href="https://qz.io/download/"
                target="_blank"
                className="mt-4 text-inherit"
                showAnchorIcon
                underline="always"
              >
                <Trans>Namestitev QzTray</Trans>
              </Link>
            }
          />

          <Divider className="mt-4" />
        </div>
      )}

      <div className="px-8 pt-4">
        <Button
          onPress={async () => {
            const response = await downloadQzTrayClientCertificate();

            // Create a URL for the blob and download the file
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "qztray_lime_client.crt"); // Specify the file name
            document.body.appendChild(link);
            link.click();

            // Clean up the URL and link
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          }}
          startContent={<Download />}
          color="primary"
          isLoading={isDownloadingClientCertificate}
        >
          <Trans>Prenesi QzTray certifikat</Trans>
        </Button>
        <ErrorCard message={downloadQzTrayClientCertificateErrorMessage} />
        <p className="mt-4 text-foreground-500">Namestitev:</p>
        <p className="text-foreground-500">
          <Trans>
            QzTray -&gt; Advanced -&gt; Site Manager -&gt; &quot;+&quot; -&gt;
            Browse
          </Trans>
        </p>
        <Divider className="mt-4" />
      </div>

      <div className="mt-4 flex flex-col gap-4 md:px-8">
        {locationsData?.locations.map((loc) => {
          return (
            <LocationPrinterConfig
              discoveredAndLocationPrintersUnion={
                discoveredAndLocationPrintersUnion
              }
              discoveredPrinters={discoveredPrinters}
              isDiscoveringPrinters={isDiscoveringPrinters}
              location={loc}
              onLocationUpdated={onLocationUpdated}
            />
          );
        })}
      </div>
    </>
  );
};

type LocationPrinterConfigForm = {
  locationId: number;
  printerName?: string;
  printerType?: GetPosPrinterTypes["response"][number];
  lineWidth?: number;
};

const LocationPrinterConfig = ({
  location,

  discoveredPrinters,
  discoveredAndLocationPrintersUnion,
  isDiscoveringPrinters,
  onLocationUpdated,
}: {
  location: GetLocations["response"]["locations"][number];

  discoveredPrinters: string[];
  discoveredAndLocationPrintersUnion: string[];
  isDiscoveringPrinters: boolean;
  onLocationUpdated: () => void;
}) => {
  const {
    mutateAsync: putLocation,
    isPending: isPutLocationPending,
    processedErrorMessage: putLocationErrorMessage,
  } = api.location.usePutLocation();

  const {
    data: printerTypes,
    isFetching: isFetchingPrinterTypes,
    processedErrorMessage: fetchPrinterTypesErrorMessage,
  } = api.values.useGetPosPrinterTypes();

  const handleSubmit = async (values: LocationPrinterConfigForm) => {
    const updateLocationPromise = putLocation({
      locationId: location.locationId,
      body: {
        printerConfig: {
          ...values,
          printerName: values.printerName!,
          printerType: values.printerType!,
          lineWidth: values.lineWidth!,
        },
      },
    });

    toast.promise(updateLocationPromise, {
      error: (data) => {
        return data || t`Napaka pri posodabljanju konfiguracije tiskalnika`;
      },
      success: (data) => {
        onLocationUpdated();
        return t`Konfiguracija printerja uspešno posodobljena`;
      },
    });
  };

  const form = useForm<LocationPrinterConfigForm>({
    initialValues: { locationId: location.locationId, ...location.PosPrinter },

    onValuesChange: (values) => {
      if (!form.isValid()) return;

      handleSubmit(values);
    },

    validate: {
      printerName: (value) => {
        if (!value || value.length < 1) return t`Printer je obvezen`;
      },

      printerType: (value) => {
        if (!value) return t`Tip printerja je obvezen`;
      },

      lineWidth: (value) => {
        if (!value) return t`Širina traku je obvezna`;
      },
    },
  });

  return (
    <Card shadow="sm" isDisabled={isPutLocationPending}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <CardBody className="gap-4">
          <p className="font-semibold">{location.label}</p>
          <LimeSingleSelect
            isLoading={isDiscoveringPrinters}
            isDisabled={!discoveredPrinters.length}
            items={discoveredAndLocationPrintersUnion.map((dp) => ({
              label: dp,
              key: dp,
            }))}
            label={t`Printer`}
            {...form.getInputProps("printerName")}
          />

          <LimeSingleSelect
            isLoading={isFetchingPrinterTypes}
            items={
              printerTypes?.map((pt) => ({
                key: pt,
                label: pt,
              })) ?? []
            }
            label={t`Vrsta tiskalnika`}
            {...form.getInputProps("printerType")}
          />
          <ErrorCard message={fetchPrinterTypesErrorMessage} />

          <LimeRadioGroup
            label={t`Število stolpcev na termalnem traku`}
            {...form.getInputProps("lineWidth")}
            value={form.getValues().lineWidth?.toString()}
          >
            <Radio value={"33"}>33 stolpcev</Radio>
            <Radio value={"48"}>48 stolpcev</Radio>
          </LimeRadioGroup>

          <ErrorCard message={putLocationErrorMessage} />
        </CardBody>
      </form>
    </Card>
  );
};
