import {
  DeleteDeliveryOrder,
  GetDeliveryOrder,
  GetDeliveryOrders,
  PostDeliveryOrder,
  PutDeliveryOrder,
} from "@/server-types";
import { useCustomMutation } from "../custom-mutation";
import { del, get, post, put } from "../request-helpers";
import { useCustomQuery } from "../custom-query";

export const usePostDeliveryOrder = () => {
  return useCustomMutation({
    mutationFn: (body: PostDeliveryOrder["body"]) =>
      post<PostDeliveryOrder>(`/delivery-order`, body),
  });
};

export const useGetDeliveryOrders = (query: GetDeliveryOrders["query"]) => {
  return useCustomQuery({
    queryKey: ["delivery-orders", query],
    queryFn: () => get<GetDeliveryOrders>("/delivery-order", query),
  });
};

export const useGetDeliveryOrderById = (deliveryOrderId: number) => {
  return useCustomQuery({
    queryKey: ["delivery-order", deliveryOrderId],
    queryFn: () => get<GetDeliveryOrder>(`/delivery-order/${deliveryOrderId}`),
    enabled: deliveryOrderId !== -1,
  });
};

export const usePutDeliveryOrderById = () => {
  return useCustomMutation({
    mutationFn: ({
      body,
      deliveryOrderId,
    }: {
      body: PostDeliveryOrder["body"];
      deliveryOrderId: number;
    }) => put<PutDeliveryOrder>(`/delivery-order/${deliveryOrderId}`, body),
  });
};

export const useDeleteDeliveryOrderById = () => {
  return useCustomMutation({
    mutationFn: ({
      deliveryOrderId,
      keepQuantity,
    }: {
      deliveryOrderId: number;
      keepQuantity: boolean;
    }) =>
      del<DeleteDeliveryOrder>(`/delivery-order/${deliveryOrderId}`, {
        keepQuantity,
      }),
    displayNotification: true,
  });
};
