import {
  DeleteResource,
  GetResource,
  GetResourceHierarchy,
  GetResources,
  PostResource,
} from "@/server-types";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { del, get, post } from "../request-helpers";

export const useGetResources = ({
  resourceGroupId,
  locationId,
}: {
  resourceGroupId?: number | undefined;
  locationId?: number | undefined;
}) => {
  return useQuery({
    queryKey: [resourceGroupId, "resources", locationId],
    queryFn: () =>
      get<GetResources>(`/resource`, { resourceGroupId, locationId }),
    placeholderData: keepPreviousData,
    // enabled: resourceGroupId != null && resourceGroupId !== -1,
    gcTime: 0,
  });
};

export const usePostResource = () => {
  return useMutation({
    mutationKey: ["resources"],
    mutationFn: (resource: PostResource["body"]) =>
      post<PostResource>("/resource", resource),
  });
};

export const useGetResource = (resourceId: number | undefined) => {
  return useQuery({
    queryKey: ["resources", resourceId],
    queryFn: () => get<GetResource>(`/resource/${resourceId ?? -1}`),
    placeholderData: keepPreviousData,
    enabled: resourceId != null && resourceId !== -1,
  });
};

export const useDeleteResource = () => {
  return useMutation({
    mutationKey: ["resources"],
    mutationFn: (resourceId: number) =>
      del<DeleteResource>(`/resource/${resourceId}`),
  });
};

export const useGetResourceHierarchy = () => {
  return useQuery({
    queryKey: ["resource-hierarchy"],
    queryFn: () => get<GetResourceHierarchy>(`/resource/hierarchy`),
    placeholderData: keepPreviousData,
  });
};
