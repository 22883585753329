import LegalPages from "@/Components/LegalPages";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { Flex, Image, Space, Text } from "@mantine/core";
import type React from "react";
import { useEffect } from "react";

type AuthFormWrapperProps = {
  isLogin: boolean;

  children: React.ReactNode;
};

export const AuthFormWrapper = ({
  isLogin,

  children,
}: AuthFormWrapperProps) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const lang = params.get("lang");

  const { preferredLanguage, setPreferredLanguage } = usePreferredLanguageStore(
    (state) => state,
  );

  useEffect(() => {
    if (lang) {
      setPreferredLanguage({
        userPreferredLanguage: lang,
        clientPreferredLanguage: lang,
      });
    }
  }, [lang]);

  return (
    <Flex
      direction={"column"}
      w={"100%"}
      px={"lg"}
      align={"center"}
      justify={"center"}
      style={{
        maxWidth: "500px",
        margin: "0 auto",
      }}
      h={"100vh"}
    >
      <Image
        src="/logo_full_green.svg"
        alt="logo"
        w="100px"
        h={"auto"}
        mb={"xl"}
      />
      <Text mb={"xs"} variant={TextVariant.Heading}>
        {isLogin
          ? t`Prijava v Lime Booking račun`
          : t`Ustvari si uporabniški račun`}
      </Text>
      <Text variant={TextVariant.Body} c={Color.SecondaryText}>
        {isLogin ? t`Dobrodošli nazaj!` : t`Preizkusi Lime Booking brezplačno!`}
      </Text>

      <Space h={"lg"} />

      <Flex direction={"column"} gap={"md"} w={"100%"}>
        {children}
      </Flex>

      <Space h={"lg"} />

      <Text variant={TextVariant.Body} c={Color.SecondaryText}>
        {isLogin ? t`Še nimaš računa?` : t`Si že registriran?`}
        {isLogin && (
          <Text
            data-identifier="register-redirect"
            variant={TextVariant.BodyEmphasized}
            component="a"
            href={"/register"}
            c={Color.Brand}
          >
            {" "}
            <Trans>Registriraj se</Trans>
          </Text>
        )}
      </Text>
      <Text
        variant={TextVariant.BodyEmphasized}
        component="a"
        href={isLogin ? "/forgot-password" : "/login"}
        c={Color.Brand}
      >
        {isLogin ? t`Si pozabil geslo?` : t`Prijavi se`}
      </Text>

      <Space h={"lg"} />

      <LegalPages
        language={lang || preferredLanguage.userPreferredLanguage || "sl"}
      />
    </Flex>
  );
};
