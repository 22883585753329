import styled from "styled-components";
import { useCalendarStore } from "../../store/useCalendarStore";
import dayjs from "dayjs";
import {
  returnHeightOfEventBlock,
  returnYoffsetForEvent,
} from "@/utils/appointment-utils";

const ShiftBlock = ({ shift, date }) => {
  const { calendarData } = useCalendarStore((state) => state);

  const locationStart = dayjs(`${date}T${calendarData.startTime}`);
  const locationEnd = dayjs(`${date}T${calendarData.endTime}`);

  const shiftStart = (() => {
    if (dayjs(shift.start).isBefore(locationStart)) {
      return locationStart;
    }
    return dayjs(shift.start);
  })();

  const shiftEnd = (() => {
    if (dayjs(shift.end).isAfter(locationEnd)) {
      return locationEnd;
    }
    return dayjs(shift.end);
  })();

  const yOffset = returnYoffsetForEvent({
    calendarStartTime: calendarData.startTime,
    startTimeEvent: shiftStart,
    timeStep: calendarData.timeStep,
    slotHeight: calendarData.slotHeight,
  });

  const height = returnHeightOfEventBlock({
    duration: shiftEnd.diff(shiftStart, "minute"),
    timeStep: calendarData.timeStep,
    slotHeight: calendarData.slotHeight,
  });

  return (
    <>
      <ShiftWrap
        className={"shift"}
        shift={shift}
        yOffset={yOffset}
        height={height}
      ></ShiftWrap>
    </>
  );
};

const ShiftWrap = styled.div`
  --inset: 5px;

  width: calc(100% - var(--inset));
  z-index: -1;

  top: calc(${(props) => props.yOffset}px + (var(--inset) / 2));
  height: calc(${(props) => props.height}px - var(--inset));

  background: #f1fcf1;
  border: 2px dashed #74b587;
  border-radius: 6px;

  left: calc(var(--inset) / 2);

  position: absolute;

  .breaks {
    width: 100%;
    height: 100%;

    position: relative;
  }
`;

export default ShiftBlock;
