/**
 * Initialize DayJS and add all the plugins that we need.
 */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isToday from "dayjs/plugin/isToday";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import minMax from "dayjs/plugin/minMax";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(isoWeek);

import("dayjs/locale/sl");
import("dayjs/locale/en");
import("dayjs/locale/de");
import("dayjs/locale/hr");
import("dayjs/locale/sr");
import("dayjs/locale/it");
import("dayjs/locale/es");

/**
 * This function is used to intercept all the requests and check if the token is expired or not.
 */
import axios from "axios";
import { jwtDecode } from "jwt-decode";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("usr_token");
    if (token) {
      const { exp } = jwtDecode(token);

      if (!exp) return config;

      const expirationTime = exp * 1000;
      const currentDate = new Date();

      if (currentDate.getTime() > expirationTime) {
        localStorage.removeItem("usr_token");
        location.reload();
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

/**
 * Load all the translations and add them to the i18n object.
 */
import { type Messages, i18n } from "@lingui/core";

import { messages as deMessages } from "../locales/de/messages.mjs";
import { messages as enMessages } from "../locales/en/messages.mjs";
import { messages as esMessages } from "../locales/es/messages.mjs";
import { messages as hrMessages } from "../locales/hr/messages.mjs";
import { messages as itMessages } from "../locales/it/messages.mjs";
import { messages as slMessages } from "../locales/sl/messages.mjs";
import { messages as srMessages } from "../locales/sr/messages.mjs";

i18n.load({
  sl: slMessages as Messages,
  en: enMessages as Messages,
  hr: hrMessages as Messages,
  sr: srMessages as Messages,
  it: itMessages as Messages,
  es: esMessages as Messages,
});

i18n.activate("sl");
