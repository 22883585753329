import { LimePagination } from "@/Components/LimePagination";
import { LimeSelect } from "@/Components/LimeSelect";
import { Button, Checkbox, Flex, Loader, Table } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { api } from "../../../../../lib/api-client/index";

type ListForm = {
  page: number;
  pageSize: number;
  userId: number | undefined;
  locationId: number | undefined;
};

type SelectForm = {
  selectedTimeOffs: number[];
};

export const TimeOffsTab = ({ clientId }: { clientId: number }) => {
  const listForm = useForm<ListForm>({
    initialValues: {
      page: 0,
      pageSize: 10,
      userId: undefined,
      locationId: undefined,
    },
  });

  const selectForm = useForm<SelectForm>({
    initialValues: {
      selectedTimeOffs: [],
    },
  });

  const {
    data,
    isPending: timeOffsPending,
    refetch,
  } = api.admin.useGetTimeOffs({
    clientId,
    userId: listForm.getValues().userId,
    locationId: listForm.getValues().locationId,
    page: Math.max(listForm.getValues().page - 1, 0),
    pageSize: listForm.getValues().pageSize,
  });

  const { mutateAsync: deleteTimeOffs, isPending: isDeleteTimeOffsPending } =
    api.admin.useDeleteTimeOffs();

  return (
    <Flex direction={"column"}>
      <Flex gap={"sm"} my={"xs"}>
        <LimeSelect
          label="Lokacija"
          data={
            data?.locations?.map((l) => ({
              value: l.locationId.toString(),
              label: l.label,
            })) ?? []
          }
          value={listForm.getValues().locationId?.toString() ?? ""}
          onChange={(value) =>
            listForm.setFieldValue("locationId", Number(value))
          }
          allowDeselect={false}
        />

        <LimeSelect
          label="Zaposlen"
          data={
            data?.users?.map((u) => ({
              value: u.userId.toString(),
              label: `${u.name} ${u.lastName}`,
            })) ?? []
          }
          value={listForm.getValues().userId?.toString() ?? ""}
          onChange={(value) => listForm.setFieldValue("userId", Number(value))}
          allowDeselect={false}
        />
      </Flex>

      <Flex align={"center"} gap={"md"}>
        <Checkbox
          label="Čekiraj vse"
          checked={
            selectForm.getValues().selectedTimeOffs.length ===
            data?.timeOffs.length
          }
          onChange={(e) => {
            if (e.target.checked) {
              selectForm.setFieldValue(
                "selectedTimeOffs",
                data?.timeOffs.map((timeoff) => timeoff.id) ?? [],
              );
            } else {
              selectForm.setFieldValue("selectedTimeOffs", []);
            }
          }}
        />

        <Button
          onClick={() => {
            modals.openConfirmModal({
              title: `pa si zihr da hoc zbrisat ${
                selectForm.getValues().selectedTimeOffs.length
              } pavz?`,
              labels: {
                confirm: "jo",
                cancel: "bol ne",
              },
              onConfirm: async () => {
                console.log(
                  "DELETING",
                  selectForm.getValues().selectedTimeOffs,
                );
                await deleteTimeOffs({
                  timeOffIds: selectForm.getValues().selectedTimeOffs,
                });

                refetch();
                selectForm.reset();
              },
            });
          }}
          loading={isDeleteTimeOffsPending}
        >
          Deletaj zbrane ({selectForm.getValues().selectedTimeOffs.length})
        </Button>
      </Flex>

      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ČEKIRAJ</Table.Th>
            <Table.Th>OD</Table.Th>
            <Table.Th>DO</Table.Th>
            <Table.Th>LOKACIJA</Table.Th>
            <Table.Th>ZAPOSLEN</Table.Th>
            <Table.Th>OPOMBA</Table.Th>
            <Table.Th>TIP</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {timeOffsPending && <Loader />}
          {data?.timeOffs.map((timeoff) => (
            <Table.Tr key={timeoff.id}>
              <Table.Td w={"md"}>
                <Checkbox
                  checked={selectForm
                    .getValues()
                    .selectedTimeOffs.includes(timeoff.id)}
                  onChange={() => {
                    if (
                      selectForm
                        .getValues()
                        .selectedTimeOffs.includes(timeoff.id)
                    ) {
                      const index = selectForm
                        .getValues()
                        .selectedTimeOffs.findIndex((id) => id === timeoff.id);
                      selectForm.removeListItem("selectedTimeOffs", index);
                    } else {
                      selectForm.insertListItem("selectedTimeOffs", timeoff.id);
                    }
                  }}
                  color="blue"
                />
              </Table.Td>
              <Table.Td>{timeoff.from}</Table.Td>
              <Table.Td>{timeoff.to}</Table.Td>
              <Table.Td>{timeoff.locationLabel}</Table.Td>
              <Table.Td>{timeoff.userName}</Table.Td>
              <Table.Td>{timeoff.note}</Table.Td>
              <Table.Td>{timeoff.type}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>

      <LimePagination
        label="pavz"
        page={listForm.getValues().page}
        perPage={listForm.getValues().pageSize.toString()}
        setPerPage={(pageSize) => {
          listForm.setFieldValue("pageSize", Number(pageSize));
          listForm.setFieldValue("page", 1);
        }}
        pageCount={data?.pageCount ?? 1}
        setPage={(page) => listForm.setFieldValue("page", page)}
      />
    </Flex>
  );
};
