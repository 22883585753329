import { Trans, t } from "@lingui/macro";
import { ActionIcon, Flex, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import PropTypes from "prop-types";
import React from "react";
import { BiTrash } from "react-icons/bi";
import {
  RiCalendarTodoLine,
  RiMapPin2Fill,
  RiSettings4Line,
} from "react-icons/ri";
import styled from "styled-components";
import { useScheduleStore } from "../Stores/useScheduleStore";

const LocationScheduleBlock = ({ schedule, handleEditClick }) => {
  const { selectSchedule, deleteSchedule } = useScheduleStore((state) => state);
  const location = useScheduleStore((state) =>
    state.locations.find(
      (location) => location.locationId === schedule.locationId,
    ),
  );

  const [isDeleting, setIsDeleting] = React.useState(false);

  const isMobile = window.innerWidth < 768;

  return (
    <Flex dir={"row"} gap={"5px"}>
      <Wrapper
        color={schedule.color}
        onClick={() => selectSchedule(schedule.scheduleId)}
      >
        <Text fw={600} size={isMobile ? "md" : "xl"}>
          {schedule.name}
        </Text>
        <div className={"info"}>
          <InfoRow icon={<RiMapPin2Fill />} text={location?.label} />
          <InfoRow
            icon={<RiCalendarTodoLine />}
            text={
              schedule.date_from_formatted + " - " + schedule.date_to_formatted
            }
          />
        </div>
      </Wrapper>
      <ActionButtons color={schedule.color}>
        <ActionButton
          tooltip={t`Urejanje`}
          color={schedule.color}
          icon={<RiSettings4Line />}
          onClick={handleEditClick}
        />
        {/*<ActionButton*/}
        {/*  tooltip={"Podvoji urnik"}*/}
        {/*  color={schedule.color}*/}
        {/*  icon={<RiFileCopyLine />}*/}
        {/*  onClick={() => {}}*/}
        {/*/>*/}
        <ActionButton
          tooltip={t`Izbriši`}
          color={schedule.color}
          icon={<BiTrash />}
          onClick={() => {
            modals.openConfirmModal({
              id: "delete-schedule",
              children: (
                <Text fw={500} size={"lg"}>
                  <Trans>Ste prepričani, da želite izbrisati urnik?</Trans>
                </Text>
              ),
              labels: {
                confirm: t`Izbriši`,
                cancel: t`Prekliči`,
              },
              confirmProps: {
                color: "red",
                loading: isDeleting,
              },
              centered: true,
              withCloseButton: false,
              padding: "2rem",
              radius: "12px",
              closeOnConfirm: false,
              onCancel: () => {},
              onConfirm: () => {
                setIsDeleting(true);
                deleteSchedule(schedule.scheduleId).finally(() =>
                  setIsDeleting(false),
                );
              },
            });
          }}
        />
      </ActionButtons>
    </Flex>
  );
};

const ActionButton = ({ icon, color, tooltip, onClick }) => {
  return (
    <Tooltip label={tooltip} position={"left"}>
      <ActionIcon
        size={"lg"}
        variant={"outline"}
        color={color}
        radius={"12px"}
        onClick={onClick}
        style={{ fontSize: "1.4rem" }}
      >
        {icon}
      </ActionIcon>
    </Tooltip>
  );
};

const InfoRow = ({ icon, text }) => {
  return (
    <Flex align={"center"} gap={"5px"} style={{ marginTop: "5px" }}>
      {icon}{" "}
      <Text size={window.innerWidth < 768 ? "sm" : "md"} span>
        {text}
      </Text>
    </Flex>
  );
};

const Wrapper = styled.div`
  border: 2px solid ${(props) => props.color};
  border-radius: 12px;
  flex: 1;

  padding: 1rem;

  cursor: pointer;

  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  .info {
    margin-top: 0.5rem;
    color: #424b64;

    svg {
      font-size: 1.2rem;
      color: ${(props) => props.color};
      flex-shrink: 0;
    }
  }

  &:hover {
    box-shadow: 0 14px 60px rgba(0, 0, 0, 0.12);
  }
`;

const ActionButtons = styled(Flex)`
  flex-direction: column;
  gap: 5px;

  button {
    border-width: 2px;

    color: #6c7b86;
    border-color: ${(props) => props.color};
  }
`;

export default LocationScheduleBlock;

LocationScheduleBlock.propTypes = {
  schedule: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    dateRange: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
};
