export const Section = ({ title, bordered, children, isMobile }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        paddingBottom: "10px",
        marginBottom: "10px",
        borderBottom: bordered ? "1px solid #e0e0e0" : "none",
      }}
    >
      <p
        style={{
          fontWeight: "600",
          paddingTop: "5px",
          paddingBottom: "10px",
          paddingLeft: isMobile ? "0" : "20px",
        }}
      >
        {title}
      </p>
      {children}
    </div>
  );
};
