import { t } from "@lingui/macro";

export const daysOfWeek = () => [
  { label: t`Ponedeljek`, value: "monday" },
  { label: t`Torek`, value: "tuesday" },
  { label: t`Sreda`, value: "wednesday" },
  { label: t`Četrtek`, value: "thursday" },
  { label: t`Petek`, value: "friday" },
  { label: t`Sobota`, value: "saturday" },
  { label: t`Nedelja`, value: "sunday" },
];
