import { numberAndCountryCodeToFullPhoneNumber } from "@/Utilities";
import styled from "styled-components";

const Container = styled.div`
  opacity: 0;
  animation-duration: 0.25s;
  animation-delay: ${(props) => props.id * 0.05}s;
  animation-name: block-initial-animation;
  animation-fill-mode: forwards;

  //width: 100%;
  background: #eeeeee40;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: ${(props) =>
    props.selected === props.id ? "15px 0px" : "15px 0px"};

  padding-left: ${(props) => (props.selected === props.id ? "30px" : "20px")};

  //box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  overflow: hidden;
  box-shadow: 2px 5px 7px rgba(0, 0, 0, 0.1);

  &:hover {
    padding-left: 30px;
    box-shadow: 5px 15px 30px rgba(116, 181, 135, 0.25);
  }

  &:hover::before {
    width: 10px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.selected === props.id ? "10px" : "0px")};
    height: 100%;
    background: #74b587;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const LocationName = styled.label`
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  pointer-events: none;
  color: #0c2d1c;
`;

const Details = styled.label`
  font-size: 0.8rem;
  line-height: 1.1rem;
  pointer-events: none;

  color: #4f5464;
`;

const LocationBlock = (props) => {
  const {
    label,
    address,
    city,
    gsm,
    locationId,
    startTime,
    endTime,
    timeZone,
    timeStep,
    country,
    showHolidays,
    disableOnHolidays,
    paymentCurrency,
    countryCode,
    defaultLanguage,
  } = props.location;

  return (
    <Container
      selected={props.selected}
      id={props.id}
      onClick={() => {
        props.updateStates({
          locationId,
          label,
          address,
          city,
          gsm: numberAndCountryCodeToFullPhoneNumber(gsm, countryCode) ?? "",
          startTime,
          endTime,
          timeZone,
          timeStep,
          country,
          showHolidays,
          disableOnHolidays,
          paymentCurrency,
          defaultLanguage,
        });

        props.setSelected(props.id);
      }}
    >
      <LocationName>{label}</LocationName>
      <Details>{address}</Details>
      <Details>{city}</Details>
    </Container>
  );
};

export default LocationBlock;
