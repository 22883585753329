import { Box, Select, SelectProps } from "@mantine/core";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabel, LimeInputLabelProps } from "./LimeInputLabel";

export const LimeSelect = ({ ...props }: LimeInputLabelProps & SelectProps) => {
  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);

  return (
    <Box {...spacingProps} w={props.w || props.width}>
      <LimeInputLabel label={props.label} action={props.action} />

      <Select radius={8} {...propsWithoutSpacing} label={undefined} />
    </Box>
  );
};
