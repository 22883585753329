import { useQuery } from "@tanstack/react-query";
import { get } from "../request-helpers";
import { GetAppVersion, GetGlobalSettings } from "@/server-types";

export const useGetAppVersion = () => {
  return useQuery({
    queryKey: ["version"],
    queryFn: () => get<GetAppVersion>("/app/version"),
    refetchInterval: 60000,
  });
};

export const useGetGlobalSettings = (disabled?: boolean) => {
  return useQuery({
    queryKey: ["globalSettings"],
    queryFn: () => get<GetGlobalSettings>("/app/global-settings"),
    enabled: !disabled,
  });
};
