type GetFinalPriceCents = {
  pricingStrategy: "flat" | "bracketed" | "scheduled";
  priceCents: number;
  priceCentsTariff?: number;
  priceCentsBracket?: number;

  overrides: {
    pricingMultiplier: "perPerson" | "perResource";
    attendance: number;
    resourceCount: number;
  };
};

export function getFinalPriceCents({
  pricingStrategy,
  priceCents,
  priceCentsTariff,
  priceCentsBracket,

  overrides,
}: GetFinalPriceCents) {
  const price = (() => {
    if (pricingStrategy === "bracketed" && priceCentsBracket != null) {
      return priceCents + priceCentsBracket;
    }

    if (pricingStrategy === "scheduled" && priceCentsTariff != null) {
      return priceCents + priceCentsTariff;
    }

    return priceCents;
  })();

  if (pricingStrategy === "flat" || pricingStrategy === "bracketed") {
    if (overrides?.pricingMultiplier === "perPerson") {
      return Math.max(Math.floor(price * overrides.attendance), 0);
    }

    if (overrides?.pricingMultiplier === "perResource") {
      return Math.max(Math.floor(price * overrides.resourceCount), 0);
    }
  }

  return Math.max(Math.floor(price), 0);
}
