import { cn } from "@/utils";
import { useLingui } from "@lingui/react";
import { Space, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { capitalize } from "lodash";

const MonthDayBlock = ({
  date,
  onDateClick,
  gap = "0px",
  disableOnHolidays,
  isHoliday,
  ...props
}) => {
  const dateObject = dayjs(date);
  const dayNumber = dateObject.date();
  const isToday = dateObject.isSame(dayjs(), "day");
  const { i18n } = useLingui();
  const locale = i18n.locale;
  const dayName = capitalize(dayjs(date).locale(locale).format("dddd"));
  return (
    <div
      className={cn(
        "relative flex h-40 flex-col items-center overflow-hidden border border-gray-200 px-1 py-1 md:h-40 md:overflow-visible md:p-[5px]",
        {
          "bg-[repeating-linear-gradient(159.5deg,transparent,transparent_8px,#f3f4f7_8px,#f3f4f7_18px)]":
            disableOnHolidays,
        },
      )}
    >
      {isHoliday && (
        <Tooltip label={isHoliday} color="#7e96cd" withArrow withinPortal>
          <div className="h-5 w-full cursor-default rounded-xl bg-[#7e96cd] px-3 text-xs leading-5 text-[#fafafa]">
            {isHoliday}
          </div>
        </Tooltip>
      )}
      <button
        type="button"
        onClick={onDateClick}
        className="flex h-5 items-center justify-center rounded-xl p-2 transition-colors hover:bg-[#00000013]"
      >
        <p
          className={cn("text-xs font-medium text-[#2F2F2F]", {
            "text-[#5a9f6c]": isToday,
          })}
        >
          {dayName}
        </p>
        <Space w={4} />
        <p
          className={cn("text-sm font-semibold text-[#2F2F2F]", {
            "text-[#5a9f6c]": isToday,
          })}
        >
          {dayNumber}
        </p>
      </button>
      <div
        className={cn("flex w-full flex-col overflow-hidden pt-1", {
          [`gap-${gap}`]: gap,
        })}
      >
        {props.children}
      </div>
    </div>
  );
};

export default MonthDayBlock;
