import axios from "axios";

const prefix = import.meta.env.VITE_BE_HOST || "http://localhost:8080";

const getConfig = () => {
  const token = localStorage.getItem("usr_token");

  return {
    headers: {
      "x-access-token": token?.replaceAll('"', ""),
    },
  };
};

export const postRole = async (data) => {
  const config = {
    ...getConfig(),
  };
  return await axios.post(`${prefix}/user/role`, data, config);
};

export const patchRole = async (data) => {
  const config = {
    ...getConfig(),
  };
  return await axios.patch(`${prefix}/user/role/${data.roleId}`, data, config);
};

export const apiDeleteRole = async (roleId) => {
  const config = {
    ...getConfig(),
  };
  return await axios.delete(`${prefix}/user/role/${roleId}`, config);
};
