import { GetLocations, GetOrganizationInvoice } from "@/server-types";
import { t } from "@lingui/macro";
import { PrintData } from "qz-tray";
import QRCode from "qrcode";

export const buildReceiptCommands = async ({
  invoice,
  printerConfig,
  isCopy,
}: {
  invoice: GetOrganizationInvoice["response"];
  printerConfig: GetLocations["response"]["locations"][number]["PosPrinter"];
  isCopy: boolean;
}): Promise<PrintData[]> => {
  if (!invoice || !printerConfig) return [] as PrintData[];

  const encoder = new TextEncoder();

  const lineWidth = printerConfig.lineWidth;

  const itemNameMaxLength = lineWidth; // Entire line width for item name
  const itemPriceMaxLength = 12; // Length for price (Cena)
  const itemQuantityMaxLength = 4; // Length for quantity (Kol)
  const itemDiscountMaxLength = 7; // Length for discount (Popust)
  const itemTotalMaxLength = 9; // Length for total value (Vrednost)

  const taxRateMaxLength = 7; // Length for tax rate
  const taxNetPriceMaxLength = 9; // Length for tax net price
  const taxTotalMaxLength = 7; // Length for tax gross price
  const taxGrossPriceMaxLength = 9; // Length for tax gross price

  // Format a single item into two lines
  const formatItemLines = (
    name: string,
    price: string,
    quantity: number,
    discount: string,
    total: string,
  ) => {
    const namePadded = name
      .substring(0, itemNameMaxLength - 1)
      .padEnd(itemNameMaxLength - 1); // Ensure the name is left-aligned
    const pricePadded = price.padStart(itemPriceMaxLength);
    const quantityPadded = `${quantity}x`.padStart(itemQuantityMaxLength);
    const discountPadded = (discount || "").padStart(itemDiscountMaxLength);
    const totalPadded = total.padStart(itemTotalMaxLength);
    const secondLine = `${pricePadded}${quantityPadded}${discountPadded}${totalPadded}\n`;
    return `${namePadded}\n${secondLine}`;
  };

  const itemsArray = invoice.additionalReceiptData.invoiceData.items.map(
    (item) =>
      encoder.encode(
        formatItemLines(
          item.name, // Item name
          item.price.toFixed(2), // Cena
          item.quantity, // Kol
          item.discount ?? "", // Popust (empty string if not provided)
          item.totalWithTax.toFixed(2), // Vrednost
        ),
      ),
  );

  const flattenedItemsArray = itemsArray.reduce((acc, val) => {
    const newArray = new Uint8Array(acc.length + val.length);
    newArray.set(acc);
    newArray.set(val, acc.length);
    return newArray;
  }, new Uint8Array());

  // Format the tax details into a properly aligned string
  const formatTaxLine = (
    taxRate: string,
    netPrice: string,
    taxTotal: string,
    grossPrice: string,
  ): string => {
    const taxRatePadded = taxRate.padStart(taxRateMaxLength);
    const netPricePadded = netPrice.padStart(taxNetPriceMaxLength);
    const taxTotalPadded = taxTotal.padStart(taxTotalMaxLength);
    const grossPricePadded = grossPrice.padStart(taxGrossPriceMaxLength);
    return `${taxRatePadded}${netPricePadded}${taxTotalPadded}${grossPricePadded}\n`;
  };

  let totalNetPrice = 0;
  let totalTaxAmount = 0;
  let totalGrossPrice = 0;

  const invoiceTaxesArray =
    invoice.additionalReceiptData.invoiceData.invoiceTaxes.map((tax) => {
      const netPrice = parseFloat(tax.base);
      const taxAmount = parseFloat(tax.totalTaxAmount);
      const grossPrice = netPrice + taxAmount;

      // Accumulate totals
      totalNetPrice += netPrice;
      totalTaxAmount += taxAmount;
      totalGrossPrice += grossPrice;

      return encoder.encode(
        formatTaxLine(
          tax.taxRate.toFixed(1), // DDV %
          netPrice.toFixed(2), // Neto
          taxAmount.toFixed(2), // DDV
          grossPrice.toFixed(2), // Bruto
        ),
      );
    });

  const totalsLine = encoder.encode(
    formatTaxLine(
      "SKUPAJ:", // No tax rate for totals
      totalNetPrice.toFixed(2), // Total Neto
      totalTaxAmount.toFixed(2), // Total DDV
      totalGrossPrice.toFixed(2), // Total Bruto
    ),
  );

  const flattenedInvoiceTaxesArray = invoiceTaxesArray.reduce((acc, val) => {
    const newArray = new Uint8Array(acc.length + val.length);
    newArray.set(acc);
    newArray.set(val, acc.length);
    return newArray;
  }, new Uint8Array());

  // Encode a divider line
  const divider = encoder.encode("-".repeat(lineWidth - 1) + "\n");

  // Concatenate the divider and totals line to the flattened array
  const taxesWithDividerAndTotals = new Uint8Array(
    flattenedInvoiceTaxesArray.length + divider.length + totalsLine.length,
  );
  taxesWithDividerAndTotals.set(flattenedInvoiceTaxesArray);
  taxesWithDividerAndTotals.set(divider, flattenedInvoiceTaxesArray.length);
  taxesWithDividerAndTotals.set(
    totalsLine,
    flattenedInvoiceTaxesArray.length + divider.length,
  );

  const qrData = encoder.encode(invoice.additionalReceiptData.QR);
  const qrDataLength = qrData.length + 3;
  const rawQrCodeData = Uint8Array.from([
    // Center alignment
    0x1b,
    0x61,
    0x01,

    // Set QR code model
    0x1d,
    0x28,
    0x6b,
    0x04,
    0x00,
    0x31,
    0x41,
    0x32,
    0x00, // Model 2 (standard)

    // Set QR code size (increase to make it bigger)
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x43,
    0x06, // Size 6

    // Set QR code error correction level (49 = M)
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x45,
    0x31,

    // Store QR code data
    0x1d,
    0x28,
    0x6b,
    qrDataLength & 0xff, // pL
    (qrDataLength >> 8) & 0xff, // pH
    0x31,
    0x50,
    0x30, // cn, fn, m
    ...qrData,

    // Print QR code
    0x1d,
    0x28,
    0x6b,
    0x03,
    0x00,
    0x31,
    0x51,
    0x30,

    // Reset alignment to left
    0x1b,
    0x61,
    0x00, // Left alignment
  ]);

  const companyData = invoice.additionalReceiptData.companyData;
  const customerData = invoice.additionalReceiptData.customerData;

  const companyPrintCommands =
    companyData != null
      ? [
          ...encoder.encode(`${companyData.name}\n`),
          ...(companyData.address
            ? encoder.encode(`${companyData.address}\n`)
            : []),
          ...(companyData.zip
            ? encoder.encode(`${companyData.zip} ${companyData.city}\n`)
            : []),
          ...encoder.encode(
            `${companyData.isTaxSubject ? "ID za DDV" : "Davcna stevilka"}: ${companyData.taxNumber || "/"}\n`,
          ),
        ]
      : [];

  const customerPrintCommands =
    customerData != null
      ? [
          ...encoder.encode(`\n\n${customerData.name}\n`),
          ...(customerData.address
            ? encoder.encode(`${customerData.address}\n`)
            : []),
          ...(customerData.zip && customerData.city
            ? encoder.encode(`${customerData.zip} ${customerData.city}\n`)
            : []),
          ...encoder.encode(`ID za DDV: ${customerData.taxNumber || "/"}\n`),
        ]
      : [];

  const paymentMethodAmountsCommands =
    invoice.additionalReceiptData.payments.map((payment) => {
      const typePadded = getPaymentTypeLabel(
        payment.type as PaymentType,
      ).padEnd(lineWidth - 10); // Adjust padding based on your needs
      const amountPadded = payment.amount.toFixed(2).padStart(9); // Right align the amount
      return encoder.encode(`${typePadded}${amountPadded}\n`);
    });

  const flattenedPaymentMethodAmountsArray =
    paymentMethodAmountsCommands.reduce((acc, val) => {
      const newArray = new Uint8Array(acc.length + val.length);
      newArray.set(acc);
      newArray.set(val, acc.length);
      return newArray;
    }, new Uint8Array());

  const printCount = invoice.additionalReceiptData.printCount + 1;

  const sectionAboveQRCode: PrintData = {
    type: "raw",
    format: "command",
    flavor: "base64",
    data: Uint8Array.from([
      0x0a, // New line
      0x0a, // New line

      // Print company info in normal size
      0x1b,
      ...(printerConfig.printerType === "star_mi" ? [0x1d] : []), // star printer
      0x61, // star printer
      0x01, // star printer
      0x00, // Normal size
      ...companyPrintCommands,

      // Print customer info if exists
      ...customerPrintCommands,

      0x0a, // New line
      0x0a, // New line

      // Print copy if isCopy
      ...(isCopy && printCount > 1
        ? encoder.encode(`Kopija ${printCount - 1}\n\n\n`)
        : []),

      // Print RACUN ST. in bold and normal text size
      0x1b,
      0x45,
      0x01, // Turn on bold (ESC E 1)
      ...(invoice.canceled ? encoder.encode("STORNIRAN RACUN\n") : []),
      ...encoder.encode(`RACUN ST.: ${invoice.number}\n`),
      0x1b,
      0x45,
      0x00, // Turn off bold (ESC E 0)

      // turn off center alignment
      0x1b,
      ...(printerConfig.printerType === "star_mi" ? [0x1d] : []),
      0x61,
      0x00,

      0x0a, // New line

      // Print table header with fixed spacing
      ...encoder.encode("Artikel/Cena Kol Popust Vrednost\n"),
      0x1b,
      0x45,
      0x01, // Turn on bold (ESC E 1)
      ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),
      0x1b,
      0x45,
      0x00, // Turn off bold (ESC E 0)

      ...flattenedItemsArray,

      // iterate over invoice.invoiceData.items here

      0x1b,
      0x45,
      0x01, // Turn on bold (ESC E 1)
      ...encoder.encode("-".repeat(lineWidth - 1) + "\n"),
      0x1b,
      0x45,
      0x00, // Turn off bold (ESC E 0)

      // Print total
      ...encoder.encode(
        `SKUPAJ: ${invoice.totalWithoutDiscount.padStart(lineWidth - 9)}\n`,
      ),
      ...(invoice.totalDiscount != 0
        ? encoder.encode(
            `POPUST: ${`-${invoice.totalDiscountFormatted}`.padStart(lineWidth - 9)}\n`,
          )
        : []),
      // Print "ZA PLACILO" in normal size
      // Reset alignment to left
      0x1b,
      0x61,
      0x00, // Left alignment

      // Print "ZA PLACILO" in normal size
      ...encoder.encode(`ZA PLACILO ${invoice.currencyId.padEnd(5)}`),

      // Switch to larger text size for the total amount on the same line
      // 0x1b,
      // 0x21,
      // 0x30, // Double height and double width (ESC ! 0x30)
      ...encoder.encode(invoice.total.padStart(lineWidth - 17)),

      // Reset to normal text size
      0x1b,
      0x21,
      0x00, // Reset to normal text size

      0x0a, // New line
      0x0a, // New line

      ...flattenedPaymentMethodAmountsArray,

      0x0a, // New line

      // Print tax details
      ...encoder.encode(
        `--DDV %-----Neto----DDV----Bruto${"-".repeat(lineWidth - 33)}\n`,
      ),
      ...taxesWithDividerAndTotals,
      0x0a, // New line

      // Print seller info in a smaller size
      0x1b,
      0x21,
      0x01, // Slightly smaller text size
      // Print date and time
      ...encoder.encode(
        `${invoice.location.city ? invoice.location.city + ", " : ""}${invoice.date}\n`,
      ),
      ...(invoice.additionalReceiptData.employeeData?.name
        ? encoder.encode(
            `Racun izdal: ${invoice.additionalReceiptData.employeeData.name}\n`,
          )
        : []),
      0x1b,
      0x21,
      0x00, // Reset to normal text size

      0x0a, // New line

      // Print ZOI and EOR
      ...(invoice.additionalReceiptData.ZOI
        ? encoder.encode(`ZOI: ${invoice.additionalReceiptData.ZOI}\n`)
        : []),
      ...(invoice.additionalReceiptData.EOR
        ? encoder.encode(`EOR: ${invoice.additionalReceiptData.EOR}\n`)
        : []),
      0x0a, // New line
    ]),
  };

  const footerData: PrintData = await (async () => {
    if (printerConfig.printerType === "escpos_generic") {
      return {
        type: "raw",
        format: "command",
        flavor: "base64" as PrintData["flavor"],
        data: Uint8Array.from([
          ...rawQrCodeData,

          ...(companyData.isTaxSubject
            ? []
            : [
                0x01, // Slightly smaller text size
                ...encoder.encode(
                  "DDV ni obracunan na podlagi 1. odstavka 94. clena ZDDV-1.",
                ),
                0x00, // Reset to normal text size
                0x0a,
                0x0a,
              ]),

          // Print centered www.lime-booking.si website url
          // Center alignment for the website URL
          0x1b,
          0x61,
          0x01, // Center alignment (ESC a 1)
          ...encoder.encode("Hvala za obisk!"),
          0x0a,
          ...encoder.encode("www.lime-booking.si"),
          0x0a,

          // Add some space at the bottom and cut paper if supported
          0x0a,
          0x0a,
        ]),
      };
    }

    // 1. Generate QR Code as a Base64 Data URL (PNG image)
    const qrDataUrl = await QRCode.toDataURL(
      invoice.additionalReceiptData.QR || "",
      {
        width: 300,
      },
    );

    const htmlContent = `
      <div style="text-align: center;">
        <img src="${qrDataUrl}" style="width: 50%; height: auto;" />

        <br />
        <span style="font-size: 12px; font-family: Arial; margin-bottom: 0;">Hvala za obisk!</span>
        <br />
        <span style="font-size: 12px; font-family: Arial; margin-top: 0;">www.lime-booking.si</span>
      </div>
    `;

    return {
      type: "pixel",
      format: "html",
      data: htmlContent,
      flavor: "plain",
    };
  })();

  // const endCommands: PrintData = {
  //   type: "raw",
  //   format: "command",
  //   flavor: "base64",
  //   data: Uint8Array.from([
  //     // Add some space at the bottom and cut paper if supported
  //     0x0a, 0x0a, 0x0a, 0x0a, 0x0a, 0x0a, 0x0a, 0x0a,
  //     // ...(printerConfig.printerType === "star_mi" ? 0x1d : []),
  //     // 0x56,
  //     // 0x00, // ESC/P - Cut paper (optional, remove if not supported)

  //     // Cut paper (STAR MICRO)
  //     0x1b, 0x64, 0x00,
  //   ]),
  // };

  const printSections = (() => {
    if (printerConfig.printerType === "escpos_generic") {
      const printData: PrintData[] = [
        {
          type: "raw",
          format: "command",
          flavor: "base64",
          data: Uint8Array.from([
            ...(sectionAboveQRCode.data as Uint8Array),
            ...(footerData.data as Uint8Array),
          ]),
        },
      ];
      return printData;
    }

    console.log(sectionAboveQRCode, footerData);
    return [sectionAboveQRCode, footerData];
  })();

  return printSections;
};

export type PaymentType =
  | "bank"
  | "online"
  | "paypal"
  | "cash"
  | "card"
  | "coupon"
  | "crypto"
  | "other";
export const getPaymentTypeLabel = (paymentType: PaymentType) => {
  switch (paymentType) {
    case "bank":
      return t`Bancno nakazilo`;
    case "online":
      return t`Spletno placilo`;
    case "paypal":
      return t`PayPal`;
    case "cash":
      return t`Gotovina`;
    case "card":
      return t`Kartica`;
    case "coupon":
      return t`Darilni bon`;
    case "crypto":
      return t`Kriptovaluta`;
    case "other":
      return t`Ostalo`;
  }
};
