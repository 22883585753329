import { TextVariant } from "@/types/text-variants";
import { Text, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import styled from "styled-components";

const LimePhoneInputField = ({
  onChange,
  value = "",
  label,
  form,
  showError,
  background = "#f8f8f8",
  color = "#000000",
  border = "none",
  disabled,
  autocomplete,
  defaultCountry = "SI",
  disablePointerEvents,
}) => {
  const [isNumberValid, setIsNumberValid] = useState(false);

  useEffect(() => {
    if ((value && isValidPhoneNumber(value)) || value === "") {
      setIsNumberValid(true);
    } else {
      setIsNumberValid(false);
    }
  }, [value]);

  return (
    <Wrapper
      background={background}
      color={color}
      error={showError || (!isNumberValid && value !== "")}
      form={form}
      border={border}
      disabled={disabled}
      style={{ pointerEvents: disablePointerEvents ? "none" : undefined }}
    >
      {disabled ? (
        <>
          {label && (
            <Text c={"rgba(33, 37, 41)"} variant={TextVariant.BodyEmphasized}>
              {label}
            </Text>
          )}
          <a href={`tel:${value}`}>
            <PhoneInput
              data-identifier="phone-input"
              disabled={disabled}
              onChange={onChange}
              value={value}
              defaultCountry={defaultCountry}
              autoComplete={autocomplete}
              inputComponent={CustomInput}
            />
          </a>
        </>
      ) : (
        <>
          {" "}
          <Text c={"rgba(33, 37, 41)"} variant={TextVariant.BodyEmphasized}>
            {label}
          </Text>
          <PhoneInput
            data-identifier="phone-input"
            disabled={disabled}
            onChange={onChange}
            value={value}
            defaultCountry={defaultCountry}
            autoComplete={autocomplete}
            inputComponent={CustomInput}
          />
        </>
      )}
    </Wrapper>
  );
};

// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef(
  ({ value, onChange, onFocus, onBlur, ...rest }, ref) => (
    <TextInput
      ref={ref}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      {...rest}
      styles={{
        input: {
          border: "none",
          fontSize: "16px",
        },
      }}
    />
  ),
);

const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  text-align: left;

  label {
    text-align: left;
    color: ${(props) => (props.error ? "red" : "#3f3f3f")};
    display: inline;
  }

  input {
    border-radius: ${(props) => (props.form ? "6px" : "8px")};
    width: 100%;
    outline: none;
    border: ${(props) =>
      props.form ? "1px solid #C9CCCF" : `1px solid ${props.background}`};
    border: ${(props) => props.error && "1px solid rgba(255,0,0,0.3)"};
    height: 2.25rem;
    padding-left: calc(2.25rem / 3);
    padding-right: calc(2.25rem / 3);
    background: ${(props) =>
      props.form ? (props.disabled ? "#f1f3f5" : "#f8f8f8") : props.background};
    color: ${(props) => props.color};
    border: ${(props) => props.border};
    font-size: 0.875rem;

    ${(props) => props.disabled && "cursor: pointer;"}
    ${(props) => props.disabled && "color: #909296;"}
    opacity: ${(props) => props.disabled && "0.6"};
  }

  .PhoneInputCountry {
    border-radius: ${(props) => (props.form ? "6px" : "8px")};
    outline: none;
    border: ${(props) =>
      props.form ? "1px solid #C9CCCF" : `1px solid ${props.background}`};
    border: ${(props) => props.border};
    border: ${(props) => props.error && "1px solid rgba(255,0,0,0.3)"};
    height: 2.25rem;
    padding-left: calc(2.25rem / 3);
    padding-right: calc(2.25rem / 3);
    background: ${(props) =>
      props.form ? (props.disabled ? "#f1f3f5" : "#f8f8f8") : props.background};
    font-size: 0.875rem;

    opacity: ${(props) => props.disabled && "0.6"};
  }

  .PhoneInputCountrySelect {
    ${(props) => props.disabled && "cursor: pointer;"}
    ${(props) => props.disabled && "color: #909296;"}
  }
`;

export default LimePhoneInputField;
