import { GetSuppliers, PostSupplier } from "@/server-types";
import { useCustomMutation } from "../custom-mutation";
import { get, post } from "../request-helpers";
import { useCustomQuery } from "../custom-query";

export const usePostSupplier = () => {
  return useCustomMutation({
    mutationFn: (body: PostSupplier["body"]) =>
      post<PostSupplier>("/supplier", body),
  });
};

export const useGetSuppliers = (query: GetSuppliers["query"]) => {
  return useCustomQuery({
    queryKey: ["suppliers"],
    queryFn: () => get<GetSuppliers>("/supplier"),
  });
};
