import React, { useState } from "react";

const StateContext = React.createContext();

const StateProvider = (props) => {
  const [state, setState] = useState({});

  const handleStateChange = (data) => {
    setState(data);
  };

  return (
    <StateContext.Provider value={{ state, handleStateChange }}>
      {props.children}
    </StateContext.Provider>
  );
};

export { StateContext };

export default StateProvider;
