import { Drawer, type DrawerProps, Flex, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import React, { ReactElement } from "react";
import classes from "./LimeDrawer.module.css";

type LimeDrawerType = {
  opened: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  size?: DrawerProps["size"] | "fullScreen";
  buttons?: {
    save?: ReactElement;
    cancel?: ReactElement;

    delete?: ReactElement;
  };
  headerActions?: React.ReactNode;
  form?: {
    form: UseFormReturnType<any>; // eslint-disable-line
    onFormSubmit: (values: any) => void; // eslint-disable-line
  };
};

const LimeDrawer = ({
  opened,
  onClose,
  title,
  children,
  size = "lg",
  buttons,
  headerActions,
  form,
  ...props
}: LimeDrawerType & DrawerProps) => {
  const footer = buttons && (
    <Flex
      pos={"sticky"}
      bottom={0}
      align={"center"}
      justify={"flex-end"}
      bg={"white"}
      h={"100px"}
      p={"20px"}
      style={{ borderTop: "1px solid #eaebed", zIndex: 5 }}
    >
      <Flex w={"100%"} justify={"space-between"}>
        {buttons.delete && buttons.delete}
        <Flex justify={"flex-end"} gap={"1rem"}>
          {buttons.cancel && buttons.cancel}
          {buttons.save && buttons.save}
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <Drawer.Root
      opened={opened}
      onClose={onClose}
      position={"right"}
      padding={"xl"}
      size={size === "fullScreen" ? "100%" : size}
      classNames={size === "fullScreen" ? undefined : classes}
      transitionProps={{
        transition: "slide-left",
        duration: 250,
        timingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      }}
      lockScroll={false}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
      {...props}
    >
      <Drawer.Overlay opacity={0.35} blur={0} />
      <Drawer.Content
        className={"drawer-outer-content"}
        style={{ overflowY: "scroll" }}
      >
        <Drawer.Header>
          <Drawer.Title>
            <Text fw={500} size={"xl"}>
              {title}
            </Text>
          </Drawer.Title>
          {headerActions}
          {!headerActions && <Drawer.CloseButton />}
        </Drawer.Header>
        {form ? (
          <form onSubmit={form.form.onSubmit(form.onFormSubmit)}>
            <Drawer.Body>{children}</Drawer.Body>

            {footer}
          </form>
        ) : (
          <>
            <Drawer.Body>{children}</Drawer.Body>

            {footer}
          </>
        )}
      </Drawer.Content>
    </Drawer.Root>
  );
};

export default LimeDrawer;
