import axios from "axios";

const prefix = import.meta.env.VITE_BE_HOST || "http://localhost:8080";

const getConfig = () => {
  const token = localStorage.getItem("usr_token");

  return {
    headers: {
      "x-access-token": token.replaceAll('"', ""),
    },
  };
};

let createScheduleAbortController = new AbortController();
export const apiCreateSchedule = (data) => {
  createScheduleAbortController.abort();
  createScheduleAbortController = new AbortController();

  let config = getConfig();
  config = {
    ...config,
    signal: createScheduleAbortController.signal,
  };
  return axios.post(prefix + `/schedule`, data, config);
};

export const apiDeleteSchedule = (scheduleId) => {
  return axios.delete(prefix + `/schedule/${scheduleId}`, getConfig());
};

export const apiDeleteScheduleForUser = (userId, scheduleId) => {
  return axios.delete(
    prefix + `/schedule/${scheduleId}/shift/${userId}`,
    getConfig(),
  );
};

export const apiUpdateSchedule = (id, data) => {
  return axios.patch(prefix + `/schedule/${id}`, data, getConfig());
};

let getSchedulesAbortController = new AbortController();
export const apiGetSchedules = () => {
  getSchedulesAbortController.abort();
  getSchedulesAbortController = new AbortController();

  let config = getConfig();
  config = {
    ...config,
    signal: getSchedulesAbortController.signal,
  };
  return axios.get(prefix + `/schedule/`, config);
};

let getScheduleAbortController = new AbortController();
export const apiGetSchedule = ({ id, startDate, endDate }) => {
  getScheduleAbortController.abort();
  getScheduleAbortController = new AbortController();

  let config = getConfig();
  config = {
    ...config,
    params: {
      startDate: startDate,
      endDate: endDate,
    },
    signal: getScheduleAbortController.signal,
  };
  return axios.get(prefix + `/schedule/${id}`, config);
};

export const apiCreateBulkShifts = (scheduleId, data) => {
  return axios.put(prefix + `/schedule/${scheduleId}/shift`, data, getConfig());
};

export const apiCreateShiftBreak = (scheduleId, shiftId, data) => {
  return axios.post(
    prefix + `/schedule/${scheduleId}/break`,
    data,
    getConfig(),
  );
};

export const apiCreateShiftBlockedTime = (scheduleId, shiftId, data) => {
  return axios.post(
    prefix + `/schedule/${scheduleId}/blocked_time`,
    data,
    getConfig(),
  );
};

let getShiftsAbortController = new AbortController();
export const apiGetShifts = ({ scheduleId, startDate, endDate, userId }) => {
  getShiftsAbortController.abort();
  getShiftsAbortController = new AbortController();

  let config = getConfig();
  config = {
    ...config,
    params: {
      startDate: startDate,
      endDate: endDate,
      user: userId,
    },
    signal: getShiftsAbortController.signal,
  };
  return axios.get(prefix + `/schedule/${scheduleId}/shift`, config);
};
