import type { FormValues } from "./ManageService";

export const getBracketCounts = (brackets: FormValues["brackets"]) => {
  if (!brackets || brackets.length === 0) {
    return [
      {
        fromCount: 1,
        toCount: 1,
      },
    ];
  }

  let previousBracketToCount = 0;
  return brackets.map((bracket, _) => {
    const fromCount = previousBracketToCount + 1;
    const toCount = previousBracketToCount + bracket.count;

    previousBracketToCount = toCount;

    return {
      fromCount,
      toCount,
    };
  });
};
