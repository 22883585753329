import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { assignZIndexes } from "@/utils/appointment-utils";
import { useDroppable } from "@dnd-kit/core";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { isInPast } from "../../../utils";
import useDrag from "../../hooks/useDrag";
import AppointmentBlock from "../AppointmentBlock";
import classes from "./ResourceColumn.module.css";
import ShiftBlock from "../ShiftBlock";

export const ResourceColumn = ({
  resource,
  date,
  resourcesInOrder,
}: {
  resourcesInOrder: number[];
  resource: {
    id: number;
    resourceSchedule: {
      dayOfWeek:
        | "monday"
        | "tuesday"
        | "wednesday"
        | "thursday"
        | "friday"
        | "saturday"
        | "sunday";
      timeFrom: string;
      timeTo: string;
    }[];
  };
  date: string;
}) => {
  const {
    calendarData,
    setSelectedAppointmentId,
    setLastSelectedResourceId,
    setLastSelectedUserId,
    setLastSelectedTime,
    setLastSelectedDate,
  } = useCalendarStore((state) => state);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { calendarHeight, slotHeight, allAppointments } = calendarData;

  const { calculateTimeForYOffset } = useDrag();

  const isDateInPast = useMemo(() => isInPast(dayjs(date).toDate()), [date]);

  const previousResourceId =
    resourcesInOrder[resourcesInOrder.indexOf(resource.id) - 1];

  const { setNodeRef } = useDroppable({
    id: `appointmentsColumn-${date}-${resource.id}`,
    data: { date, resourceId: resource.id },
  });

  const filteredAppointments = useMemo(
    () =>
      (
        allAppointments?.filter(
          (appointment: {
            date: string;
            resources: number[];
            startTime: string;
            duration: number;
          }) =>
            dayjs(date).isBetween(
              dayjs(`${appointment.date}T${appointment.startTime}`),
              dayjs(`${appointment.date}T${appointment.startTime}`).add(
                appointment.duration,
                "minutes",
              ),
              "day",
              "[]",
            ) && appointment.resources?.includes(resource.id),
        ) || []
      ).map((a: { resources: number[] }) => {
        //calculate number of next consecutive resources
        const nextAppResources = a.resources.slice(
          a.resources.indexOf(resource.id),
        );
        const nextResourcesInOrder = resourcesInOrder.slice(
          resourcesInOrder.indexOf(resource.id),
        );

        let numberOfNextResourcesInOrder = 0;

        for (
          let i = 0;
          i < Math.min(nextAppResources.length, nextResourcesInOrder.length);
          i++
        ) {
          if (nextAppResources[i] === nextResourcesInOrder[i]) {
            numberOfNextResourcesInOrder++;
          } else {
            break;
          }
        }

        return {
          ...a,
          isOnPreviousResource: a.resources?.includes(previousResourceId),
          numberOfNextResources: numberOfNextResourcesInOrder,
        };
      }),
    [allAppointments, date, resource, previousResourceId, resourcesInOrder],
  );

  const appointments = useMemo(
    () => assignZIndexes(filteredAppointments),
    [filteredAppointments],
  );

  const dayOfWeek = useMemo(
    () => dayjs(date).locale("en").format("dddd"),
    [date],
  );

  const dayOfWeekPrev = dayjs(date)
    .subtract(1, "day")
    .locale("en")
    .format("dddd");

  const schedules = resource.resourceSchedule
    .filter(
      (rs) =>
        rs.dayOfWeek === dayOfWeek.toLocaleLowerCase() ||
        (rs.timeFrom >= rs.timeTo &&
          rs.dayOfWeek === dayOfWeekPrev.toLocaleLowerCase()),
    )
    .map((s) => {
      const isNextDaySchedule = s.dayOfWeek !== dayOfWeek.toLowerCase();

      const start = dayjs(date)
        .subtract(isNextDaySchedule ? 1 : 0, "day")
        .set("hour", Number(s.timeFrom.split(":")[0]))
        .set("minute", Number(s.timeFrom.split(":")[1]))
        .toDate();

      const end = dayjs(date)
        .subtract(isNextDaySchedule ? 1 : 0, "day")
        .set("hour", Number(s.timeTo.split(":")[0]))
        .set("minute", Number(s.timeTo.split(":")[1]))
        .add(s.timeFrom >= s.timeTo ? 1 : 0, "day")
        .toDate();

      return { ...s, start, end };
    });

  return (
    <div
      role="button"
      tabIndex={0}
      className={`${classes.resourceColumn} ${
        !permissions.manage_appointments ? classes.disabled : ""
      }`}
      style={{ height: calendarHeight, backgroundSize: `100% ${slotHeight}px` }}
      onClick={(e) => {
        if (!permissions.manage_appointments) return;
        e.stopPropagation();
        setLastSelectedResourceId(resource.id);
        setLastSelectedUserId(null);
        setSelectedAppointmentId(-1);

        const { currentTarget } = e;
        const rect = currentTarget.getBoundingClientRect();
        const yOffset = e.clientY - rect.top;

        const time = calculateTimeForYOffset(yOffset);
        const splitTime = time.split(":");
        const dateTime = dayjs(date)
          .set("hour", Number(splitTime[0]))
          .set("minute", Number(splitTime[1]))
          .format("YYYY-MM-DDTHH:mm");

        setLastSelectedTime(dateTime);
        setLastSelectedDate(date);
      }}
      onKeyDown={() => {}}
    >
      {isDateInPast && <div className={classes.pastOverlay}></div>}
      <div className={`${classes.appointments}`} ref={setNodeRef}>
        {appointments
          .filter(
            (a: { isOnPreviousResource: boolean }) => !a.isOnPreviousResource,
          )
          .map((appointment: unknown, _: number) => {
            return (
              <AppointmentBlock
                appointment={appointment}
                key={_}
                resource={resource.id}
                resourcesInOrder={resourcesInOrder}
                columnDate={date}
              ></AppointmentBlock>
            );
          })}
      </div>

      {/* {scheduleForDate && (
        <div
          style={{
            top: `calc(${scheduleForDate.yOffset}px + (5px / 2))`,
            height: `calc(${scheduleForDate.blockHeight}px - 5px)`,
          }}
          className={classes.schedule}
        ></div>
      )} */}

      {schedules.map((schedule, index) => (
        <ShiftBlock shift={schedule} date={date} key={index}></ShiftBlock>
      ))}
    </div>
  );
};
