import { TextVariant } from "@/types/text-variants";
import { Flex, Text } from "@mantine/core";

type StripePageHeaderTypes = {
  title: string;
  subtitle?: string;
  rightSection?: React.ReactNode;
};

export const StripePageHeader = ({
  title,
  subtitle,
  rightSection,
}: StripePageHeaderTypes) => {
  return (
    <>
      <Flex direction={"row"} justify={"space-between"} w={"100%"} mb={"md"}>
        <Flex direction={"column"}>
          <Text variant={TextVariant.Body}>{title}</Text>
          {subtitle && (
            <Text variant={TextVariant.Caption} c="var(--secondary-text)">
              {subtitle}
            </Text>
          )}
        </Flex>
        {rightSection}
      </Flex>
    </>
  );
};
