import { Checkbox, Flex, Text, Tooltip, UnstyledButton } from "@mantine/core";

import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import classes from "./CheckboxListItem.module.css";

export const CheckboxListItem = ({
  onClick,
  label,

  rightSection,
  isSelected,
}: {
  onClick: () => void;
  label: string;
  isSelected: boolean;

  rightSection?: React.ReactNode;
}) => {
  return (
    <Tooltip
      label={label}
      multiline
      w={300}
      bg={Color.Divider}
      c={"black"}
      hidden={label?.length < 120}
    >
      <UnstyledButton
        classNames={classes}
        data-selected={isSelected}
        onClick={onClick}
      >
        <Flex align={"center"} justify={"space-between"} w={"100%"} h={"100%"}>
          <Flex gap={"md"} align={"center"}>
            <Checkbox checked={isSelected} readOnly tabIndex={-1} size="xs" />
            <Text variant={TextVariant.Caption} ta={"left"} lineClamp={2}>
              {label}
            </Text>
          </Flex>

          {rightSection}
        </Flex>
      </UnstyledButton>
    </Tooltip>
  );
};
