import { PostCheckPromoCode } from "@/server-types";
import { useMutation } from "@tanstack/react-query";
import { post } from "../request-helpers";

/**
 * POST /promoCode/check
 *
 */

export const useCheckPromocode = () => {
  return useMutation({
    mutationFn: (body: PostCheckPromoCode["body"]) =>
      post<PostCheckPromoCode>("/promoCode/check", body),
  });
};
