import { Loader } from "@mantine/core";
import dayjs from "dayjs";
import styled from "styled-components";
import { useScheduleStore } from "../Stores/useScheduleStore";
import { GetDatesInRange } from "../Utils";
import DateRows from "./DayWeekComponents/DateRows";
import UserRows from "./DayWeekComponents/UserRows";

const DayWeekSchedule = ({ scheduleType, filteredUsers }) => {
  const { selectedScheduleData, selectedScheduleId, schedules } =
    useScheduleStore((state) => state);

  if (selectedScheduleData == null) return <Loader />;

  const schedule = schedules.find(
    (schedule) => schedule.scheduleId === selectedScheduleId,
  );

  const dates = GetDatesInRange(
    selectedScheduleData.date_from,
    selectedScheduleData.date_to,
  );

  const minDate = dayjs(schedule.date_from).utc(false);
  const maxDate = dayjs(schedule.date_to).utc(false);

  const disabledDates = dates.filter((date) => {
    return (
      date.isBefore(minDate, "day") || date.isAfter(maxDate, "day") /*||
      date.isBefore(dayjs().startOf("day"))*/
    );
  });

  return (
    <Wrapper>
      <DateRows disabledDates={disabledDates} dates={dates} />
      <UserRows
        scheduleType={scheduleType}
        dates={dates}
        disabledDates={disabledDates}
        filteredUsers={filteredUsers}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0.5rem 0;

  --block-height: 70px;

  .row {
    width: 100%;
    height: var(--block-height);
    border-bottom: 2px solid #eaebed;
  }

  .date-row {
    position: relative;

    .user-shift-text {
      text-align: left;
      padding-left: 10px;
      background: #fafafa;
      color: #6c7386;
      font-size: 0.8rem;
      font-weight: 600;
      height: 30px;

      div {
        line-height: 30px;
      }
    }
  }

  .date-column {
    padding: 5px 0;
  }

  .columns {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
`;

export const Column = styled.div`
  flex: 1;
  height: 100%;
  max-width: ${(props) => props.maxWidth};
  border-right: ${(props) =>
    props.noRightBorder ? "none" : "1px solid #eaebed"};

  @media (max-width: 768px) {
    &.desktop {
      display: none;
    }
  }
`;

export default DayWeekSchedule;
