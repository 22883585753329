import dayjs from "dayjs";
import { MdOutlineError } from "react-icons/md";
import { PiWarningFill } from "react-icons/pi";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";

export const getCalendarTimes = (
  start: string,
  end: string,
  timeStep: number,
) => {
  let startObject = dayjs(start, "HH:mm");
  const endObject = dayjs(end, "HH:mm");
  const times = [];
  while (startObject.isBefore(endObject)) {
    times.push(startObject.format("HH:mm"));
    startObject = startObject.add(timeStep, "minutes");
  }
  return times;
};

export const isInPast = (date: Date) => {
  return dayjs(date).isBefore(dayjs(), "day");
};

export const getStatusIcon = (status: "success" | "warning" | "error") => {
  switch (status) {
    case "success":
      return <TbSquareRoundedCheckFilled size=".85rem" />;
    case "warning":
      return <PiWarningFill />;
    case "error":
      return <MdOutlineError />;
    default:
      return null;
  }
};

export const getStartTimeOfServiceInLinked = ({
  selectedServices,
  index,
  startTime,
}: {
  selectedServices: {
    serviceId: number;
    startDuration: number | null;
    timeOffDuration: number | null;
    endDuration: number | null;
    users: number[];
    resources: number[];
    blockBeforeMins: number | null;
    blockAfterMins: number | null;
    removed: boolean;
  }[];
  index: number;
  startTime: string;
}) => {
  if (!selectedServices[index]) return null;

  const absoluteStart = dayjs(startTime);

  const offset = selectedServices
    .slice(0, index + 1)
    .filter((s) => !s.removed)
    .reduce((acc, service, i) => {
      const serviceDuration =
        i === index
          ? 0
          : (service.startDuration || 0) +
            (service.endDuration || 0) +
            (service.timeOffDuration || 0);

      if (i === 0) {
        return acc + serviceDuration;
      }

      const currentUsers = service.users || [];
      const currentResources = service.resources || [];

      const prevUsers = selectedServices[i - 1].users || [];
      const prevResources = selectedServices[i - 1].resources || [];

      const areUsersOverlapping = currentUsers.some((user) =>
        prevUsers.includes(user),
      );

      const areResourcesOverlapping = currentResources.some((resource) =>
        prevResources.includes(resource),
      );

      if (areUsersOverlapping || areResourcesOverlapping) {
        return (
          acc +
          serviceDuration +
          (service.blockBeforeMins || 0) +
          (selectedServices[i - 1].blockAfterMins || 0)
        );
      }

      return acc + serviceDuration;
    }, 0);

  return absoluteStart.add(offset, "minutes");
};
