import { getStatusIcon } from "@/pages/Dashboard/Calendar/utils";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Badge, Text } from "@mantine/core";

import classes from "./AlertChip.module.css";

type AlertChipProps = {
  text: string;
  variant: "success" | "warning" | "error";
  icon?: React.ReactNode;
};

const getColors = (variant: AlertChipProps["variant"]) => {
  switch (variant) {
    case "success":
      return {
        background: "#E6F5E8",
        color: Color.Success,
      };
    case "warning":
      return {
        background: "#FFFBEB",
        color: Color.Warning,
      };
    case "error":
      return {
        background: "#FEF2F2",
        color: Color.Error,
      };
    default:
      return {
        background: "white",
        color: "black",
      };
  }
};

export const AlertChip = ({ text, variant, icon }: AlertChipProps) => {
  const rightIcon = icon == null ? getStatusIcon(variant) : icon;

  const { background, color } = getColors(variant);

  return (
    <Badge
      rightSection={rightIcon}
      radius={4}
      styles={{
        root: {
          background: background,
          color: color,
          border: `1px solid ${color}`,
          padding: "0 6px",
          textTransform: "unset",
        },
        section: {
          display: "flex",
          alignItems: "center",
        },
      }}
      size={"md"}
      h={"fit-content"}
    >
      <Text
        variant={TextVariant.Caption}
        c="inherit"
        fw={500}
        classNames={classes}
      >
        {text}
      </Text>
    </Badge>
  );
};
