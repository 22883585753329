import {
  useCustomerDataForDateSpan,
  useRevenue,
} from "@/lib/api-client/paths/analytics";
import { Color } from "@/types/colors";
import { t } from "@lingui/macro";
import { Divider, Flex, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAnalyticsStore } from "../stores/useAnalyticsStore";

type DateChartType = {
  date: string;
  lastDate: string;
  [key: string]: number | string | undefined;
}[];

type GeneralDataType = {
  label: string;
  value: string | JSX.Element;
  valueSuffix?: string;
  valueIconLeft?: React.ReactNode;
  valueColor?: string;
}[];

export const useTabAppointments = () => {
  const { selectedLocations, dateRange } = useAnalyticsStore((state) => state);

  const [appointments, setAppointments] = useState({ all: 0, fromForm: 0 });
  const [customers, setCustomers] = useState({ all: 0, new: 0 });

  const [appointmentsPerDate, setAppointmentsPerDate] = useState<DateChartType>(
    [],
  );
  const [helperDataPerDate, setHelperDataPerDate] = useState<{
    firstFuture?: string;
  }>({});

  // box charts
  const [generalData, setGeneralData] = useState<GeneralDataType>([]);

  const { data: customersData, isLoading: isCustomersLoading } =
    useCustomerDataForDateSpan(
      {
        selectedLocations,
        dateRange: dateRange.map((date: Date) =>
          dayjs(date).format("YYYY-MM-DD"),
        ),
      },
      !!(selectedLocations?.length > 0 && dateRange[0] && dateRange[1]),
    );

  const { data: revenueData, isLoading: isRevenueLoading } = useRevenue(
    {
      selectedLocations,
      dateRange: dateRange.map((date: Date) =>
        dayjs(date).format("YYYY-MM-DD"),
      ),
    },
    !!(selectedLocations?.length > 0 && dateRange[0] && dateRange[1]),
  );

  useEffect(() => {
    if (
      !selectedLocations ||
      selectedLocations.length < 1 ||
      !dateRange[0] ||
      !dateRange[1]
    ) {
      setAppointmentsPerDate([]);
      setAppointments({ all: 0, fromForm: 0 });
    }
  }, [selectedLocations, dateRange]);

  useEffect(() => {
    if (!customersData) return;
    if (!revenueData) return;

    const prepareData = async () => {
      const { groupedByDate } = revenueData;

      let totalAppointments = 0;
      let appointmentsFromForm = 0;

      //setup data for date chart
      const dataForDateChart = groupedByDate.map((d) => ({
        date: dayjs(d.date).format("DD. MM."),
        lastDate: dayjs(d.lastMonth.date).format("DD. MM."),
        [t`Izbrano obdobje`]: !d.isInFuture ? d.countAppointments : undefined,
        [t`Preteklo obdobje`]: d.lastMonth.countAppointments,
        [t`Prihodnje obdobje`]: d.isInFuture ? d.countAppointments : undefined,
      }));

      const firstFutureDate = groupedByDate.find((d) => d.isInFuture)?.date;
      const firstFutureDateIndex = groupedByDate.findIndex((d) => d.isInFuture);

      if (firstFutureDateIndex > 0) {
        dataForDateChart[firstFutureDateIndex][t`Izbrano obdobje`] =
          dataForDateChart[firstFutureDateIndex][t`Prihodnje obdobje`];
      }

      setAppointmentsPerDate(dataForDateChart);
      setHelperDataPerDate({
        firstFuture: firstFutureDate
          ? dayjs(firstFutureDate).format("DD. MM.")
          : undefined,
      });

      groupedByDate.forEach((d) => {
        totalAppointments += d.countAppointments;
        appointmentsFromForm += d.countAppointmentsForm;
      });

      setAppointments({
        all: totalAppointments,
        fromForm: appointmentsFromForm,
      });

      let totalCustomers = 0;
      let newCustomers = 0;

      const { customers } = customersData;

      customers.forEach((c) => {
        if (c.isNew) {
          newCustomers++;
        }
        totalCustomers++;
      });

      setCustomers({
        all: totalCustomers,
        new: newCustomers,
      });

      const generalData: GeneralDataType = [];

      generalData.push({
        label: t`Skupaj terminov`,
        value: totalAppointments.toString(),
        valueColor: Color.PrimaryText,
      });

      generalData.push({
        label: t`Skupaj strank`,
        value: totalCustomers.toString(),
        valueColor: Color.PrimaryText,
      });

      generalData.push({
        label: t`Skupaj novih strank`,
        value: (
          <Flex gap={5}>
            <Text>{newCustomers.toString()}</Text>
            <Divider size={2} orientation="vertical"></Divider>
            <Text c={"#7E96CD"}>
              {((newCustomers / totalCustomers) * 100)
                .toFixed(2)
                .replaceAll(".", ",") || 0}{" "}
              %
            </Text>
          </Flex>
        ),
      });

      generalData.push({
        label: t`Termini preko spletne forme`,
        value: (
          <Flex gap={5}>
            <Text>{appointmentsFromForm.toString()}</Text>
            <Divider size={2} orientation="vertical"></Divider>
            <Text c={"#7E96CD"}>
              {((appointmentsFromForm / totalAppointments) * 100)
                .toFixed(2)
                .replaceAll(".", ",") || 0}{" "}
              %
            </Text>
          </Flex>
        ),
      });

      setGeneralData(generalData);
    };

    prepareData();
  }, [customersData, revenueData]);

  return {
    appointmentsPerDate,
    appointments,
    generalData,
    customers,
    helperDataPerDate,
    isCustomersLoading,
    isRevenueLoading,
  };
};
