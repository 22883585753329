import { useSidebarStore } from "@/stores/useSidebarStore";
import { ActionIcon } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { RiArrowLeftLine, RiMenu2Line } from "react-icons/ri";

type MobileSidebarToggleProps = {
  onMobileBackButtonClick?: () => void;
  withoutRightMargin?: boolean;
};

export const MobileSidebarToggle = ({
  onMobileBackButtonClick,
  withoutRightMargin,
}: MobileSidebarToggleProps) => {
  const { toggle: toggleSidebar } = useSidebarStore((state) => state);

  const isMobile = useMediaQuery("(max-width: 768px)");

  if (!isMobile) return null;

  return (
    <ActionIcon
      size={"40px"}
      variant={onMobileBackButtonClick ? "transparent" : "filled"}
      radius={"xl"}
      color={"brand"}
      mr={withoutRightMargin ? 0 : 30}
      onClick={() => {
        if (onMobileBackButtonClick) onMobileBackButtonClick();
        else toggleSidebar(true);
      }}
    >
      {onMobileBackButtonClick ? (
        <RiArrowLeftLine
          style={{ fontSize: "1.2rem", color: "var(--primary-text)" }}
        />
      ) : (
        <RiMenu2Line style={{ fontSize: "1.2rem" }} />
      )}
    </ActionIcon>
  );
};
