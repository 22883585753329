import { TextVariant } from "@/types/text-variants";
import { Flex, Text, Tooltip } from "@mantine/core";
import { Row } from "../../pages/Dashboard/Completed/Completed.styled";

const PageContentTableRow = ({
  object,
  selectRow,
  toggleSelectedObject,
  values,
  objectId,
  checkboxSelectors,
  isSelected,
  style = {},
}) => {
  return (
    <Row onClick={() => selectRow(object)} style={style}>
      {checkboxSelectors && (
        <td className={"checkbox"} style={{ padding: "8px 12px" }}>
          <button
            style={{ border: "none", background: "none" }}
            onClick={(e) => {
              e.stopPropagation();
              toggleSelectedObject(objectId);
            }}
          >
            <input type={"checkbox"} checked={isSelected} onChange={() => {}} />
          </button>
        </td>
      )}
      {values.map((value, index) => (
        <td
          key={index}
          style={{ padding: "8px 12px", borderBottom: "1px solid #ddd" }}
        >
          <Flex>
            {value.icons?.map((icon, iconIndex) => (
              <Tooltip key={iconIndex} label={icon?.tooltip}>
                <div
                  style={{
                    display: "inline-block",
                    color: "grey",
                    marginRight: "5px",
                    height: "16px",
                  }}
                >
                  {icon?.icon}
                </div>
              </Tooltip>
            ))}
            <Text variant={TextVariant.Body}>{value.value}</Text>
          </Flex>
        </td>
      ))}
    </Row>
  );
};

export default PageContentTableRow;
