import {
  Avatar,
  Button,
  Flex,
  NumberInput,
  Popover,
  Select,
  Switch,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";

import useAccountInfo from "@/hooks/useAccountInfo";
import { api } from "@/lib/api-client";
import type { GetClientDetails } from "@/server-types";
import { ImageUploadView } from "../ImageUploadView";
import { LuHistory } from "react-icons/lu";
import dayjs from "dayjs";

export const GeneralTab = ({
  selectedClientId,
  refetchPrice,
}: {
  selectedClientId: number;
  refetchPrice: () => void;
}) => {
  const { data, refetch } = api.admin.useClientDetails(selectedClientId);

  const [isEditingLogo, setIsEditingLogo] = useState(false);

  const accountInfo = useAccountInfo((state) => state.accountInfo);

  const { mutateAsync: loginAsAdmin } = api.admin.useLoginAsAdmin();

  const [newData, setNewData] = useState<
    (GetClientDetails["response"]["client"] & { initLoad: boolean }) | null
  >(null);

  const updateNewData = (
    key: keyof GetClientDetails["response"]["client"],
    value: string | number | boolean | null,
  ) => {
    setNewData((prev) => {
      if (!prev) return null;

      if (prev[key] === value) return prev;

      return { ...prev, [key]: value, initLoad: false };
    });
  };

  const { mutateAsync: updateClientDetails } =
    api.admin.useUpdateClientDetails();

  const { mutateAsync: uploadImage } = api.admin.useUploadClientLogo();

  const apiUpdateClientData = async () => {
    const { ...dataForUpdate } = newData;

    try {
      await updateClientDetails({
        clientId: selectedClientId,
        body: {
          ...dataForUpdate,
          internalBusinessName: dataForUpdate.internalBusinessName || undefined,
        },
      });

      notifications.show({
        message: "Podatki uspešno posodobljeni",
        color: "green",
      });

      refetchPrice();
    } catch (e) {
      notifications.show({
        message: "Napaka pri posodabljanju podatkov",
        color: "red",
      });
    }
  };

  useEffect(() => {
    setNewData(null);
  }, []);

  useEffect(() => {
    if (data) {
      setNewData({ ...data.client, initLoad: true });
    }
  }, [data]);

  if (!data || !newData) return null;

  const currentTierData = data.pricingTiers.find(
    (tier) => tier.pricingTierId === newData.pricingTierId,
  );

  const handleLogin = async () => {
    try {
      const response = await loginAsAdmin(selectedClientId);

      localStorage.setItem("usr_token", response.token);
      window.location.reload();
    } catch (e) {
      notifications.show({
        message: "Niste sistemski admin",
        color: "red",
      });
    }
  };

  return (
    <Flex
      style={{ border: "1px solid rgb(234, 234, 234)", borderRadius: "8px" }}
      p={15}
      mt={15}
      direction={"column"}
    >
      <Table>
        <Table.Tbody>
          {accountInfo.systemRole === "admin" && (
            <Table.Tr>
              <Table.Td>
                <Button onClick={handleLogin} variant="outline">
                  Infiltrate
                </Button>
              </Table.Td>
            </Table.Tr>
          )}
          {accountInfo.systemRole === "admin" && (
            <Table.Tr>
              <Table.Th>
                <Text> Račun aktiven</Text>
              </Table.Th>
              <Table.Td>
                <Switch
                  checked={!newData.isDeleted}
                  onChange={(e) =>
                    updateNewData("isDeleted", !e.target.checked)
                  }
                ></Switch>
              </Table.Td>
            </Table.Tr>
          )}
          <Table.Tr>
            <Table.Th>
              <Text>Email Ime Podjetja</Text>
            </Table.Th>
            <Table.Td>
              <TextInput
                value={newData.businessName}
                onChange={(e) => updateNewData("businessName", e.target.value)}
              ></TextInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <Text>Ime Podjetja</Text>
            </Table.Th>
            <Table.Td>
              <TextInput
                value={newData.internalBusinessName || undefined}
                onChange={(e) =>
                  updateNewData("internalBusinessName", e.target.value)
                }
              ></TextInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <Text> Datum obračuna</Text>
            </Table.Th>
            <Table.Td>
              <NumberInput
                value={newData.billingCycleDate}
                onChange={(e) => updateNewData("billingCycleDate", e)}
              ></NumberInput>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <Text> Sales guy</Text>
            </Table.Th>
            <Table.Td>
              <Select
                allowDeselect={false}
                value={newData.salesPerson}
                data={data.salesPeople}
                onChange={(e) => updateNewData("salesPerson", e)}
              ></Select>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <Text> Industrija</Text>
            </Table.Th>
            <Table.Td>
              <Select
                allowDeselect={false}
                value={newData.industry}
                data={data.industries}
                onChange={(e) => updateNewData("industry", e)}
              ></Select>
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>
              <Text>Sender ID</Text>
            </Table.Th>
            <Table.Td>
              <TextInput
                value={newData.smsId || ""}
                onChange={(e) => updateNewData("smsId", e.target.value)}
              ></TextInput>
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Th>
              <Text>2FA kanalček</Text>
            </Table.Th>
            <Table.Td>
              <Select
                data={["sms", "email"]}
                value={newData.twoFactorAuthChannel}
                onChange={(e) => {
                  if (e) {
                    updateNewData("twoFactorAuthChannel", e);
                  }
                }}
                allowDeselect={false}
              ></Select>
            </Table.Td>
          </Table.Tr>

          {accountInfo.systemRole === "admin" && (
            <Table.Tr>
              <Table.Th>
                <Text> Sistemska vloga</Text>
              </Table.Th>
              <Table.Td>
                <Select
                  allowDeselect={false}
                  value={newData.systemRole}
                  data={[
                    { value: "client", label: "Stranka" },
                    { value: "admin", label: "Admin" },
                    { value: "salesman", label: "Prodajalec" },
                  ]}
                  onChange={(e) => updateNewData("systemRole", e)}
                ></Select>
              </Table.Td>
            </Table.Tr>
          )}

          <Table.Tr>
            <Table.Th>
              <Text>Barve</Text>
            </Table.Th>
            <Table.Td>
              <Select
                data={
                  data.client.formConfigs.map((config) => ({
                    value: config.id.toString(),
                    label: config.hash,
                  })) || []
                }
                value={newData.rescheduleColorsFormId?.toString()}
                onChange={(e) => updateNewData("rescheduleColorsFormId", e)}
                allowDeselect={true}
              ></Select>
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Th>
              <Text>NORMAL default form config</Text>
            </Table.Th>
            <Table.Td>
              <Select
                data={
                  data.client.formConfigs
                    .filter((config) => config.formType !== "prescheduled")
                    .map((config) => ({
                      value: config.id.toString(),
                      label: config.hash,
                    })) || []
                }
                value={newData.rescheduleDefaultNormalFormId?.toString()}
                onChange={(e) =>
                  updateNewData("rescheduleDefaultNormalFormId", e)
                }
                allowDeselect={true}
              ></Select>
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Th>
              <Text>PRESCHEDULED default form config</Text>
            </Table.Th>
            <Table.Td>
              <Select
                data={
                  data.client.formConfigs
                    .filter((config) => config.formType === "prescheduled")
                    .map((config) => ({
                      value: config.id.toString(),
                      label: config.hash,
                    })) || []
                }
                value={newData.rescheduleDefaultPrescheduledFormId?.toString()}
                onChange={(e) =>
                  updateNewData("rescheduleDefaultPrescheduledFormId", e)
                }
                allowDeselect={true}
              ></Select>
            </Table.Td>
          </Table.Tr>

          <Table.Tr
            onClick={() => setIsEditingLogo(true)}
            style={{ cursor: "pointer" }}
          >
            <Table.Td>
              <Text>Logo</Text>
            </Table.Td>
            <Table.Td>
              {data.client.fullLogoUrl != null && (
                <Avatar src={data.client.fullLogoUrl}></Avatar>
              )}
            </Table.Td>
          </Table.Tr>

          {isEditingLogo && (
            <ImageUploadView
              close={() => setIsEditingLogo(false)}
              existingImage={data.client.fullLogoUrl}
              onUploadImage={async (formData) => {
                await uploadImage({
                  body: formData,
                  params: { clientId: selectedClientId },
                });

                setIsEditingLogo(false);
                refetch();
              }}
              defaultCenterCrop={true}
              aspect={1 / 1}
            />
          )}

          <Table.Tr>
            <Table.Td>
              <Button onClick={apiUpdateClientData}>Shrani</Button>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Flex>
  );
};

const TierDetailRow = ({
  title,
  tierDetail,
  additional,
  handleChange,
  dataId,
}: {
  title: string;
  tierDetail: number;
  additional: number;
  dataId: keyof GetClientDetails["response"]["client"];
  handleChange: (
    key: keyof GetClientDetails["response"]["client"],
    value: number | string,
  ) => void;
}) => {
  return (
    <Table.Tr style={{ borderBottom: "none" }}>
      <Table.Td className="flex items-center gap-4">
        <Text>{title}</Text>
      </Table.Td>
      <Table.Td>
        <Flex align={"center"} gap={10}>
          <Text w={"5ch"}>{tierDetail}</Text>
          <Text>+</Text>
          <NumberInput
            value={additional}
            onChange={(e) => {
              if (dataId === "additionalPrice") {
                handleChange(dataId, Number(e) * 100);
              } else {
                handleChange(dataId, e);
              }
            }}
            w={"10ch"}
          ></NumberInput>
        </Flex>
      </Table.Td>
      <Table.Td w="15vh">
        <Flex gap={20}>
          <Text>Skupaj</Text>
          <Text>{(tierDetail || 0) + additional}</Text>
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
};
