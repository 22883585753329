import {
  DeleteProduct,
  GetProduct,
  GetProductCategories,
  GetProducts,
  PostProductCategory,
  PostProducts,
  PutProducts,
  GetProductCategory,
  PutProductCategory,
  DeleteProductCategory,
  GetProductStocks,
} from "@/server-types";
import { useCustomQuery } from "../custom-query";
import { del, get, post, put } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";

export const useGetProducts = (query: GetProducts["query"]) => {
  return useCustomQuery({
    queryKey: ["getProducts", query],
    queryFn: () => get<GetProducts>("/product", query),
  });
};

export const useGetProductCategories = (
  query: GetProductCategories["query"],
) => {
  return useCustomQuery({
    queryKey: ["getProductCategories", query],
    queryFn: () => get<GetProductCategories>("/product/category", query),
  });
};

export const useGetProductCategoryById = (id: number) => {
  return useCustomQuery({
    queryKey: ["getProductCategoryById", id],
    queryFn: () => get<GetProductCategory>(`/product/category/${id}`),
    enabled: id !== -1,
    retry: false,
  });
};

export const usePostProduct = () => {
  return useCustomMutation({
    mutationFn: (body: PostProducts["body"]) =>
      post<PostProducts>("/product", body),
  });
};

export const useGetProductById = (id: number) => {
  return useCustomQuery({
    queryKey: ["getProductById", id],
    queryFn: () => get<GetProduct>(`/product/${id}`),
    enabled: id !== -1,
    retry: false,
  });
};

export const usePutProduct = () => {
  return useCustomMutation({
    mutationFn: ({
      body,
      productId,
    }: {
      body: PutProducts["body"];
      productId: number;
    }) => put<PutProducts>(`/product/${productId}`, body),
    displayNotification: true,
  });
};

export const usePostProductCategory = () => {
  return useCustomMutation({
    mutationFn: (body: PostProductCategory["body"]) =>
      post<PostProductCategory>("/product/category", body),
  });
};

export const useDeleteProductById = () => {
  return useCustomMutation({
    mutationFn: (id: number) => del<DeleteProduct>(`/product/${id}`),
    displayNotification: true,
  });
};

export const usePutProductCategory = () => {
  return useCustomMutation({
    mutationFn: ({
      body,
      categoryId,
    }: {
      body: PutProductCategory["body"];
      categoryId: number;
    }) => put<PutProductCategory>(`/product/category/${categoryId}`, body),
    displayNotification: true,
  });
};

export const useDeleteProductCategoryById = () => {
  return useCustomMutation({
    mutationFn: (id: number) =>
      del<DeleteProductCategory>(`/product/category/${id}`),
    displayNotification: true,
  });
};

// product stocks

export const useGetProductStocks = (query: { locationId: number | null }) => {
  return useCustomQuery({
    queryKey: ["getProductStocks", query],
    queryFn: () =>
      get<GetProductStocks>(
        "/product/stock",
        query as GetProductStocks["query"],
      ),
    enabled: !!query.locationId,
  });
};
