import { useEffect, useState } from "react";

const initialState = {
  name: "",
  address: "",
  city: "",
  gsm: "",
  startTime: null,
  endTime: null,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  country: "SI",
  showHolidays: false,
  disableOnHolidays: false,
  paymentCurrency: "EUR",
  defaultLanguage: "sl",
};

const useModalLocation = (state = initialState) => {
  const [fields, setFields] = useState(JSON.parse(JSON.stringify(state)));
  const [modalOpened, setModalOpened] = useState(false);
  const [updateLocation, setUpdateLocation] = useState(false);

  useEffect(() => {
    if (!modalOpened) {
      setFields(JSON.parse(JSON.stringify(initialState)));
      setUpdateLocation(false);
    }
  }, [modalOpened]);

  const handleFieldChange = async (value, id) => {
    setFields((old) => {
      return {
        ...old,
        [id]: value,
      };
    });
  };

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const handleStartTimeChange = (value) => {
    console.log("cas ", value);
    setFields((old) => {
      return {
        ...old,
        startTime: value,
      };
    });
  };

  const handleEndTimeChange = (value) => {
    setFields((old) => {
      return {
        ...old,
        endTime: value,
      };
    });
  };

  const updateFields = async ({
    locationId,
    label,
    address,
    city,
    gsm,
    startTime,
    endTime,
    timeZone,
    country,
    disableOnHolidays,
    showHolidays,
    paymentCurrency,
    defaultLanguage,
  }) => {
    setFields((prevState) => {
      return {
        ...prevState,
        name: label,
        address: address,
        city: city,
        gsm: gsm,
        locationId: locationId,
        startTime,
        endTime,
        timeZone,
        country,
        disableOnHolidays,
        showHolidays,
        paymentCurrency,
        defaultLanguage,
      };
    });
    setModalOpened(true);
    setUpdateLocation(true);
  };
  return [
    fields,
    handleFieldChange,
    modalOpened,
    toggleModal,
    updateFields,
    updateLocation,
    handleStartTimeChange,
    handleEndTimeChange,
  ];
};

export default useModalLocation;
