import { api } from "@/lib/api-client";
import { ActionIcon, Table } from "@mantine/core";
import React from "react";
import { RiEditLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

const PricingTiers = () => {
  const { data: pricingTierData, isLoading } = api.admin.useGetPricingTiers();

  const navigate = useNavigate();

  if (isLoading) return <div>Loading...</div>;

  if (!pricingTierData) return <div>No data</div>;

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Tier Name</Table.Th>
          <Table.Th>Tier Price</Table.Th>
          <Table.Th>Število fičersov</Table.Th>
          <Table.Th>Uredi</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {pricingTierData?.pricingTiers.map((tier) => (
          <Table.Tr key={tier.pricingTierId}>
            <Table.Td>{tier.tierName}</Table.Td>
            <Table.Td>{tier.tierPriceCents / 100}</Table.Td>
            <Table.Td>{tier._count.pricing_tier_feature}</Table.Td>
            <Table.Td>
              <Link to={`${tier.pricingTierId}`}>
                <RiEditLine />
              </Link>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default PricingTiers;
