import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { apiGetUserPermissions } from "../apiCalls";

const useUserPermissionsStore = create(
  devtools((set, get) => ({
    defaultPermissions: {},
    permissions: {},
    setFullPermissions: (permissions) => set({ permissions }),
    setPermissions: (value, id) => {
      set((state) => ({
        permissions: {
          ...state.permissions,
          [id]: value,
        },
      }));
    },

    setDefaultPermissions: (permissions) => {
      set({ defaultPermissions: permissions });
    },

    initLoadPermissions: async () => {
      const token = localStorage.getItem("usr_token") || "";
      const response = await apiGetUserPermissions(token);

      const { permissions } = response.data;

      const _permissions = { ...get().permissions };

      for (const key in permissions) {
        _permissions[key] = permissions[key];
      }

      get().setFullPermissions(_permissions);
    },

    mergeUserPermissionsWithDefault: (permissions) => {
      const _permissions = { ...get().defaultPermissions };

      for (const key in permissions) {
        _permissions[key] = permissions[key];
      }

      return _permissions;
    },
  })),
);

export default useUserPermissionsStore;
