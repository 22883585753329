import { Divider } from "@mantine/core";
import styled from "styled-components";

export const Box = ({
  label,
  value,
  valueSuffix,
  valueIconLeft,
  valueColor,
}) => {
  return (
    <Wrapper valueColor={valueColor}>
      <span className="label">{label}</span>
      <Divider opacity={1} size={2}></Divider>
      <div className="value">
        {valueIconLeft} {value} {valueSuffix}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #f5f5f5;

  text-align: center;

  border-radius: 10px;

  justify-content: space-between;

  width: 90%;

  margin: auto;

  @media (min-width: 768px) {
    margin: unset;
    width: calc(50% - 30px);
  }

  @media (min-width: 1000px) {
    width: calc(25% - 30px);

    height: 100%;
  }

  .label {
    color: #8c8c8c;
    font-size: 14px;

    padding: 20px 18px 12px 18px;
  }

  .value {
    color: #548f68;
    font-size: 20px;
    padding: 12px 18px 20px 18px;

    display: flex;
    gap: 5px;

    align-items: center;
    justify-content: center;

    ${(props) => props.valueColor && `color: ${props.valueColor};`}
  }
`;
