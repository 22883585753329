import { useEffect } from "react";

const useEscape = (onEscape) => {
  function handleEsc(event) {
    if (event.keyCode === 27) {
      onEscape();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return null;
};

export default useEscape;
