import { Trans } from "@lingui/macro";
import {
  Accordion,
  Button,
  Divider,
  Flex,
  NumberInput,
  Text,
  rem,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { RiMoneyEuroCircleLine, RiTimeLine } from "react-icons/ri";

import { TextVariant } from "@/types/text-variants";
import classes from "../Schedule/Components/UserScheduleModalComponents/BulkDaysList.module.css";
import {
  formatCurrency,
  returnCurrencySymbol,
} from "../../../../../shared/utils/utils";
import { i18n } from "@lingui/core";

export const ModalManageCustomValuesForServices = ({
  services,
  customValuesForServices,
  setCustomValuesForServices,
  setIsManagingCustomValuesForServices,
  isBackButton,
  setIsBackButton,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const changeServicePrice = (value, serviceId) => {
    setCustomValuesForServices((prev) => {
      const _services = structuredClone(prev);
      const serviceIndex = _services.findIndex(
        (s) => s.serviceId === serviceId,
      );
      if (serviceIndex === -1) {
        _services.push({
          serviceId: serviceId,
          price: value,
        });
        return _services;
      } else {
        _services[serviceIndex].price = value;
        return _services;
      }
    });
  };

  const resetPrice = (serviceId) => {
    setCustomValuesForServices((prev) => {
      const _services = structuredClone(prev);
      const serviceIndex = _services.findIndex(
        (s) => s.serviceId === serviceId,
      );

      if (serviceIndex !== -1) {
        _services[serviceIndex].price = null;

        return _services;
      }
      return prev;
    });
  };

  const resetDuration = (serviceId) => {
    setCustomValuesForServices((prev) => {
      const _services = structuredClone(prev);
      const serviceIndex = _services.findIndex(
        (s) => s.serviceId === serviceId,
      );
      if (serviceIndex !== -1) {
        _services[serviceIndex].duration = null;
        return _services;
      }
      return prev;
    });
  };

  const changeServiceDuration = (value, serviceId) => {
    setCustomValuesForServices((prev) => {
      const _services = structuredClone(prev);
      const serviceIndex = _services.findIndex(
        (s) => s.serviceId === serviceId,
      );
      if (serviceIndex === -1) {
        _services.push({
          serviceId: serviceId,
          duration: value,
        });
        return _services;
      } else {
        _services[serviceIndex].duration = value;
        return _services;
      }
    });
  };

  const { locale } = i18n;

  return (
    <div>
      <Accordion classNames={classes} className={classes.root}>
        {services
          .filter((s) =>
            customValuesForServices.find((c) => c.serviceId === s.serviceId),
          )
          .map((service, index) => {
            let price = customValuesForServices?.find(
              (s) => s.serviceId === service.serviceId,
            )?.price;
            if (price === null || price === undefined) {
              price = service.price;
            }
            if (price === null || price === undefined) {
              price = 0;
            }

            let duration = customValuesForServices.find(
              (s) => s.serviceId === service.serviceId,
            )?.duration;
            if (duration === null || duration === undefined) {
              duration = service.duration * 1;
            }

            if (duration === null || duration === undefined) {
              duration = 0;
            }

            return (
              <Accordion.Item
                key={index}
                value={service.name}
                className={"accordion_item"}
              >
                <Accordion.Control>
                  <Flex justify={"space-between"}>
                    <Text variant={TextVariant.Body}> {service.name}</Text>
                    <Flex justify={"end"} gap={15}>
                      <Flex align={"center"} gap={5}>
                        <RiTimeLine />
                        <Text variant={TextVariant.Caption}>
                          {duration} min
                        </Text>
                      </Flex>
                      <Flex align={"center"} gap={5}>
                        <RiMoneyEuroCircleLine />
                        <Text variant={TextVariant.Caption}>
                          {formatCurrency({
                            amount: price,
                            currency: service.currency,
                            locale,
                          })}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  <Flex direction={"column"} gap={"md"}>
                    <Flex direction={"column"} gap={"10px"}>
                      <Divider></Divider>

                      <Flex justify={"space-between"} align={"center"}>
                        <Text variant={TextVariant.BodyEmphasized}>
                          <Trans>Urejanje cene</Trans>
                        </Text>
                        <Button
                          variant={"subtle"}
                          size="xs"
                          onClick={() => {
                            resetPrice(service.serviceId);
                          }}
                        >
                          <Trans> Ponastavi ceno</Trans>
                        </Button>
                      </Flex>
                      <Flex
                        direction={"row"}
                        gap={70}
                        align={"center"}
                        justify={"space-between"}
                      >
                        <Flex gap={10}>
                          <Text variant={TextVariant.Body}>
                            <Trans>Redna cena:</Trans>{" "}
                            {formatCurrency({
                              amount: service.price,
                              currency: service.currency,
                              locale,
                            })}
                          </Text>
                        </Flex>
                        <Flex align={"center"} gap={10}>
                          <Text variant={TextVariant.Body}>
                            <Trans>Cena po meri:</Trans>{" "}
                          </Text>
                          <NumberInput
                            precision={1}
                            min={0}
                            noClampOnBlur
                            hideControls={true}
                            value={price}
                            size="xs"
                            onChange={(value) => {
                              changeServicePrice(value, service.serviceId);
                            }}
                            styles={{ input: { width: "8ch" } }}
                            rightSection={
                              <Text variant={TextVariant.Caption}>
                                {returnCurrencySymbol({
                                  currency: service.currency,
                                })}
                              </Text>
                            }
                          ></NumberInput>{" "}
                        </Flex>
                      </Flex>
                    </Flex>

                    <Divider></Divider>

                    <Flex direction={"column"} gap={"10px"}>
                      <Flex justify={"space-between"} align={"center"}>
                        <Text variant={TextVariant.BodyEmphasized}>
                          <Trans>Urejanje trajanja</Trans>
                        </Text>
                        <Button
                          variant={"subtle"}
                          size="xs"
                          onClick={() => {
                            resetDuration(service.serviceId);
                          }}
                        >
                          <Trans>Ponastavi trajanje</Trans>
                        </Button>
                      </Flex>
                      <Flex
                        direction={"row"}
                        gap={70}
                        align={"center"}
                        justify={"space-between"}
                      >
                        <Flex gap={10}>
                          <Text variant={TextVariant.Body}>
                            <Trans>
                              Redno trajanje: {service.duration} min
                            </Trans>
                          </Text>
                        </Flex>
                        <Flex align={"center"} gap={10}>
                          <Text variant={TextVariant.Body}>
                            <Trans>Trajanje po meri</Trans>:{" "}
                          </Text>
                          <NumberInput
                            precision={1}
                            noClampOnBlur
                            hideControls={true}
                            value={duration}
                            size="xs"
                            onChange={(value) => {
                              changeServiceDuration(value, service.serviceId);
                            }}
                            styles={{ input: { width: "8ch" } }}
                            rightSection={
                              <Text variant={TextVariant.Caption}>min</Text>
                            }
                          ></NumberInput>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
      </Accordion>

      <Flex
        justify={isMobile ? "flex-start" : "flex-end"}
        direction={isMobile ? "column-reverse" : "row"}
        align={isMobile ? "initial" : "flex-end"}
        pt={rem(1)}
        gap={"md"}
      >
        <Button
          variant="white"
          onClick={() => {
            setIsManagingCustomValuesForServices(false);
          }}
        >
          {isBackButton || !isMobile ? (
            <Trans>Nazaj</Trans>
          ) : (
            <Trans>Prekliči</Trans>
          )}
        </Button>
        <Button
          onClick={() => {
            setIsManagingCustomValuesForServices(false);
            setIsBackButton(false);
          }}
        >
          <Trans>Nadaljuj</Trans>
        </Button>
      </Flex>
    </div>
  );
};
