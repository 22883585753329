import type { GetAppointmentsLog } from "@/server-types";
import { useQuery } from "@tanstack/react-query";
import { get } from "../request-helpers";

/**
 * GET /log/appointments
 */
export const useAppointmentsLog = (query: GetAppointmentsLog["query"]) => {
  return useQuery({
    queryKey: ["getAppointmentLog", query],
    queryFn: () => get<GetAppointmentsLog>("/log/appointments", query),
  });
};
