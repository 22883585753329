import { TextVariant } from "@/types/text-variants";
import { Button, Flex, Text } from "@mantine/core";
import { LimeTooltip } from "./LimeTooltip";

type ActionProps = {
  leftSection?: React.ReactNode;
  text: string;
  onClick: () => void;
  active?: boolean;
  identifier?: string;
};

export type LimeInputLabelProps = {
  label?: React.ReactNode;
  toolTip?: string;
  action?: ActionProps | React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isActionProps = (action: any): action is ActionProps => {
  return action?.text !== undefined && action?.onClick !== undefined;
};

export const LimeInputLabel = ({
  label,
  toolTip,
  action,
}: LimeInputLabelProps) => {
  const finalLabel =
    typeof label !== "string" ? (
      label
    ) : (
      <Text variant={TextVariant.BodyEmphasized}>{label}</Text>
    );

  const finalAction = isActionProps(action) ? (
    <Button
      variant="light"
      onClick={(action as ActionProps).onClick}
      size="xs"
      w={"fit-content"}
      leftSection={(action as ActionProps).leftSection}
      radius={"xl"}
      color={
        action.active != null ? (action.active ? "brand" : "gray") : undefined
      }
      data-identifier={(action as ActionProps).identifier}
    >
      {(action as ActionProps).text}
    </Button>
  ) : (
    action
  );

  return (
    <>
      {(finalLabel || action) && (
        <Flex w={"100%"} justify={"space-between"} align={"center"} mb={6}>
          <Flex align={"center"}>
            {finalLabel}
            <LimeTooltip label={toolTip} />
          </Flex>
          {finalAction}
        </Flex>
      )}
    </>
  );
};
