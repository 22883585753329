import { EasyNumberInput } from "@/Components/EasyNumberInput";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { Trans, t } from "@lingui/macro";
import { Button, Divider, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useSearchParams } from "react-router-dom";
import { FormValues } from "./ManageService";

type Form = {
  label: string;
  price: number;
};

export const ManageTier = ({
  tier,
  handleSaveTier,
}: {
  tier: FormValues["tiers"][number] | undefined;
  handleSaveTier: (values: Form) => void;
}) => {
  const [_, setSearchParams] = useSearchParams();

  const form = useForm<Form>({
    initialValues: {
      label: tier?.label || "",
      price: tier?.price || 0,
    },

    validate: {
      label: (value) => {
        if (value.length === 0) {
          return "Vnesite ime";
        }
      },

      price: (value) => {
        if (value < 0) {
          return "Cena ne sme biti negativna";
        }
      },
    },
  });

  return (
    <form
      style={{ display: "contents" }}
      onSubmit={form.onSubmit(handleSaveTier)}
    >
      <Flex direction={"column"}>
        <LimeTextInput
          {...form.getInputProps("label")}
          label={t`Ime kategorije`}
          px={"md"}
        />

        <Divider my={"md"} />

        <EasyNumberInput
          label={t`Določite ceno kategorije`}
          {...form.getInputProps("price")}
          px={"md"}
          // toolTip="PLACEHOLDER"
        />

        <Divider my={"md"} />
      </Flex>

      <Flex
        align={"center"}
        justify={"center"}
        gap={"sm"}
        pos={"fixed"}
        bottom={"20px"}
        left={0}
        w={"100%"}
      >
        <Button
          variant="outline"
          onClick={() =>
            setSearchParams((searchParams) => {
              searchParams.delete("tierIdx");
              return searchParams;
            })
          }
        >
          <Trans>Prekliči</Trans>
        </Button>
        <Button type={"submit"}>
          <Trans>Shrani</Trans>
        </Button>
      </Flex>
    </form>
  );
};
