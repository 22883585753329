import { TextVariant } from "@/types/text-variants";
import { Skeleton, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  Content,
  Line,
  SkeletonLoaderRow,
  TopRow,
} from "../../pages/Dashboard/Completed/Completed.styled";
import PageContentTableRow from "./PageContentTableRow";

const DeviceColumnCounts = {
  mobile: 2,
  tablet: 3,
};

const PageContentTable = ({
  isMobile,
  objects,
  selectRow,
  toggleSelectedObject,
  toggleAllSelected,
  values = [],
  idValue,
  checkboxSelectors = true,
  isLoading,
  perPage,
  globalSelectedCustomers = [],
}) => {
  const isTablet = useMediaQuery("(max-width: 1200px)");

  let displayedValues = values;

  if (Array.isArray(displayedValues)) {
    if (isMobile) {
      displayedValues = displayedValues.map((value) =>
        value.slice(0, DeviceColumnCounts.mobile),
      );
    } else if (isTablet) {
      displayedValues = displayedValues.map((value) =>
        value.slice(0, DeviceColumnCounts.tablet),
      );
    }
  }

  return (
    <Content id="completed_table">
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <TopRow>
            {checkboxSelectors && <th style={{ padding: "8px" }} />}
            {displayedValues?.[0]?.map((value) => (
              <th
                key={value.label}
                style={{
                  padding: "8px 12px",
                  textAlign: "left",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <Text variant={TextVariant.Body}>{value.label}</Text>
              </th>
            ))}
            <Line />
          </TopRow>
        </thead>
        <tbody>
          {isLoading && (
            <>
              {Array.from(Array(perPage ?? 5).keys()).map((i) => (
                <SkeletonLoaderRow key={i}>
                  {Array.from(
                    Array(displayedValues?.[0]?.length ?? 5).keys(),
                  ).map((j) => (
                    <td key={j} style={{ padding: "18px 10px" }}>
                      <Skeleton height={10} mt={6} radius="xl" />
                    </td>
                  ))}
                </SkeletonLoaderRow>
              ))}
            </>
          )}
          {!isLoading &&
            objects &&
            displayedValues &&
            displayedValues.length > 0 &&
            objects.map((object, index) => (
              <PageContentTableRow
                selectRow={selectRow}
                object={object}
                key={index}
                toggleSelectedObject={toggleSelectedObject}
                values={displayedValues[index]}
                objectId={object[idValue]}
                checkboxSelectors={checkboxSelectors}
                isTablet={isTablet}
                isSelected={globalSelectedCustomers.some(
                  (c) => c.customerId === object[idValue],
                )}
                style={{ padding: "8px 12px", borderBottom: "1px solid #ddd" }}
              />
            ))}
        </tbody>
      </table>
    </Content>
  );
};

export default PageContentTable;
