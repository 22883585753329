import { GetLocations, GetOrganizationInvoice } from "@/server-types";
import { buildReceiptCommands } from "@/utils/pos-printer";
import { t } from "@lingui/macro";
import { notifications } from "@mantine/notifications";
import { create } from "zustand";
import qz from "qz-tray";
import rs, { hextorstr, stob64 } from "jsrsasign";

type PrinterType = "bluetooth" | "http" | "qztray";

type PosPrinterState = {
  cachedBluetoothPrinter: BluetoothDevice | undefined;
  isPrinting: boolean;
  selectedPrinterType: PrinterType;
  isQzTraySigningSetup: boolean;
  qzTrayClientCert: string | undefined;
  qzTrayClientKey: string | undefined;
};

type PosPrinterActions = {
  setPrinterType: (printerType: PrinterType) => Promise<void>;
  connectToDevice: ({
    shouldThrow,
  }: {
    shouldThrow?: boolean;
  }) => Promise<boolean>;
  printReceipt: ({
    invoice,
    isCopy,
    printerConfig,
    shouldThrow,
  }: {
    invoice: GetOrganizationInvoice["response"];
    isCopy: boolean;
    printerConfig: GetLocations["response"]["locations"][number]["PosPrinter"];
    shouldThrow?: boolean;
  }) => Promise<void>;
  setupQzTray: ({
    qzTrayClientCert,
    qzTrayClientKey,
  }: {
    qzTrayClientCert?: string;
    qzTrayClientKey?: string;
  }) => void;
};

type PosPrinterStore = PosPrinterState & PosPrinterActions;

export const usePosPrinterStore = create<PosPrinterStore>((set, get) => ({
  cachedBluetoothPrinter: undefined,
  isPrinting: false,
  selectedPrinterType: "qztray",
  isQzTraySigningSetup: false,
  qzTrayClientCert: undefined,
  qzTrayClientKey: undefined,

  setupQzTray: ({ qzTrayClientCert, qzTrayClientKey }) => {
    // if (get().isQzTraySigningSetup) {
    //   return;
    // }

    const key = qzTrayClientKey || get().qzTrayClientKey;
    const cert = qzTrayClientCert || get().qzTrayClientCert;

    if (!key || !cert) {
      console.error("QZ Tray client certificate or key is not set");
      return;
    }

    qz.security.setCertificatePromise((resolve, reject) => {
      resolve(cert);
    });
    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        try {
          const pk = rs.KEYUTIL.getKey(key);
          const sig = new rs.KJUR.crypto.Signature({
            alg: "SHA512withRSA",
          }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
          sig.init(pk);
          sig.updateString(toSign);
          const hex = sig.sign();
          // console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject("Signature error: " + err);
        }
      };
    });

    set({
      isQzTraySigningSetup: true,
      qzTrayClientCert,
      qzTrayClientKey,
    });
  },

  setPrinterType: async (printerType: PrinterType) => {
    if (printerType === get().selectedPrinterType) {
      return;
    }

    const previousType = get().selectedPrinterType;

    if (previousType === "bluetooth") {
      console.log("disconnecting from bluetooth");
      get().cachedBluetoothPrinter?.gatt?.disconnect();
    }

    if (previousType === "qztray") {
      console.log("disconnecting from qztray");
      await qz.websocket.disconnect();
      set({ isQzTraySigningSetup: false });
    }

    set({ selectedPrinterType: printerType });
  },

  connectToDevice: async ({ shouldThrow }) => {
    const { selectedPrinterType } = get();

    if (selectedPrinterType === "bluetooth") {
      if (!navigator.bluetooth) {
        notifications.show({
          message: t`Bluetooth is not supported in this browser`,
        });
        return false;
      }

      if (get().cachedBluetoothPrinter) {
        return true;
      }

      // Bluetooth connection
      try {
        const device = await navigator.bluetooth.requestDevice({
          filters: [{ services: ["e7810a71-73ae-499d-8c15-faa9aef0c3f2"] }],
        });
        set({ cachedBluetoothPrinter: device });
        return true;
      } catch (error) {
        console.error("Bluetooth connection error:", error);

        if (shouldThrow) {
          throw error;
        }

        return false;
      }
    } else if (selectedPrinterType === "qztray") {
      if (!get().isQzTraySigningSetup) {
        get().setupQzTray({});
      }

      // QZ Tray connection
      try {
        if (!qz.websocket.isActive()) {
          await qz.websocket.connect();
        }
        console.log("Connected to QZ Tray");

        return true;
      } catch (err) {
        console.error("Failed to connect to QZ Tray:", err);

        if (shouldThrow) {
          throw err;
        }

        return false;
      }
    }

    // For HTTP printers, no connection needed in advance
    return true;
  },

  printReceipt: async ({ invoice, isCopy, printerConfig, shouldThrow }) => {
    const { selectedPrinterType, cachedBluetoothPrinter: cachedPrinter } =
      get();
    console.log("printing with selectedPrinterType", selectedPrinterType);

    // Build the receipt data
    const commands = await buildReceiptCommands({
      invoice,
      printerConfig,
      isCopy,
    });

    if (selectedPrinterType === "bluetooth") {
      if (!cachedPrinter) {
        console.error("Bluetooth printer is not connected");
        return;
      }

      console.error("Bluetooth not supported");

      // try {
      //   const server = await cachedPrinter.gatt?.connect();
      //   const service = await server?.getPrimaryService(
      //     "e7810a71-73ae-499d-8c15-faa9aef0c3f2",
      //   );
      //   const characteristics = await service?.getCharacteristics();

      //   const chunkSize = 512; // Split data into chunks
      //   const chunks = [];
      //   for (let i = 0; i < commands.length; i += chunkSize) {
      //     chunks.push(commands.slice(i, i + chunkSize));
      //   }

      //   for (const characteristic of characteristics!) {
      //     for (const chunk of chunks) {
      //       await characteristic.writeValue(chunk);
      //     }
      //   }
      //   console.log("Bluetooth receipt sent");
      // } catch (error) {
      //   console.error("Error during Bluetooth print:", error);
      //   if (shouldThrow) {
      //     throw error;
      //   }
      // }
    } else if (selectedPrinterType === "qztray") {
      try {
        // const printers = await qz.printers.find();
        if (!printerConfig) {
          console.error(t`Printer config not provided`);
          if (shouldThrow) {
            throw new Error(t`Printer config not provided`);
          }
          return;
        }

        if (!qz.websocket.isActive()) {
          console.log("connecting to qztray");
          await qz.websocket.connect({
            retries: 5,
            delay: 1,
          });
        }

        console.log("Found printer:", printerConfig.printerName);
        const config = qz.configs.create(printerConfig.printerName, {
          copies: 1,
          jobName: `Receipt Print ${invoice.number}`,
          colorType: "blackwhite",
          duplex: false,
          margins: 0,
          rotation: 0,
        });

        for (const command of commands) {
          await qz.print(config, [command]);
        }

        console.log("Receipt printed via QZ Tray");
      } catch (error) {
        console.error("QZ Tray print error:", error);
        if (shouldThrow) {
          throw error;
        }
      }
    } else if (selectedPrinterType === "http") {
      console.error("HTTP NOT SUPPORTED YET");

      // HTTP printing logic (for a network printer with an API)
      // if (!httpPrinterUrl) {
      //   console.error("No HTTP printer URL configured");
      //   return;
      // }

      // try {
      //   const response = await fetch(httpPrinterUrl, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       commands, // Send the receipt commands as payload
      //     }),
      //   });

      //   if (!response.ok) {
      //     throw new Error("Failed to send print job over HTTP");
      //   }
      //   console.log("Receipt printed via HTTP");
      // } catch (error) {
      //   console.error("HTTP print error:", error);
      // }
    }

    // Update printing state
    set({ isPrinting: false });
  },
}));
