import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Box, Flex, Text } from "@mantine/core";

export const ValueDisplay = ({
  label,
  text,
}: {
  label: string;
  text: string;
}) => {
  return (
    <>
      <Text px={"md"} variant={TextVariant.BodyEmphasized} mt={"md"} mb={"xs"}>
        {label}
      </Text>
      <Box px={"md"}>
        <Flex
          align={"center"}
          justify={"center"}
          h={"50px"}
          style={{
            border: `1px solid ${Color.Border}`,
            borderRadius: "8px",
          }}
          bg={Color.TableHeader}
        >
          <Text variant={TextVariant.BodyEmphasized} c={Color.Disabled}>
            {text}
          </Text>
        </Flex>
      </Box>
    </>
  );
};
