import { Trans } from "@lingui/macro";
import { Button, Popover } from "@mantine/core";
import dayjs from "dayjs";
import styled from "styled-components";
import MonthDayBlock from "../../../../../Components/MonthDayBlock";
import { useCalendarStore } from "../../store/useCalendarStore";
import MonthAppointmentBlock from "./MonthAppointmentBlock";
const MonthCalendar = () => {
  const { calendarData, dateActions, filterItems } = useCalendarStore(
    (state) => state,
  );

  const { allAppointments } = calendarData;

  return (
    <Wrapper>
      {calendarData?.days?.map((day, _index) => {
        const { date } = day;

        const dayDateObject = dayjs(date);

        let dayAppointments = allAppointments.filter((appointment) => {
          const appointmentDate = dayjs(appointment.date);
          return (
            appointmentDate.date() === dayDateObject.date() &&
            appointmentDate.month() === dayDateObject.month() &&
            appointmentDate.year() === dayDateObject.year()
          );
        });

        // filter services and users
        dayAppointments = dayAppointments.filter((appointment) => {
          const isShownService = filterItems.services.includes(
            appointment.serviceId,
          );

          const isShownUser = filterItems.users.some((i) =>
            appointment.userIds.includes(i),
          );

          return isShownService && isShownUser;
        });

        const hasTooManyAppointments = dayAppointments.length > 3;

        return (
          <MonthDayBlock
            disableOnHolidays={day.disableOnHolidays}
            isHoliday={day.isHoliday}
            key={date}
            date={date}
            onDateClick={() =>
              dateActions.setSelectedDateAndView(dayjs(date), "day")
            }
          >
            <div
              className={
                "mb-1 flex w-full flex-1 flex-col justify-start gap-1 overflow-hidden"
              }
            >
              {dayAppointments
                .slice(0, hasTooManyAppointments ? 3 : 3)
                .map((appointment) => {
                  return (
                    <MonthAppointmentBlock
                      key={`s-${appointment.appointmentId}`}
                      appointment={appointment}
                    />
                  );
                })}
            </div>
            {hasTooManyAppointments && (
              <Popover
                shadow="md"
                position="bottom"
                width={200}
                withinPortal={true}
                withArrow={true}
              >
                <Popover.Target>
                  <Button size="xs" compact variant="light">
                    <Trans>Prikaži vse</Trans>...
                  </Button>
                </Popover.Target>
                <Popover.Dropdown className="z-10">
                  <div
                    className="flex flex-col gap-1"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {dayAppointments.slice(3).map((appointment) => (
                      <MonthAppointmentBlock
                        key={`a-${appointment.appointmentId}`}
                        appointment={appointment}
                      />
                    ))}
                  </div>
                </Popover.Dropdown>
              </Popover>
            )}
          </MonthDayBlock>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  padding-left: 0;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;

  .all-appointments {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .appointments {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    gap: 5px;
    justify-content: flex-start;
    flex: 1;
    margin-bottom: 5px;
  }

  .mantine-Popover-dropdown {
    z-index: 4;
    background: white;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(7, 1fr);
  }
`;

export default MonthCalendar;
