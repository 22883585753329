import { SketchPicker } from "@hello-pangea/color-picker";
import { ColorSwatch, Flex, Group, Text } from "@mantine/core";
import { BsCheck } from "react-icons/bs";
import { RiAddFill } from "react-icons/ri";

const ColorSwatches = ({
  predefinedColors,
  selectedColor,
  setSelectedColor,
  label,
  allowCustomColor,
}) => {
  const swatches = predefinedColors.map((color) => (
    <ColorSwatch
      key={color}
      color={color}
      onClick={() => setSelectedColor(color)}
      size={"50"}
      style={{ cursor: "pointer" }}
    >
      {selectedColor === color && <BsCheck color={"white"} size="2.5rem" />}
    </ColorSwatch>
  ));

  const isPredefinedColorSelected = predefinedColors.includes(selectedColor);

  return (
    <>
      <Flex direction={"column"} gap={label && "3px"}>
        {label && (
          <Text fz={"sm"} fw={500}>
            {label}
          </Text>
        )}
        <Group spacing={"xs"}>
          {swatches}
          {allowCustomColor && selectedColor && !isPredefinedColorSelected && (
            <ColorSwatch
              color={selectedColor}
              size={"50"}
              style={{ cursor: "not-allowed" }}
              onClick={() => {}}
            >
              <BsCheck color={"white"} fontSize={"2.5rem"} />
            </ColorSwatch>
          )}
          {allowCustomColor &&
            (selectedColor == null || isPredefinedColorSelected) && (
              <ColorSwatch
                color={"#D9D9D9"}
                size={"50"}
                style={{ cursor: "pointer" }}
                onClick={() => setSelectedColor("#FFFFFF")}
              >
                <RiAddFill color={"white"} fontSize={"2.5rem"} />
              </ColorSwatch>
            )}
        </Group>
      </Flex>
      {allowCustomColor && selectedColor && !isPredefinedColorSelected && (
        <SketchPicker
          color={selectedColor}
          onChange={(e) => setSelectedColor(e.hex)}
        />
      )}
    </>
  );
};

export default ColorSwatches;
