import { api } from "@/lib/api-client";
import { Table } from "@mantine/core";
import dayjs from "dayjs";
import React from "react";

const InvoicesTab = ({ clientId }: { clientId: number }) => {
  const {
    data,
    refetch: refetchBillingData,
    isFetching,
  } = api.admin.useGetInvoices(clientId);

  if (isFetching) return <div>Loading...</div>;

  if (!data) return <div>Error</div>;

  return (
    <div>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Invoice ID</Table.Th>
            <Table.Th>Document ID</Table.Th>
            <Table.Th>Invoice type</Table.Th>
            <Table.Th>Date Created</Table.Th>
            <Table.Th>Price tier</Table.Th>
            <Table.Th>Price notifications</Table.Th>
            <Table.Th>Price additional items</Table.Th>
            <Table.Th>Price total</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((invoice) => {
            const priceNotifications = invoice.InvoiceNotification.reduce(
              (acc, notification) => {
                return (
                  (acc + notification.priceCentsPer * notification.amount) / 100
                );
              },
              0,
            );

            const priceAdditionalItems =
              invoice.InvoiceAdditionalItem.reduce((acc, additionalItem) => {
                return acc + additionalItem.priceCentsTotal;
              }, 0) / 100 || 0;

            const priceTier = invoice.TierPeriod[0]?.priceCents / 100 || 0;

            const invoiceType = (() => {
              const types = [];
              if (invoice.TierPeriod[0]) {
                types.push("Tier");
              }
              if (invoice.InvoiceNotification[0]) {
                types.push("SMS");
              }
              return types.join(", ");
            })();

            return (
              <Table.Tr key={invoice.id}>
                <Table.Td>{invoice.id}</Table.Td>
                <Table.Td>{invoice.externalInvoiceId}</Table.Td>
                <Table.Td>{invoiceType}</Table.Td>
                <Table.Td>
                  {dayjs(invoice.dateCreated).format("DD. MM. YYYY")}
                </Table.Td>
                {/* <Table.Td>
                  {invoice.datePaid
                    ? dayjs(invoice.datePaid).format("DD. MM. YYYY")
                    : null}
                </Table.Td> */}
                <Table.Td>{priceTier}</Table.Td>
                <Table.Td>{priceNotifications}</Table.Td>
                <Table.Td>{priceAdditionalItems}</Table.Td>
                <Table.Td>
                  {priceTier + priceNotifications + priceAdditionalItems}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </div>
  );
};

export default InvoicesTab;
