import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import styled from "styled-components";
import { Box } from "./Box";

export const BoxValues = ({ data }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Wrapper>
      {isMobile ? (
        <Carousel
          slideSize={"100%"}
          loop
          withIndicators
          withControls={false}
          styles={{
            root: {
              height: "100%",
              maxWidth: "100%",
            },
            indicators: {
              bottom: "-15px",
            },
            indicator: {
              width: "7px",
              height: "7px",
              backgroundColor: "#E6F5E8",

              "&[data-active]": {
                backgroundColor: "#74B587",
              },
            },
          }}
        >
          {data.map((d, i) => {
            return (
              <Carousel.Slide key={i}>
                <Box {...d}></Box>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      ) : (
        data.map((d, i) => {
          return <Box {...d} key={i}></Box>;
        })
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;

    justify-content: space-between;

    flex-wrap: wrap;

    gap: 30px;
  }

  @media (min-width: 1000px) {
    justify-content: left;
  }
`;
