import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DeleteMessageSchedule,
  DeleteMessageTemplateById,
  GetMessageTemplate,
  GetMessageTemplateById,
  GetUserAppointmentMessageSchedule,
  PostMessageTemplate,
  PostUserAppointmentMessageSchedule,
  PutMessageTemplateById,
} from "../../../../../server/src/types";
import { del, get, post, put } from "../request-helpers";

/**
 *
 * POST /message/schedule/userAppointment
 */
export const usePostUserAppointmentMessageSchedule = () => {
  return useMutation({
    mutationKey: ["userAppointmentMessageSchedule"],
    mutationFn: (resource: PostUserAppointmentMessageSchedule["body"]) =>
      post<PostUserAppointmentMessageSchedule>(
        "/message/schedule/userAppointment",
        resource,
      ),
  });
};

/**
 *
 * GET /message/schedule/userAppointment
 */
export const useGetUserAppointmentMessageSchedule = (
  query: GetUserAppointmentMessageSchedule["query"],
) => {
  return useQuery({
    queryKey: ["userAppointmentMessages", query],
    queryFn: () =>
      get<GetUserAppointmentMessageSchedule>(
        "/message/schedule/userAppointment",
        query,
      ),
  });
};

/**
 *
 * DELETE /message/schedule/userAppointment
 */
export const useDeleteMessageScheduleById = () => {
  return useMutation({
    mutationKey: ["useDeleteMessageSchedule"],
    mutationFn: (id: number) =>
      del<DeleteMessageSchedule>(`/message/schedule/${id}`),
  });
};

/**
 * GET
 */
export const useGetMessageTemplate = (query: GetMessageTemplate["query"]) => {
  return useQuery({
    queryKey: ["getMessageTemplate", query],
    queryFn: () => get<GetMessageTemplate>("/message/template", query),
    gcTime: 0,
  });
};

/**
 *
 * POST /message/schedule/userAppointment
 */
export const usePostMessageTemplate = () => {
  return useMutation({
    mutationKey: ["postMessageTemplate"],
    mutationFn: (template: PostMessageTemplate["body"]) =>
      post<PostMessageTemplate>("/message/template", template),
  });
};

/**
 * GET BY ID
 */
export const getMessageByTemplateId = (id: number) => {
  return get<GetMessageTemplateById>(`/message/template/${id}`);
};

/**
 *
 * PUT /message/schedule/userAppointment
 */
export const usePutMessageByTemplateId = () => {
  return useMutation({
    mutationKey: ["postMessageTemplate"],
    mutationFn: ({
      id,
      template,
    }: {
      id: number;
      template: PutMessageTemplateById["body"];
    }) => put<PutMessageTemplateById>(`/message/template/${id}`, template),
  });
};

/**
 *
 * DELETE /message/schedule/userAppointment
 */
export const useDeleteMessageTemplateById = () => {
  return useMutation({
    mutationKey: ["deleteMessageTemplate"],
    mutationFn: ({ id }: { id: number }) =>
      del<DeleteMessageTemplateById>(`/message/template/${id}`),
  });
};
