import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Flex, Switch, SwitchProps, Text } from "@mantine/core";
import { useRef } from "react";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabelProps } from "./LimeInputLabel";
import { LimeTooltip } from "./LimeTooltip";

export const LimeSwitch = ({
  error,
  ...props
}: LimeInputLabelProps & SwitchProps) => {
  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);

  const ref = useRef<HTMLInputElement>(null);

  return (
    <Flex {...spacingProps} align={"center"} w={"inherit"}>
      <Flex
        align={"center"}
        w={"100%"}
        renderRoot={(props) => <label {...props} htmlFor={ref.current?.id} />}
      >
        <Flex direction={"column"}>
          <Text variant={TextVariant.BodyEmphasized}>{props.label}</Text>
          {error ? (
            <Text variant={TextVariant.Caption} c={Color.Error}>
              * {error}
            </Text>
          ) : null}
        </Flex>
        <LimeTooltip label={props.toolTip} />
      </Flex>
      <Switch ref={ref} {...propsWithoutSpacing} label={undefined} />
    </Flex>
  );
};
