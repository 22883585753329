import styled from "styled-components";

const ServiceAnalyticsList = ({ data }) => {
  const sum = data.reduce((a, b) => a + b.value, 0);
  return (
    <List>
      {data.map((item, index) => {
        return (
          <span key={index}>
            <ListItemName>{item.label}</ListItemName>
            <ListItemValue>
              ({Math.round((item.value / sum) * 100)}%)
            </ListItemValue>
          </span>
        );
      })}
    </List>
  );
};

const List = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const ListItemName = styled.label`
  font-size: 1.2rem;
  color: #424b64 !important;
`;

const ListItemValue = styled.label`
  font-size: 1.2rem;
  color: #969ba9 !important;
  margin-left: 0.5rem;
`;

export default ServiceAnalyticsList;
