import type { TimeType } from "@/../../shared/types/time-type";
import { TimeInput } from "@mantine/dates";
import { useEffect, useRef } from "react";
import { isSafari } from "react-device-detect";

export const ControlledTimeInput = ({
  timeValue,
  onTimeChange,
  minTime,
  maxTime,
  disabled,
}: {
  timeValue: TimeType | "";
  onTimeChange: (time: TimeType) => void;
  minTime?: TimeType;
  maxTime?: TimeType;
  disabled?: boolean;
}) => {
  const timeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let finalTime = timeValue;

    if (maxTime && finalTime && finalTime > maxTime) {
      finalTime = maxTime;

      onTimeChange(finalTime);
    }

    if (timeRef.current && timeValue != null)
      timeRef.current.value = finalTime.toString();
  }, [timeValue]);

  return (
    <TimeInput
      variant={"filled"}
      ref={timeRef}
      onChange={(event) => onTimeChange(event.currentTarget.value as TimeType)}
      min={minTime}
      max={maxTime}
      style={{
        width: isSafari ? "70px" : "unset",
      }}
      disabled={disabled}
    />
  );
};
