import { Box, TextInput, type TextInputProps } from "@mantine/core";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabel, type LimeInputLabelProps } from "./LimeInputLabel";

export const LimeTextInput = ({
  ...props
}: LimeInputLabelProps & TextInputProps) => {
  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);

  return (
    <Box {...spacingProps} w={props.w}>
      <LimeInputLabel {...props} />

      <TextInput
        styles={{
          label: {
            fontWeight: 500,
          },
        }}
        radius={8}
        {...propsWithoutSpacing}
        label={undefined}
      />
    </Box>
  );
};
