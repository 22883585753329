import dayjs from "dayjs";
import {
  RiFirstAidKitLine,
  RiPauseCircleLine,
  RiRestTimeLine,
  RiRestaurantFill,
  RiWalkFill,
} from "react-icons/ri";

export const ConvertResponseScheduleToSchedule = (schedule) => {
  const dateFromDayObject = dayjs(schedule.date_from);
  const dateToDayObject = dayjs(schedule.date_to);

  return {
    ...schedule,
    dateRange: [dateFromDayObject.toDate(), dateToDayObject.toDate()],
  };
};

export const ConvertScheduleToResponseSchedule = (schedule) => {
  return {
    ...schedule,
    dateRange: [
      dayjs(schedule.dateRange[0]).format("YYYY-MM-DD"),
      dayjs(schedule.dateRange[1]).format("YYYY-MM-DD"),
    ],
  };
};

export const GetShiftsForDate = (date, shifts) => {
  return shifts.filter(
    (shift) => shift.date.substring(0, 10) === date.format("YYYY-MM-DD"),
  );
};

export const GetShiftsForUsers = (userId, shifts) => {
  const _shifts = JSON.parse(JSON.stringify(shifts));

  _shifts.forEach((shift) => {
    shift.shifts = shift.shifts.filter((shift) => shift.userId === userId);
  });

  return _shifts.filter((shift) => shift.shifts.length > 0);
};

export const GetStartAndEndDate = (selectedDate, view) => {
  if (selectedDate == null) return { startDate: null, endDate: null };

  let startDate;
  let endDate;

  if (view === "week") {
    startDate = selectedDate.startOf("week").format("YYYY-MM-DD");
    endDate = selectedDate.endOf("week").format("YYYY-MM-DD");
  } else if (view === "month") {
    startDate = selectedDate.startOf("month");
    if (startDate.day() !== 1) {
      while (startDate.day() !== 1) {
        startDate = startDate.subtract(1, "day");
      }
    }
    startDate = startDate.format("YYYY-MM-DD");
    endDate = selectedDate.endOf("month").format("YYYY-MM-DD");
  } else if (view === "day") {
    startDate = selectedDate.format("YYYY-MM-DD");
    endDate = selectedDate.format("YYYY-MM-DD");
  }

  return { startDate, endDate };
};

export const GetDatesInRange = (dateStart, dateEnd) => {
  const dates = [];
  const startDate = dateStart;
  const endDate = dateEnd;
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = currentDate.add(1, "day");
  }
  return dates;
};

export const GetBreakIcon = (breakType) => {
  switch (breakType) {
    case "break":
      return <RiPauseCircleLine />;
    case "sick":
      return <RiFirstAidKitLine />;
    case "vacation":
      return <RiRestTimeLine />;
    case "lunch":
      return <RiRestaurantFill />;
    case "walkin":
      return <RiWalkFill />;
    default:
      return <RiRestTimeLine />;
  }
};

export const GetBreakColor = (breakName) => {
  switch (breakName) {
    case "lunch":
      return "#DFB81E";
    case "sick":
      return "#7E96CD";
    case "vacation":
      return "#74B587";
    case "break":
      return "#969BA9";
    case "walkin":
      return "#F94144";
    default:
      return "#969BA9";
  }
};
