import { api } from "@/lib/api-client";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { GetUserAppointmentList } from "../../../../server/src/types";

import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { getLocalizedName } from "@/utils";
import { Divider, Flex, Table, Text } from "@mantine/core";
import { FaRegComment } from "react-icons/fa";
import classes from "./ExportPdfModal.module.css";

type Appointment = GetUserAppointmentList["response"]["appointments"][0];

type GroupedAppointment = {
  date: string;
  appointments: Appointment[];
};

export const useExportPdfModal = () => {
  const ref = useRef(null);

  const { calendarMode } = useCalendarStore((state) => state);

  const [dateRange, setDateRange] = useState<(Date | null)[]>([
    dayjs().toDate(),
    dayjs().toDate(),
  ]);

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);

  const { selectedLocationId } = useCalendarStore((state) => state);

  const [userIds, setUserIds] = useState<number[]>([]);
  const [serviceIds, setServiceIds] = useState<number[]>([]);
  const [resourceIds, setResourceIds] = useState<number[]>([]);

  const [biggerRowSize, setBiggerRowSize] = useState<boolean>(false);

  const [contentToPrint, setContentToPrint] = useState<JSX.Element | null>(
    null,
  );

  const [modalOpened, setModalOpened] = useState(false);

  const { refetch } = api.appointments.useUserAppointmentList(
    {
      dateRange: dateRange
        .filter((date) => date !== null)
        .map((date) => dayjs(date).utc(true).format("YYYY-MM-DD")),
      userIds: calendarMode === "users" ? userIds : undefined,
      serviceIds,
      resourceIds: calendarMode === "resources" ? resourceIds : undefined,
      locationId: selectedLocationId,
    },
    true,
  );

  const { data: usersServicesData } =
    api.appointments.useUsersServicesForAppointments({});

  const print = useReactToPrint({
    content: () => ref.current,
    documentTitle: `LimeBooking - ${
      dayjs(dateRange[0]).format("DD.MM.YY") +
      (dayjs(dateRange[0]).isSame(dateRange[1], "day")
        ? ""
        : "-" + dayjs(dateRange[1]).format("DD.MM.YY"))
    }`,
  });

  useEffect(() => {
    if (!usersServicesData) return;

    setServiceIds(
      usersServicesData.services.map((service) => service.serviceId),
    );

    setUserIds(usersServicesData.users.map((user) => user.userId));

    setResourceIds(usersServicesData.resources.map((resource) => resource.id));
  }, [usersServicesData]);

  useEffect(() => {
    if (!contentToPrint) return;

    const container = document.getElementById("print-content");

    container?.style.setProperty("display", "block");
    print();
    container?.style.setProperty("display", "none");

    setContentToPrint(null);
    setModalOpened(false);
  }, [contentToPrint, print]);

  const handleExport = async () => {
    if (!ref) return;

    const response = await refetch();

    if (response.isError || !response.data) {
      // TODO handle error
      return;
    }

    const container = document.getElementById("print-content");
    if (!container) return;

    const { data } = response;

    const { appointments } = data;

    const groupedAppointments: GroupedAppointment[] = appointments.reduce(
      (acc: GroupedAppointment[], curr) => {
        const formattedDate = dayjs(curr.startTimeLocal).format("DD. MM. YYYY");

        // Find the existing date entry
        const existingDateEntry = acc.find(
          (entry) => entry.date === formattedDate,
        );

        if (existingDateEntry && existingDateEntry.appointments) {
          // If date entry exists, push the current appointment to its appointments array
          existingDateEntry.appointments.push(curr);
        } else {
          // If date entry doesn't exist, create a new one and initialize its appointments array
          acc.push({
            date: formattedDate,
            appointments: [curr],
          });
        }

        return acc;
      },
      [],
    );

    const defaultLanguage =
      preferredLanguage.userPreferredLanguage ||
      preferredLanguage.clientPreferredLanguage ||
      "sl";

    const content = (
      <Table p={20}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Th
              colSpan={12}
              className={classes["table-data"]}
              style={{ textAlign: "left" }}
            >
              {dayjs(dateRange[0]).format("DD. MM.")} -{" "}
              {dayjs(dateRange[1]).format("DD. MM.")}
            </Table.Th>
          </Table.Tr>
          {groupedAppointments.map((groupedAppointment) => (
            <>
              <Table.Tr style={{ background: "#F5F5F5", width: "100%" }}>
                <Table.Td colSpan={12} className={classes["table-data"]}>
                  <Text c={Color.PrimaryText} size={"12px"}>
                    {groupedAppointment.date}
                  </Text>
                </Table.Td>
              </Table.Tr>
              {groupedAppointment.appointments.map((appointment) => {
                if (!appointment.startTimeLocal) return null;

                return (
                  <>
                    <Table.Tr>
                      <Table.Td
                        className={classes["table-data"]}
                        style={{
                          width: "7ch",
                          // paddingBottom: biggerRowSize ? "70px" : "",
                        }}
                      >
                        <Text c={Color.PrimaryText} size={"9px"}>
                          {dayjs(appointment.startTimeLocal).format("HH:mm")}
                        </Text>
                      </Table.Td>
                      <Table.Td className={classes["table-data"]}>
                        <Text c={Color.PrimaryText} size={"9px"}>
                          {`${appointment.customer?.name} ${appointment.customer?.lastName}`}
                        </Text>
                      </Table.Td>
                      <Table.Td className={classes["table-data"]}>
                        <Text c={Color.PrimaryText} size={"9px"}>
                          {appointment.appointment.service.nameLocalized.find(
                            (nl) => nl.language === defaultLanguage,
                          )?.name ||
                            appointment.appointment.service.nameLocalized[0]
                              .name}
                        </Text>
                      </Table.Td>
                      <Table.Td className={classes["table-data"]}>
                        <Text c={Color.PrimaryText} size={"9px"}>
                          {appointment.customer?.email}
                        </Text>
                      </Table.Td>
                      <Table.Td className={classes["table-data"]}>
                        <Text c={Color.PrimaryText} size={"9px"}>
                          {appointment.customer?.gsm}
                        </Text>
                      </Table.Td>
                      <Table.Td className={classes["table-data"]}>
                        <Text c={Color.PrimaryText} size={"9px"}>
                          {appointment.appointment.appointmentUsers
                            .map((u) => `${u.user.name} ${u.user.lastName}`)
                            .join(", ")}
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                    {appointment.comment && (
                      <Table.Tr>
                        <Table.Td colSpan={1}>
                          <Text
                            mx={"md"}
                            size="9px"
                            c={Color.PrimaryText}
                            ta={"center"}
                          >
                            <FaRegComment />
                          </Text>
                        </Table.Td>
                        <Table.Td colSpan={11}>
                          <Text size={"9px"} c={Color.PrimaryText} w={"100%"}>
                            {appointment.comment}
                          </Text>
                        </Table.Td>
                      </Table.Tr>
                    )}

                    {/** EXTRA FIELDS */}
                    {appointment.extraDetails &&
                      Object.entries(appointment.extraDetails).length > 0 && (
                        <Table.Tr>
                          <Table.Td colSpan={2}>
                            {appointment.extraDetails.map((detail) =>
                              renderExtraField(detail),
                            )}
                          </Table.Td>
                        </Table.Tr>
                      )}

                    {biggerRowSize && (
                      <Table.Tr>
                        <Table.Td
                          style={{
                            paddingBottom: "70px",
                          }}
                        ></Table.Td>
                      </Table.Tr>
                    )}

                    <Table.Tr>
                      <Table.Td colSpan={12}>
                        <Divider mt={"xs"} />
                      </Table.Td>
                    </Table.Tr>
                  </>
                );
              })}
            </>
          ))}
        </Table.Tbody>
      </Table>
    );
    setContentToPrint(content);
  };

  const renderExtraField = (detail: Appointment["extraDetails"][number]) => {
    const renderValue = () => {
      if (detail.type === "radio" || detail.type === "select") {
        return (
          getLocalizedName(
            Object.entries(detail.value).map(([language, name]) => ({
              language,
              name,
            })),
          ) || "/"
        );
      }

      if (detail.type === "checkbox" || detail.type === "multi-select") {
        return detail.value
          .map(
            (value) =>
              getLocalizedName(
                Object.entries(value).map(([language, name]) => ({
                  language,
                  name,
                })),
              ) || "/",
          )
          .join(", ");
      }

      return detail.value.toString() || "/";
    };

    return (
      <Flex gap={"sm"}>
        <Text size={"xs"} c={Color.PrimaryText} w={"100%"}>
          {getLocalizedName(
            Object.entries(detail.label).map(([language, name]) => ({
              language,
              name,
            })),
          )}
        </Text>
        <Text size="xs" c={Color.SecondaryText} w={"100%"}>
          {renderValue()}
        </Text>
      </Flex>
    );
  };

  const selectAllUsers = () => {
    if (!usersServicesData) return;
    if (userIds.length === usersServicesData.users.length) {
      setUserIds([]);
    } else {
      setUserIds(
        usersServicesData.users.map((user: { userId: number }) => user.userId),
      );
    }
  };

  const selectAllServices = () => {
    if (!usersServicesData) return;
    if (serviceIds.length === usersServicesData.services.length) {
      setServiceIds([]);
    } else {
      setServiceIds(
        usersServicesData.services.map(
          (service: { serviceId: number }) => service.serviceId,
        ),
      );
    }
  };

  const selectAllResources = () => {
    if (!usersServicesData) return;
    if (resourceIds.length === usersServicesData.resources.length) {
      setResourceIds([]);
    } else {
      setResourceIds(
        usersServicesData.resources.map(
          (resource: { id: number }) => resource.id,
        ),
      );
    }
  };

  const toggleServiceSelection = (serviceId: number) => {
    if (serviceIds.includes(serviceId)) {
      setServiceIds((prev) => prev.filter((id) => id !== serviceId));
    } else {
      setServiceIds([...serviceIds, serviceId]);
    }
  };

  const toggleResourceSelection = (resourceId: number) => {
    if (resourceIds.includes(resourceId)) {
      setResourceIds((prev) => prev.filter((id) => id !== resourceId));
    } else {
      setResourceIds([...resourceIds, resourceId]);
    }
  };

  const toggleUserSelection = (userId: number) => {
    if (userIds.includes(userId)) {
      setUserIds((prev) => prev.filter((id) => id !== userId));
    } else {
      setUserIds([...userIds, userId]);
    }
  };

  return {
    ref,
    dateRange,
    setDateRange,
    userIds,
    serviceIds,
    print,
    usersServicesData,
    handleExport,
    selectAllUsers,
    selectAllServices,
    toggleServiceSelection,
    toggleResourceSelection,
    toggleUserSelection,
    modalOpened,
    setModalOpened,
    contentToPrint,
    biggerRowSize,
    setBiggerRowSize,
    selectAllResources,
    resourceIds,
  };
};
