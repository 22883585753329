import styled from "styled-components";
import { useScheduleStore } from "../../Stores/useScheduleStore";
import { GetShiftsForDate, GetShiftsForUsers } from "../../Utils";
import ExistingShiftBlock from "../ExistingShiftBlock";
import UserBlock from "./UserBlock";

const UserRows = ({ scheduleType, dates, filteredUsers, disabledDates }) => {
  const {
    selectedScheduleData,
    toggleScheduleModal,
    selectedScheduleViewType,
  } = useScheduleStore((state) => state);

  const shownUsers = filteredUsers.filter((user) => user.shown);

  return (
    <Wrapper>
      {shownUsers?.map((user) => {
        const userShifts = GetShiftsForUsers(
          user.userId,
          selectedScheduleData?.shifts,
        );

        return (
          <div key={user.userId} className={"row"}>
            <div className={"columns"}>
              <ShiftBlock
                maxWidth={"300px"}
                onClick={() => {
                  toggleScheduleModal({
                    userId: user.userId,
                    date: null,
                    opened: true,
                  });
                }}
              >
                <UserBlock user={user} viewType={selectedScheduleViewType} />
              </ShiftBlock>
              {dates.map((date) => {
                const dateUserShifts = GetShiftsForDate(date, userShifts);
                const shift = dateUserShifts?.at(0)?.shifts?.at(0);

                const isDisabled = disabledDates.includes(date);

                if (shift == null) {
                  return (
                    <ShiftBlock
                      noContent={true}
                      striped
                      key={date?.format("YYYY-MM-DD")}
                      style={{ color: "#424B64" }}
                      isDisabled={isDisabled}
                      onClick={() => {
                        if (isDisabled) return;

                        toggleScheduleModal({
                          userId: user.userId,
                          date: date,
                          opened: true,
                        });
                      }}
                    >
                      <ShiftBlockContent
                        isDisabled={isDisabled}
                        noContent={true}
                      ></ShiftBlockContent>
                    </ShiftBlock>
                  );
                }

                return (
                  <ShiftBlock
                    onClick={() => {
                      if (isDisabled) return;

                      toggleScheduleModal({
                        userId: user.userId,
                        date: date,
                        opened: true,
                      });
                    }}
                    key={date?.format("YYYY-MM-DD")}
                    isDisabled={isDisabled}
                  >
                    <ExistingShiftBlock
                      shift={shift}
                      scheduleType={scheduleType}
                    />
                  </ShiftBlock>
                );
              })}
            </div>
          </div>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ShiftBlock = styled.button`
  flex: 1;
  height: var(--block-height);
  border: none;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  max-width: ${(props) => props.maxWidth};
  flex-shrink: 0;

  border-right: ${(props) =>
    props.noRightBorder ? "none" : "1px solid #eaebed"};

  background-image: ${(props) =>
    props.striped &&
    `
      repeating-linear-gradient(159.5deg, transparent, transparent 8px, rgba(243, 244, 247, 1) 8px, rgba(243, 244, 247, 1) 11px );
      `};
  background-size: auto auto;
  background-color: rgba(255, 255, 255, 0);
  &:hover {
    ${(props) =>
      !props.isDisabled &&
      `
      background-image: none;
    `};
  }

  &:hover {
    background-color: ${(props) =>
      !props.noContent && !props.isDisabled && "#f6f6f8"};
  }
`;

export const ShiftBlockContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
  white-space: nowrap;

  .mantine-Popover-dropdown {
    padding: 0;
    border-radius: 12px;
  }

  .break-icon {
    position: absolute;
    top: -2px;
    right: -6px;
    z-index: 1;
    padding: 5px;
    font-size: 0.9rem;
  }

  &:hover {
    &:after {
      ${(props) =>
        props.noContent &&
        !props.isDisabled &&
        `
          opacity: 1;
          transform: scale(1);
        `};
    }
  }

  &:after {
    content: "+";
    color: #c4c4c4;
    font-size: 2.75rem;
    font-weight: 300;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

export default UserRows;
