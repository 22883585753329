import { Article } from "./POS";

/**
 * This function is also on the server in tax-register/organization/invoice/post.ts, please update both if changing
 */
export const calculateFinalArticlePrice = (
  article: Article,
  excludeDiscount?: boolean,
) => {
  if (article.discountAmountCents != null && !excludeDiscount) {
    return (
      (article.priceCents - article.discountAmountCents) * article.quantity
    );
  }

  if (article.discountPercentage != null && !excludeDiscount) {
    return (
      (article.priceCents -
        (article.priceCents * article.discountPercentage) / 100) *
      article.quantity
    );
  }

  return article.priceCents * article.quantity;
};

export const calculateFinalInvoicePrice = ({
  articles,
  invoiceDiscountAmountCents,
  invoiceDiscountPercentage,
  excludeDiscountsForArticleIds,
}: {
  articles: Article[];
  invoiceDiscountAmountCents?: number;
  invoiceDiscountPercentage?: number;
  excludeDiscountsForArticleIds?: number[];
}) => {
  const articlesFinalPrice = articles.reduce((acc, curr) => {
    return (
      acc +
      calculateFinalArticlePrice(
        curr,
        excludeDiscountsForArticleIds?.includes(curr.id),
      )
    );
  }, 0);

  if (invoiceDiscountAmountCents != null) {
    return articlesFinalPrice - invoiceDiscountAmountCents;
  }

  if (invoiceDiscountPercentage != null) {
    return (
      articlesFinalPrice -
      (articlesFinalPrice * invoiceDiscountPercentage) / 100
    );
  }
  return articlesFinalPrice;
};
