import { TextVariant } from "@/types/text-variants";
import { Button, Text } from "@mantine/core";
import classes from "./DayOfWeekButton.module.css";

export const TimeStepButton = ({
  text,
  onClick,
  selected,
}: {
  text: string;
  onClick: () => void;
  selected: boolean;
}) => {
  return (
    <Button
      onClick={onClick}
      variant={"filled"}
      color={"gray"}
      radius={"8px"}
      size={"sm"}
      h={"44px"}
      style={{
        background: selected ? "#e6f5e8" : "#f5f5f5",
        color: selected ? "var(--mantine-color-brand-6)" : "#8c8c8c",
        border: "0px solid var(--mantine-color-brand-6)",
        borderWidth: selected ? "1px" : "0px",
      }}
      px={14}
    >
      <Text variant={TextVariant.CaptionEmphasized}>{text}</Text>
    </Button>
  );
};
