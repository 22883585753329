import { LimeTextarea } from "@/Components/LimeTextarea";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { Button, Modal, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect, useRef, useState } from "react";
import { count } from "sms-length";
import styled from "styled-components";
import { apiPostBulkSms } from "./CompletedApi";

export const BulkSMSModal = ({
  selectedCustomerIds,
  opened,
  onToggle,
}: {
  selectedCustomerIds: number[];
  opened: boolean;
  onToggle: (opened: boolean) => void;
}) => {
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const [isSendingSms, setIsSendingSms] = useState(false);

  const tags = [
    { label: t`Ime stranke`, value: "{customerName}", length: 10 },
    { label: t`Priimek stranke`, value: "{customerLastName}", length: 10 },
    {
      label: t`Povezava za prenaročanje`,
      value: "{rescheduleLink}",
      length: 23,
    },
  ];

  const form = useForm({
    initialValues: {
      message: "",
    },
  });

  useEffect(() => {
    if (opened) form.reset();
  }, [opened]);

  const handleTagClick = (tagValue: string) => {
    const cursorPos = messageRef.current?.selectionStart ?? 0;

    form.setFieldValue(
      "message",
      form.values.message.slice(0, cursorPos) +
        tagValue +
        form.values.message.slice(cursorPos),
    );
  };

  const insertStringsOfTagsLengthInContent = (content: string | undefined) => {
    if (!content) return;

    let newContent = content;

    tags.forEach((tag) => {
      newContent = newContent.replaceAll(tag.value, "X".repeat(tag.length));
    });

    return newContent;
  };
  const contentWithTags = insertStringsOfTagsLengthInContent(
    form.values.message,
  );
  const smsInfo = count(replaceSumniki(contentWithTags ?? "") ?? "");

  const handleSendBulkSms = async () => {
    setIsSendingSms(true);
    try {
      await sendBulkSms({
        selectedCustomerIds,
        message: form.values.message,
      });
    } catch {
      notifications.show({
        title: t`Napaka`,
        message: t`Sporočila niso bila poslana`,
        color: "red",
      });
    }
    setIsSendingSms(false);
  };

  return (
    <Modal
      title={t`Pošlji SMS ${selectedCustomerIds.length}. strankam.`}
      opened={opened}
      onClose={() => onToggle(false)}
    >
      <ModalWrapper>
        <span>
          <Trans>Vnesite sporočilo:</Trans>
        </span>
        <div className={"tags"}>
          {tags.map((tag, idx) => (
            <button
              key={idx}
              className={"tag"}
              style={{
                border: "none",
              }}
              onClick={() => handleTagClick(tag.value)}
            >
              {tag.label}
            </button>
          ))}
        </div>
        <LimeTextarea
          ref={messageRef}
          {...form.getInputProps("message")}
          required
          label={t`Sporočilo`}
          autosize
          minRows={4}
          data-autofocus
        ></LimeTextarea>

        <Text variant={TextVariant.Caption} mt={"xs"}>
          <Trans>Porabljenih znakov</Trans>:{" "}
          {smsInfo.characterPerMessage - smsInfo.remaining} od{" "}
          {smsInfo.characterPerMessage} <Trans>v</Trans> {smsInfo.messages}{" "}
          <Trans>SMS sporočilih</Trans>
        </Text>

        <div className={"actions"}>
          <Button loading={isSendingSms} onClick={handleSendBulkSms}>
            <Trans>Pošlji</Trans>
          </Button>
        </div>
      </ModalWrapper>
    </Modal>
  );
};

const sendBulkSms = async ({
  selectedCustomerIds,
  message,
}: {
  selectedCustomerIds: number[];
  message: string;
}) => {
  notifications.show({
    title: t`Uspešno`,
    message: t`Sporočila bodo poslana`,
  });

  return await apiPostBulkSms(message, selectedCustomerIds);
};

const replaceSumniki = (string: string) => {
  return string
    .replaceAll("č", "c")
    .replaceAll("š", "s")
    .replaceAll("ž", "z")
    .replaceAll("Č", "C")
    .replaceAll("Š", "S")
    .replaceAll("Ž", "Z")
    .replaceAll("ć", "c")
    .replaceAll("đ", "dj")
    .replaceAll("Ć", "C")
    .replaceAll("Đ", "Dj");
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .tag {
    background-color: #f5f5f5;
    padding: 4px 10px;
    border-radius: 15px;
    color: #949494;

    font-size: 14px;

    cursor: pointer;

    &:hover {
      background-color: #ebf8ef;
      color: #74b587;
    }
  }
`;
