import dayjs from "dayjs";

export const getDateAppointments = (allAppointments, appointmentStartTime) => {
  return allAppointments?.filter(
    (appointment) =>
      dayjs(
        appointment.date + " " + appointment?.startTime,

        "YYYY-MM-DD HH:mm",
      ).format("YYYY-MM-DD") ===
      dayjs(appointmentStartTime).format("YYYY-MM-DD"),
  );
};

export const isAppointmentInPast = (startTime) => {
  if (!startTime) return false;

  return dayjs(startTime).isBefore(dayjs());
};

// get total duration of all selected services
export const getAppointmentEndTime = (startTime, selectedServices) => {
  let endTime = dayjs(startTime);
  if (selectedServices?.length > 0) {
    const totalDuration = selectedServices
      .filter((s) => !s.removed)
      .reduce((acc, service, i) => {
        const serviceDuration =
          (service.startDuration || 0) +
          (service.endDuration || 0) +
          (service.timeOffDuration || 0);
        if (i === 0) {
          return acc + serviceDuration;
        }

        const currentUsers = service.users || [];
        const currentResources = service.resources || [];

        const prevUsers = selectedServices[i - 1].users || [];
        const prevResources = selectedServices[i - 1].resources || [];

        const areUsersOverlapping = currentUsers.some((user) =>
          prevUsers.includes(user),
        );

        const areResourcesOverlapping = currentResources.some((resource) =>
          prevResources.includes(resource),
        );

        if (areUsersOverlapping || areResourcesOverlapping) {
          return (
            acc +
            serviceDuration +
            service.blockBeforeMins +
            (selectedServices[i - 1]?.blockAfterMins || 0)
          );
        }

        return acc + serviceDuration;
      }, 0);

    endTime = endTime.add(totalDuration, "minute");
  }
  return endTime.format("HH:mm");
};
