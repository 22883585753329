import { keepPreviousData } from "@tanstack/react-query";
import {
  PostOrganization,
  PutOrganization,
  PostOrganizationCertificate,
  PostOrganizationBusinessPremise,
  GetOrganizationBusinessPremise,
  GetOrganizationElectronicDevice,
  PostOrganizationElectronicDevice,
  GetOrganizationSetupProgress,
  GetOrganization,
  GetOrganizationInvoices,
  PostOrganizationInvoice,
  GetOrganizationInvoice,
  PostSendInvoiceEmail,
  PostCancelInvoice,
  DeleteOrganizationBusinessPremise,
  PutOrganizationElectronicDevice,
  PutOrganizationBusinessPremise,
  GetCashRegisterStatus,
  PostCashRegisterClosure,
  GetCashRegisterClosures,
  GetCashRegisterClosure,
  DeleteCashRegisterClosure,
  GetOrganizationTaxRegisterSettings,
  PostOrganizationTaxRegisterSettings,
  PutOrganizationInvoicePrintCount,
  PutInvoicePaymentMethod,
} from "@/server-types";
import { del, get, post, put } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";

export const usePutInvoicePaymentMethod = () => {
  return useCustomMutation({
    mutationFn: ({
      invoiceId,
      body,
    }: {
      invoiceId: string;
      body: PutInvoicePaymentMethod["body"];
    }) =>
      put<PutInvoicePaymentMethod>(
        `/tax-register/organization/invoice/${invoiceId}/change-payment-method`,
        body,
      ),
  });
};

export const usePutOrganizationInvoicePrintCount = () => {
  return useCustomMutation({
    mutationFn: (invoiceId: string | undefined) =>
      put<PutOrganizationInvoicePrintCount>(
        `/tax-register/organization/invoice/${invoiceId}/print-count`,
      ),
  });
};

export const useGetOrganizationTaxRegisterSettings = (
  query?: GetOrganizationTaxRegisterSettings["query"],
) => {
  return useCustomQuery({
    queryKey: ["getOrganizationTaxRegisterSettings", query],
    queryFn: () =>
      get<GetOrganizationTaxRegisterSettings>(
        "/tax-register/organization/settings",
        query,
      ),
  });
};

export const usePostOrganizationTaxRegisterSettings = () => {
  return useCustomMutation({
    mutationFn: (body: PostOrganizationTaxRegisterSettings["body"]) =>
      post<PostOrganizationTaxRegisterSettings>(
        "/tax-register/organization/settings",
        body,
      ),
  });
};

export const useGetCashRegisterClosures = ({
  businessPremiseId,
  query,
}: {
  businessPremiseId: string;
  query?: GetCashRegisterClosures["query"];
}) => {
  return useCustomQuery({
    queryKey: ["cashRegisterClosures", businessPremiseId, query],
    queryFn: () =>
      get<GetCashRegisterClosures>(
        `/tax-register/organization/business-premise/${businessPremiseId}/cash-register/close`,
        query,
      ),
  });
};

export const useDeleteCashRegisterClosure = ({
  businessPremiseId,
}: {
  businessPremiseId: string;
}) => {
  return useCustomMutation({
    mutationFn: (closureId: number) =>
      del<DeleteCashRegisterClosure>(
        `/tax-register/organization/business-premise/${businessPremiseId}/cash-register/close/${closureId}`,
      ),
  });
};

export const useGetCashRegisterClosure = ({
  businessPremiseId,
  closureId,
}: {
  businessPremiseId: string;
  closureId?: number;
}) => {
  return useCustomQuery({
    queryKey: ["cashRegisterClosure", businessPremiseId, closureId],
    queryFn: () =>
      get<GetCashRegisterClosure>(
        `/tax-register/organization/business-premise/${businessPremiseId}/cash-register/close/${closureId!}`,
      ),
    enabled: closureId != null,
  });
};

export const usePostCashRegisterClosure = () => {
  return useCustomMutation({
    mutationFn: ({ businessPremiseId }: { businessPremiseId: string }) =>
      post<PostCashRegisterClosure>(
        `/tax-register/organization/business-premise/${businessPremiseId}/cash-register/close`,
      ),
  });
};

export const useGetCashRegisterStatus = ({
  businessPremiseId,
  query,
}: {
  businessPremiseId: string;
  query?: GetCashRegisterStatus["query"];
}) => {
  return useCustomQuery({
    queryKey: ["cashRegisterStatus", businessPremiseId, query],
    queryFn: () =>
      get<GetCashRegisterStatus>(
        `/tax-register/organization/business-premise/${businessPremiseId}/cash-register/status`,
        query,
      ),
  });
};

export const useUpdateOrganization = () => {
  return useCustomMutation({
    mutationFn: ({ body }: { body: PutOrganization["body"] }) =>
      put<PutOrganization>("/tax-register/organization", body),
  });
};

export const useUpdateBusinessPremise = () => {
  return useCustomMutation({
    mutationFn: ({
      businessPremiseId,
      body,
    }: {
      businessPremiseId: string;
      body: PutOrganizationBusinessPremise["body"];
    }) =>
      put<PutOrganizationBusinessPremise>(
        `/tax-register/organization/business-premise/${businessPremiseId}`,
        body,
      ),
  });
};

export const useUpdateElectronicDevice = () => {
  return useCustomMutation({
    mutationFn: ({
      electronicDeviceId,
      body,
    }: {
      electronicDeviceId: string;
      body: PutOrganizationElectronicDevice["body"];
    }) =>
      put<PutOrganizationElectronicDevice>(
        `/tax-register/organization/electronic-device/${electronicDeviceId}`,
        body,
      ),
  });
};

export const useDeleteBusinessPremise = () => {
  return useCustomMutation({
    mutationFn: ({ businessPremiseId }: { businessPremiseId: string }) =>
      del<DeleteOrganizationBusinessPremise>(
        `/tax-register/organization/business-premise/${businessPremiseId}`,
      ),
  });
};

export const usePostCancelInvoice = () => {
  return useCustomMutation({
    mutationFn: ({
      invoiceId,
      body,
    }: {
      invoiceId: string;
      body: PostCancelInvoice["body"];
    }) =>
      post<PostCancelInvoice>(
        `/tax-register/organization/invoice/${invoiceId}/cancel`,
        body,
      ),
  });
};

/**
 * POST /tax-register/invoice/{invoiceId}/send
 */
export const usePostSendInvoiceEmail = () => {
  return useCustomMutation({
    mutationFn: ({
      invoiceId,
      body,
    }: {
      invoiceId: string;
      body: PostSendInvoiceEmail["body"];
    }) =>
      post<PostSendInvoiceEmail>(
        `/tax-register/organization/invoice/${invoiceId}/send-email`,
        body,
      ),
  });
};

/**
 * GET /tax-register/organization/invoice/{invoiceId}
 */
export const useGetOrganizationInvoice = (
  data: Omit<GetOrganizationInvoice["params"], "invoiceId"> & {
    invoiceId: string | undefined;
  },
) => {
  return useCustomQuery({
    queryKey: ["getOrganizationInvoice", data],
    queryFn: () =>
      get<GetOrganizationInvoice>(
        `/tax-register/organization/invoice/${data.invoiceId}`,
        data,
      ),
    enabled: data.invoiceId != undefined,
  });
};

/**
 * GET /tax-register/organization/invoice
 */
export const useGetOrganizationInvoices = (
  query: GetOrganizationInvoices["query"] & {
    disabled?: boolean;
  },
) => {
  return useCustomQuery({
    queryKey: ["getOrganizationInvoices", query],
    queryFn: () =>
      get<GetOrganizationInvoices>(`/tax-register/organization/invoice`, query),
    enabled: !query?.disabled,
  });
};

/**
 * POST /tax-register/organization/invoice
 */
export const usePostOrganizationInvoice = () => {
  return useCustomMutation({
    mutationFn: (body: PostOrganizationInvoice["body"]) =>
      post<PostOrganizationInvoice>(`/tax-register/organization/invoice`, body),
  });
};

/**
 * GET /tax-register/organization
 */
export const useGetOrganization = (query: GetOrganization["query"]) => {
  return useCustomQuery({
    queryKey: ["getOrganization", query],
    queryFn: () => get<GetOrganization>("/tax-register/organization", query),
    retry: false,
  });
};

/**
 * GET /tax-register/organization/setup-progress
 */
export const useGetOrganizationSetupProgress = (disabled?: boolean) => {
  return useCustomQuery({
    queryKey: ["getOrganizationSetupProgress", disabled],
    queryFn: () =>
      get<GetOrganizationSetupProgress>(
        "/tax-register/organization/setup-progress",
      ),
    placeholderData: keepPreviousData,
    enabled: !disabled,
  });
};

export const useGetOrganizationSetupProgressMutation = () => {
  return useCustomMutation({
    mutationFn: () =>
      get<GetOrganizationSetupProgress>(
        "/tax-register/organization/setup-progress",
      ),
  });
};

/**
 * POST /tax-register/organization
 */
export const usePostCreateOrganization = () => {
  return useCustomMutation({
    mutationFn: (body: PostOrganization["body"]) =>
      post<PostOrganization>("/tax-register/organization", body),
  });
};

/**
 * POST /tax-register/organization/{organizationId}/certificate
 */
export const usePostOrganizationCertificate = () => {
  return useCustomMutation({
    mutationFn: (body: FormData) =>
      post<PostOrganizationCertificate>(
        `/tax-register/organization/certificate`,
        body as unknown as { certificate: string; password: string }, // no bueno ampak bueno,
      ),
  });
};

/**
 * POST /tax-register/organization/businessPremise
 */
export const usePostOrganizationBusinessPremise = () => {
  return useCustomMutation({
    mutationFn: (body: PostOrganizationBusinessPremise["body"]) =>
      post<PostOrganizationBusinessPremise>(
        `/tax-register/organization/business-premise`,
        body,
      ),
    mutationKey: ["postOrganizationBusinessPremise"],
  });
};

/**
 * GET /tax-register/organization/businessPremise
 */
export const useGetOrganizationBusinessPremise = ({
  disabled,
}: {
  disabled?: boolean;
}) => {
  return useCustomQuery({
    queryKey: ["getOrganizationBusinessPremise"],
    queryFn: () =>
      get<GetOrganizationBusinessPremise>(
        `/tax-register/organization/business-premise`,
      ),
    enabled: !disabled,
  });
};

/**
 * GET /tax-register/organization/electronic-device
 */
export const useGetOrganizationElectronicDevice = (
  query: GetOrganizationElectronicDevice["query"] & {
    enabled?: boolean;
  },
) => {
  return useCustomQuery({
    queryKey: ["getOrganizationElectronicDevice", query],
    queryFn: () =>
      get<GetOrganizationElectronicDevice>(
        `/tax-register/organization/electronic-device`,
        query,
      ),
    enabled: query?.enabled,
  });
};

/**
 * POST /tax-register/organization/electronic-device
 */
export const usePostOrganizationElectronicDevice = () => {
  return useCustomMutation({
    mutationFn: (body: PostOrganizationElectronicDevice["body"]) =>
      post<PostOrganizationElectronicDevice>(
        `/tax-register/organization/electronic-device`,
        body,
      ),
    mutationKey: ["postOrganizationElectronicDevice"],
  });
};
