import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Button, Divider, Flex, Text } from "@mantine/core";
import React from "react";
import { BiChevronRight } from "react-icons/bi";

import classes from "./OptionsList.module.css";

type OptionsListTypes = {
  options: {
    label: string;
    description?: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
};

export const OptionsList = ({ options }: OptionsListTypes) => {
  return (
    <>
      {options.map((option) => {
        return (
          <React.Fragment key={option.label}>
            <Button
              mt="md"
              onClick={option.onClick}
              disabled={option.disabled}
              w={"100%"}
              variant={"white"}
              rightSection={<BiChevronRight size={"1.5rem"} />}
              fw={500}
              classNames={classes}
            >
              <Flex direction={"column"} align={"flex-start"}>
                <Text
                  variant={TextVariant.Body}
                  c={option.disabled ? Color.Disabled : Color.PrimaryText}
                >
                  {option.label}
                </Text>
                <Text
                  c={option.disabled ? Color.Disabled : Color.SecondaryText}
                  variant={TextVariant.Caption}
                  fw={400}
                >
                  {option.description}
                </Text>
              </Flex>
            </Button>
            <Divider mt="md" mb="sm" w="100%" />
          </React.Fragment>
        );
      })}
    </>
  );
};
