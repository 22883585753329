import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { apiGetLocations } from "../../../../apiCalls";
import { useAnalyticsStore } from "../stores/useAnalyticsStore";

export const useAnalytics = () => {
  const { setSelectedLocations, dateRange, setDateRange } = useAnalyticsStore(
    (state) => state,
  );
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    loadLocations();

    const startDate = dayjs().subtract(1, "week");
    const endDate = dayjs();

    return () => {
      setSelectedLocations([]);
    };
  }, []);

  const loadLocations = async () => {
    const { data } = await apiGetLocations(localStorage.getItem("usr_token"));

    const { locations } = data;

    const locationsIds = locations.map((l) => l.locationId);

    setSelectedLocations(locationsIds);
    setLocations(locations);
  };

  return { locations };
};
