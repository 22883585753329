import { Section } from "./Section";
import { Toggle } from "./Toggle";

export const Toggles = ({
  permissions,
  togglePermission,
  toggles,
  serviceToggles,
  userToggles,
  locationToggles,
  resourceToggles,
  isMobile,
  disabled,
}) => {
  return (
    <div style={{ flex: 2 }}>
      {toggles.map((toggle, index) => {
        return (
          <Toggle
            disabled={disabled}
            isMobile={isMobile}
            key={index}
            toggle={toggle}
            isToggledOn={permissions[toggle.id] == true}
            togglePermission={togglePermission}
          />
        );
      })}
      {!permissions["calendar_services"] &&
        serviceToggles &&
        serviceToggles.length > 0 && (
          <Section isMobile={isMobile} title={serviceToggles[0].sectionName}>
            {serviceToggles[0].toggles.map((serviceToggle, index) => {
              return (
                <Toggle
                  disabled={disabled}
                  isMobile={isMobile}
                  key={index}
                  toggle={{
                    id: serviceToggle.serviceId,
                    label: serviceToggle.serviceName,
                  }}
                  isToggledOn={serviceToggle.view}
                  togglePermission={() => {
                    togglePermission(serviceToggle.serviceId);
                  }}
                />
              );
            })}
          </Section>
        )}
      {!permissions["calendar_locations"] &&
        locationToggles &&
        locationToggles.length > 0 && (
          <Section isMobile={isMobile} title={locationToggles[0].sectionName}>
            {locationToggles[0].toggles.map((locationToggle, index) => {
              return (
                <Toggle
                  disabled={disabled}
                  isMobile={isMobile}
                  key={index}
                  toggle={{
                    id: locationToggle.locationId,
                    label: locationToggle.locationName,
                  }}
                  isToggledOn={locationToggle.view}
                  togglePermission={() => {
                    togglePermission(locationToggle.locationId);
                  }}
                />
              );
            })}
          </Section>
        )}
      {!permissions["calendar_users"] &&
        userToggles &&
        userToggles.length > 0 &&
        userToggles.map((userToggle, index) => {
          return (
            <Section
              isMobile={isMobile}
              key={index}
              title={userToggle.sectionName}
              bordered={index !== userToggles.length - 1}
            >
              {userToggle.toggles.map((user, index) => {
                return (
                  <Toggle
                    disabled={disabled}
                    isMobile={isMobile}
                    key={index}
                    toggle={{
                      id: user.userId,
                      label: user.label,
                    }}
                    isToggledOn={user.view}
                    togglePermission={() => {
                      togglePermission(user.userId);
                    }}
                  />
                );
              })}
            </Section>
          );
        })}
      {!permissions["calendar_resources"] &&
        resourceToggles &&
        resourceToggles.length > 0 &&
        resourceToggles.map((resourceToggle, index) => {
          return (
            <Section
              isMobile={isMobile}
              key={index}
              title={resourceToggle.sectionName}
              bordered={index !== resourceToggles.length - 1}
            >
              {resourceToggle.toggles.map((res, index) => {
                return (
                  <Toggle
                    disabled={disabled}
                    isMobile={isMobile}
                    key={index}
                    toggle={{
                      id: res.resourceId,
                      label: res.label,
                    }}
                    isToggledOn={res.view}
                    togglePermission={() => {
                      togglePermission(res.resourceId);
                    }}
                  />
                );
              })}
            </Section>
          );
        })}
    </div>
  );
};
