import { api } from "@/lib/api-client";
import { TextVariant } from "@/types/text-variants";
import { t } from "@lingui/macro";
import { Flex, Table, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { LuTrash2 } from "react-icons/lu";
import { useLimeAlertModal } from "../Modals/useLimeAlertModal";

export const UserAppointmentMessageList = ({
  userAppointmentId,
}: {
  userAppointmentId: number;
}) => {
  const { data, isLoading, isSuccess, refetch } =
    api.message.useGetUserAppointmentMessageSchedule({
      userAppointmentId,
    });

  const { mutateAsync: deleteMessageSchedule } =
    api.message.useDeleteMessageScheduleById();

  const { alertModal } = useLimeAlertModal();

  const handleDeleteMessageSchedule = async (id: number) => {
    await deleteMessageSchedule(id)
      .then(async () => {
        notifications.show({
          message: t`Sporočilo je bilo uspešno izbrisano`,
          color: "green",
        });
        await refetch();
      })
      .catch((e) => {
        notifications.show({
          title: t`Napaka pri brisanju sporočila`,
          message: e.message,
          color: "red",
        });
      });
  };

  if (isLoading || !isSuccess) return null;

  return (
    <Table verticalSpacing={1} withRowBorders={false}>
      {data.userAppointmentMessageSchedule.map((message) => (
        <Table.Tr>
          <Table.Td>
            <Text variant={TextVariant.Body}>{message.scheduledTime}</Text>
          </Table.Td>
          <Table.Td>
            <Text variant={TextVariant.Body}>{message.channel}</Text>
          </Table.Td>
          <Table.Td>
            <Flex justify={"end"}>
              <LuTrash2
                onClick={() => {
                  alertModal({
                    title: t`Izbriši sporočilo`,
                    content: t`Želite izbrisati sporočilo?`,
                    onConfirm: () => handleDeleteMessageSchedule(message.id),
                    onCancel: () => {},
                  });
                }}
                style={{ cursor: "pointer" }}
              ></LuTrash2>
            </Flex>
          </Table.Td>
        </Table.Tr>
      ))}
    </Table>
  );
};
