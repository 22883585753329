import React, { useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../Components/Sidebar/Sidebar";
import useAccountInfo from "../../hooks/useAccountInfo";
import CalendarPage from "./Calendar/CalendarPage";
import Completed from "./Completed/Completed";
import Locations from "./Locations/Locations";
import Schedule from "./Schedule/Schedule";
import Users from "./Users/Users";

import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import * as Sentry from "@sentry/react";
import useFeature from "../../hooks/useFeature";
import usePreferredLanguageStore from "../../stores/usePreferredLanguageStore";
import { Analytics } from "./Analytics/Analytics";
import { CalendarOnboardingPopup } from "./Calendar/components/CalendarOnboardingPopup";
import ChangeLog from "./ChangeLog/ChangeLog";
import { ClientSettings } from "./ClientSettings/ClientSettings";
import { LimeAdmin } from "./LimeAdmin/LimeAdmin";
import { NotificationSettings } from "./NotificationSettings/NotificationSettings";
import { ManageResource } from "./ResourceGroups/ManageResource";
import { ManageResourceGroup } from "./ResourceGroups/ManageResourceGroup";
import { ResourceGroups } from "./ResourceGroups/ResourceGroups";
import { GeneralTab as ResourceGroupGeneralTab } from "./ResourceGroups/Tabs/GeneralTab";
import { ResourcesTab as ResourceGroupResourcesTab } from "./ResourceGroups/Tabs/ResourcesTab";
import { ScheduleTab as ResourceGroupScheduleTab } from "./ResourceGroups/Tabs/ScheduleTab";
import { ServicesTab as ResourceGroupServicesTab } from "./ResourceGroups/Tabs/ServicesTab";
import { ManageCategory } from "./Services/ManageCategory";
import { ManageService } from "./Services/ManageService";
import { ServiceCategories } from "./Services/ServiceCategories";
import { Services } from "./Services/Services";
import { AccountSettings as StripeAccountSettings } from "./StripeSettings/AccountSettings";
import { GeneralTab as StripeGeneralTab } from "./StripeSettings/GeneralTab";
import { PaymentsOverviewTab as StripePaymentsOverviewTab } from "./StripeSettings/PaymentsOverviewTab";
import { ServicePaymentSettings as StripeServicePaymentSettings } from "./StripeSettings/ServicePaymentSettings";
import { Stripe } from "./StripeSettings/Stripe";
import { Integrations } from "./Integrations/Integrations";
import { FacebookConversionApi } from "./Integrations/FacebookConversionApi";
import { TaxRegisterSetup } from "./TaxRegister/Setup/Setup";
import { TaxRegisterSetupBasicInfo } from "./TaxRegister/Setup/BasicInfo";
import { TaxRegisterSetupCertificate } from "./TaxRegister/Setup/Certificate";
import { TaxRegisterSetupBusinessPremise } from "./TaxRegister/Setup/BusinessPremise/BusinessPremise";
import { TaxRegisterSetupElectronicDevice } from "./TaxRegister/Setup/ElectronicDevice/ElectronicDevice";
import { TaxRegisterInvoices } from "./TaxRegister/Invoices/Invoices";
import { POS } from "./TaxRegister/Invoices/POS/POS";
import { TaxRegisterSetupAct } from "./TaxRegister/Setup/Act";
import { TaxRegisterSetupPrinters } from "./TaxRegister/Setup/Printers";
import { CashRegister } from "./TaxRegister/CashRegister/CashRegister";
import { Products } from "./Products/Products";
import { ManageProduct } from "./Products/ManageProduct";
import { ManageProductCategory } from "./Products/ManageProductCategory";
import { api } from "@/lib/api-client";
import { usePosPrinterStore } from "@/stores/pos-printer-store";
import { notifications } from "@mantine/notifications";
import { t } from "@lingui/macro";
import { DeliveryOrders } from "./DeliveryOrders/DeliveryOrders";
import { ManageDeliveryOrder } from "./DeliveryOrders/ManageDeliveryOrder/ManageDeliveryOrder";
import { GiftCard } from "./GiftCard/GiftCard";
import { GiftCardDetails } from "./GiftCard/GiftCardDetails";
import { ProductStock } from "./ProductStock/ProductStock";
import { CashRegisterClosure } from "./TaxRegister/CashRegister/Closure";
import { CashRegisterStatus } from "./TaxRegister/CashRegister/Status";
import RouteChangeListener from "./RouteChangeListener";
import { toast } from "sonner";

const MainContent = styled.div<{
  ignorePadding?: boolean;
  ignoreBottomPadding?: boolean;
}>`
  flex: 1;

  position: relative;

  z-index: 19;
  overflow: visible;

  background: white;

  //box-shadow: -2px 0 30px rgba(50, 67, 61, 0.08);

  min-height: 100vh;
  padding: ${(props) => !props.ignorePadding && "0 15px"};
  padding-bottom: ${(props) => !props.ignoreBottomPadding && "70px"};

  width: 100%;

  @media (min-width: 768px) {
    overflow: scroll;
    /*padding: 0 50px;*/
    padding: ${(props) => !props.ignorePadding && "1rem 50px"};
    padding-bottom: ${(props) => !props.ignoreBottomPadding && "50px"};
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */

    height: 100vh;
  }
`;

export const PageOuter = styled.div<{
  isMobile?: boolean;
  isLoading?: boolean;
}>`
  position: relative;
  //width: ${(props) => (props.isMobile ? "95%" : "100%")};
  margin: 0 auto;
  //padding-right: ${(props) => (props.isMobile ? "0" : "3rem")};
  margin-bottom: 2rem;
  ${(props) =>
    props.isLoading &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding-right: 0;
  `}
`;

const Dashboard = () => {
  const [showOnboardingPopup, setShowOnboardingPopup] = React.useState(false);

  const { loadPreferredLanguage } = usePreferredLanguageStore((state) => state);

  const accountInfo = useAccountInfo((state) => state.accountInfo);

  const location = useLocation();

  const { isFeatureEnabled } = useFeature();

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { data: globalSettings } = api.app.useGetGlobalSettings(
    !isFeatureEnabled("TAX_REGISTER"),
  );

  const { setupQzTray } = usePosPrinterStore((state) => state);

  useEffect(() => {
    loadPreferredLanguage();
  }, []);

  useEffect(() => {
    if (!globalSettings) return;

    const qzTrayClientCert = globalSettings.find(
      (setting) => setting.settingName === "qz_tray_client_crt",
    );
    const qzTrayClientKey = globalSettings.find(
      (setting) => setting.settingName === "qz_tray_client_key",
    );

    if (
      !qzTrayClientCert ||
      !qzTrayClientKey ||
      !qzTrayClientCert.settingValue ||
      !qzTrayClientKey.settingValue
    ) {
      toast.error(t`Povezave s POS tiskalniku ni bilo mozno vzpostaviti.`);
      return;
    }

    setupQzTray({
      qzTrayClientCert: atob(qzTrayClientCert.settingValue),
      qzTrayClientKey: atob(qzTrayClientKey.settingValue),
    });
  }, [globalSettings]);

  useEffect(() => {
    if (!accountInfo?.onboardingDone) {
      setShowOnboardingPopup(true);
    }
  }, [accountInfo]);

  const ignoreContentPaddingPathNames = [
    "/dashboard/calendar",
    "/dashboard/resource-groups",
    "/dashboard/services",
    "/dashboard/notifications",
    "/dashboard/settings",
    "/dashboard/locations",
    "/dashboard/users",
    "/dashboard/integrations",
    "/dashboard/tax-register",
    "/dashboard/products",
    "/dashboard/delivery-orders",
    "/dashboard/gift-card",
    "/dashboard/product-stock",
  ];
  const ignoreContentPadding = ignoreContentPaddingPathNames.filter((path) =>
    location.pathname.startsWith(path),
  );

  const ignoreBottomPaddingPathNames = ["/dashboard/tax-register"];
  const ignoreBottomPadding = ignoreBottomPaddingPathNames.filter((path) =>
    location.pathname.startsWith(path),
  );

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <>
      <RouteChangeListener />
      <div
        style={{ background: "#f2f7f5", minHeight: "100vh", display: "flex" }}
      >
        <Sidebar page={location.pathname} />

        {showOnboardingPopup && (
          <CalendarOnboardingPopup
            handleSkipOnboarding={() => setShowOnboardingPopup(false)}
          />
        )}

        <MainContent
          ignorePadding={ignoreContentPadding.length > 0}
          ignoreBottomPadding={ignoreBottomPadding.length > 0}
        >
          <SentryRoutes>
            {permissions.view_settings_page && (
              <Route path="/settings" element={<ClientSettings />}></Route>
            )}
            {permissions.view_users_page && (
              <Route path="/users" element={<Users></Users>}></Route>
            )}
            {permissions.view_analytics && (
              <Route
                path="/analytics"
                element={<Analytics></Analytics>}
              ></Route>
            )}
            {permissions.view_locations_page && (
              <Route
                path="/locations"
                element={<Locations></Locations>}
              ></Route>
            )}
            {permissions.view_services_page && (
              <>
                <Route path="/services" element={<Services />} />
                <Route
                  path="/services/categories"
                  element={<ServiceCategories />}
                />

                <Route path="/services/:id" element={<ManageService />} />
                <Route
                  path="/services/categories/:id"
                  element={<ManageCategory />}
                />
              </>
            )}

            <Route
              path="/calendar"
              element={<CalendarPage></CalendarPage>}
            ></Route>
            {permissions.view_customer_list && (
              <Route path="/completed" element={<Completed />}></Route>
            )}
            {permissions.view_availability && (
              <Route path="/schedule" element={<Schedule></Schedule>}></Route>
            )}
            {permissions.view_notifications_page && (
              <Route
                path="/notifications/*"
                element={<NotificationSettings></NotificationSettings>}
              ></Route>
            )}
            {["admin", "salesman"].includes(accountInfo.systemRole) && (
              <Route path="/admin/*" element={<LimeAdmin></LimeAdmin>}></Route>
            )}
            {isFeatureEnabled("CHANGELOG") &&
              permissions.view_change_log_page && (
                <Route
                  path="/change-log"
                  element={<ChangeLog></ChangeLog>}
                ></Route>
              )}
            {isFeatureEnabled("PAYMENTS") && (
              <Route path="/stripe" element={<Stripe />}>
                <Route index element={<Navigate to="general" replace />} />
                <Route path="general" element={<StripeGeneralTab />} />
                <Route
                  path="payments"
                  element={<StripePaymentsOverviewTab />}
                />
                <Route path="settings" element={<StripeAccountSettings />} />
                <Route
                  path="settings/services"
                  element={<StripeServicePaymentSettings />}
                />
              </Route>
            )}
            {isFeatureEnabled("RESOURCES") && (
              <>
                <Route path="resource-groups" element={<ResourceGroups />} />
                <Route
                  path="resource-groups/:id"
                  element={<ManageResourceGroup />}
                >
                  <Route index element={<Navigate to="general" replace />} />
                  <Route path="general" element={<ResourceGroupGeneralTab />} />
                  <Route
                    path="services"
                    element={<ResourceGroupServicesTab />}
                  />
                  <Route
                    path="schedule"
                    element={<ResourceGroupScheduleTab />}
                  />
                  <Route
                    path="resources/:resourceId"
                    element={<ManageResource />}
                  />
                  <Route
                    path="resources"
                    element={<ResourceGroupResourcesTab />}
                  />
                </Route>
              </>
            )}
            {isFeatureEnabled("INTEGRATIONS") && (
              <>
                <Route path="integrations" element={<Integrations />}>
                  <Route path="fbcapi" element={<FacebookConversionApi />} />
                </Route>
              </>
            )}

            {isFeatureEnabled("GIFTCARD") && (
              <>
                <Route path="gift-card" element={<GiftCard />} />
                <Route path="gift-card/:id" element={<GiftCardDetails />} />
              </>
            )}

            {isFeatureEnabled("TAX_REGISTER") && (
              <>
                <Route
                  path="/tax-register/invoice/:invoiceId"
                  element={<TaxRegisterInvoices />}
                ></Route>
                <Route
                  path="/tax-register/invoice/new"
                  element={<POS />}
                ></Route>
                <Route
                  path="/tax-register/invoice"
                  element={<TaxRegisterInvoices />}
                ></Route>

                <Route
                  path="/tax-register/cash-register"
                  element={<CashRegister />}
                >
                  <Route
                    path=":businessPremiseId/status"
                    element={<CashRegisterStatus />}
                    index
                  />
                  <Route
                    path=":businessPremiseId/closure"
                    element={<CashRegisterClosure />}
                  />
                </Route>

                <Route
                  path="/tax-register/settings/setup"
                  element={<TaxRegisterSetup />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/info"
                  element={<TaxRegisterSetupBasicInfo />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/cert"
                  element={<TaxRegisterSetupCertificate />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/business-premise"
                  element={<TaxRegisterSetupBusinessPremise />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/electronic-devices"
                  element={<TaxRegisterSetupElectronicDevice />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/act"
                  element={<TaxRegisterSetupAct />}
                ></Route>
                <Route
                  path="/tax-register/settings/setup/printers"
                  element={<TaxRegisterSetupPrinters />}
                ></Route>

                <Route
                  path="/products"
                  element={<Products tab="products" />}
                ></Route>
                <Route
                  path="/products/categories"
                  element={<Products tab="categories" />}
                ></Route>

                <Route
                  path="/products/categories/:categoryId"
                  element={<ManageProductCategory />}
                ></Route>
                <Route
                  path="/products/:productId"
                  element={<ManageProduct />}
                ></Route>

                <Route
                  path="/delivery-orders"
                  element={<DeliveryOrders />}
                ></Route>
                <Route
                  path="/delivery-orders/:deliveryOrderId"
                  element={<ManageDeliveryOrder />}
                ></Route>
                <Route
                  path="/delivery-orders/:deliveryOrderId"
                  element={<ManageDeliveryOrder key={"deliveryOrder"} />}
                ></Route>

                <Route
                  path="/product-stock"
                  element={<ProductStock></ProductStock>}
                ></Route>
              </>
            )}

            <Route
              path={"*"}
              element={<Navigate to={"/dashboard/calendar"} />}
            />
          </SentryRoutes>
        </MainContent>
      </div>
    </>
  );
};

export default Dashboard;
