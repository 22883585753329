import { GetQzTrayClientCertificate } from "@/server-types";
import { post, get } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";

export const useDownloadQzTrayClientCertificate = () => {
  return useCustomMutation({
    mutationKey: ["postSetupFacebookConversionAPI"],
    mutationFn: () =>
      get<GetQzTrayClientCertificate>(
        "/storage/file/qz-tray/client-certificate",
        undefined,
      ),
  });
};
