import { GetLocations, PostLocation, PutLocationById } from "@/server-types";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { get, post, put } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";
/**
 * GET /location
 */
export const useGetLocations = (query: GetLocations["query"]) => {
  return useCustomQuery({
    queryKey: ["clientList", query],
    queryFn: () => get<GetLocations>("/location", query),
    placeholderData: keepPreviousData,
  });
};

export const usePostLocation = () => {
  return useCustomMutation({
    mutationFn: (body: PostLocation["body"]) =>
      post<PostLocation>("/location", body),
  });
};

export const usePutLocation = () => {
  return useCustomMutation({
    mutationFn: ({
      locationId,
      body,
    }: {
      locationId: number;
      body: PutLocationById["body"];
    }) => put<PutLocationById>(`/location/${locationId}`, body),
  });
};
