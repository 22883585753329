import {
  Accordion,
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  UnstyledButton,
} from "@mantine/core";

import { Color } from "@/types/colors";
import { Trans, t } from "@lingui/macro";
import { RiCheckboxCircleLine, RiDownload2Line } from "react-icons/ri";
import { DateRangeActions } from "../DateRangeActions";
import { LimeBottomDrawer } from "../LimeBottomDrawer/LimeBottomDrawer";

import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";
import classes from "./ExportPdfModal.module.css";
import { useExportPdfModal } from "./useExportPdfModal";

export const ExportPdfModal = () => {
  const { calendarMode } = useCalendarStore((state) => state);

  const {
    ref,
    userIds,
    serviceIds,
    setDateRange,
    usersServicesData,
    handleExport,
    modalOpened,
    setModalOpened,
    selectAllServices,
    selectAllUsers,
    toggleServiceSelection,
    toggleResourceSelection,
    toggleUserSelection,
    contentToPrint,
    biggerRowSize,
    setBiggerRowSize,
    selectAllResources,
    resourceIds,
  } = useExportPdfModal();

  if (!usersServicesData) return null;

  return (
    <>
      <Button
        onClick={() => setModalOpened(true)}
        leftSection={
          <Box bg={"#F5F5F5"} p={7} c="#8C8C8C" style={{ borderRadius: "4px" }}>
            <RiDownload2Line></RiDownload2Line>
          </Box>
        }
        variant="transparent"
        p={0}
      >
        <Text c={Color.PrimaryText}>
          <Trans>Prenos v PDF obliki</Trans>
        </Text>
      </Button>

      <div id="print-content" ref={ref} style={{ display: "none" }}>
        {contentToPrint}
      </div>

      <LimeBottomDrawer
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        content={
          <Flex direction={"column"} gap={15}>
            <DateRangeActions
              handleChangeDateRange={(start, end) => {
                setDateRange([start, end]);
              }}
              defaultSelectedOption="1d"
              future={true}
            ></DateRangeActions>

            <Checkbox
              label={t`Dodaj prostor za zapiske pod termini`}
              checked={biggerRowSize}
              onChange={(e) => setBiggerRowSize(e.target.checked)}
            ></Checkbox>

            <Accordion>
              {calendarMode === "users" ? (
                <Accordion.Item value="users">
                  <Accordion.Control>
                    <Text>
                      <Trans>Zaposleni</Trans>
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <UnstyledButton
                      onClick={selectAllUsers}
                      styles={{
                        root: {
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",

                          borderRadius: 7,

                          marginBottom: 12,

                          padding: "0.5rem 1rem",
                          width: "100%",

                          background: "#74B58710",
                          color: `${
                            userIds.length === usersServicesData.users.length
                              ? "#74B587"
                              : "#949494"
                          }`,
                        },
                      }}
                    >
                      <RiCheckboxCircleLine size={20}></RiCheckboxCircleLine>
                      <Trans>Izberi vse</Trans>{" "}
                    </UnstyledButton>
                    {usersServicesData.users.map((user, index) => (
                      <UnstyledButton
                        key={index}
                        onClick={() => {
                          toggleUserSelection(user.userId);
                        }}
                        className={classes.item}
                        styles={{
                          root: {
                            display: "flex",
                            alignItems: "center",

                            borderRadius: 7,
                            padding: "0.5rem 1rem",

                            width: "100%",

                            border: `1px solid ${
                              userIds.includes(user.userId)
                                ? "#74B587"
                                : "rgb(236, 237, 239)"
                            }`,
                            fontSize: "0.92rem",

                            marginBottom: 12,
                          },
                        }}
                      >
                        <div
                          className={` ${
                            userIds.includes(user.userId) ? classes.active : ""
                          } ${classes.color}`}
                        ></div>
                        {`${user.name} ${user.lastName}`}
                      </UnstyledButton>
                    ))}
                  </Accordion.Panel>
                </Accordion.Item>
              ) : undefined}

              {calendarMode === "resources" ? (
                <Accordion.Item value="resources">
                  <Accordion.Control>
                    <Text>
                      <Trans>Sredstva</Trans>
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <UnstyledButton
                      className={"select-all"}
                      onClick={selectAllResources}
                      styles={{
                        root: {
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",

                          borderRadius: 7,
                          marginBottom: 12,

                          padding: "0.5rem 1rem",
                          width: "100%",

                          background: "#74B58710",
                          color: `${
                            resourceIds.length ===
                            usersServicesData.resources.length
                              ? "#74B587"
                              : "#949494"
                          }`,
                        },
                      }}
                    >
                      <RiCheckboxCircleLine size={20}></RiCheckboxCircleLine>
                      <Trans>Izberi vse</Trans>{" "}
                    </UnstyledButton>
                    {usersServicesData.resources.map((resource, index) => (
                      <UnstyledButton
                        className={classes.item}
                        key={index}
                        onClick={() => {
                          toggleResourceSelection(resource.id);
                        }}
                        styles={{
                          root: {
                            display: "flex",
                            alignItems: "center",

                            borderRadius: 7,
                            padding: "0.5rem 1rem",

                            width: "100%",

                            border: `1px solid ${
                              resourceIds.includes(resource.id)
                                ? "#74B587"
                                : "rgb(236, 237, 239)"
                            }`,
                            fontSize: "0.92rem",

                            marginBottom: 12,
                          },
                        }}
                      >
                        <div
                          className={` ${
                            resourceIds.includes(resource.id)
                              ? classes.active
                              : ""
                          } ${classes.color}`}
                        ></div>
                        {resource.label}
                      </UnstyledButton>
                    ))}
                  </Accordion.Panel>
                </Accordion.Item>
              ) : undefined}

              <Accordion.Item value="services">
                <Accordion.Control>
                  <Text>
                    <Trans>Storitve</Trans>
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <UnstyledButton
                    className={"select-all"}
                    onClick={selectAllServices}
                    styles={{
                      root: {
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",

                        borderRadius: 7,
                        marginBottom: 12,

                        padding: "0.5rem 1rem",
                        width: "100%",

                        background: "#74B58710",
                        color: `${
                          serviceIds.length ===
                          usersServicesData.services.length
                            ? "#74B587"
                            : "#949494"
                        }`,
                      },
                    }}
                  >
                    <RiCheckboxCircleLine size={20}></RiCheckboxCircleLine>
                    <Trans>Izberi vse</Trans>{" "}
                  </UnstyledButton>
                  {usersServicesData.services.map((service, index) => (
                    <UnstyledButton
                      className={classes.item}
                      key={index}
                      onClick={() => {
                        toggleServiceSelection(service.serviceId);
                      }}
                      styles={{
                        root: {
                          display: "flex",
                          alignItems: "center",

                          borderRadius: 7,
                          padding: "0.5rem 1rem",

                          width: "100%",

                          border: `1px solid ${
                            serviceIds.includes(service.serviceId)
                              ? "#74B587"
                              : "rgb(236, 237, 239)"
                          }`,
                          fontSize: "0.92rem",

                          marginBottom: 12,
                        },
                      }}
                    >
                      <div
                        className={` ${
                          serviceIds.includes(service.serviceId)
                            ? classes.active
                            : ""
                        } ${classes.color}`}
                      ></div>
                      {service.name}
                    </UnstyledButton>
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Flex>
        }
        title={t`Nastavitve prenosa`}
        buttons={{
          save: {
            text: t`Natisni`,
            disabled: false,
            action: handleExport,
          },
          cancel: {
            action: () => setModalOpened(false),
          },
        }}
      ></LimeBottomDrawer>
    </>
  );
};
