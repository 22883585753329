import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  const setExtraPlayers = (extraPlayers) => {
    setValues({ ...fields, extraPlayers });
  };

  const resetFields = () => {
    setValues(initialState);
  };

  function updateValues(value, id) {
    setValues((prevState) => {
      return {
        ...prevState,
        [id]: value,
        customerId: null,
      };
    });
  }

  return [fields, updateValues, resetFields, setValues, setExtraPlayers];
}
