import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Center, Flex, Loader } from "@mantine/core";
import { useScheduleStore } from "../Stores/useScheduleStore";
import LocationScheduleBlock from "./LocationScheduleBlock";
import LocationScheduleModal from "./LocationScheduleModal";

const LocationScheduleList = ({
  isEditingLocationSchedule: editingSchedule,
  setIsEditingLocationSchedule: setEditingSchedule,
}) => {
  useLingui();
  const { schedules } = useScheduleStore((state) => state);

  return (
    <>
      <Box
        mt={{
          base: undefined,
          sm: "md",
        }}
      >
        <Flex direction={"column"} gap={"1rem"}>
          {schedules == null && <Loader />}
          {schedules.length === 0 && (
            <Center>
              <h3>
                <Trans>Ni urnikov</Trans>
              </h3>
            </Center>
          )}
          {schedules.map((schedule) => (
            <LocationScheduleBlock
              key={schedule.scheduleId}
              schedule={schedule}
              handleEditClick={() => setEditingSchedule(schedule)}
            />
          ))}
        </Flex>
      </Box>

      <LocationScheduleModal
        editingSchedule={editingSchedule}
        handleClose={() => setEditingSchedule(null)}
      />
    </>
  );
};

export default LocationScheduleList;
