import { LimeColorPicker } from "@/Components/LimeColorPicker";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import { api, queryClient } from "@/lib/api-client";
import type { PostCategory } from "@/server-types";
import usePreferredLanguageStore, {
  SUPPORTED_LANGUAGES,
} from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect } from "react";
import { VscTrash } from "react-icons/vsc";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

type Form = {
  id: number | undefined;
  name: string;
  appointmentType: PostCategory["body"]["appointmentType"];
  nameLocalized: PostCategory["body"]["nameLocalized"];
  color: string | null;
  customColor: boolean;
};

export const ManageCategory = ({
  catId,
  handleClose,
  withoutHeader,
}: {
  catId?: string;
  handleClose?: (categoryId?: number) => void;
  withoutHeader?: boolean;
}) => {
  const { id } = useParams();
  const [_, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  const categoryId = catId ?? id;

  const { alertModal } = useLimeAlertModal();

  const {
    data: category,
    isLoading: categoryIsLoading,
    isError: categoryIsError,
    isSuccess: categoryIsSuccess,
  } = api.category.useGetCategory(parseInt(categoryId || "-1"));

  const { mutateAsync: postCategory, isPending: isPostingCategory } =
    api.category.usePostCategory();

  const { mutateAsync: deleteCategory } = api.category.useDeleteCategory();

  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (categoryIsError) {
      setSearchParams({ tab: "categories" });
    }
  }, [categoryIsError]);

  const form = useForm<Form>({
    initialValues: {
      id: undefined,
      name: "",
      appointmentType: "adhoc",
      color: null,
      customColor: false,
      nameLocalized: SUPPORTED_LANGUAGES.toSorted((a, b) =>
        a === defaultLanguage ? -1 : b === defaultLanguage ? 1 : 0,
      ).map((language) => ({
        language,
        name: "",
      })),
    },
  });

  useEffect(() => {
    if (category) {
      form.initialize({
        id: category.tagId,
        name: category.tagName,
        color: category.color || null,
        customColor: category.color != null,
        appointmentType: category.appointmentType,
        nameLocalized: SUPPORTED_LANGUAGES.toSorted((a, b) =>
          a === defaultLanguage ? -1 : b === defaultLanguage ? 1 : 0,
        ).map((language) => ({
          language,
          name:
            category.TagNameLocalized.find(
              (ln: {
                language: (typeof category.TagNameLocalized)[number]["language"];
              }) => ln.language === language,
            )?.name || "",
        })),
      });
    }
  }, [category]);

  const handleSubmit = async (
    values: Form,
    event: React.FormEvent<HTMLFormElement> | undefined,
  ) => {
    event?.preventDefault();
    event?.stopPropagation();

    const newCategory = await postCategory(values);

    close({
      invalidate: true,
      category: newCategory,
    });
  };

  if (categoryIsLoading) {
    return <div>Loading...</div>;
  }

  if (categoryIsError && !categoryIsSuccess) {
    return <div>Error</div>;
  }

  const close = ({
    invalidate,
    category,
  }: {
    invalidate: boolean;
    category?: PostCategory["response"];
  }) => {
    if (invalidate) {
      queryClient.invalidateQueries({ queryKey: ["categories"] });
    }

    if (handleClose) handleClose(category?.tagId);
    else navigate("/dashboard/services/categories");
  };

  const firstLanguage = i18n.locale;
  const sortedLanguages = form.values.nameLocalized.sort((a, b) =>
    a.language === firstLanguage ? -1 : b.language === firstLanguage ? 1 : 0,
  );

  return (
    <form
      style={{ display: "contents" }}
      onSubmit={form.onSubmit(handleSubmit)}
    >
      {withoutHeader ? (
        <Space h={isMobile ? undefined : "md"} />
      ) : (
        <LimePageHeader
          title=""
          subPage={{
            title: "Uredi kategorijo",
            onBackButtonClick: () => close({ invalidate: false }),
            rightSection:
              categoryId !== "-1"
                ? {
                    options: [
                      {
                        label: t`Izbriši kategorijo`,
                        color: Color.Error,
                        icon: <VscTrash />,
                        onClick: async () => {
                          if (category) {
                            alertModal({
                              title: t`Ali ste prepričani, da želite izbrisati storitev?`,
                              onConfirm: async () => {
                                await deleteCategory(category.tagId);
                                close({ invalidate: true });
                              },
                              isDanger: true,
                            });
                          }
                        },
                      },
                    ],
                  }
                : undefined,
          }}
        />
      )}

      <Flex
        direction={"column"}
        mt={isMobile ? "xs" : undefined}
        w={"100%"}
        align={"center"}
        p={"1rem"}
      >
        {sortedLanguages.map((localizedName, index) => {
          return (
            <React.Fragment key={index}>
              <TextInput
                mx={"md"}
                radius={8}
                maw={"450px"}
                w={"100%"}
                mt={index === 0 ? "md" : undefined}
                label={
                  <Text variant={TextVariant.BodyEmphasized} mb={5}>
                    <Trans>Ime kategorije</Trans>
                    <Text
                      ml={5}
                      span
                      c={Color.SecondaryText}
                      variant={TextVariant.Caption}
                    >
                      ({localizedName.language.toUpperCase()})
                    </Text>
                  </Text>
                }
                {...form.getInputProps(`nameLocalized.${index}.name`)}
              />
              <Divider my={"md"} w={"100%"} maw={"450px"} />
            </React.Fragment>
          );
        })}

        <Box w={"100%"} maw={"450px"}>
          <LimeSwitch
            checked={form.values.customColor}
            onChange={(event) => {
              const newValue = event.currentTarget.checked;
              form.setValues({ customColor: newValue });

              if (!newValue) {
                form.setValues({ color: undefined });
              }

              if (newValue) {
                form.setValues({ color: "#000000" });
              }
            }}
            label={t`Določi barvo kategorije`}
          />
          <Collapse in={form.values.customColor} w={"100%"}>
            <LimeColorPicker
              {...form.getInputProps("color")}
              value={form.values.color || ""}
              label={t`Barva kategorije`}
              mt={"md"}
            />
          </Collapse>
        </Box>

        <Divider my={"md"} w={"100%"} maw={"450px"} />

        <Flex justify={"center"} gap={"xs"} mb={"md"}>
          <Button
            variant="outline"
            onClick={() => close({ invalidate: false })}
          >
            <Trans>Prekliči</Trans>
          </Button>
          <Button loading={isPostingCategory} type="submit">
            <Trans>Shrani</Trans>
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
