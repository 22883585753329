import { LimeLoader } from "@/Components/LimeLoader";
import { Color } from "@/types/colors";
import { t } from "@lingui/macro";
import { Flex, LoadingOverlay, Switch, Text } from "@mantine/core";
import { useState } from "react";
import classes from "./Tables.module.css";

export const AnalyticsTable = ({
  title,
  icon,
  content,
  numberOfSelected,
  isLoading,
}: {
  title: string;
  icon: React.ReactNode;
  content?: {
    title: string;
    value: string;
    valuePercentage?: string;
    colorTitle: keyof typeof Color;
    colorValue: keyof typeof Color;
    colorValuePercentage?: keyof typeof Color;
    hide?: boolean;
  }[];
  numberOfSelected: number;
  isLoading: boolean;
}) => {
  const [view, setView] = useState<"number" | "percentage">("number");

  return (
    <div className={`${classes.table} analytics-table`}>
      <LoadingOverlay
        visible={numberOfSelected === 0}
        loaderProps={{ children: t`Ni izbranih zaposlenih` }}
      />
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ children: <LimeLoader></LimeLoader> }}
      />

      <div className={classes.header}>
        <div className={classes.titleIcon}>
          <div className={classes.title}>{title}</div>
          <div className={classes.icon}>
            {icon} <div className={classes.number}>{numberOfSelected}</div>
          </div>
        </div>
        <Switch
          onLabel={t`Št`}
          offLabel="%"
          checked={view === "number"}
          onChange={() => {
            setView((prev) => (prev === "number" ? "percentage" : "number"));
          }}
        ></Switch>
      </div>
      <div className={classes.content}>
        {content
          ?.filter((f) => !f.hide)
          .map((item, index) => (
            <Flex justify={"space-between"} key={index}>
              <Text c={Color[item.colorTitle]}>{item.title}</Text>
              {view === "percentage" &&
              item.valuePercentage &&
              item.colorValuePercentage ? (
                <Text
                  c={Color[item.colorValuePercentage]}
                  fw={500}
                  miw={"fit-content"}
                >
                  {item.valuePercentage}
                </Text>
              ) : (
                <Text c={Color[item.colorValue]} fw={500} miw={"fit-content"}>
                  {item.value}
                </Text>
              )}
            </Flex>
          ))}
      </div>
    </div>
  );
};
