import { t } from "@lingui/macro";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { useRevenue } from "@/lib/api-client/paths/analytics";
import { useLingui } from "@lingui/react";
import { RiArrowRightUpLine } from "react-icons/ri";
import { useAnalyticsStore } from "../stores/useAnalyticsStore";

import { returnCurrencySymbol } from "../../../../../../shared/utils/utils";

type GeneralDataType = {
  label: string;
  value: string;
  valueSuffix?: string;
  valueIconLeft?: React.ReactNode;
  valueColor?: string;
}[];

type DayChartType = {
  dayId: number;
  dayName: string;
  Prihodek: number;
  nrOfDays: number;
}[];

type DateChartType = {
  date: string;
  lastDate: string;
  [key: string]: number | string | undefined;
}[];

export const useTabGeneral = () => {
  const { selectedLocations, dateRange } = useAnalyticsStore((state) => state);
  const { i18n } = useLingui();
  const locale = i18n.locale;
  const [dataPerDate, setDataPerDate] = useState<DateChartType>([]);
  const [helperDataPerDate, setHelperDataPerDate] = useState<{
    firstFuture?: string;
  }>({});

  //chart 1
  const [dataPerDay, setDataPerDay] = useState<DayChartType>([]);

  //box charts
  const [generalData, setGeneralData] = useState<GeneralDataType>([]);

  const { data, isLoading } = useRevenue(
    {
      selectedLocations,
      dateRange: dateRange.map((date: Date) =>
        dayjs(date).format("YYYY-MM-DD"),
      ),
    },
    !!(selectedLocations?.length > 0 && dateRange[0] && dateRange[1]),
  );

  useEffect(() => {
    if (!selectedLocations || selectedLocations.length < 1) {
      setDataPerDate([]);
      setDataPerDay([]);
      setGeneralData([]);
      setHelperDataPerDate({});
    }
  }, [selectedLocations, dateRange]);

  useEffect(() => {
    if (!data) return;

    const prepareData = () => {
      if (!data) return;

      const { groupedByDate } = data;

      let totalRevenue = 0;
      let totalFutureRevenue = 0;
      let totalAppoitnmentsNotInFuture = 0;

      const dataForDateChart = groupedByDate.map((d) => ({
        date: dayjs(d.date).format("DD. MM."),
        lastDate: dayjs(d.lastMonth.date).format("DD. MM."),
        [t`Izbrano obdobje`]: !d.isInFuture ? d.revenue : undefined,
        [t`Preteklo obdobje`]: d.lastMonth.revenue,
        [t`Prihodnje obdobje`]: d.isInFuture ? d.revenue : undefined,
      }));

      const firstFutureDate = groupedByDate.find((d) => d.isInFuture)?.date;
      const firstFutureDateIndex = groupedByDate.findIndex((d) => d.isInFuture);

      if (firstFutureDateIndex > 0) {
        dataForDateChart[firstFutureDateIndex][t`Izbrano obdobje`] =
          dataForDateChart[firstFutureDateIndex][t`Prihodnje obdobje`];
      }

      //setup data for day chart
      let dataForDayChart: DayChartType = groupedByDate.reduce(
        (acc: DayChartType, curr) => {
          if (curr.isInFuture) {
            totalFutureRevenue += curr.revenue;
            return acc;
          }

          totalRevenue += curr.revenue;
          totalAppoitnmentsNotInFuture += curr.countAppointments;

          const dayExists = acc.find((a) => a.dayId === curr.dayId);
          if (!dayExists) {
            acc.push({
              dayId: curr.dayId,
              dayName:
                dayjs()
                  .day(curr.dayId)
                  .locale(locale)
                  .format("dddd")[0]
                  .toUpperCase() +
                dayjs().day(curr.dayId).locale(locale).format("dddd").slice(1),
              Prihodek: curr.revenue,
              nrOfDays: 1,
            });
            return acc;
          } else {
            dayExists.Prihodek += curr.revenue;
            dayExists.nrOfDays += 1;
          }
          return acc;
        },
        [],
      );

      dataForDayChart = dataForDayChart.map((d) => {
        return { ...d, Prihodek: d.Prihodek / d.nrOfDays };
      });

      [0, 1, 2, 3, 4, 5, 6].forEach((dayId) => {
        const dayExists = dataForDayChart.find((a) => a.dayId === dayId);
        if (!dayExists) {
          dataForDayChart.push({
            dayId,
            dayName:
              dayjs().day(dayId).format("dddd")[0].toUpperCase() +
              dayjs().day(dayId).format("dddd").slice(1),
            Prihodek: 0,
            nrOfDays: 0,
          });
        }
      });

      dataForDayChart.sort((a, b) => {
        if (a.dayId === 0) {
          return 1;
        }
        if (b.dayId === 0) {
          return -1;
        }

        return a.dayId - b.dayId;
      });

      //setup data for general boxes

      const _generalData = [
        {
          label: t`Skupaj prihodki`,
          value: totalRevenue.toFixed(2).replaceAll(".", ","),
          valueSuffix: returnCurrencySymbol({ currency: data.currency }),
        },
        {
          label: t`Povprečna vrednost termina`,
          value: (totalRevenue / (totalAppoitnmentsNotInFuture || 1))
            .toFixed(2)
            .replaceAll(".", ","),
          valueSuffix: returnCurrencySymbol({ currency: data.currency }),
          valueColor: "black",
        },
        {
          label: t`Napovedani prihodki`,
          value: totalFutureRevenue.toFixed(2).replaceAll(".", ","),
          valueSuffix: returnCurrencySymbol({ currency: data.currency }),
          valueIconLeft: <RiArrowRightUpLine />,
          valueColor: "black",
        },
      ];

      setDataPerDate(dataForDateChart);
      setDataPerDay(dataForDayChart);
      setGeneralData(_generalData);
      setHelperDataPerDate({
        firstFuture: firstFutureDate
          ? dayjs(firstFutureDate).format("DD. MM.")
          : undefined,
      });
    };

    prepareData();
  }, [data]);

  return {
    dataPerDate,
    helperDataPerDate,
    dataPerDay,
    selectedLocations,
    generalData,
    isLoading,
    currency: data?.currency || "EUR",
  };
};
