const EmptyState = ({ title, description, icon, wrapperStyle }) => {
  return (
    <div
      className="flex h-[70vh] flex-col items-center justify-center gap-1"
      style={wrapperStyle}
    >
      {icon}
      <h1>{title}</h1>
      <p className="c-[#6c7b86] pb-2 text-sm">{description}</p>
    </div>
  );
};

export default EmptyState;
