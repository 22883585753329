import { LimeLoader } from "@/Components/LimeLoader";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Carousel, Embla } from "@mantine/carousel";
import {
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Skeleton,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import classes from "./AnalyticsCarousel.module.css";

export type ChartWithLabel = {
  label: string;
  chart: JSX.Element;
};

export const AnalyticsCarousel = ({
  charts,
  isLoaded,
  indicatorPosition = "default",
  indicatorYOffset = 0,
  labelPosition = "bottom",
  gap = 30,
  mobileActionButton,
  isLoading,
}: {
  charts: ChartWithLabel[];
  isLoaded: boolean;
  indicatorPosition?: "under" | "default";
  indicatorYOffset?: number;
  labelPosition?: "bottom" | "top";
  gap?: number;
  mobileActionButton?: React.ReactNode;
  isLoading?: boolean;
}) => {
  const [embla, setEmbla] = useState<Embla | null>(null);

  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(false);

  const [activeSlide, setActiveSlide] = useState(0);

  const isDesktop = useMediaQuery("(min-width:768px)");

  const handleCarouselNext = () => {
    embla?.scrollNext();

    setIsFirst(!embla?.canScrollPrev() || false);
    setIsLast(!embla?.canScrollNext() || false);

    setActiveSlide(embla?.selectedScrollSnap() || 0);
  };

  const handleCarouselPrev = () => {
    embla?.scrollPrev();

    setIsFirst(!embla?.canScrollPrev() || false);
    setIsLast(!embla?.canScrollNext() || false);

    setActiveSlide(embla?.selectedScrollSnap() || 0);
  };

  return (
    <Box pos={"relative"}>
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ children: <LimeLoader></LimeLoader> }}
      ></LoadingOverlay>
      <Flex direction={"column"} h={"100%"} gap={gap}>
        {labelPosition === "top" && (
          <Text
            variant={isDesktop ? TextVariant.Body : TextVariant.Subheading}
            c={isDesktop ? Color.SecondaryText : Color.PrimaryText}
          >
            {charts[activeSlide].label}
          </Text>
        )}
        {!isDesktop && charts.length > 0 && (
          <Flex justify={"space-between"} align={"center"}>
            <Text variant={TextVariant.Body} c={Color.SecondaryText}>
              {charts[activeSlide].label}
            </Text>

            {mobileActionButton}
          </Flex>
        )}
        <Skeleton h={"100%"} w={"100%"} visible={!isLoaded}>
          <Carousel
            getEmblaApi={setEmbla}
            draggable={false}
            withIndicators={isDesktop}
            withControls={false}
            styles={{
              indicators: {
                bottom:
                  indicatorPosition === "under"
                    ? `${-10 + indicatorYOffset}px`
                    : ``,
              },
            }}
            classNames={{
              root: classes.root,
              indicator: classes.indicator,
            }}
          >
            {charts.map((chart, index) => {
              return (
                <Carousel.Slide w={"100%"} key={index}>
                  {chart.chart}
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Skeleton>
        <Flex
          w={"100%"}
          justify={isDesktop ? "end" : "center"}
          align={"start"}
          gap={15}
          style={{
            justifyContent: isDesktop ? "right" : "space-between",
            alignItems: isDesktop ? "center" : "start",
          }}
          mt={isDesktop ? 0 : 15}
        >
          {isLoaded && isDesktop && labelPosition === "bottom" && (
            <Text
              variant={isDesktop ? TextVariant.Body : TextVariant.Subheading}
              c={isDesktop ? Color.SecondaryText : Color.PrimaryText}
            >
              {charts[activeSlide].label}
            </Text>
          )}
          <Group
            style={{
              flexWrap: "nowrap",
            }}
          >
            <Button
              variant="subtle"
              bg={"#F5F5F5"}
              styles={() => ({
                root: {
                  padding: 0,
                  height: "fit-content",
                  border: 0,
                  borderRadius: 4,
                },
                label: {
                  height: "fit-content",
                },
                inner: {
                  height: "fit-content",
                },
              })}
              onClick={handleCarouselPrev}
            >
              <RiArrowLeftSLine
                size={isDesktop ? 24 : 30}
                color={isFirst ? "#BFBFBF" : "#8C8C8C"}
              ></RiArrowLeftSLine>
            </Button>
            <Button
              variant="subtle"
              bg={"#F5F5F5"}
              styles={() => ({
                root: {
                  padding: 0,
                  height: "fit-content",
                  border: 0,
                  borderRadius: 4,
                },
                label: {
                  height: "fit-content",
                },
                inner: {
                  height: "fit-content",
                },
              })}
              onClick={handleCarouselNext}
            >
              <RiArrowRightSLine
                size={isDesktop ? 24 : 30}
                color={isLast ? "#BFBFBF" : "#8C8C8C"}
              ></RiArrowRightSLine>
            </Button>
          </Group>
        </Flex>
      </Flex>
    </Box>
  );
};
