import { Box } from "@mantine/core";
import { DateTimePicker, DateTimePickerProps } from "@mantine/dates";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabel, LimeInputLabelProps } from "./LimeInputLabel";

export const LimeDateTimePicker = ({
  ...props
}: LimeInputLabelProps & DateTimePickerProps) => {
  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);

  return (
    <Box {...spacingProps} w={props.w}>
      <LimeInputLabel {...props} />

      <DateTimePicker
        styles={{
          label: {
            fontWeight: 500,
          },
        }}
        radius={8}
        {...propsWithoutSpacing}
        label={undefined}
      />
    </Box>
  );
};
