import LoadingSpinner from "@/Components/Dashboard/LoadingSpinner/LoadingSpinner";
import { api } from "@/lib/api-client";
import { GetResourceGroups, GetResourceHierarchy } from "@/server-types";
import { TextVariant } from "@/types/text-variants";
import { Accordion, AccordionProps, Divider, Flex, Text } from "@mantine/core";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";

import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const accordionStyles: AccordionProps["styles"] = {
  content: {
    padding: 0,
  },
  item: {
    borderBottom: 0,
  },
};

export const ResourceHierarchy = () => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 768px)");

  /**
   * Control state of resource groups because we want to toggle them only
   * when user clicks on the chevron, not when he clicks on the whole control.
   */
  const [openedResourceGroups, setOpenedResourceGroups] = useState<string[]>(
    [],
  );

  const { data, isLoading, isError, isSuccess } =
    api.resources.useGetResourceHierarchy();

  const { data: resourceGroups } = api.resourceGroups.useGetResourceGroups({});

  const navigateToEdit = ({
    resourceGroupId,
    resourceId,
  }: {
    resourceGroupId: number;
    resourceId?: number;
  }) => {
    const resourcePart = resourceId ? `/resources/${resourceId}` : "";

    navigate(`/dashboard/resource-groups/${resourceGroupId}${resourcePart}`);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || !isSuccess) {
    return <>error</>;
  }

  return (
    <Flex direction={"column"} px={isMobile ? undefined : "xl"}>
      {Object.keys(data).map((resourceGroupId) => {
        const resourceGroup = resourceGroups?.resourceGroups?.find(
          (rg) => rg.id === Number(resourceGroupId),
        );

        const resources = data[Number(resourceGroupId)];

        if (!resourceGroup) {
          return null;
        }

        return (
          <BuildResourceGroupHierarchy
            key={resourceGroupId}
            resourceGroup={resourceGroup}
            resources={resources}
            onNavigateToEdit={navigateToEdit}
            isOpen={openedResourceGroups.includes(resourceGroup.id.toString())}
            onToggleOpen={(open) => {
              if (open) {
                setOpenedResourceGroups((prev) => [
                  ...prev,
                  resourceGroup.id.toString(),
                ]);
              } else {
                setOpenedResourceGroups((prev) =>
                  prev.filter((id) => id !== resourceGroup.id.toString()),
                );
              }
            }}
          />
        );
      })}
    </Flex>
  );
};

const BuildResourceGroupHierarchy = ({
  resourceGroup,
  resources,
  onNavigateToEdit,
  isOpen,
  onToggleOpen,
}: {
  resourceGroup: GetResourceGroups["response"]["resourceGroups"][number];
  resources: GetResourceHierarchy["response"][number][number][];
  onNavigateToEdit: ({
    resourceGroupId,
    resourceId,
  }: {
    resourceGroupId: number;
    resourceId?: number;
  }) => void;
  isOpen: boolean;
  onToggleOpen: (open: boolean) => void;
}) => {
  return (
    <Accordion
      multiple={false}
      styles={accordionStyles}
      value={isOpen ? resourceGroup.id.toString() : null}
    >
      <Accordion.Item
        key={resourceGroup.id}
        value={resourceGroup.id.toString()}
      >
        <Accordion.Control
          chevron={
            <RiArrowDownSLine
              onClick={(e) => {
                e.stopPropagation();

                onToggleOpen(!isOpen);
              }}
            />
          }
          onClick={() =>
            onNavigateToEdit({ resourceGroupId: resourceGroup.id })
          }
        >
          <Text variant={TextVariant.BodyEmphasized}>{resourceGroup.name}</Text>
        </Accordion.Control>
        <Divider />
        <Accordion.Panel>
          <RecursivelyBuildResourceHierarchy
            resources={resources}
            onResourceClick={(resourceId) => {
              onNavigateToEdit({
                resourceGroupId: resourceGroup.id,
                resourceId,
              });
            }}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

const RecursivelyBuildResourceHierarchy = ({
  resources,
  index = 1,
  onResourceClick,
}: {
  resources: GetResourceHierarchy["response"][number][number][];
  index?: number;
  onResourceClick: (resourceId: number) => void;
}) => {
  return (
    <Accordion
      styles={{
        ...accordionStyles,
        control: {
          ...accordionStyles.control,
          paddingLeft: index === 1 ? 30 : 20 + index * 20,
        },
      }}
    >
      {resources.map((resource) => {
        const hasChildren = resource.children.length > 0;

        return (
          <Accordion.Item key={resource.id} value={resource.id.toString()}>
            <Accordion.Control
              chevron={hasChildren ? undefined : <RiArrowRightSLine />}
              styles={{
                chevron: {
                  transform: !hasChildren ? "rotate(0)" : undefined,
                },
              }}
              onClick={() => {
                if (!hasChildren) {
                  onResourceClick(resource.id);
                }
              }}
            >
              <Flex align={"center"} gap={"xs"}>
                <RiArrowDownSLine
                  style={{
                    transform: "rotate(-45deg)",
                  }}
                />
                <Text variant={TextVariant.BodyEmphasized}>
                  {resource.label}
                </Text>
              </Flex>
            </Accordion.Control>
            <Divider />
            <Accordion.Panel>
              <RecursivelyBuildResourceHierarchy
                resources={resource.children}
                index={index + 1}
                onResourceClick={onResourceClick}
              />
            </Accordion.Panel>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};
