import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ResponsivePie } from "@nivo/pie";
import styled from "styled-components";
const AttendancePieChart = ({ data, isMobile }) => {
  useLingui();

  const margin = isMobile
    ? { top: 0, right: 20, bottom: 0, left: 20 }
    : { top: 5, right: 20, bottom: 5, left: 20 };
  const isEnoughtData = data.find((item) => item.value > 0) !== undefined;

  return (
    <>
      <CustomWrapper isMobile={isMobile}>
        {isEnoughtData ? (
          <PieChartContainer isMobile={isMobile}>
            <ResponsivePie
              data={data}
              margin={margin}
              startAngle={-130}
              sortByValue={true}
              activeInnerRadiusOffset={14}
              activeOuterRadiusOffset={13}
              colors={["#74B587", "#ea6d6d", "#AFD4BA"]}
              borderColor={{
                from: "color",
                modifiers: [["darker", "0"]],
              }}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={5}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              enableArcLabels={false}
              arcLabelsRadiusOffset={0}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              transitionMode="startAngle"
              theme={{
                legends: {
                  text: {
                    fontSize: "1rem",
                  },
                },
              }}
            />
          </PieChartContainer>
        ) : (
          <NoDataContainer>
            <p>
              <Trans>Ni podatka.</Trans>
            </p>
          </NoDataContainer>
        )}

        <LegendsContainer>
          <h2>
            <Trans>Udeležil/a</Trans>
          </h2>
          <LegendItem>
            <LegendColor color="#74B587" />
            <LegendText>
              <Trans>Da</Trans>
            </LegendText>
            <LegendDetails>
              {data.find((item) => item.id === t`Prisoten`).label} %
            </LegendDetails>
          </LegendItem>
          <LegendItem>
            <LegendColor color="#ea6d6d" />
            <LegendText>
              <Trans>Ne</Trans>
            </LegendText>
            <LegendDetails>
              {data.find((item) => item.id === t`Neprisoten`).label} %
            </LegendDetails>
          </LegendItem>
          <LegendItem>
            <LegendColor color="#AFD4BA" />
            <LegendText>
              <Trans>Ni označeno</Trans>
            </LegendText>
            <LegendDetails>
              {data.find((item) => item.id === t`Ni označena prisotnost`).label}{" "}
              %
            </LegendDetails>
          </LegendItem>
          <LegendItem
            style={{
              marginTop: "1rem",
            }}
          >
            <div>
              <Trans>Skupno število obiskov</Trans>:
            </div>
            <LegendDetails>
              {data.find((item) => item.id === t`Prisoten`).value}
            </LegendDetails>
          </LegendItem>
        </LegendsContainer>
      </CustomWrapper>
    </>
  );
};

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;
const CustomWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;

const LegendsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  width: 100%;

  h2 {
    font-size: 1.2rem;
    color: #2f2f2f;
  }
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  width: 100%;
`;

const LegendColor = styled.div`
  width: 45px;
  height: 10px;
  border-radius: 10px;
  background: ${(props) => props.color};
`;

const LegendText = styled.div`
  color: #424b64;
`;

const LegendDetails = styled.div`
  color: #969ba9;
`;

const PieChartContainer = styled.div`
  ${(props) =>
    props.isMobile
      ? "width: 200px; height:200px"
      : "width: 300px; height: 300px;"}
`;

export default AttendancePieChart;
