import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useDebouncedState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  deleteCustomerWithId,
  getCustomerData,
  getCustomerList,
  postMergeCustomers,
} from "../apiSchedule";

export const useCustomers = () => {
  useLingui();
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [globalSelectedCustomerData, setGlobalSelectedCustomerData] = useState(
    [],
  );
  const [searchQuery, setSearchQuery] = useDebouncedState("", 500);
  const [perPage, setPerPage] = useState(10);
  const [paginationPage, setPaginationPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [mergeAlert, setMergeAlert] = useState({ opened: false, data: {} });
  const [sortBy, setSortBy] = useState("lastVisitDesc");

  useEffect(() => {
    updateCustomers();
  }, [paginationPage, searchQuery, perPage, sortBy]);

  useEffect(() => {
    if (mergeAlert.opened) {
      const data = globalSelectedCustomerData?.at(0) ?? {};

      const baseComment = data?.comment ?? "";

      globalSelectedCustomerData.forEach((customer) => {
        if (customer.comment?.length > baseComment.length) {
          data.comment = customer.comment;
        }
      });

      setMergeAlert((prevState) => ({ ...prevState, data }));
    }
  }, [mergeAlert.opened, globalSelectedCustomerData]);

  const toggleSelectedCustomer = (customerId) => {
    const isSelected = globalSelectedCustomerData.some(
      (c) => c.customerId === customerId,
    );

    const updatedSelection = isSelected
      ? globalSelectedCustomerData.filter((c) => c.customerId !== customerId)
      : [
          ...globalSelectedCustomerData,
          customers.find((c) => c.customerId === customerId),
        ];

    setGlobalSelectedCustomerData(updatedSelection);
  };

  const mergeCustomers = async (data) => {
    const customerIds = globalSelectedCustomerData.map((c) =>
      Number(c.customerId),
    );

    if (customerIds.length === 0) {
      console.error("No valid customer IDs found for merging");
      return;
    }

    const mergeData = {
      name: data.name ?? "",
      lastName: data.lastName ?? "",
      gsm: data.gsm ?? "",
      email: data.email ?? "",
    };

    try {
      const req = await postMergeCustomers(customerIds, mergeData);
      if (req.status === 200) {
        notifications.show({
          title: t`Uspešno`,
          message: t`Uporabniki so bili uspešno združeni`,
        });
        setMergeAlert({ opened: false, data: {} });
      } else {
        notifications.show({
          title: t`Napaka`,
          message: t`Napaka pri združevanju uporabnikov: ${req.data.message}`,
          color: "red",
        });
        setMergeAlert({ opened: false, data: {} });
      }
    } catch (error) {
      console.error("Merge request failed:", error);
    }

    updateCustomers();
  };

  const updateCustomers = async () => {
    setError(null);
    setIsLoading(true);

    try {
      const cust = await getCustomerList(
        paginationPage,
        searchQuery,
        perPage,
        sortBy,
      );
      if (cust.status === 200) {
        if (cust.data.pagination) {
          setPagination(cust.data.pagination);
        }
        setCustomers(cust.data.customers);
      } else {
        setError({ status: cust.status, message: cust.statusText });
      }
    } catch (error) {
      setError({ status: 500, message: error.message });
    }

    setIsLoading(false);
  };

  const deleteCustomer = async (customerId) => {
    setError(null);
    setIsLoading(true);
    const token = localStorage.getItem("usr_token");
    try {
      await deleteCustomerWithId(customerId, token);
    } catch (error) {
      setError({ status: 500, message: error.message });
    }
    setIsLoading(false);
  };

  const refreshCustomerData = async (customerId) => {
    setError(null);
    const token = localStorage.getItem("usr_token");
    const customer = await getCustomerData(
      customerId ?? customerData?.customerId,
      token,
    );

    if (customer.status === 200) {
      setCustomerData({
        ...customer.data,
        birthday: customer.data.birthday
          ? dayjs(customer.data.birthday).toDate()
          : null,
      });
    } else {
      setError({ status: customer.status, message: customer.statusText });
    }
  };

  return {
    refreshCustomerData,
    customers,
    customerData,
    isLoading,
    error,
    setCustomerData,
    deleteCustomer,
    updateCustomers,
    globalSelectedCustomerData,
    setGlobalSelectedCustomerData,
    toggleSelectedCustomer,
    mergeCustomers,
    mergeAlert,
    setMergeAlert,
    paginationPage,
    setPaginationPage,
    pagination,
    searchQuery,
    setSearchQuery,
    perPage,
    setPerPage,
    sortBy,
    setSortBy,
  };
};
