import { Trans, t } from "@lingui/macro";
import { Box, Button, Flex } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const ActionButton = ({
  text,
  isLoading,
  onBackButtonClick,
}: {
  text?: string;
  isLoading?: boolean;
  onBackButtonClick?: () => void;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      pos={"fixed"}
      bottom={0}
      left={isMobile ? 0 : undefined}
      p={15}
      pb={30}
      w={"100%"}
      bg={"white"}
      style={{ zIndex: 5 }}
      maw={isMobile ? "100%" : "450px"}
    >
      <Flex gap={"md"} justify={"center"}>
        {!isMobile && onBackButtonClick ? (
          <Button
            type="button"
            loading={isLoading}
            variant="outline"
            color="gray"
            onClick={onBackButtonClick}
          >
            <Trans>Nazaj</Trans>
          </Button>
        ) : undefined}
        <Button
          fullWidth={isMobile || !onBackButtonClick}
          type="submit"
          loading={isLoading}
        >
          {text ? text : t`Nadaljuj`}
        </Button>
      </Flex>
    </Box>
  );
};
