import { TextVariant } from "@/types/text-variants";
import { ActionIcon, Popover, Text } from "@mantine/core";
import { HiOutlineInformationCircle } from "react-icons/hi";

export const LimeTooltip = ({ label }: { label: string | undefined }) => {
  if (!label) {
    return null;
  }

  return (
    <Popover withArrow position="bottom" width={"400px"} shadow="md">
      <Popover.Target>
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <HiOutlineInformationCircle size={"1.2rem"} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Text variant={TextVariant.Body}>{label}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};
