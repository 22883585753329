import { api } from "@/lib/api-client";
import { Avatar, Flex, Table } from "@mantine/core";
import { useState } from "react";
import { ImageUploadView } from "../ImageUploadView";
import { notifications } from "@mantine/notifications";

export const UsersTab = ({ clientId }: { clientId: number }) => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  // API
  const { data, refetch } = api.user.useUserList({
    clientId,
    includeDeleted: false,
  });

  const { mutateAsync: uploadImage } = api.admin.useUploadUserImage();

  return (
    <>
      {selectedUserId ? (
        <ImageUploadView
          close={() => setSelectedUserId(null)}
          existingImage={
            data?.users.find((user) => user.userId === selectedUserId)?.imageUrl
          }
          onUploadImage={async (formData) => {
            try {
              const response = await uploadImage({
                body: formData,
                params: { clientId, userId: selectedUserId },
              });

              notifications.show({
                message: "Slika uspešno posodobljena",
              });

              setSelectedUserId(null);
              refetch();
            } catch (e) {
              notifications.show({
                message: "Napaka pri posodabljanju slik",
                color: "red",
                // message: (e as any).message,
              });
            }
          }}
          circularCrop
          defaultCenterCrop
          aspect={1 / 1}
        />
      ) : (
        <Flex direction={"column"}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>Ime</Table.Th>
                <Table.Th>Priimek</Table.Th>
                <Table.Th>Email</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data?.users.map((user) => (
                <Table.Tr
                  key={user.userId}
                  onClick={() => setSelectedUserId(user.userId)}
                  style={{ cursor: "pointer" }}
                >
                  <Table.Td>
                    <Avatar src={user.imageUrl}>
                      {`${user.name?.split(" ").at(0)?.at(0) || ""} ${
                        user.name?.split(" ").at(1)?.at(0) || ""
                      }`}
                    </Avatar>
                  </Table.Td>
                  <Table.Td>{user.name}</Table.Td>
                  <Table.Td>{user.lastName}</Table.Td>
                  <Table.Td>{user.email}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Flex>
      )}
    </>
  );
};
