import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { t } from "@lingui/macro";
import { Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { OpenConfirmModal } from "node_modules/@mantine/modals/lib/context";
import React from "react";

type LimeAlertModal = {
  title: string;
  onConfirm: () => void;

  content?: string | React.ReactNode;

  confirmLabel?: string;
  cancelLabel?: string;
  onCancel?: () => void;
  isDanger?: boolean;
};

export const useLimeAlertModal = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const alertModal = ({
    title,
    content,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel,
    isDanger,
    ...rest
  }: LimeAlertModal & Omit<OpenConfirmModal, "labels">) => {
    const children =
      typeof content === "string" ? (
        <Text ta={"center"} variant={TextVariant.Body} c={Color.SecondaryText}>
          {content}
        </Text>
      ) : (
        content
      );

    modals.openConfirmModal({
      title: (
        <Text variant={isMobile ? TextVariant.Heading : TextVariant.Subheading}>
          {title}
        </Text>
      ),
      children: children,
      centered: true,
      groupProps: { align: "center", w: "100%", justify: "center" },
      onConfirm: onConfirm,
      onCancel: onCancel,
      labels: {
        confirm: confirmLabel || t`Potrdi`,
        cancel: cancelLabel || t`Prekliči`,
      },
      confirmProps: isDanger ? { color: "red" } : undefined,
      styles: {
        header: {
          justifyContent: "center",
          paddingBottom: title ? 12 : 0,
          alignItems: "flex-start",
          textAlign: "center",
        },
        content: {
          padding: "5px",
        },
      },
      withCloseButton: false,
      ...rest,
    });
  };

  return { alertModal };
};
