export function convertMinutesToHHmm(minutes: number) {
  // Calculate total hours and remaining minutes
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  // Format hours and minutes to ensure two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(remainingMinutes).padStart(2, "0");

  // Return the formatted time
  return `${formattedHours}:${formattedMinutes}`;
}
