import { BsCheck } from "react-icons/bs";
import {
  FaCheck,
  FaCheckCircle,
  FaEdit,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import styled from "styled-components";
import useUserPermissionsStore from "../../../../stores/useUserPermissionsStore";

const AppointmentActions = (props) => {
  const { setConfirmingDeleteAppointment } = { ...props };

  const permissions = useUserPermissionsStore((state) => state.permissions);

  return (
    <>
      <AppointmentActionsWrapper isMobile={props.isMobile}>
        {props.selectedAppointmentType === 0
          ? !!permissions.manage_appointments && (
              <>
                <Icons isMobile={props.isMobile}>
                  <>
                    <BtnsRow>
                      {props.isEditing && (
                        <CustomActionsIcon
                          onClick={() => {
                            props.updateUserAppointmentComment();
                          }}
                        >
                          <BsCheck />
                        </CustomActionsIcon>
                      )}
                      <CustomActionsIcon
                        onClick={() => {
                          // props.setShowComment(true);

                          if (!props.isEditing) {
                            props.setEditedComment(props.appointment.comment);
                          }

                          props.setIsEditing(!props.isEditing);
                        }}
                      >
                        {props.isEditing ? <FaTimes /> : <FaEdit />}
                      </CustomActionsIcon>

                      <CustomActionsIcon
                        onClick={() => {
                          setConfirmingDeleteAppointment(true);
                        }}
                      >
                        <FaTrash />
                      </CustomActionsIcon>
                    </BtnsRow>
                  </>
                </Icons>
                {/*<Button style={{marginTop: "10px"}}>Prenaroči</Button>*/}
              </>
            )
          : !!permissions.manage_appointments && (
              <>
                <Icons isMobile={props.isMobile}>
                  <BtnsRow style={{ marginBottom: "1rem" }}>
                    {!props.isEditing && (
                      <>
                        <CustomActionsIcon
                          color={
                            props.appointment.customerDidAttend === 1
                              ? "#74B587"
                              : "#969ba9"
                          }
                          onClick={() => {
                            if (props.appointment.customerDidAttend === 1) {
                              return;
                            }

                            props.updateUserAppointmentAttendance(
                              props.appointment.userAppointmentId,
                              1,
                            );
                          }}
                        >
                          <FaCheckCircle />
                        </CustomActionsIcon>
                        <CustomActionsIcon
                          color={
                            props.appointment.customerDidAttend === -1
                              ? "#FD4539"
                              : "#969ba9"
                          }
                          onClick={() => {
                            if (props.appointment.customerDidAttend === -1) {
                              return;
                            }
                            props.updateUserAppointmentAttendance(
                              props.appointment.userAppointmentId,
                              -1,
                            );
                          }}
                        >
                          <FaXmark />
                        </CustomActionsIcon>
                      </>
                    )}
                  </BtnsRow>
                  <BtnsRow>
                    {props.isEditing && (
                      <CustomActionsIcon
                        onClick={() => {
                          props.updateUserAppointmentComment();
                        }}
                      >
                        <FaCheck />
                      </CustomActionsIcon>
                    )}

                    <CustomActionsIcon
                      onClick={() => {
                        if (!props.isEditing) {
                          props.setEditedComment(props.appointment.comment);
                        }

                        props.setIsEditing(!props.isEditing);
                      }}
                    >
                      {props.isEditing ? <FaTimes /> : <FaEdit />}
                    </CustomActionsIcon>

                    <CustomActionsIcon
                      onClick={() => {
                        setConfirmingDeleteAppointment(true);
                      }}
                    >
                      <FaTrash />
                    </CustomActionsIcon>
                  </BtnsRow>
                </Icons>
                {/*<Button>Ponovno naroči</Button>*/}
              </>
            )}
      </AppointmentActionsWrapper>
    </>
  );
};

const BtnsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  margin-left: 1rem;
  align-items: center;
`;

const CustomActionsIcon = styled.div`
  color: ${(props) => props.color || "#8e8e8e;"};
  cursor: pointer;
  font-size: 1.3rem;

  @media (min-width: 768px) {
    :hover {
      color: #000000;
    }
  }
`;

const Icons = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  justify-content: flex-end;
  align-items: flex-start;
`;

const AppointmentActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: fit-content;
  margin-top: 0.5rem;
  ${(props) => props.isMobile && "gap:1rem"}
`;

export default AppointmentActions;
