import { useMutation, useQuery } from "@tanstack/react-query";

import {
  PostSetupFacebookConversionAPI,
  GetConversionApiData,
} from "@/server-types";
import { get, post } from "../request-helpers";

export const useGetFacebookConversionApiData = () => {
  return useQuery({
    queryKey: ["getFacebookConversionApiData"],
    queryFn: () =>
      get<GetConversionApiData>("/integration/facebook/conversion-api"),
  });
};

export const usePostSetupFacebookConversionAPI = () => {
  return useMutation({
    mutationKey: ["postSetupFacebookConversionAPI"],
    mutationFn: (body: PostSetupFacebookConversionAPI["body"]) =>
      post<PostSetupFacebookConversionAPI>(
        "/integration/facebook/conversion-api/setup",
        body,
      ),
  });
};
