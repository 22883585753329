import { Box, MultiSelect, MultiSelectProps } from "@mantine/core";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabel, LimeInputLabelProps } from "./LimeInputLabel";

export const LimeMultiSelect = ({
  ...props
}: LimeInputLabelProps & MultiSelectProps) => {
  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);

  return (
    <Box {...spacingProps}>
      <LimeInputLabel {...props} />

      <MultiSelect
        radius={8}
        {...propsWithoutSpacing}
        label={undefined}
        multiple
      />
    </Box>
  );
};
