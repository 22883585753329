import { RiCloseLine } from "react-icons/ri";
import styled from "styled-components";

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);

  border-radius: 12px;
  padding: ${(props) => (props.isMobile ? "1rem" : "2rem")};

  position: fixed;
  top: 20px;
  bottom: 20px;

  width: ${(props) => props.modalWidth};
  box-sizing: border-box;
  z-index: 1200;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  right: 20px;

  transform: ${(props) =>
    props.opened ? "translateX(0)" : "translateX(calc(100% + 20px))"};

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    gap: 15px;
  }

  @media (max-width: 768px) {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    padding-top: 20px;

    border-radius: 0;

    transform: ${(props) =>
      props.opened ? "translateY(0)" : "translateY(100%)"};
  }
`;

const Background = styled.div`
  position: fixed;
  z-index: 150;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  background: ${(props) =>
    props.isOpen ? "rgba(0, 0, 0, 0.2)" : "transparent"};
  pointer-events: ${(props) => (!props.isOpen ? "none" : "all")};
`;

const CustomIcon = styled.div`
  cursor: pointer;
  margin-left: 20px;
  font-size: 1.2rem;
`;

const IconsContainer = styled.div`
  height: fit-content;
  display: flex;
  /* margin-left: auto; */
  margin-bottom: ${(props) => (props.isMobile ? "1rem" : "3rem")};
  justify-content: space-between;
  align-items: baseline;
`;

const UsersModal = ({
  isMobile,
  opened,
  closeHandle,
  title,
  isEditingPermissions,
  isEditingNotifications,
  isEditingCustomValuesForServices,
  children,
}) => {
  let modalWidth;
  if (isMobile) modalWidth = "100%";
  else {
    if (isEditingPermissions) modalWidth = "80%";
    else if (isEditingNotifications) modalWidth = "550px";
    else if (isEditingCustomValuesForServices) modalWidth = "60%";
    else modalWidth = "450px";
  }

  return (
    <Background onMouseDown={() => closeHandle(false)} isOpen={opened}>
      <ModalWrapper
        modalWidth={modalWidth}
        isMobile={isMobile}
        onMouseDown={(e) => e.stopPropagation()}
        opened={opened}
      >
        <IconsContainer isMobile={isMobile}>
          <p
            style={{
              fontSize: "1.1rem",
              fontWeight: 600,
              margin: 0,
              color: "#639b74",
              paddingLeft: isMobile ? "3rem" : 0,
            }}
          >
            {title}
          </p>
          <CustomIcon onClick={() => closeHandle(false)}>
            <RiCloseLine
              size={25}
              style={{ cursor: "pointer", color: "gray" }}
            ></RiCloseLine>
          </CustomIcon>
        </IconsContainer>

        <div className="content">{children}</div>
      </ModalWrapper>
    </Background>
  );
};

export default UsersModal;
