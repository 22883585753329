import { Color } from "@/types/colors";
import {
  Divider,
  Input,
  type MantineThemeOverride,
  SegmentedControl,
  Text,
} from "@mantine/core";
import { isMobileSafari } from "react-device-detect";
import inputClasses from "../styles/input.module.css";
import segmentedControlClasses from "../styles/segmented-control.module.css";
import textClasses from "../styles/text.module.css";

const mantineTheme: MantineThemeOverride = {
  fontFamily: "Inter, sans-serif",
  colors: {
    brand: [
      "#EEF1EF",
      "#D5DED7",
      "#BDCFC2",
      "#A5C2AE",
      "#8DBA9A",
      "#74B587",
      "#68A379",
      "#61906F",
      "#5B7D65",
      "#556E5C",
    ],
  },
  primaryColor: "brand",
  components: {
    Input: Input.extend({
      classNames: inputClasses,
      styles: {
        input: {
          fontSize: isMobileSafari ? "max(1rem, 16px)" : "0.75rem",
        },
      },
    }),

    Text: Text.extend({
      classNames: textClasses,
    }),

    Divider: Divider.extend({
      vars: (_, __) => {
        return {
          root: {
            "--divider-color": Color.Divider,
          },
        };
      },
    }),

    Select: {
      defaultProps: {
        checkIconPosition: "right",
      },
    },

    ActionIcon: {
      defaultProps: {
        variant: "transparent",
        color: "var(--mantine-color-gray-7)",
      },
    },

    SegmentedControl: SegmentedControl.extend({
      classNames: segmentedControlClasses,
      defaultProps: {
        styles: {
          root: {
            backgroundColor: Color.TableHeader,
          },
        },
      },
    }),

    TextInput: {
      styles: {
        input: {
          borderColor: Color.Border,
        },
        icon: {
          fontSize: "0.85rem",
        },
      },
    },

    Textarea: {
      styles: {
        input: {
          borderColor: Color.Border,
        },
      },
    },

    ColorInput: {
      defaultProps: {
        styles: {
          input: {
            borderColor: Color.Border,
          },
        },
      },
    },
  },

  cursorType: "pointer",
};

export default mantineTheme;
