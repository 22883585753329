import TimeSpanRow from "@/Components/TimeSpanRow";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Collapse,
  Divider,
  Flex,
  Group,
  Modal,
  Select,
  Space,
  Switch,
  Text,
} from "@mantine/core";
import { Calendar } from "@mantine/dates";
import dayjs from "dayjs";
import React from "react";
import { RiEditFill } from "react-icons/ri";
import styled from "styled-components";
import { useScheduleStore } from "../../Stores/useScheduleStore";

const DayScheduleSettings = ({ data, updateData, date }) => {
  useLingui();
  const { selectedScheduleId, schedules } = useScheduleStore((state) => state);
  const schedule = schedules?.find((s) => s.scheduleId === selectedScheduleId);

  const [tempSelectedSpecificDates, setTempSelectedSpecificDates] =
    React.useState(null);

  React.useEffect(() => {
    onRepeatChange(data?.repeat);
  }, [data?.repeat]);

  const onRepeatChange = (value) => {
    if (value != null && value === "specific_dates") {
      if (data?.selected_specific_dates != null) {
        setTempSelectedSpecificDates(data?.selected_specific_dates);
      } else {
        setTempSelectedSpecificDates([]);
      }
    } else {
      setTempSelectedSpecificDates(null);
      updateValue("selected_specific_dates", null);
    }

    updateValue("repeat", value);
  };

  const handleSelectTempSpecificDate = (date) => {
    const onlyDate = dayjs(date).format("YYYY-MM-DD");

    const isSelected = tempSelectedSpecificDates.some((s) =>
      dayjs(onlyDate).isSame(s, "date"),
    );
    if (isSelected) {
      setTempSelectedSpecificDates((current) =>
        current.filter((d) => !dayjs(d).isSame(onlyDate, "date")),
      );
    } else {
      setTempSelectedSpecificDates((current) => [...current, onlyDate]);
    }
  };

  const updateValue = (key, value) => updateData(date, key, value);

  const isFormTimesSameAsWorkingTimes =
    data?.start === data?.formStart && data?.end === data?.formEnd;

  const workingTimesSet = data?.start != null && data?.end != null;

  const setFormTimesToWorkingTimes = () => {
    updateValue("formStart", data.start);
    updateValue("formEnd", data.end);
  };

  const scheduleStartDayObject = dayjs(schedule?.date_from);
  const scheduleEndDayObject = dayjs(schedule?.date_to);
  const todayDayObject = dayjs();

  let specificDateMinDate = todayDayObject;
  const specificDateMaxDate = scheduleEndDayObject;

  if (scheduleStartDayObject.isAfter(todayDayObject)) {
    specificDateMinDate = scheduleStartDayObject;
  }

  return (
    <Wrapper>
      <Flex direction={"column"} gap={"1rem"}>
        <Space h={"2"} />
        <Switch
          label={t`Delovni dan`}
          labelPosition={"left"}
          checked={data?.is_working}
          onChange={(event) =>
            updateValue("is_working", event.currentTarget.checked)
          }
        />
        <Collapse in={data?.is_working}>
          <Flex direction={"column"} gap={"1rem"}>
            <TimeSpanRow
              fromTimeValue={
                data.start ? dayjs(data.start).format("HH:mm") : null
              }
              toTimeValue={data.end ? dayjs(data.end).format("HH:mm") : null}
              onFromTimeChange={(value) => {
                if (!value.match(/^[0-9]{2}:[0-9]{2}$/)) return;

                const newDate = dayjs(`${date}T${value}`);

                updateValue("start", newDate.format("YYYY-MM-DD HH:mm:ss"));
              }}
              onToTimeChange={(value) => {
                if (!value.match(/^[0-9]{2}:[0-9]{2}$/)) return;

                const start = dayjs(data.start);
                const end = dayjs(`${date}T${value}`);

                updateValue(
                  "end",
                  end
                    .add(start.isAfter(end) ? 1 : 0, "day")
                    .format("YYYY-MM-DD HH:mm:ss"),
                );
              }}
              allowAnyTime
            />
            <Space h={"xs"} />
            <Switch
              label={t`Naročanje`}
              labelPosition={"left"}
              checked={data?.is_form_working}
              onChange={(event) =>
                updateValue("is_form_working", event.currentTarget.checked)
              }
            />
            <Collapse in={data?.is_form_working}>
              <TimeSpanRow
                fromTimeValue={
                  data.formStart ? dayjs(data.formStart).format("HH:mm") : null
                }
                toTimeValue={
                  data.formEnd ? dayjs(data.formEnd).format("HH:mm") : null
                }
                onFromTimeChange={(value) => {
                  if (!value.match(/^[0-9]{2}:[0-9]{2}$/)) return;

                  updateValue(
                    "formStart",
                    dayjs(`${date} ${value}`).format("YYYY-MM-DD HH:mm:ss"),
                  );
                }}
                onToTimeChange={(value) => {
                  if (!value.match(/^[0-9]{2}:[0-9]{2}$/)) return;

                  updateValue(
                    "formEnd",
                    dayjs(`${date} ${value}`).format("YYYY-MM-DD HH:mm:ss"),
                  );
                }}
                allowAnyTime
                isAllDay={isFormTimesSameAsWorkingTimes}
                setAllDay={workingTimesSet && setFormTimesToWorkingTimes}
                // minTime={dayjs(data.start).format("HH:mm")}
                // maxTime={dayjs(data.end).format("HH:mm")}
                aboveMaxErrorText={t`Čas naročanja mora biti v okviru delovnega časa`}
                belowMinErrorText={t`Čas naročanja mora biti v okviru delovnega časa`}
              />
            </Collapse>
            <Space h={"xs"} />
            <Text c={"#969BA9"} size={"sm"} inline>
              <Trans>Izberite, kolikokrat se urnik ponovi:</Trans>
            </Text>
            <Divider color={"#EAEBED"} />
            <Select
              data={[
                { label: t`Tedensko`, value: "weekly" },
                {
                  label: t`Ponovi vsak 2. teden`,
                  value: "biweekly",
                },
                {
                  label: t`Specifični datumi`,
                  value: "specific_dates",
                },
              ]}
              onChange={(value) => {
                updateValue("repeat", value);

                if (value === "specific_dates") {
                  setTempSelectedSpecificDates([]);
                }
              }}
              placeholder={t`Brez ponavljanja`}
              clearable
            />
            {data?.selected_specific_dates != null && (
              <>
                <Text c={"#969BA9"} size={"sm"} inline>
                  <Trans>Izbrani datumi:</Trans>
                  <Button
                    ml={"0.5rem"}
                    variant={"light"}
                    size={"xs"}
                    leftSection={<RiEditFill />}
                    onClick={() => {
                      onRepeatChange("specific_dates");
                    }}
                  >
                    <Trans>Uredi</Trans>
                  </Button>
                </Text>
                <Flex gap={"5px"} wrap={"wrap"}>
                  {data?.selected_specific_dates?.map((date, index) => (
                    <Text key={date} c={"#969BA9"} size={"sm"} inline span>
                      {dayjs(date).format("DD. MM. YYYY")}
                      {index !== data?.selected_specific_dates?.length - 1 &&
                        ", "}
                    </Text>
                  ))}
                </Flex>
              </>
            )}
            <Space h={"50px"} />
          </Flex>
        </Collapse>
      </Flex>

      <Modal
        opened={tempSelectedSpecificDates != null}
        onClose={() => {
          setTempSelectedSpecificDates(null);
        }}
        title={t`Izberite datume`}
        withinPortal={false}
        size={"auto"}
        centered
        lockScroll={false}
      >
        <Flex direction={"column"}>
          <Group position="center">
            <Calendar
              locale={"sl"}
              minDate={specificDateMinDate.toDate()}
              maxDate={specificDateMaxDate.toDate()}
              defaultDate={specificDateMinDate.toDate()}
              getDayProps={(date) => ({
                selected: tempSelectedSpecificDates?.some((s) =>
                  dayjs(date).isSame(s, "date"),
                ),
                onClick: () => handleSelectTempSpecificDate(date),
              })}
            />
          </Group>

          <Space h={"md"} />
          <Flex justify={"center"}>
            <Button
              onClick={() => {
                setTempSelectedSpecificDates(null);
              }}
              variant={"white"}
              color={"red"}
            >
              <Trans>Prekliči</Trans>
            </Button>
            <Button
              onClick={() => {
                updateValue(
                  "selected_specific_dates",
                  tempSelectedSpecificDates,
                );
                setTempSelectedSpecificDates(null);
              }}
              variant={"outline"}
            >
              <Trans>Potrdi</Trans>
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  .mantine-Switch-root {
    cursor: pointer;
    border-bottom: 1px solid #eaebed;
    padding-bottom: 0.5rem;
  }

  .mantine-Switch-body {
    justify-content: space-between;
  }

  .mantine-Switch-labelWrapper {
    width: 100%;
    color: #969ba9;
  }
`;

export default DayScheduleSettings;
