import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import {
  Collapse,
  ColorInput,
  ColorInputProps,
  ColorSwatch,
  Flex,
  Text,
} from "@mantine/core";
import { RiAddLine, RiCheckLine } from "react-icons/ri";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabel, LimeInputLabelProps } from "./LimeInputLabel";

const colors = [
  "#E9B2BF",
  "#EFE8CB",
  "#84D39C",
  "#DAE1F1",
  "#FFD964",
  "#B2DFE9",
];

export const LimeColorPicker = ({
  value,
  onChange,
  ...props
}: {
  value: string;
  onChange: (color: string) => void;
} & LimeInputLabelProps &
  ColorInputProps) => {
  const isCustomColor = !colors.includes(value);

  const { spacingProps } = extractSpacingProps(props);

  return (
    <Flex direction={"column"} {...spacingProps}>
      <LimeInputLabel {...props} />

      <Flex justify={"space-between"}>
        {colors.map((color) => (
          <ColorSwatch
            component="button"
            type={"button"}
            key={color}
            color={color}
            onClick={() => onChange(color)}
            size={35}
            withShadow={false}
            style={{ cursor: "pointer" }}
          >
            {color === value && "✓"}
          </ColorSwatch>
        ))}

        <ColorSwatch
          component="button"
          type={"button"}
          color={isCustomColor ? value : "#F5F5F5"}
          onClick={() => (isCustomColor ? undefined : onChange("#F5F5F5"))}
          size={35}
          style={{ cursor: "pointer" }}
        >
          {isCustomColor ? <RiCheckLine /> : <RiAddLine />}
        </ColorSwatch>
      </Flex>

      <Collapse in={isCustomColor}>
        <ColorInput
          mt={"md"}
          value={value}
          onChange={(color) => onChange(color)}
          radius={props.radius ?? 8}
        />
      </Collapse>

      {props.error ? (
        <Text
          mt={"calc(var(--mantine-spacing-xs) / 2)"}
          c={Color.Error}
          variant={TextVariant.Caption}
        >
          {props.error}
        </Text>
      ) : undefined}
    </Flex>
  );
};
