import { Box, Textarea, TextareaProps } from "@mantine/core";
import { forwardRef } from "react";
import { extractSpacingProps } from "./LimeComponentUtils";
import { LimeInputLabel, LimeInputLabelProps } from "./LimeInputLabel";

export const LimeTextarea = forwardRef<
  HTMLTextAreaElement,
  LimeInputLabelProps & TextareaProps
>((props, ref) => {
  const { propsWithoutSpacing, spacingProps } = extractSpacingProps(props);

  return (
    <Box {...spacingProps}>
      <LimeInputLabel {...props} />

      <Textarea
        styles={{
          label: {
            fontWeight: 500,
          },
        }}
        radius={8}
        {...propsWithoutSpacing}
        label={undefined}
        ref={ref}
      />
    </Box>
  );
});

LimeTextarea.displayName = "LimeTextarea";
