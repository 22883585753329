import { t, Trans } from "@lingui/macro";
import { z } from "zod";
import { formatCurrency } from "../../../../../../shared/utils/utils";
import { Button, Card, CardBody, CardHeader, Divider } from "@nextui-org/react";
import { Pencil, Trash } from "lucide-react";
import { manageDeliveryOrderSchema } from "./utils";
import { GetProducts } from "@/server-types";

export const ProductCard = ({
  product,
  index,
  products,
  currency,
  locale,

  onEditClick,
  onRemoveClick,
}: {
  product: z.infer<typeof manageDeliveryOrderSchema>["products"][number];
  index: number;
  products?: GetProducts["response"];
  currency?: string;
  locale: string;

  onEditClick?: () => void;
  onRemoveClick?: () => void;
}) => {
  const productInfo = products?.products.find(
    (p) => p.id === product.productId,
  );

  if (!productInfo) return null;

  const netPriceTotal =
    Math.round(product.netPricePer * product.quantity * 100) / 100;

  const grossPriceTotal =
    Math.round(product.grossPricePer * product.quantity * 100) / 100;

  const netPriceTotalFormatted = formatCurrency({
    amount: netPriceTotal,
    currency: currency || "EUR",
    locale,
  });

  const grossPriceTotalFormatted = formatCurrency({
    amount: grossPriceTotal,
    currency: currency || "EUR",
    locale,
  });

  return (
    <Card shadow="sm" radius="md">
      <CardHeader className="justify-between">
        <p>
          {index + 1}. {productInfo.name}
        </p>
        <div className="flex gap-2">
          {onEditClick ? (
            <Button
              isIconOnly
              className="bg-transparent"
              size="sm"
              onPress={onEditClick}
            >
              <Pencil size={20} />
            </Button>
          ) : undefined}
          {onRemoveClick ? (
            <Button
              isIconOnly
              className="bg-transparent"
              size={"sm"}
              onPress={onRemoveClick}
            >
              <Trash size={20} />
            </Button>
          ) : undefined}
        </div>
      </CardHeader>

      <Divider />

      <CardBody>
        <div className="flex w-full flex-col gap-2 text-sm">
          <div className="flex justify-between">
            <p className="font-medium">
              <Trans>Količina</Trans>
            </p>
            <p>{product.quantity}</p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium">
              <Trans>Neto cena skupaj</Trans>
            </p>
            <p>{netPriceTotalFormatted}</p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium">
              <Trans>DDV</Trans>
            </p>
            <p>{product.taxPercentage}%</p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium">
              <Trans>Bruto cena skupaj</Trans>
            </p>
            <p>{grossPriceTotalFormatted}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
