import { parsePhoneNumber } from "react-phone-number-input";

export const emailValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const fullPhoneNumberToNumberAndCountryCode = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

  if (parsedPhoneNumber == null) {
    return null;
  }

  const gsm = parsedPhoneNumber.formatNational().replaceAll(" ", "").trim();
  const countryCode = parsedPhoneNumber.countryCallingCode;

  return { gsm, countryCode };
};

export const numberAndCountryCodeToFullPhoneNumber = (
  phoneNumber,
  countryCode,
) => {
  const hasCountryCode = phoneNumber?.startsWith("+");

  if (!phoneNumber || (!hasCountryCode && !countryCode)) {
    return "";
  }

  let finalPhoneNumber = phoneNumber;
  if (!hasCountryCode) {
    finalPhoneNumber = `+${countryCode}${phoneNumber}`;
  }

  const parsedNumber = parsePhoneNumber(finalPhoneNumber);
  if (parsedNumber == null) {
    return "";
  }

  return parsedNumber.number;
};

export const areTimesValid = (startTime, endTime) => {
  return startTime != null && endTime != null && startTime < endTime;
};

export const getDurationsAndPrice = (service, user) => {
  let _startDuration;
  let _timeOffDuration;
  let _endDuration;

  const _hasTimeOff = service.timeOffStart != 0 && service.timeOffDuration != 0;

  const fullDuration = (user?.duration ?? service.duration) * 1;

  if (_hasTimeOff) {
    const startDuration = service.timeOffStart;

    let endDuration = fullDuration - startDuration - service.timeOffDuration;
    if (endDuration < 0) endDuration = 0;

    _startDuration = startDuration;
    _timeOffDuration = service.timeOffDuration;
    _endDuration = endDuration;
  } else {
    _startDuration = fullDuration;
    _timeOffDuration = null;
    _endDuration = null;
  }

  const _price = user?.price ?? service?.price ?? 0;

  return {
    startDuration: _startDuration,
    timeOffDuration: _timeOffDuration,
    endDuration: _endDuration,
    price: _price,
    hasTimeOff: _hasTimeOff,
  };
};

export function calculateYOffsetForTime({
  time,
  startTime,
  endTime,
  timeStep,
  slotHeight,
}) {
  const timeSplit = time.split(":");
  const hour = parseInt(timeSplit[0]);
  const minute = parseInt(timeSplit[1]);

  const startSplit = startTime.split(":");
  const startHour = parseInt(startSplit[0]);
  const startMinute = parseInt(startSplit[1]);

  const minutesFromStart = (hour - startHour) * 60 + (minute - startMinute);

  const endSplit = endTime.split(":");
  const endHour = parseInt(endSplit[0]);
  const endMinute = parseInt(endSplit[1]);

  const minutesFromEnd = (endHour - hour) * 60 + (endMinute - minute);

  // If the time is before the start time, return 0
  if (minutesFromStart < 0) {
    return 0;
  }

  // If the time is after the end time, return the height of the last slot
  if (minutesFromEnd < 0) {
    const minutesBetweenStartAndEnd =
      (endHour - startHour) * 60 + (endMinute - startMinute);

    return (minutesBetweenStartAndEnd / timeStep) * slotHeight;
  }

  const rowIndex = minutesFromStart / timeStep;

  return rowIndex * slotHeight;
}
