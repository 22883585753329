import { ActionIcon, Flex, NumberInput, Text } from "@mantine/core";
import { RiAddLine, RiSubtractFill } from "react-icons/ri";

const StepperNumberInput = ({
  value,
  onChange,
  step,
  unitText,
  label,
  min,
  max,
  disabled,
  ...props
}) => {
  return (
    <div>
      <Text size={"sm"} variant="caption">
        {label}
      </Text>
      <Flex align={"center"} gap={"2px"}>
        {disabled ? null : (
          <ActionIcon
            w={"36px"}
            h={"36px"}
            bg={"#f8f8f8"}
            onClick={() => {
              if (min != null && value - step < min) {
                onChange(min);
                return;
              }
              onChange(value - step);
            }}
          >
            <RiSubtractFill />
          </ActionIcon>
        )}
        <NumberInput
          value={value}
          onChange={onChange}
          hideControls={true}
          variant={"filled"}
          {...props}
          disabled={disabled}
          label={null}
          style={{ width: disabled ? undefined : "80px" }}
          min={min}
          max={max}
          rightSection={unitText}
          styles={{
            section: {
              fontSize: "14px",
              color: "#6b7280",
              paddingRight: "10px",
            },
          }}
        />

        {disabled ? null : (
          <ActionIcon
            w={"36px"}
            h={"36px"}
            bg={"#f8f8f8"}
            onClick={() => {
              if (max != null && value + step > max) {
                onChange(max);
                return;
              }
              onChange(value + step);
            }}
          >
            <RiAddLine />
          </ActionIcon>
        )}
      </Flex>
    </div>
  );
};

export default StepperNumberInput;
