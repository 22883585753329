import dayjs from "dayjs";

export function assignZIndexes(
  appointments: {
    startTime: string;
    overlapIndex: number;
    duration: number;
    endTime: string;
    appointmentId: number;
    isOnPreviousResource: boolean;
    formattedData: {
      startTime: string;
    };
  }[],
) {
  // Calculate endTime for each appointment
  const newAppointments = structuredClone(appointments).map((a) => ({
    ...a,
    overlapIndex: 0,
  }));

  newAppointments.sort((a, b) =>
    dayjs(a.startTime ?? a.formattedData.startTime, "HH:mm").diff(
      dayjs(b.startTime ?? b.formattedData.startTime, "HH:mm"),
    ),
  );

  newAppointments.map((a) => {
    const startTimeCurrent = a.startTime;
    const endTimeCurrent = dayjs(startTimeCurrent, "HH:mm")
      .add(a.duration, "minute")
      .format("HH:mm");

    const allOverlappingAppointments = newAppointments.filter((app) => {
      if (app.appointmentId === a.appointmentId) {
        return false;
      }
      const startTimePrev = app.startTime;
      const endTimePrev = dayjs(startTimePrev, "HH:mm")
        .add(app.duration, "minute")
        .format("HH:mm");

      const isOverlapping =
        endTimeCurrent > startTimePrev && startTimeCurrent < endTimePrev;
      return isOverlapping;
    });

    if (allOverlappingAppointments.length === 0) {
      a.overlapIndex = 0;
      return null;
    }

    allOverlappingAppointments.sort((a, b) => {
      return a.overlapIndex - b.overlapIndex;
    });

    let currentIndex = -1;

    allOverlappingAppointments.forEach((app) => {
      if (app.overlapIndex === currentIndex) {
        currentIndex++;
        if (currentIndex === 0) {
          currentIndex++;
        }
      }
    });

    a.overlapIndex = currentIndex;
  });

  return newAppointments;
}

export const returnYoffsetForEvent = ({
  startTimeEvent,
  calendarStartTime,
  timeStep,
  slotHeight,
}: {
  startTimeEvent: string;
  calendarStartTime: string;
  timeStep: number;
  slotHeight: number;
}) => {
  return (
    (dayjs(dayjs(startTimeEvent).format("HH:mm"), "HH:mm").diff(
      dayjs(calendarStartTime, "HH:mm"),
      "minute",
    ) /
      timeStep) *
    slotHeight
  );
};

export const returnHeightOfEventBlock = ({
  duration,
  timeStep,
  slotHeight,
}: {
  duration: number;
  timeStep: number;
  slotHeight: number;
}) => {
  const ratio = duration / timeStep;
  return ratio * slotHeight;
};
