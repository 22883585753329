import { LimeTextInput } from "@/Components/LimeTextInput";
import { TextVariant } from "@/types/text-variants";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Divider,
  MultiSelect,
  Space,
  TagsInput,
  Text,
  Flex,
} from "@mantine/core";
import _ from "lodash";
import { RiArrowRightLine } from "react-icons/ri";
import { isValidPhoneNumber } from "react-phone-number-input";
import LimePhoneInputField from "../../../Components/LimePhoneInputField";
import LocalePicker from "../../../Components/LocalePicker";
import useFeature from "../../../hooks/useFeature";
import { LimeNumberInput } from "@/Components/LimeNumberInput";
import { useState } from "react";
import { RiAlertLine } from "react-icons/ri";
import { BiShow, BiHide } from "react-icons/bi";
import { LimeSwitch } from "@/Components/LimeSwitch";

const IsPasswordLengthValid = (value) => (value ? value.length >= 8 : false);
const IsPasswordNumberValid = (value) => (value ? /\d/.test(value) : false);
const IsPasswordSpecialCharacterValid = (value) =>
  value ? /[!@#$%^&*]/.test(value) : false;
const IsPasswordBigCharacterValid = (value) =>
  value ? /[A-Z]/.test(value) : false;
const IsPasswordSmallCharacterValid = (value) =>
  value ? /[a-z]/.test(value) : false;

export const ManagingUserView = ({
  hasSubmitted,
  deleteUser,
  addUserUpdate,
  updateUser,
  setIsEditingPermissions,
  setIsEditingNotifications,
  setIsEditingCustomValuesForServices,
  fields,
  setValues,
  user,
  closeHandle,
  handleAddUserContinue,
  isSignedInUser,
  permissions,
  editingAdmin,
  hasVisitedAllPermissions,
  addUser,
  roles,
  locations,
  setRoles,
  userServices,
  services,
}) => {
  useLingui();
  const { isFeatureEnabled } = useFeature();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  let editPermissionsAction;
  let editNotificationsAction;
  let editCustomValuesForServices = null;

  if (
    (permissions && user != null) ||
    (user == null && hasVisitedAllPermissions)
  ) {
    editPermissionsAction = (
      <SubpageButton
        disabled={editingAdmin}
        onClick={() => setIsEditingPermissions(true)}
      >
        <span>
          {!editingAdmin ? (
            <Trans>Urejanje dovoljenj osebe</Trans>
          ) : (
            <Trans>Urejanje dovoljenj ni mogoče</Trans>
          )}
        </span>
        <RiArrowRightLine />
      </SubpageButton>
    );

    if (updateUser && isFeatureEnabled("C_NOTIFICATION")) {
      editNotificationsAction = (
        <SubpageButton onClick={() => setIsEditingNotifications(true)}>
          <span>
            <Trans>Urejanje obvestil osebe</Trans>
          </span>
          <RiArrowRightLine />
        </SubpageButton>
      );
    } else {
      editNotificationsAction = null;
    }

    if (
      isFeatureEnabled("USER_ROLES") &&
      userServices.length > 0 &&
      services.filter((s) =>
        userServices.some((us) => us.serviceId === s.serviceId),
      ).length > 0
    ) {
      editCustomValuesForServices = (
        <SubpageButton
          onClick={() => setIsEditingCustomValuesForServices(true)}
        >
          <span>
            <Trans>Urejanje cen in trajanj po meri</Trans>
          </span>
          <RiArrowRightLine />
        </SubpageButton>
      );
    }
  }
  if (!permissions) {
    editPermissionsAction = (
      <p>
        <Trans>
          Napaka pri dovoljenjih osebe! Kontaktirajte administratorja
        </Trans>
      </p>
    );
  }

  let buttons;
  if (updateUser) {
    buttons = {
      positive: {
        label: t`Posodobi`,
        onClick: addUserUpdate,
        disabled:
          !_.isEmpty(fields.phone) &&
          isValidPhoneNumber(fields.phone) === false,
      },
      negative: !isSignedInUser && {
        label: t`Izbriši osebo`,
        onClick: deleteUser,
      },
    };
  } else {
    buttons = {
      positive: {
        label: hasVisitedAllPermissions ? t`Shrani` : t`Nadaljuj`,
        onClick: hasVisitedAllPermissions ? addUser : handleAddUserContinue,
        disabled:
          hasVisitedAllPermissions &&
          !_.isEmpty(fields.phone) &&
          isValidPhoneNumber(fields.phone) === false,
      },
      negative: {
        label: t`Prekliči`,
        onClick: closeHandle,
      },
    };
  }

  const rolesData =
    roles.map((role) => {
      return {
        ...role,
        value: role.value?.toString() ?? "",
      };
    }) ?? [];

  return (
    <>
      <TagsInput
        label={t`Vloga`}
        data={rolesData}
        maxTags={1}
        variant={"filled"}
        onChange={(values) => {
          if (values.length === 0) {
            setValues(null, "role");
            return;
          }

          const newRole = values[0];

          const isNew = !roles.some((role) => role.label === newRole);
          if (!isNew) {
            setValues(
              roles.find((role) => role.label === newRole)?.value,
              "role",
            );
          } else {
            setRoles([...roles, { value: newRole, label: newRole, new: true }]);
            setValues(newRole, "role");
          }
        }}
        onOptionSubmit={(value) => {
          setValues(parseInt(value), "role");
        }}
        value={
          fields.role
            ? [roles.find((role) => role.value == fields.role)?.label]
            : []
        }
      />
      <LimeTextInput
        label={t`Ime`}
        id={"name"}
        error={hasSubmitted && fields.name === "" && t`* To polje je obvezno`}
        value={fields.name}
        onChange={(e) => {
          setValues(e.currentTarget.value, "name");
        }}
        required
        variant={"filled"}
      />
      <LimeTextInput
        label={t`Priimek`}
        id={"lastName"}
        error={
          hasSubmitted && fields.lastName === "" && t`* To polje je obvezno`
        }
        value={fields.lastName}
        onChange={(e) => {
          setValues(e.currentTarget.value, "lastName");
        }}
        required
        variant={"filled"}
      />
      <LimeTextInput
        label={t`Elektronski naslov`}
        id={"email"}
        error={hasSubmitted && fields.email === "" && t`* To polje je obvezno`}
        value={fields.email}
        onChange={(e) => {
          setValues(e.currentTarget.value, "email");
        }}
        required
        variant={"filled"}
      />
      <LimeTextInput
        label={t`Geslo`}
        id={"password"}
        error={
          hasSubmitted &&
          fields.password !== "" &&
          (!IsPasswordLengthValid(fields.password) ||
            !IsPasswordNumberValid(fields.password) ||
            !IsPasswordSpecialCharacterValid(fields.password) ||
            !IsPasswordBigCharacterValid(fields.password) ||
            !IsPasswordSmallCharacterValid(fields.password)) &&
          t`Geslo ne izpolnjuje zahtev`
        }
        value={fields.password || ""}
        onChange={(e) => {
          setValues(e.currentTarget.value, "password");
        }}
        type={showPassword ? "text" : "password"}
        required
        variant={"filled"}
        autoComplete={"dont-fill"}
        rightSection={
          !showPassword ? (
            <BiShow
              color="#c9cccf"
              size={23}
              onClick={togglePasswordVisibility}
            />
          ) : (
            <BiHide
              color="#c9cccf"
              size={23}
              onClick={togglePasswordVisibility}
            />
          )
        }
      />
      {fields.password && (
        <PasswordRequirements
          smallCharacters={IsPasswordSmallCharacterValid(fields.password)}
          bigCharacters={IsPasswordBigCharacterValid(fields.password)}
          specialCharacters={IsPasswordSpecialCharacterValid(fields.password)}
          numbers={IsPasswordNumberValid(fields.password)}
          length={IsPasswordLengthValid(fields.password)}
        />
      )}
      <LimeTextInput
        label={t`Opis`}
        id={"description"}
        value={fields.description}
        onChange={(e) => {
          const value = e.currentTarget.value;
          if (value.length > 180) return;
          setValues(e.currentTarget.value, "description");
        }}
        variant={"filled"}
      />
      <LimePhoneInputField
        onChange={(e) => {
          if (!e) return;
          setValues(e, "phone");
        }}
        value={fields.phone}
        label={t`Telefonska številka`}
      />
      <LimeTextInput
        onChange={(e) => {
          if (!e) return;
          setValues(e.currentTarget.value, "taxNumber");
        }}
        min={10000000}
        value={fields.taxNumber}
        label={t`Davčna številka`}
      />
      <LimeSwitch
        label={t`Je samostojni podjetnik`}
        checked={fields.isSoleTrader}
        onChange={(e) => {
          setValues(e.currentTarget.checked, "isSoleTrader");
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.25rem",
        }}
      >
        <Text c={"rgba(33, 37, 41)"} variant={TextVariant.BodyEmphasized}>
          <Trans>Jezik</Trans>
        </Text>
        <LocalePicker
          value={fields.preferred_language}
          onChange={(e) => {
            setValues(e, "preferred_language");
          }}
        />
      </div>
      <Divider />
      <MultiSelect
        label={t`Lokacije na katerih dela zaposleni`}
        data={locations.map((location) => {
          return {
            ...location,
            value: location.value?.toString() ?? "",
          };
        })}
        placeholder={t`Izberite lokacije`}
        searchable
        onChange={(values) => {
          setValues(
            values.map((v) => Number(v)),
            "locations",
          );
        }}
        value={fields.locations.map((l) => l.toString())}
        variant="filled"
      ></MultiSelect>
      {editPermissionsAction}
      {editNotificationsAction}
      {editCustomValuesForServices}
      <Space h={"1.5rem"} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {buttons.negative && (
          <Button
            className="button_submit"
            onClick={buttons.negative.onClick}
            color="red"
            mr={"md"}
            variant="white"
          >
            {buttons.negative.label}
          </Button>
        )}

        {buttons.positive && (
          <Button
            onClick={buttons.positive.onClick}
            disabled={buttons.positive.disabled}
          >
            {buttons.positive.label}
          </Button>
        )}
      </div>
    </>
  );
};

const PasswordRequirements = ({
  smallCharacters,
  bigCharacters,
  specialCharacters,
  numbers,
  length,
}) => {
  return (
    <div>
      <Flex gap={"md"} align={"flex-start"}>
        <RiAlertLine
          color={
            smallCharacters &&
            bigCharacters &&
            specialCharacters &&
            numbers &&
            length
              ? "green"
              : "red"
          }
          size={"1.5rem"}
          style={{
            flexShrink: 0,
          }}
        />
        <Text variant={TextVariant.Caption}>
          <Trans>Geslo mora vsebovati</Trans>{" "}
          {
            <PasswordRequirementText
              text={t`eno malo začetnico`}
              valid={smallCharacters}
            />
          }
          ,{" "}
          {
            <PasswordRequirementText
              text={t`veliko začetnico`}
              valid={bigCharacters}
            />
          }
          ,{" "}
          {
            <PasswordRequirementText
              text={t`poseben znak`}
              valid={specialCharacters}
            />
          }
          , {<PasswordRequirementText text={t`številko`} valid={numbers} />}.
          Dolgo mora biti{" "}
          {<PasswordRequirementText text={t`vsaj 8 znakov`} valid={length} />}.
        </Text>
      </Flex>
    </div>
  );
};

const PasswordRequirementText = ({ text, valid }) => {
  return (
    <Text
      variant={TextVariant.CaptionEmphasized}
      inline
      span
      c={valid ? "green" : "red"}
    >
      {text}
    </Text>
  );
};

const SubpageButton = (props) => {
  return (
    <button
      {...props}
      className="c-[#3f3f3f] flex justify-between border-b border-[#e0e0e0] py-2 font-medium"
    >
      {props.children}
    </button>
  );
};
