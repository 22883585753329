import { GetTimeOff } from "@/server-types";
import { notifications } from "@mantine/notifications";
import { useMutation, useQuery } from "@tanstack/react-query";
import { PutTimeoff } from "../../../../../server/src/Controllers/Calendar/timeoff/put";
import { get, post } from "../request-helpers";

/**
 * GET /calendar/timeoff
 */
export const useGetTimeoff = (query: GetTimeOff["query"]) => {
  return useQuery({
    queryKey: ["timeoff", query],
    queryFn: () => get<GetTimeOff>("/calendar/timeoff", query),
  });
};

/**
 * POST /calendar/timeoff
 */
export const usePutTimeoff = () => {
  return useMutation({
    mutationFn: (body: PutTimeoff["body"]) =>
      post<PutTimeoff>("/calendar/timeoff", body),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      console.log("ERR", error, typeof error);
      notifications.show({
        color: "red",
        message: error.response.data.error,
      });
    },
  });
};
