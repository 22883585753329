import LoadingSpinner from "@/Components/Dashboard/LoadingSpinner/LoadingSpinner";
import { EasyNumberInput } from "@/Components/EasyNumberInput";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { api } from "@/lib/api-client";
import { PostResourceGroup } from "@/server-types";
import usePreferredLanguageStore, {
  SUPPORTED_LANGUAGES,
} from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import {
  Accordion,
  Box,
  Button,
  Divider,
  Drawer,
  Flex,
  Modal,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { UseFormReturnType, useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useEffect, useRef, useState } from "react";
import { MdTranslate } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ActionButton } from "../Components/ActionButton";
import useManageResourceGroup from "../useManageResourceGroup";

type Form = PostResourceGroup["body"];

const defaultData: Form = {
  capacityForm: 0,
  capacityMax: 0,
  namesLocalized: SUPPORTED_LANGUAGES.map((language) => ({
    language,
    namePlural: "",
    nameSingular: "",
  })),
  resourceCount: 0,
};

export const GeneralTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const selectedResourceGroupId = id ? parseInt(id) : undefined;
  const isEditing =
    selectedResourceGroupId != -1 && searchParams.get("onboarding") == null;

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  const { navigateToTab } = useManageResourceGroup();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [
    localizationDrawerOpened,
    { open: openLocalizationDrawer, close: closeLocalizationDrawer },
  ] = useDisclosure(false);

  const formRef = useRef<HTMLFormElement>(null);

  const [savedLocalizationData, setSavedLocalizationData] = useState<
    PostResourceGroup["body"]["namesLocalized"]
  >([]);

  const { mutateAsync: postResourceGroup, isPending: isPostingResource } =
    api.resourceGroups.usePostResourceGroup();

  const { mutateAsync: putResourceGroup, isPending: isPuttingResource } =
    api.resourceGroups.usePutResourceGroup();

  const {
    data,
    isLoading: isGettingResourceGroup,
    isError: isGettingResourceGroupError,
  } = api.resourceGroups.useGetResourceGroup(selectedResourceGroupId);

  const validateNamesLocalized = (
    localizedName: PostResourceGroup["body"]["namesLocalized"][number],
    field: "singular" | "plural",
  ) => {
    const nameSingular = localizedName.nameSingular;
    const namePlural = localizedName.namePlural;

    if (field === "singular" && !nameSingular && namePlural) {
      return t`Ime skupine sredstev je obvezen podatek.`;
    }

    if (field === "plural" && !namePlural && nameSingular) {
      return t`Ime skupine sredstev je obvezen podatek.`;
    }

    return null;
  };

  const form = useForm<Form>({
    initialValues: defaultData,

    validate: {
      namesLocalized: {
        nameSingular: (_, values, path) => {
          const index = parseInt(path.split(".")[1]);
          const namesLocalized = values[
            "namesLocalized"
          ] as PostResourceGroup["body"]["namesLocalized"];

          return validateNamesLocalized(namesLocalized[index], "singular");
        },
        namePlural: (_, values, path) => {
          const index = parseInt(path.split(".")[1]);
          const namesLocalized = values[
            "namesLocalized"
          ] as PostResourceGroup["body"]["namesLocalized"];

          return validateNamesLocalized(namesLocalized[index], "plural");
        },
      },

      capacityForm: (value) => {
        if (value < 0) {
          return t`Kapaciteta uporabnikov sredstva ne more biti negativna.`;
        }

        console.log("cform", value, form.values.capacityMax);

        if (value > form.values.capacityMax) {
          return t`Kapaciteta uporabnikov sredstva ne more biti večja od kapacitete sredstva preko spletne forme.`;
        }

        return null;
      },

      capacityMax: (value) => {
        if (value < 0) {
          return t`Kapaciteta sredstva preko spletne forme ne more biti negativna.`;
        }

        return null;
      },
    },
  });

  useEffect(() => {
    if (isGettingResourceGroupError) {
      navigate("/dashboard/resources");
      return;
    }

    if (data) {
      const existingNamesLocalized = data.namesLocalized.map(
        (name) => name.language,
      );
      const missingNamesLocalized = SUPPORTED_LANGUAGES.filter(
        (language) => !existingNamesLocalized.includes(language),
      );

      const newData = {
        ...data,
        namesLocalized: [
          ...data.namesLocalized,
          ...missingNamesLocalized.map((language) => ({
            language,
            namePlural: "",
            nameSingular: "",
          })),
        ],
      };

      form.initialize(newData);
    }
  }, [data, form, isGettingResourceGroupError, navigate]);

  const handleOpenLocalizationDrawer = () => {
    setSavedLocalizationData(form.values.namesLocalized);

    openLocalizationDrawer();
  };

  const handleCancelLocalizationDrawer = () => {
    form.setValues({ namesLocalized: savedLocalizationData });

    closeLocalizationDrawer();
  };

  const handleSaveLocalizationDrawer = () => {
    closeLocalizationDrawer();
  };

  const handleSubmit = async (values: PostResourceGroup["body"]) => {
    try {
      if (selectedResourceGroupId && selectedResourceGroupId !== -1) {
        await putResourceGroup({
          resourceGroupId: selectedResourceGroupId,
          resourceGroup: values,
        });

        if (isEditing) {
          notifications.show({
            message: t`Sredstvo uspešno posodobljeno`,
            color: "green",
          });
          return;
        }

        navigateToTab({
          currentTab: "general",
          resourceGroupId: selectedResourceGroupId,
          direction: "next",
        });
      } else {
        const response = await postResourceGroup(values);
        const resourceGroupId = response.resourceGroupId;

        if (!resourceGroupId || isEditing) {
          return;
        }

        navigateToTab({
          currentTab: "general",
          resourceGroupId,
          direction: "next",
        });
      }
    } catch (e) {
      notifications.show({
        message: t`Napaka pri shranjevanju sredstva.`,
        color: "red",
      });
    }
  };

  if (isGettingResourceGroup) {
    return <LoadingSpinner />;
  }

  const defaultLanguageLocalizedNameIndex =
    form.values.namesLocalized.findIndex(
      (name) => name.language === defaultLanguage,
    );
  return (
    <form
      ref={formRef}
      onSubmit={form.onSubmit(handleSubmit)}
      style={{ display: "contents" }}
    >
      <Flex
        direction={"column"}
        gap={"md"}
        align={"flex-end"}
        mt={"md"}
        pb={15}
        w={"100%"}
      >
        <Flex
          px={"md"}
          w={"100%"}
          direction={"column"}
          gap={"md"}
          align={"flex-end"}
        >
          <LimeTextInput
            label={t`Ime skupine sredstev (ednina)`}
            {...form.getInputProps(
              `namesLocalized.${defaultLanguageLocalizedNameIndex}.nameSingular`,
            )}
            w={"100%"}
            required
          />
          <LimeTextInput
            label={t`Ime skupine sredstev (množina)`}
            {...form.getInputProps(
              `namesLocalized.${defaultLanguageLocalizedNameIndex}.namePlural`,
            )}
            radius={8}
            w={"100%"}
            required
          />
          <Button
            variant="light"
            onClick={handleOpenLocalizationDrawer}
            size="xs"
            w={"fit-content"}
            leftSection={<MdTranslate />}
          >
            <Trans>Nastavitve prevodov</Trans>
          </Button>
        </Flex>

        <Divider orientation="horizontal" w={"100%"} />

        <Box px={"md"} w={"100%"}>
          <EasyNumberInput
            label={t`Kapaciteta uporabnikov sredstva`}
            {...form.getInputProps("capacityMax")}
            min={0}
          />
        </Box>

        <Divider orientation="horizontal" w={"100%"} />

        <Box px={"md"} w={"100%"}>
          <EasyNumberInput
            label={t`Kapaciteta sredstva preko spletne forme`}
            {...form.getInputProps("capacityForm")}
            min={0}
          />
        </Box>
        {selectedResourceGroupId === -1 && (
          <>
            <Divider orientation="horizontal" w={"100%"} />

            <Box px={"md"} w={"100%"}>
              <EasyNumberInput
                label={t`Število sredstev`}
                {...form.getInputProps("resourceCount")}
                min={0}
              />
            </Box>
          </>
        )}
      </Flex>

      <LanguageSettingsDrawer
        opened={(isMobile || false) && localizationDrawerOpened}
        form={form}
        cancel={handleCancelLocalizationDrawer}
        save={handleSaveLocalizationDrawer}
      />

      <LanguageSettingsModal
        opened={!isMobile && localizationDrawerOpened}
        form={form}
        cancel={handleCancelLocalizationDrawer}
        save={handleSaveLocalizationDrawer}
      />

      <ActionButton
        isLoading={isPostingResource || isPuttingResource}
        text={isEditing ? t`Shrani` : t`Nadaljuj`}
      />
    </form>
  );
};

const LanguageSettingsModal = ({
  opened,
  cancel,
  save,
  form,
}: {
  opened: boolean;
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<PostResourceGroup["body"]>;
}) => {
  return (
    <Modal
      opened={opened}
      onClose={cancel}
      centered
      withinPortal={false}
      styles={{
        root: {
          position: "fixed",
          left: 0,
          zIndex: 200,
        },
        inner: {
          padding: 0,
        },
      }}
      title={t`Nastavitve prevodov`}
      radius={12}
    >
      <Divider orientation={"horizontal"} />

      <LanguageSettingsContent form={form} cancel={cancel} save={save} />
    </Modal>
  );
};

const LanguageSettingsDrawer = ({
  opened,
  cancel,
  save,
  form,
}: {
  opened: boolean;
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<PostResourceGroup["body"]>;
}) => {
  return (
    <Drawer
      opened={opened}
      position="bottom"
      onClose={cancel}
      title={t`Nastavitve prevodov`}
      overlayProps={{ backgroundOpacity: 0.2, blur: 1 }}
      styles={{
        content: {
          borderTopLeftRadius: "var(--mantine-radius-lg)",
          borderTopRightRadius: "var(--mantine-radius-lg)",
        },
        body: {
          paddingBottom: 0,
        },
      }}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
      size={"450px"}
    >
      <LanguageSettingsContent form={form} cancel={cancel} save={save} />
    </Drawer>
  );
};

const LanguageSettingsContent = ({
  cancel,
  save,
  form,
}: {
  cancel: () => void;
  save: () => void;
  form: UseFormReturnType<PostResourceGroup["body"]>;
}) => {
  const firstLanguage = i18n.locale;
  const sortedLanguages = form.values.namesLocalized.sort((a, b) =>
    a.language === firstLanguage ? -1 : b.language === firstLanguage ? 1 : 0,
  );

  return (
    <Flex direction={"column"} justify={"space-between"} h={"370px"}>
      <Accordion>
        {sortedLanguages.map((localizedName, index) => (
          <Accordion.Item
            key={localizedName.language}
            value={localizedName.language}
          >
            <Accordion.Control>
              <Text>
                <Text
                  span
                  c={Color.PrimaryText}
                  variant={TextVariant.Subheading}
                >
                  <Trans>Ime skupine sredstev</Trans>
                </Text>{" "}
                <Text span c={Color.SecondaryText} variant={TextVariant.Body}>
                  ({localizedName.language.toUpperCase()})
                </Text>
              </Text>
            </Accordion.Control>
            <Accordion.Panel>
              <TextInput
                label={t`Ednina`}
                {...form.getInputProps(`namesLocalized.${index}.nameSingular`)}
              />
              <Space h={"md"} />
              <TextInput
                label={t`Množina`}
                {...form.getInputProps(`namesLocalized.${index}.namePlural`)}
              />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>

      <Box pb={"lg"}>
        <Divider my={"md"} />

        <Flex justify={"center"} align={"center"} gap={"md"}>
          <Button variant="white" onClick={cancel}>
            <Trans>Prekliči</Trans>
          </Button>
          <Button
            onClick={() => {
              form.clearErrors();

              form.values.namesLocalized.forEach((_, index) => {
                form.validateField(`namesLocalized.${index}.nameSingular`);
                form.validateField(`namesLocalized.${index}.namePlural`);
              });

              if (!form.isValid()) {
                return;
              }

              save();
            }}
          >
            <Trans>Shrani</Trans>
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
