import { create } from "zustand";

type SidebarStore = {
  isOpen: boolean;
  toggle: (isOpen: boolean) => void;
};

export const useSidebarStore = create<SidebarStore>((set) => ({
  isOpen: false,
  toggle: (isOpen) => set({ isOpen }),
}));
