import {
  GetRedirectLink,
  GetRedirectRescheduleLink,
} from "../../../../../server/src/types";
import { get } from "../request-helpers";

export const getRedirectLink = (query: GetRedirectLink["query"]) => {
  return get<GetRedirectLink>("/public/form/redirect", query);
};

export const getRedirectRescheduleLink = (
  query: GetRedirectRescheduleLink["query"],
) => {
  return get<GetRedirectRescheduleLink>(
    "/public/form/redirect-reschedule",
    query,
  );
};
