import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { getLegalPages } from "@/utils";
import { Trans } from "@lingui/macro";
import { Anchor, Divider, Flex } from "@mantine/core";

const LegalPages = ({
  noTopMargin = false,
  bottomMargin = "0px",
  language,
}) => {
  const { termsAndConditionsUrl, privacyPolicyUrl } = getLegalPages({
    language,
  });

  return (
    <Flex gap={"md"}>
      <Anchor
        variant={TextVariant.Caption}
        c={Color.SecondaryText}
        href={"https://lime-booking.com/"}
      >
        © Lime Booking
      </Anchor>
      <Divider orientation="vertical" />
      <Anchor
        variant={TextVariant.Caption}
        c={Color.SecondaryText}
        href={privacyPolicyUrl}
      >
        <Trans>Politika zasebnosti</Trans>
      </Anchor>
      <Divider orientation="vertical" />
      <Anchor
        variant={TextVariant.Caption}
        c={Color.SecondaryText}
        href={termsAndConditionsUrl}
      >
        <Trans>Splošni pogoji</Trans>
      </Anchor>
    </Flex>
  );
};

export default LegalPages;
