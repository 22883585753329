import { api } from "@/lib/api-client";
import { Avatar, Flex, Table } from "@mantine/core";
import { useState } from "react";
import { ImageUploadView } from "../ImageUploadView";

export const ServicesTab = ({ clientId }: { clientId: number }) => {
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const { data: servicesData, refetch } = api.service.useGetServices({
    clientId,
    pagination: {
      page: 1,
      perPage: 1000,
    },
    includeDeleted: false,
  });

  const { mutateAsync: uploadImage } = api.admin.useUploadServiceImage();

  return (
    <>
      {selectedId ? (
        <ImageUploadView
          close={() => setSelectedId(null)}
          existingImage={
            servicesData?.services.find(
              (service) => service.serviceId === selectedId,
            )?.imageUrl
          }
          onUploadImage={async (formData) => {
            await uploadImage({
              body: formData,
              params: { clientId, serviceId: selectedId },
            });

            setSelectedId(null);
            refetch();
          }}
          defaultCenterCrop={true}
          aspect={1 / 1}
        />
      ) : (
        <Flex direction={"column"}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th w="15ch">ID</Table.Th>
                <Table.Th>Ime</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {servicesData?.services.map((s) => (
                <Table.Tr
                  key={s.serviceId}
                  onClick={() => setSelectedId(s.serviceId)}
                  style={{ cursor: "pointer" }}
                >
                  <Table.Td>
                    {s.imageUrl != null && <Avatar src={s.imageUrl}></Avatar>}
                  </Table.Td>
                  <Table.Td>{s.serviceId}</Table.Td>
                  <Table.Td>{s.name}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Flex>
      )}
    </>
  );
};
