import { Trans } from "@lingui/macro";
import {
  Checkbox,
  Popover,
  Space,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import {
  RiCheckboxCircleLine,
  RiFilterFill,
  RiFilterOffFill,
} from "react-icons/ri";
import styled from "styled-components";

const Filter = ({
  isFiltered,
  children,
}: {
  isFiltered?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Wrapper>
      <Popover trapFocus position={"bottom-end"} shadow={"md"}>
        <Popover.Target>
          <button className={"iconWrapper"}>
            {!isFiltered ? <RiFilterOffFill /> : <RiFilterFill />}
          </button>
        </Popover.Target>
        <Popover.Dropdown>
          <div className={"body"}>
            <Text ta={"left"} fw={500} size={"lg"} pl={"md"}>
              <Trans>Filter</Trans>
            </Text>
            <Space h={"sm"} />
            <div className={"columns-wrapper"}>{children}</div>
          </div>
        </Popover.Dropdown>
      </Popover>
    </Wrapper>
  );
};

const Column = ({
  allToggled,
  children,
}: {
  allToggled?: boolean;
  children: React.ReactNode;
}) => {
  return <ColumnWrapper allToggled={allToggled}>{children}</ColumnWrapper>;
};
Column.displayName = "FilterColumn";
Filter.Column = Column;

const ColumnHeader = ({
  onToggleAll,
  children,
  style,
}: {
  onToggleAll?: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <div className={"header"}>
      {children}
      {onToggleAll != null && (
        <UnstyledButton onClick={onToggleAll}>
          <RiCheckboxCircleLine />
          <span>
            <Trans>Izberi vse</Trans>
          </span>
        </UnstyledButton>
      )}
    </div>
  );
};
ColumnHeader.displayName = "FilterColumnHeader";
Filter.ColumnHeader = ColumnHeader;

const ColumnBody = ({
  data,
}: {
  data: {
    label: string;
    value: string;
    checked?: boolean;
    onChange?: (value: boolean) => void;
    color?: string;
  }[];
}) => {
  const theme = useMantineTheme();

  return (
    <div className={"body"}>
      {data.map((item) => (
        <StyledCheckbox
          key={item.label}
          label={item.label}
          checked={item.checked}
          onChange={() => item.onChange?.(!item.checked)}
          color={item.color ?? theme.colors.blue[5]}
          radius={"xl"}
          size={"md"}
        />
      ))}
    </div>
  );
};
ColumnBody.displayName = "FilterColumnBody";
Filter.ColumnBody = ColumnBody;

const StyledCheckbox = styled(Checkbox)`
  .mantine-Checkbox-input {
    background: ${(props) => props.color};
    border: none;
  }
`;

const ColumnWrapper = styled.div<{
  allToggled?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    width: 300px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    color: #969ba9;
  }

  button {
    background: ${({ allToggled }) => (allToggled ? "#EBF8EF" : "#F2F2F2")};
    padding: 0.5rem 1rem;
    border-radius: 20px;

    color: ${({ allToggled }) => (allToggled ? "#74B587" : "#949494")};
    font-weight: 500;

    flex-shrink: 0;

    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      font-size: 1.2rem;
    }

    span {
      font-size: 0.9rem;
    }
  }

  .body {
    background: #fafafa;
    border-radius: 4px;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    height: 250px;
    overflow-y: scroll;

    .mantine-Checkbox-input,
    .mantine-Checkbox-labelWrapper,
    .mantine-Checkbox-label {
      cursor: pointer;
    }

    .mantine-Checkbox-labelWrapper {
      width: 100%;
      text-align: left;
      cursor: pointer;

      overflow: hidden;
    }

    .mantine-Checkbox-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #6c7386;
    }
  }
`;

const Wrapper = styled.div`
  .mantine-Popover-dropdown {
    width: 100% !important;

    @media (min-width: 768px) {
      width: max-content !important;
    }
  }

  .body {
    min-width: 300px;

    .columns-wrapper {
      display: flex;
      gap: 1rem;

      flex-direction: column;
      @media (min-width: 768px) {
        flex-direction: row;
      }
    }
  }

  .iconWrapper {
    height: 40px;
    background: #fafafa;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    border: none;

    padding: 0;

    svg {
      color: #6c7b86;
      font-size: 1.3rem;
    }
  }

  .dropdownWrapper {
    display: flex;
    gap: 30px;
    max-height: 400px;
    overflow-y: hidden;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .mantine-Checkbox-labelWrapper {
      width: 100%;
      text-align: left;
      cursor: pointer;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 5px;
      min-width: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      width: 100%;
      padding: 5px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        position: sticky;
        top: 0;
        background: white;
        z-index: 2;

        .label {
          font-weight: 500;
        }
      }

      .mantine-Checkbox-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mantine-Checkbox {
      }
    }
  }
`;

export default Filter;
