import useAlertModal from "@/Components/Modals/useAlertModal";
import { api } from "@/lib/api-client";
import { t } from "@lingui/macro";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";

export const useNotificationSettings = () => {
  // API CALLS

  const { data, refetch } = api.notification.useGetNotificationSettings();

  const { data: billingData } = api.client.useBillingCycle({});

  const { mutateAsync: deleteNotificationSetting } =
    api.notification.useDeleteNotificationSetting();

  const { mutateAsync: toggleNotificationSetting } =
    api.notification.useToggleNotificationSetting();

  const { alertModal } = useAlertModal();

  useEffect(() => {
    if (
      location.pathname.includes("/new") ||
      location.pathname.includes("/edit")
    )
      return;

    refetch();
  }, [location.pathname]);

  const handleDeleteNotificationSetting = async (
    notificationSettingId: number,
  ) => {
    try {
      await deleteNotificationSetting(notificationSettingId);

      notifications.show({
        title: "Success",
        message: t`Obvestilo zbrisano`,
        color: "green",
      });

      refetch();
    } catch (error) {
      notifications.show({
        message: t`Napaka pri brisanju obvestila`,
        color: "red",
      });
    }
  };

  const openDeleteAlertModal = (notificationSettingId: number) => {
    alertModal(
      t`Brisanje obvestila`,
      t`Ali ste prepričani, da želite izbrisati obvestilo?`,
      { labelConfirm: t`Izbriši`, labelCancel: t`Prekliči` },
      () => handleDeleteNotificationSetting(notificationSettingId),
    );
  };

  const handleToggleNotificationSetting = async (
    notificationSettingId: number,
  ) => {
    try {
      const toggleResponse = await toggleNotificationSetting(
        notificationSettingId,
      );

      if (toggleResponse.error) throw new Error();

      notifications.show({
        message: t`Obvestilo ${
          toggleResponse.enabled ? "vklopljeno" : "izklopljeno"
        }`,
        color: "green",
      });

      refetch();
    } catch (error) {
      notifications.show({
        message: t`Napaka pri brisanju obvestila`,
        color: "red",
      });
    }
  };

  return {
    data,
    openDeleteAlertModal,
    handleToggleNotificationSetting,
    billingData,
  };
};
