import { modals } from "@mantine/modals";
import React from "react";

/**
 * @deprecated Use `useLimeAlertModal` hook instead
 */
const useAlertModal = () => {
  const alertModal = (
    title: string,
    children: React.ReactNode,
    {
      labelConfirm,
      labelCancel,
    }: { labelConfirm: string; labelCancel: string },
    onConfirm: () => void,
    onCancel?: () => void,
  ) =>
    modals.openConfirmModal({
      title: title,
      children: children,
      centered: true,
      groupProps: { align: "center" },
      closeButtonProps: {
        style: { border: "none", color: "red" },
        className: "close-button",
      },
      onConfirm: onConfirm,
      onCancel: onCancel,

      labels: { confirm: labelConfirm, cancel: labelCancel },
      withCloseButton: false,
    });

  return { alertModal };
};

export default useAlertModal;
