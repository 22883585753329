export enum Color {
  Primary = "var(--primary-color)",
  Secondary = "var(--secondary-color)",

  Brand = "var(--brand)",

  Success = "var(--success)",
  Error = "var(--error)",
  Background = "var(--background)",
  Border = "var(--border)",
  Disabled = "var(--disable)",
  Divider = "var(--divider)",
  PrimaryText = "var(--primary-text)",
  SecondaryText = "var(--secondary-text)",
  Yellow = "var(--yellow)",
  Warning = Yellow,
  TableHeader = "var(--table-header)",
  Neutral = "var(--neutral)",
}
