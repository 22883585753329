import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Divider, Flex, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

export const PieGraph = ({
  label,
  value,
  height,
  pieOuterRadius = 100,
  pieWidth = 30,
}: {
  label: string;
  value: number;
  height: number;
  pieOuterRadius?: number;
  pieWidth?: number;
}) => {
  const data = [
    { name: "asd", value, color: "#74b587" },
    { name: "all", value: 100 - value, color: "#D8D8D8" },
  ];

  const isMobile = useMediaQuery("(max-width: 768px)");

  const pieInnerRadius = pieOuterRadius - pieWidth;
  const innerLabel = (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={3}
      maw={"30%"}
      style={(_) => ({
        maxWidth: "30%",
        "@media (min-width: 550px)": {
          maxWidth: "20%",
        },
        "@media (min-width: 1000px)": {
          maxWidth: "50%",
        },
        "@media (min-width: 1400px)": {
          maxWidth: "30%",
        },
        "@media (min-width: 2350px)": {
          maxWidth: "20%",
        },
      })}
    >
      <Text
        variant={isMobile ? TextVariant.Body : TextVariant.Caption}
        ta="center"
        fw={500}
        lh={"16px"}
        c={Color.SecondaryText}
      >
        {" "}
        {label}
      </Text>
      <Divider w="90%"></Divider>
      <Text c="#548F68" size={"18px"} fw={600}>
        {value.toFixed(2)} %
      </Text>
    </Flex>
  );

  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <PieChart
          style={{
            fontSize: "12px",
          }}
        >
          <Pie
            startAngle={90}
            endAngle={-270}
            paddingAngle={0}
            outerRadius={pieOuterRadius}
            innerRadius={pieInnerRadius}
            dataKey={"value"}
            data={data}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Flex
        justify={"center"}
        align={"center"}
        top={0}
        left={0}
        w={"100%"}
        h={"100%"}
        pos={"absolute"}
      >
        {innerLabel}
      </Flex>
    </>
  );
};
